import { breakPoints } from 'src/theme/defaultTheme';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  display: flex;
  padding: 40px 48px;

  @media (max-width: ${breakPoints.mb3}px) {
    flex-direction: column;
    padding: 32px 19px;
  }
`;

export const StyledForm = styled.form`
  width: 400px;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${breakPoints.mb3}px) {
    width: auto;
    max-width: 400px;
    min-height: auto;
  }
`;

export const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
