import { useQuery } from '@tanstack/react-query';
import { AssignmentResponse } from 'src/models/service';
import { QUERY_KEYS } from 'src/enums';
import { getAssignment } from 'src/services/api';

const useAssignment = (homeworkId: string) => {
  const { data, isLoading, isError, isFetching, refetch } = useQuery<AssignmentResponse>(
    [QUERY_KEYS.ASSIGNMENT],
    () => getAssignment(homeworkId),
    {
      enabled: Boolean(homeworkId),
      refetchOnMount: true,
      cacheTime: 0, // Set cacheTime to 0 to disable cache
      staleTime: Infinity //
    }
  );
  return {
    data,
    isLoading,
    isError,
    isFetching,
    refetch
  };
};

export default useAssignment;
