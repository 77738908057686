import React from 'react';
import { Box, Grid } from '@mui/material';
import Footer from '../footer/Footer';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'src/context/authContext';
import Navigate from 'src/components/common/navigate';
import { useLanguage } from 'src/context/languageContext';
import LeftMenu from 'src/components/UI/leftMenu';
import UserCard from 'src/components/UI/profile/UserCard';
import { ReactComponent as CubeIcon } from 'src/assets/icons/cube.svg';

const AuthorizedLayout: React.FC = () => {
  const { isLoggedIn } = useAuth();
  const { locale } = useLanguage();
  const { pathname } = useLocation();

  if (!isLoggedIn) {
    if (pathname === `/${locale}/my-course`) return <Navigate to="/login" />;
    else return <Navigate to="" />;
  }

  return (
    <Grid display={'flex'}>
      <Box
        width={{ sm: '300px', md: '375px' }}
        display={{ xs: 'none', mb3: 'none', sm: 'flex' }}
        position={'fixed'}>
        <LeftMenu>
          <UserCard />
        </LeftMenu>
        <Box position="absolute" bottom={-8} right={0}>
          <CubeIcon />
        </Box>
      </Box>
      <Box
        marginLeft={{ sm: '300px', md: '375px' }}
        paddingRight={'5px'}
        flex={1}
        sx={{
          background: '#F8F8F8'
        }}>
        <Outlet />
      </Box>
      <Grid item xs={12} position={'fixed'} bottom={0} right={0} left={0} zIndex={100}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default AuthorizedLayout;
