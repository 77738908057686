import React, { FC, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Header from '../header/Header';
import TopHeader from '../topHeader';
import { Outlet } from 'react-router-dom';
import { useAuth } from 'src/context/authContext';
import Navigate from 'src/components/common/navigate';
import Footer from 'src/components/layouts/footer/Footer';
import useDetectDevice from 'src/hooks/useDetectDevice';

const PageLayout: FC = () => {
  const [over, setOver] = useState(false);
  const { isLoggedIn } = useAuth();

  const { isSmall } = useDetectDevice();

  useEffect(() => {
    const handleScroll = () => setOver(window.pageYOffset > 800);

    if (typeof window !== undefined && !isSmall) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
      setOver(false);
    };
  }, [isSmall]);

  if (isLoggedIn) return <Navigate to="/my-course/general" />;

  return (
    <Grid container minHeight="100vh" alignItems="flex-start">
      <Grid item xs={12}>
        <TopHeader />
        <Header className={over && 'over fixed'} />
      </Grid>
      <Grid item xs={12} alignSelf="flex-start" mt="100px">
        <Outlet />
      </Grid>
      <Grid item xs={12} alignSelf="flex-end">
        <Footer />
      </Grid>
    </Grid>
  );
};

export default PageLayout;
