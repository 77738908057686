import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'src/context/authContext';
import { QUERY_KEYS } from 'src/enums';
import { PositionedRating } from 'src/models/general';
import { KahootRatingResponse, RatingParams } from 'src/models/service';
import { getKahootRating } from 'src/services/api';
import { generateRating } from 'src/utils';
import useProfile from './useProfile';

const useRating = (params: RatingParams) => {
  const { isAdmin } = useAuth();
  const { data: user } = useProfile();
  const { firstName = '', lastName = '' } = user || {};

  const { data, isLoading, isError } = useQuery<KahootRatingResponse>(
    [QUERY_KEYS.RATING, params],
    () => getKahootRating(params),
    {
      enabled: Boolean(!isAdmin)
    }
  );

  const rating = data?.data || [];
  let scores: PositionedRating[] = [];
  const fullName = `${firstName.trim()}.${lastName.trim()}`;
  const index = rating.findIndex((item) => item.username.trim() === fullName);
  const studentInList = rating[index];
  scores = generateRating(index, rating, studentInList);
  return {
    data,
    scores,
    isLoading,
    isError
  };
};

export default useRating;
