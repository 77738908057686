import styled from 'styled-components';
import palette from 'src/theme/overrides/palette';
import { Box } from '@mui/material';
import { breakPoints } from 'src/theme/defaultTheme';

export const ProfileContainer = styled.div`
  width: 100%;
  gap: 48px;
  justify-content: center;
  background: white;
  margin-bottom: 180px;

  @media (max-width: ${breakPoints.sm}px) {
    justify-content: space-between;
    gap: 20px;
    min-height: unset;
  }
`;

export const UserCardContainer = styled.div`
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${breakPoints.sm}px) {
    min-width: 268px;
  }

  @media (max-width: ${breakPoints.mb3}px) {
    display: none;
  }
`;

export const UserActionsContainer = styled.div`
  min-width: 821px;
  border: 1.2px solid ${palette.primary.light};
  border-radius: 16px 16px 0px 0px;
  overflow: hidden;
  min-height: 600px;

  @media (max-width: ${breakPoints.m}px) {
    min-width: calc(100% - 381px);
  }

  @media (max-width: ${breakPoints.sm}px) {
    width: 100%;
    min-height: auto;
  }
`;

export const Head = styled.div`
  width: 100%;
  height: 67px;
  background-color: ${palette.text.primary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  position: relative;
  overflow: hidden;

  @media (max-width: ${breakPoints.mb2}px) {
    height: 56px;
    padding: 0 24px;
  }
`;

export const HeadBackground = styled.img`
  position: absolute;
  left: -10%;
  object-fit: cover;
  width: 120%;
  opacity: 0.1;
`;

export const ActionsCardInnerContainer = styled.div`
  padding: 56px 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (max-width: ${breakPoints.mb2}px) {
    padding: 40px 16px;
  }
`;

export const ContactCardContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const ContactCardContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const StyledIcon = styled.img`
  max-width: 32px;
`;

export const StyledLink = styled.a`
  cursor: pointer;
  display: flex;
  gap: 8px;
  font-size: 16px;
  margin-left: 11px;
  line-height: 25px;
  align-items: center;
  width: calc(100% - 16px);
  border-radius: 4px;
  transition: all 0.5s;
  padding: 8px 2px;
  justify-self: flex-end;
  position: absolute;
  bottom: 24px;
  left: 8px;

  & svg {
    max-width: 32px;
    fill: ${palette.primary.main};
  }

  &:hover {
    background-color: ${palette.lightBlue};
  }
`;

export const StyledLogout = styled.button`
  width: 320px;
  height: 56px;
  padding: 0 75px;
  margin-left: 26px;
  display: flex;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  align-items: center;
  border-radius: 8px;

  &:hover {
    background-color: #e9f2fc;
  }
  &.active {
    background-color: #e9f2fc;
    font-weight: bold;
  }

  @media (max-width: ${breakPoints.l + 'px'}) {
    height: 50px;
  }

  @media (max-width: ${breakPoints.md + 'px'}) {
    width: 300px;
    margin-left: 10px;
    border-radius: 0px;
  }
`;

export const StyledBox = styled(Box)`
  width: 110px;
  height: 110px;
  margin-left: 11px;
  margin-bottom: 32px;
  background-color: ${palette.primary.main};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  @media (max-width: ${breakPoints.l}px) {
    height: 100px;
    width: 100px;
    margin-bottom: 40px;
  }

  @media (max-height: 800px) {
    margin-top: -45px;
  }
`;

export const Avatar = styled.img`
  width: 110px;
  height: 110px;
  border-radius: 50%;
  object-fit: cover;

  @media (max-width: ${breakPoints.l}px) {
    height: 100px;
    width: 100px;
  }
`;

export const Navigation = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StatusBox = styled.div`
  display: flex;
  width: 65%;
  flex-direction: column;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 0 0 1px #dedede; /* this draws the table border  */
  @media (max-width: ${breakPoints.m}px) {
    flex-direction: row;
    width: 100%;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;

  @media (max-height: 780px) {
    margin-top: 10px;
  }
`;

export const FlexRow = styled.div`
  width: 100%;
  display: flex;
  @media (max-width: ${breakPoints.m}px) {
    flex-direction: column;

    p {
      font-size: 14px !important;
    }

    &:last-of-type {
      p {
        width: 100%;
        text-align: right;
      }
    }
  }
`;

export const FlexBox = styled.div`
  border: 0.5px solid ${palette.primary.light};
  height: 56px !important;
  display: flex;
  align-items: center;
  padding-left: 16px;

  &.fr {
    flex-basis: 40%;
  }
  &.sc {
    flex-basis: 35%;
  }
  &.th {
    flex-basis: 35%;
  }

  @media (max-width: ${breakPoints.m}px) {
    padding-right: 32px;
    &.fr,
    &.sc,
    &.th {
      flex-basis: unset;
      width: 100%;
    }
  }

  @media (max-width: ${breakPoints.mb2}px) {
    padding: 0 16px;
  }
`;

export const ImageWrap = styled.div`
  img {
    max-width: 50px;
  }
`;
