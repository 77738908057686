import React, {
  createContext,
  Dispatch,
  FC,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';

interface ModalProps {
  isOpen: boolean;
  title: string;
  form: ReactNode;
  locale?: string;
  action?: () => void;
}

export interface FormModalContextInterface {
  modalProps: ModalProps;
  setModalProps: Dispatch<SetStateAction<ModalProps>>;
  constructModal: (mdl: ModalProps) => void;
  resetModal: () => void;
}

export const FormModalContext = createContext<FormModalContextInterface | null>(null);

interface Props {
  children: ReactNode;
}

const FormModalContextProvider: FC<Props> = ({ children }): ReactElement => {
  const [modalProps, setModalProps] = useState<ModalProps>({
    isOpen: false,
    title: '',
    form: null
  });

  const constructModal = useCallback((modal: ModalProps) => {
    setModalProps(modal);
  }, []);

  const resetModal = useCallback(() => {
    setModalProps({
      isOpen: false,
      title: '',
      form: null
    });
  }, []);

  const contextValues = useMemo(
    () => ({
      modalProps,
      setModalProps,
      constructModal,
      resetModal
    }),
    [modalProps, setModalProps, constructModal, resetModal]
  );

  return <FormModalContext.Provider value={contextValues}>{children}</FormModalContext.Provider>;
};

export default FormModalContextProvider;

export const useFormModal = () => {
  const value = useContext(FormModalContext);

  if (!value) {
    throw new Error('Modal Context Provider is not defined');
  }
  return value;
};
