import styled from 'styled-components';
import { breakPoints } from 'src/theme/defaultTheme';

export const Container = styled.div`
  width: 450px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledForm = styled.form`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 24px;

  @media (max-height: 730px) {
    margin-bottom: 15px;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  @media (max-width: ${breakPoints.mb2}px) {
    flex-direction: column;
  }
  gap: 24px;
`;

export const Actions = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  @media (max-width: ${breakPoints.mb2}px) {
    flex-direction: column;
  }
`;
