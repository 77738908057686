import { Select, Stack, Typography } from '@mui/material';
import styled from '@mui/system/styled';

export const NavigationButton = styled('button')(({ theme }) => ({
  borderRadius: 40,
  backgroundColor: 'black',
  display: 'flex',
  border: 'none',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  cursor: 'pointer',
  ':disabled': {
    opacity: '0.7'
  }
}));

export const StyledButton = styled('button')(({ theme }) => ({
  paddingBlock: 18,
  paddingInline: 40,
  borderRadius: '28px',
  backgroundColor: '#01CE7C',
  display: 'flex',
  border: 'none',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  cursor: 'pointer',
  height: 56,
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('m')]: {
    display: 'none'
  }
}));

export const ResponsiveStyledButton = styled('button')(({ theme }) => ({
  paddingBlock: 18,
  paddingInline: 40,
  borderRadius: '28px',
  backgroundColor: '#01CE7C',
  display: 'flex',
  border: 'none',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  cursor: 'pointer',
  height: 48,
  width: 150,
  whiteSpace: 'nowrap'
}));

export const ViewSwitcher = styled(Select)(({ theme }) => ({
  borderRadius: 8,
  width: 120,
  height: 56,
  paddingLeft: '10px',
  color: 'black',
  backgroundColor: 'white',
  fontWeight: 500,
  fontSize: 18,
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#000'
  },

  [theme.breakpoints.down('m')]: {
    height: 44
  }
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down('m')]: {
    display: 'none'
  }
}));

export const StyledAddEvent = styled(Typography)(() => ({
  fontWeight: 500,
  color: 'inherit',
  fontSize: '16px',
  textTransform: 'uppercase',
  padding: '0'
}));
