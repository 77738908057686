import './App.css';
import React, { useCallback, useEffect } from 'react';
import styledTheme from './styledTheme';
import { decodeUserRole } from './utils';
import AppRouter from './routes/appRouter';
import { LOCALSTORAGE_KEYS, USER_ROLES } from './enums';
import ThemeWrapper from './theme/ThemeWrapper';
import { useAuth } from './context/authContext';
import { ThemeProvider } from 'styled-components';
import FormModal from './components/modals/FormModal';
import ScrollTop from './components/common/ScrollTop';
import { useLocation, useNavigate } from 'react-router-dom';
import FeedbackModal from './components/modals/FeedbackModal';
import FormModalContextProvider from './context/formModalContext';
import FeedbackModalContextProvider from './context/feedbackModalContext';
import { SKILLWILL_TARGET_ORIGIN } from './constants';

const App = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();
  const { pathname } = useLocation();
  const { token, setRole, lmsIframe } = useAuth();

  const sendMessageToIframe = useCallback(
    (access_token: string) => {
      if (lmsIframe && lmsIframe.current) {
        console.log('lmsIframe && lmsIframe.current');

        const iframeContentWindow = lmsIframe?.current?.contentWindow;
        iframeContentWindow?.postMessage({ token: access_token }, SKILLWILL_TARGET_ORIGIN);
      }
    },
    [lmsIframe]
  );

  useEffect(() => {
    if (pathname === '/') {
      const baseUrl = localStorage.getItem(LOCALSTORAGE_KEYS.LOCALE) || 'ge';

      navigate(baseUrl);
    }
  }, [navigate, pathname]);

  useEffect(() => {
    if (!isLoggedIn) {
      document.querySelector('#fb-root')?.classList.remove('vanish');
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (token) {
      const role = decodeUserRole(token);
      setRole(role);
      if (role === USER_ROLES.STUDENT) sendMessageToIframe(token);
    }
  }, [token, setRole, sendMessageToIframe]);

  return (
    <ThemeWrapper>
      <ThemeProvider theme={styledTheme}>
        <FeedbackModalContextProvider>
          <FormModalContextProvider>
            <FormModal />
            <FeedbackModal />
            <ScrollTop />
            <AppRouter />
          </FormModalContextProvider>
        </FeedbackModalContextProvider>
        <iframe
          ref={lmsIframe}
          id="skillwill-public-iframe"
          src={SKILLWILL_TARGET_ORIGIN}
          title="skillwill-public-domain"
          style={{
            visibility: 'hidden',
            height: 0,
            width: 0
          }}
        />
      </ThemeProvider>
    </ThemeWrapper>
  );
};

export default App;
