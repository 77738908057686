import React from 'react';
import { Box } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

// ExpandIcon is used for MUI Accordion

const ExpandIcon = () => {
  return (
    <Box
      sx={{
        '.Mui-expanded & > .collapsIconWrapper': {
          display: 'none'
        },
        '.expandIconWrapper': {
          display: 'none'
        },
        '.Mui-expanded & > .expandIconWrapper': {
          display: 'block'
        }
      }}>
      <Box className="expandIconWrapper">
        <RemoveIcon sx={{ marginTop: '15px' }} fontSize="medium" />
      </Box>
      <Box className="collapsIconWrapper">
        <AddIcon fontSize="medium" />
      </Box>
    </Box>
  );
};

export default ExpandIcon;
