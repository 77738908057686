import { QUERY_KEYS } from 'src/enums';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { removeNews } from 'src/services/api';

const useRemoveNews = () => {
  const client = useQueryClient();
  const { mutate, error, isLoading } = useMutation<void, AxiosError, { id: string }>(
    ['remove-news'],
    ({ id }) => removeNews(id),
    {
      onSuccess: () => {
        client.invalidateQueries([QUERY_KEYS.NEWS]);
      }
    }
  );

  return {
    mutate,
    isLoading,
    error
  };
};

export default useRemoveNews;
