import React from 'react';
import { useParams } from 'react-router-dom';
import Loader from 'src/components/common/Loader';
import { COURSE_STATUS } from 'src/enums';
import useSyllabus from 'src/hooks/queries/useSyllabus';
import CourseDetails from '../courseDetails';

const SpecificChapter = () => {
  const { chapterId } = useParams<{ chapterId: string }>();

  const {
    data,
    isLoading: loading,
    isError
  } = useSyllabus({
    status: COURSE_STATUS.SPECIFIC,
    chapter_id: chapterId
  });

  if (loading) {
    return <Loader />;
  }

  if (isError) {
    return <div>Chapter not found</div>;
  }
  return <CourseDetails months={data} />;
};

export default SpecificChapter;
