import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import React, { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFeedbackModal } from 'src/context/feedbackModalContext';
import { ErrorMessages, Month, Subject, Week } from 'src/models/service';
import Loader from 'src/components/common/Loader';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useMonth from 'src/hooks/queries/useMonth';
import useUpdateHomework from 'src/hooks/mutations/useUpdateHomework';
import { useLanguage } from 'src/context/languageContext';
import useAssignment from 'src/hooks/queries/useAssignment';
import { actualDate } from 'src/utils';
import { ReactComponent as PlusIcon } from 'src/assets/icons/addPlus.svg';
import ArrowBack from '@mui/icons-material/ArrowBack';
import useCategories from 'src/hooks/queries/useCategories';

const AssignmentEditForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutate, isLoading } = useUpdateHomework();
  const { locale } = useLanguage();
  const { constructModal } = useFeedbackModal();
  const [errors] = useState<ErrorMessages | null>(null);
  const ref = createRef<HTMLInputElement>();
  const [file, setFile] = useState<File | null>();
  const { homeworkId } = useParams();
  const [search] = useSearchParams();
  const { data: homework } = useAssignment(homeworkId!);
  const { data } = useMonth(search.get('course')!, 'general');
  const qs = new URLSearchParams(window.location.search);
  const section = qs.get('section');

  const { categories = [] } = useCategories();

  const [week, setWeek] = useState<number | null | undefined>(null);
  const [month, setMonth] = useState<number | null>(null);

  interface FormData {
    title: string | null;
    description: string | null;
    attachment: File | null;
    points: number;
    due_date: Date | null;
    status: string | null;
    subject_id: number | null;
    estimation: string;
    category: number[] | undefined;
  }

  const formik = useFormik<FormData>({
    initialValues: {
      title: null,
      description: null,
      attachment: null,
      points: 0,
      due_date: null,
      status: null,
      subject_id: null,
      estimation: '',
      category: homework?.homework?.evaluation_criteria?.map((crt) => crt.id) || []
    },
    validateOnMount: true,
    onSubmit: async ({
      title,
      description,
      attachment,
      points,
      status,
      due_date,
      subject_id,
      estimation,
      category
    }) => {
      mutate(
        {
          title,
          description,
          attachment,
          points,
          status,
          due_date,
          subject_id,
          chapter_id: homeworkId!,
          estimation,
          evaluation_criteria: category
        },
        {
          onSuccess: () => {
            navigate(`/${locale}/assignments/${search.get('course')!}`);
          },
          onError: () => {
            constructModal({
              title: t('common.error'),
              btnText: t('modal.close'),
              type: 'error',
              subtitle: t('errors.networkError')
            });
          }
        }
      );
    },
    validateOnBlur: false,
    validateOnChange: true,
    enableReinitialize: true
  });

  const weekId = homework?.homework?.subjectWithRelations.week_id;
  const defMonth = data?.data?.find((d: Month) => d?.week?.find((w) => w.id === weekId));
  const defWeek = defMonth?.week?.find((w: Week) => w.id === weekId);

  useEffect(() => {
    if (file && file instanceof File) {
      formik.setFieldValue('attachment', file);
    }
  }, [file]); // eslint-disable-line

  if (isLoading) {
    return (
      <Grid container justifyContent="center" maxWidth="650px" height="550px" margin="auto">
        <Loader />;
      </Grid>
    );
  }
  return (
    <>
      {homework && (
        <form onSubmit={formik.handleSubmit}>
          <Grid>
            <Grid>
              <Box
                padding="24px 60px 60px 24px"
                sx={{
                  background: '#fff',
                  borderBottomLeftRadius: 8,
                  borderBottomRightRadius: 8,
                  border: '1px solid #DEDEDE'
                }}>
                <Grid
                  gap={1}
                  container
                  alignItems="center"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => navigate(-1)}>
                  <ArrowBack sx={{ color: '#2A7DE1' }} />
                  <Typography variant="body1" color="violet" fontSize={16}>
                    {t('careerCare.backToList')}
                  </Typography>
                </Grid>
                <Typography variant="body1" fontSize={20} mt={4} mb={2}>
                  {t('labels.assignment')}
                </Typography>
                <Divider />
                {/* <Grid container mt={'24px'} justifyContent={{ xs: 'center' }}>
                  <Grid item sm={8} xs={12}>
                    <TextField
                      name="title"
                      variant="outlined"
                      onBlur={formik.handleBlur}
                      label={t('labels.title')}
                      onChange={formik.handleChange}
                      defaultValue={homework?.homework.title}
                      helperText={formik.touched.title && formik.errors.title}
                      error={formik.touched.title && Boolean(formik.errors.title)}
                      FormHelperTextProps={{
                        error: formik.touched.title && Boolean(formik.errors.title || errors?.title)
                      }}
                    />
                  </Grid>
                </Grid> */}
                <Grid container alignItems="center" rowGap={2} mt={6} columnSpacing={1}>
                  <Grid item xs={4}>
                    <TextField
                      name="description"
                      onBlur={formik.handleBlur}
                      label={t('labels.description')}
                      onChange={formik.handleChange}
                      defaultValue={homework?.homework.description}
                      helperText={formik.touched.description && formik.errors.description}
                      error={formik.touched.description && Boolean(formik.errors.description)}
                      FormHelperTextProps={{
                        error:
                          formik.touched.description &&
                          Boolean(formik.errors.description || errors?.description)
                      }}
                      sx={{ height: '51px' }}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      name="estimation"
                      variant="outlined"
                      sx={{ marginTop: '28px' }}
                      label={t('labels.estimation')}
                      onChange={formik.handleChange}
                      value={formik.values.estimation || homework?.homework?.estimate_time}
                      helperText={'Format: 1m, 2h, 3d'}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <FormControl>
                      <InputLabel>{t('labels.category')}</InputLabel>
                      <Select
                        multiple
                        name="category"
                        value={formik.values.category}
                        sx={{ height: '51px' }}
                        variant="outlined"
                        label={t('labels.category')}
                        onChange={formik.handleChange}>
                        {categories.map((ctgr: any) => {
                          return (
                            <MenuItem key={ctgr} value={ctgr.id}>
                              {ctgr.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={4} position={'relative'}>
                    {!month && (
                      <Typography
                        color={defMonth ? 'black' : '#b4b4b4'}
                        fontSize={'16px'}
                        fontWeight={400}
                        sx={{
                          position: 'absolute',
                          top: '16px',
                          left: '16px',
                          zIndex: '100',
                          pointerEvents: 'none'
                        }}>
                        {defMonth ? `Month ${defMonth.month}` : 'Select a month'}
                      </Typography>
                    )}
                    <FormControl>
                      {/* <InputLabel id="month">{t('labels.chooseMonth')}</InputLabel> */}
                      <Select
                        name="month"
                        sx={{ height: '51px' }}
                        value={month}
                        onChange={(e) => {
                          setMonth(Number(e.target.value));
                          setWeek(undefined);
                        }}>
                        {data?.data.map((m: Month) => (
                          <MenuItem key={m.id} value={m.id}>
                            Month {m.month}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {(month || section === 'edit') && (
                    <Grid item xs={4} position={'relative'}>
                      {week === null && (
                        <Typography
                          color={defWeek ? 'black' : '#b4b4b4'}
                          fontSize={'16px'}
                          fontWeight={400}
                          sx={{
                            position: 'absolute',
                            top: '16px',
                            left: '16px',
                            zIndex: '100',
                            pointerEvents: 'none'
                          }}>
                          {defWeek ? `Week ${defWeek.title}` : 'Select a week'}
                        </Typography>
                      )}

                      {week === undefined && (
                        <Typography
                          color={defWeek ? 'black' : '#b4b4b4'}
                          fontSize={'16px'}
                          fontWeight={400}
                          sx={{
                            position: 'absolute',
                            top: '16px',
                            left: '16px',
                            zIndex: '100',
                            pointerEvents: 'none'
                          }}>
                          {'Select a week'}
                        </Typography>
                      )}
                      <FormControl>
                        {/* <InputLabel id="week">{t('labels.week')}</InputLabel> */}
                        <Select
                          name="week"
                          // label={t('labels.week')}
                          sx={{ height: '51px' }}
                          value={week}
                          onChange={(e) => setWeek(Number(e.target.value))}>
                          {data?.data
                            .find((m: Month) => m.id === month)
                            ?.week.map((w: Week) => (
                              <MenuItem key={w.id} value={w.id}>
                                week {w.title}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}

                  <Grid item xs={4} sx={{ position: 'relative' }}>
                    {homework?.homework.subject && !formik.values.subject_id && (
                      <Typography
                        fontSize={'16px'}
                        fontWeight={400}
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        sx={{
                          position: 'absolute',
                          top: '16px',
                          left: '16px',
                          zIndex: '100',
                          maxWidth: '85%'
                        }}>
                        {homework?.homework.subject}
                      </Typography>
                    )}

                    {!homework?.homework.subject && !formik.values.subject_id && (
                      <Typography
                        color={'#b4b4b4'}
                        fontSize={'16px'}
                        fontWeight={400}
                        sx={{ position: 'absolute', top: '16px', left: '16px', zIndex: '100' }}>
                        Select a subject:
                      </Typography>
                    )}

                    <FormControl>
                      {/* <InputLabel id="subject_id">{t('labels.subject')}</InputLabel> */}
                      <Select
                        name="subject_id"
                        variant="outlined"
                        onBlur={formik.handleBlur}
                        // label={t('labels.subject')}
                        onChange={formik.handleChange}
                        // value={formik.values.subject_id}
                        sx={{ height: '51px' }}>
                        {week &&
                          data?.data
                            .find((m: Month) => m.id === month)
                            .week.find((w: Week) => w.id === week)
                            .subjects.map((s: Subject) => (
                              <MenuItem key={s.id} value={s.id}>
                                {s.title}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={4} position={'relative'}>
                    {homework?.homework.due_date && !formik.values.due_date && (
                      <Typography
                        fontSize={'16px'}
                        fontWeight={400}
                        sx={{
                          position: 'absolute',
                          top: '16px',
                          left: '16px',
                          zIndex: '100',
                          pointerEvents: 'none',
                          background: 'white'
                        }}>
                        {homework &&
                          actualDate(homework?.homework.due_date).split(' ')[0] + ' 23:59:00'}
                      </Typography>
                    )}

                    {formik.values.due_date && (
                      <Button
                        onClick={() => formik.setFieldValue('due_date', null)}
                        sx={{
                          position: 'absolute',
                          color: 'crimson',
                          right: '-65px',
                          width: '10%'
                        }}>
                        <CloseIcon />
                      </Button>
                    )}

                    <TextField
                      type="date"
                      name="due_date"
                      sx={{ height: '51px' }}
                      variant="outlined"
                      label={t('labels.due_date')}
                      onChange={formik.handleChange}
                      value={formik.values.due_date as Date}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={4} position={'relative'} sx={{ textAlign: 'start' }}>
                    {!formik.values.status ||
                      (!homework?.homework.status && (
                        <Typography
                          color={'#b4b4b4'}
                          fontSize={'16px'}
                          fontWeight={400}
                          sx={{
                            position: 'absolute',
                            top: '16px',
                            left: '16px',
                            zIndex: '100',
                            pointerEvents: 'none'
                          }}>
                          Select status
                        </Typography>
                      ))}

                    <FormControl>
                      {/* <InputLabel id="status">{t('labels.status')}</InputLabel> */}
                      <Select
                        name="status"
                        variant="outlined"
                        // label={t('labels.status')}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        defaultValue={homework?.homework.status}
                        error={formik.touched.status && Boolean(formik.errors.status)}
                        sx={{
                          textAlign: 'start',
                          borderRadius: '8px',
                          height: '51px',
                          border:
                            formik.touched.status && Boolean(formik.errors.status)
                              ? '1px solid red'
                              : ''
                        }}>
                        <MenuItem value={'active'}>active</MenuItem>
                        <MenuItem value={'finished'}>finished</MenuItem>
                      </Select>
                    </FormControl>

                    {formik.touched.status && Boolean(formik.errors.status) && (
                      <small style={{ paddingLeft: '15px', color: '#F73E48' }}>
                        Status is required
                      </small>
                    )}
                  </Grid>
                  <Grid mt={6} item xs={12}>
                    <a target="_blank" rel="noreferrer" href={homework?.homework.attachment}>
                      <Typography fontWeight="normal" color="violet">
                        {t('labels.attached_file')} - {homework?.homework?.title}
                      </Typography>
                    </a>
                    <Grid display="flex" alignItems="center" gap={2} mt={2}>
                      <Button
                        onClick={() => {
                          if (!file) {
                            ref.current?.click();
                          }
                        }}
                        variant="filled">
                        <input
                          ref={ref}
                          type="file"
                          hidden
                          onChange={(e) => {
                            if (e.target.files) {
                              setFile(e.target.files[0]);
                            }
                          }}
                        />
                        <Typography
                          fontSize={16}
                          color="darkblue"
                          fontWeight={450}
                          textTransform="uppercase">
                          {t('button.upFile')}
                          <PlusIcon />
                        </Typography>
                      </Button>
                      {file && (
                        <Box display="flex" gap="6px" alignItems="center">
                          <Typography variant="listItem" fontSize={16} color="black">
                            {file.name.substring(0, 20)}
                          </Typography>
                          <CloseIcon
                            sx={{ cursor: 'pointer', background: '#00000029', fontSize: 20 }}
                            onClick={() => {
                              setFile(null);
                            }}
                          />
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Box
                  mt={15}
                  display="flex"
                  gap={{ sm: 2, xs: 0.5 }}
                  justifyContent={{ sm: 'flex-end', xs: 'center' }}
                  alignItems="center">
                  <Button
                    type="submit"
                    variant="primary"
                    sx={{
                      height: '56px !important',
                      minWidth: '170px'
                    }}>
                    <Typography fontSize={'16px !important'} mr={1.5}>
                      {t('button.submit')}
                    </Typography>
                  </Button>

                  <Button sx={{ height: '54px' }} variant="secondary">
                    <Typography fontSize={'15px'} mr={1.5}>
                      {t('button.cancel')}
                    </Typography>
                  </Button>
                </Box>

                {/* <Grid container mt={'24px'} justifyContent={{ xs: 'center' }}>
                  <Grid item xs={8}>
                    <Button
                      type="submit"
                      onClick={() => {
                        formik.submitForm();
                      }}
                      variant="containedPrimary"
                      sx={{
                        border: '1px solid #D5D2F3',
                        borderRadius: '8px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#5946D4'
                      }}
                      fullWidth>
                      <Typography
                        color="white"
                        fontWeight="400"
                        textTransform="uppercase"
                        fontSize={{ xs: 16, l: 18 }}>
                        {t('button.submit')}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid> */}
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
};

export default AssignmentEditForm;
