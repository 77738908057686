import { Typography } from '@mui/material';
import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useLanguage } from 'src/context/languageContext';
import { breakPoints } from 'src/theme/defaultTheme';
import styled from 'styled-components';

interface Props {
  title: string;
  to: string;
  icon: any;
  active: string[];
  clickable?: boolean;
  clickHandler?: () => void;
}

const StyledLink = styled(Link)<{ clickable?: boolean }>`
  width: 320px;
  height: 55px;
  padding-left: 75px;
  margin-left: 26px;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  border-radius: 8px;

  svg {
    opacity: ${(props) => (props.clickable ? 1 : 0.7)};
  }

  p {
    opacity: ${(props) => (props.clickable ? 1 : 0.7)};
  }

  &.active {
    background-color: #e9f2fc;
    font-weight: bold;

    p {
      font-weight: bold;
    }
  }

  @media (max-width: ${breakPoints.l + 'px'}) {
    height: 50px;
  }

  @media (max-width: ${breakPoints.md + 'px'}) {
    width: 260px;
    margin-left: 26px;
    padding-left: 55px;
  }

  @media (max-height: 780px) {
    height: 40px;
  }
`;

const hideOverflowText = {
  clear: 'both',
  display: 'inline-block',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
};

const CustomNavLink: FC<Props> = ({ title, to, icon, active, clickable, clickHandler }) => {
  const router = useLocation();
  const { locale } = useLanguage();
  const isActive = active.some((act) => act === `/${router.pathname.split('/')[2]}`);

  return (
    <StyledLink
      to={`/${locale}${to}`}
      clickable={clickable}
      onClick={(e) => {
        clickHandler && clickHandler();
        if (!clickable || clickHandler) e.preventDefault();
      }}
      className={isActive ? 'active' : ''}>
      {icon}
      <Typography
        sx={hideOverflowText}
        fontWeight={'regular'}
        fontSize={{ md: '18px', l: '20px' }}
        ml={2}>
        {title}
      </Typography>
    </StyledLink>
  );
};

export default CustomNavLink;
