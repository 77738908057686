import styled from 'styled-components';

export const TagTrashBox = styled.button`
  border: 1px solid #afa8ef;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  fill: crimson;

  &:hover {
    border: 1px solid black;
    cursor: pointer;
  }

  @media (max-width: 500px) {
    width: 40px;
  }
`;
