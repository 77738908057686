import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { COURSE_STATUS } from 'src/enums';
import CourseDetails from '../courseDetails';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/context/authContext';
import { useMutation } from '@tanstack/react-query';
import useRating from 'src/hooks/queries/useRating';
import { readWelcomeMessage } from 'src/services/api';
import SpecCard from 'src/components/common/SpecCard';
import useProfile from 'src/hooks/queries/useProfile';
import Navigate from 'src/components/common/navigate';
import useQueryString from 'src/hooks/useQueryString';
import useSyllabus from 'src/hooks/queries/useSyllabus';
import useDetectDevice from 'src/hooks/useDetectDevice';
import { useNavigate } from 'src/context/languageContext';
import { useFeedbackModal } from 'src/context/feedbackModalContext';
import useExtendedCourses from 'src/hooks/queries/useExtendedCourses';

const ModifyGeneral = () => {
  const { isAdmin } = useAuth();
  const { t } = useTranslation();
  const { isMobile } = useDetectDevice();
  const { constructModal } = useFeedbackModal();

  const { data: user, myCourses = [] } = useProfile();
  const { showWelcomeMessage = false } = user || {};
  const { mutate: readMessage } = useMutation(readWelcomeMessage);

  // useEffect(() => {
  //   if (showWelcomeMessage) {
  //     constructModal({
  //       btnText: t('modal.close'),
  //       title: t('common.success'),
  //       action: () => readMessage(),
  //       subtitle: t('modal.welcomeMessage')
  //     });
  //   }
  // }, [t, constructModal, showWelcomeMessage, readMessage]);

  const navigate = useNavigate();
  const { parseQuery } = useQueryString();
  const { chapterId = '', course = '' } = parseQuery();
  const { courses } = useExtendedCourses();

  const courseIdx = isMobile && myCourses?.length > 1 ? 1 : 0;

  const activeCourse = myCourses?.find((crs: any) => crs?.courseId.toString() === course);
  const groupId = activeCourse?.groupId || myCourses?.[courseIdx]?.groupId;

  const { data } = useSyllabus({
    status: COURSE_STATUS.GENERAL,
    ...(chapterId && isAdmin
      ? { chapter_id: chapterId.toString() }
      : { chapter_id: course.toString() || myCourses[courseIdx]?.courseId.toString() })
  });

  const { scores } = useRating({ group_id: groupId });

  const hiddenIds = [2, 3, 4, 5, 6, 7, 8, 9, 10];

  if (!isAdmin && !user?.myCourses[0]?.courseId) return <Navigate to="/profile" />;

  if (isAdmin && !chapterId) {
    return (
      <Grid container spacing={2} justifyContent={{ sm: 'flex-start', xs: 'center' }}>
        {courses
          ?.filter((cr) => !hiddenIds.includes(cr.id))
          .map((spec: any) => {
            return (
              <Grid
                item
                key={spec.id}
                width={{ xs: 160, mb2: 276 }}
                maxHeight={{ xs: 270, mb2: 333 }}>
                <SpecCard
                  isGeneral
                  spec={spec}
                  clickHandler={() => {
                    navigate(`/my-course/general?chapterId=${spec.id}`);
                  }}
                />
              </Grid>
            );
          })}
      </Grid>
    );
  }

  return <CourseDetails months={data} rating={scores} />;
};

export default ModifyGeneral;
