import { AxiosError } from 'axios';
import { QUERY_KEYS } from 'src/enums';
import { addCriteria } from 'src/services/api';
import { AddCriteriaPayload } from 'src/models/service';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const useAddCriteria = (reset: () => void) => {
  const client = useQueryClient();

  const { mutate: addCriteriaMutation, isLoading } = useMutation<
    { success?: boolean },
    AxiosError,
    AddCriteriaPayload
  >((payload) => addCriteria(payload), {
    onSettled: () => {
      reset();
      client.invalidateQueries([QUERY_KEYS.CRITERIA]);
    }
  });

  return {
    isLoading,
    addCriteriaMutation
  };
};

export default useAddCriteria;
