import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { QUERY_KEYS } from 'src/enums';
import { EditSubjectPayload } from 'src/models/service';
import { updateSubjectTitle } from 'src/services/api';

const useUpdateSubject = () => {
  const client = useQueryClient();
  const { mutate, isLoading } = useMutation<void, AxiosError, { subjectId: number; payload: any }>(
    ['edit-subject'],
    ({ subjectId, payload }) => updateSubjectTitle(subjectId, payload),
    {
      onSettled: () => {
        client.invalidateQueries([QUERY_KEYS.SYLLABUS]);
      }
    }
  );

  return {
    mutate,
    isLoading
  };
};

export default useUpdateSubject;
