import { QUERY_KEYS } from 'src/enums';
import { addTag } from 'src/services/api';
import { AddTagPayload } from 'src/models/service';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

const useAddTag = () => {
  const client = useQueryClient();
  const { mutate, error, isLoading } = useMutation<void, AxiosError, AddTagPayload>(
    ['add-tag'],
    (payload: AddTagPayload) => addTag(payload),
    {
      onSuccess: () => {
        client.invalidateQueries([QUERY_KEYS.TAGS]);
      }
    }
  );

  return {
    mutate,
    isLoading,
    error
  };
};

export default useAddTag;
