import Grid from '@mui/material/Grid';
import React, { FC, useState } from 'react';
import SpecCard from 'src/components/common/SpecCard';
import { Button, Stack, Typography } from '@mui/material';
import useDetectDevice from 'src/hooks/useDetectDevice';
import { registerUserOnSubGroup } from 'src/services/api';
import { RegisterOnSubCoursePayload, SubCourseInfo } from 'src/models/service';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { QUERY_KEYS } from 'src/enums';
import useUserCourse from 'src/hooks/queries/useUserCourse';
import { useNavigate } from 'src/context/languageContext';

interface Props {
  specs: SubCourseInfo[];
  isEditing?: boolean;
  selectedId?: number;
  daysLeft: number;
}

const ModifySpecialization: FC<Props> = ({ specs, selectedId, daysLeft, isEditing = false }) => {
  const navigate = useNavigate();
  const [choosedSpec, setChoosedSpec] = useState<SubCourseInfo | null>(null);
  const { isMobile } = useDetectDevice();
  const { data: course } = useUserCourse();
  const { groups: [{ id: initGroupId = 0 } = {}] = [] } = course || {};

  const client = useQueryClient();
  const { mutate } = useMutation<void, AxiosError, RegisterOnSubCoursePayload>(
    ['regsiter-subgroup'],
    (payload) => registerUserOnSubGroup(payload)
  );

  const handleSpecChange = (spec: SubCourseInfo) => {
    spec.id !== choosedSpec?.id ? setChoosedSpec(spec) : setChoosedSpec(null);
  };

  const handleSaveSpec = async () => {
    const subGroupId = choosedSpec?.subGroups[0]?.id;
    if (!subGroupId || !initGroupId) {
      return;
    }
    mutate(
      {
        subGroupId,
        groupId: initGroupId
      },
      {
        onSuccess: () => {
          navigate('/my-course/specific');
          client.invalidateQueries([QUERY_KEYS.PROFILE]);
        }
      }
    );
  };
  const { t } = useTranslation();

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Grid container justifyContent="center" pt={{ xs: 4, l: 6 }}>
        <Grid item xs={12} px={{ xs: 3, mb2: 17 }} pb={{ xs: 5, l: 11 }}>
          <Grid container justifyContent="center" columnSpacing={3} rowSpacing={2}>
            <Grid item xs={12} pb={{ xs: 4, l: 5 }}>
              <Typography fontWeight={400} fontSize={{ xs: 14, mb2: 18 }} textAlign="center">
                {t('course.chooseText')}
              </Typography>
            </Grid>

            {specs.map((spec) => (
              <Grid key={spec.id} item width={{ xs: 160, mb2: 276 }} height={{ xs: 270, mb2: 333 }}>
                <SpecCard
                  spec={spec}
                  isSelected={
                    choosedSpec?.id
                      ? spec.id === choosedSpec?.id
                      : spec?.subGroups[0]?.id === selectedId
                  }
                  clickHandler={handleSpecChange}
                />
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} paddingTop={{ xs: 2, mb2: 8, l: 13 }}>
            <Grid container justifyContent="space-between" alignItems="center">
              {isMobile ? (
                <Typography
                  paddingX={3}
                  textAlign="center"
                  paddingBottom={{ xs: 3, mb2: 0 }}
                  variant="subtitle2">
                  {`სპეციალიზაციის არჩევამდე დარჩენილია: ${daysLeft} დღე`}
                </Typography>
              ) : (
                <Grid item>
                  <Stack direction="row" spacing={2}>
                    <Typography>
                      {isEditing ? t('course.daysBeforeChange') : t('course.daysLeft')}
                    </Typography>
                    <Typography color="primary">{t('course.days', { count: daysLeft })}</Typography>
                  </Stack>
                </Grid>
              )}
              <Grid item minWidth={{ xs: 220, l: 408 }} width={{ xs: '100%', mb2: 'auto' }}>
                <Button fullWidth variant="containedPrimary" onClick={handleSaveSpec}>
                  {t('common.save')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default ModifySpecialization;
