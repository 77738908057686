import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'src/enums';
import { SylabussParams } from 'src/models/service';
import { getStudentCourse } from 'src/services/api';
import useLanguageCode from '../useLanguageCode';
import useQueryString from 'src/hooks/useQueryString';

const useSyllabus = (params: SylabussParams, isEnabled = true) => {
  const { lang } = useLanguageCode();
  const { parseQuery } = useQueryString();
  const { chapterId = '' } = parseQuery();

  const { data, isLoading, isError } = useQuery(
    [QUERY_KEYS.SYLLABUS, params, lang, chapterId],
    () => getStudentCourse(params, lang),
    {
      enabled: isEnabled
    }
  );

  return {
    data: data?.data || [],
    isLoading,
    isError
  };
};

export default useSyllabus;
