import { CardContent, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC, memo } from 'react';
import { generateNextStatus, generateStatusByCourse } from 'src/utils';
import { freshman, wizz, rookie, pro, newbie, jedi } from 'src/assets/images';
import { useTranslation } from 'react-i18next';
import { PositionedRating } from 'src/models/general';
import useProfile from 'src/hooks/queries/useProfile';
import useQueryString from 'src/hooks/useQueryString';

interface RankingStatusProps {
  list: PositionedRating[];
}

const getStatusImage = (status: string) => {
  let src;

  switch (status) {
    case 'Newbie':
      src = newbie;
      break;
    case 'Freshman':
      src = freshman;
      break;
    case 'Rookie':
      src = rookie;
      break;
    case 'Wizz':
      src = wizz;
      break;
    case 'Pro':
      src = pro;
      break;
    case 'Jedi':
      src = jedi;
      break;
    default:
      src = freshman;
  }

  return src;
};

const RankingStatus: FC<RankingStatusProps> = ({ list = [] }) => {
  const { t } = useTranslation();

  const { data: user, myCourses = [] } = useProfile();
  const { firstName = '', lastName = '' } = user || {};

  const { parseQuery } = useQueryString();
  const { course = '' } = parseQuery();

  const courseId = course || myCourses[0]?.courseId;

  const score =
    list.find((val) => `${firstName.trim()}.${lastName.trim()}` === val.username.trim())
      ?.total_score ?? 0;

  const currStatus = generateStatusByCourse(Number(courseId), score);
  const nextStatus = generateNextStatus(Number(courseId), currStatus);

  return (
    <Box mt={3}>
      <CardContent sx={{ border: 'none !important', background: '#fff' }}>
        <Box px={3} pt={2} display="flex" justifyContent="center">
          <img alt={`${currStatus}-logo`} src={getStatusImage(currStatus)} />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" gap={0.8} py={2} px={3}>
          <Typography fontSize={14} variant="h6">
            {t('course.scoreCount', { score })}
          </Typography>
          <Typography fontSize={14}>
            {t('course.ratingSubtitle')} <strong>{currStatus}</strong>
          </Typography>
          {nextStatus && (
            <Typography fontSize={12} color="gray" textAlign="center">
              {t('course.newStatusTitle')} {nextStatus}!
            </Typography>
          )}
        </Box>
      </CardContent>
    </Box>
  );
};

export default memo(RankingStatus);
