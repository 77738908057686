import { QUERY_KEYS } from 'src/enums';
import { useQuery } from '@tanstack/react-query';
import useLanguageCode from '../useLanguageCode';
import { useAuth } from 'src/context/authContext';
import { getCurrentSubject } from 'src/services/api';
import useQueryString from 'src/hooks/useQueryString';

const useCurrentSubject = () => {
  const { isAdmin } = useAuth();
  const { lang } = useLanguageCode();
  const { parseQuery } = useQueryString();
  const { course = '' } = parseQuery();

  const { data, isLoading } = useQuery(
    [QUERY_KEYS.CURRENT, course],
    () => getCurrentSubject(lang),
    {
      enabled: !isAdmin
    }
  );

  return {
    data,
    activeSubject: data?.subject,
    isLoading
  };
};

export default useCurrentSubject;
