import { QUERY_KEYS } from 'src/enums';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'src/context/authContext';
import { getCareerContent } from 'src/services/api';
import useLanguageCode from 'src/hooks/useLanguageCode';

const useCareerContent = () => {
  const { isAdmin } = useAuth();
  const { langCode } = useLanguageCode();

  const { data, isLoading, isError } = useQuery(
    [QUERY_KEYS.CAREER_CONTENT, langCode],
    () => getCareerContent(null, langCode),
    { enabled: isAdmin }
  );

  return {
    isError,
    isLoading,
    contents: data?.contents
  };
};

export default useCareerContent;
