import { Grid } from '@mui/material';
import styled from 'styled-components';
import palette from 'src/theme/overrides/palette';
import { breakPoints } from 'src/theme/defaultTheme';
import { mainCover, mobileMainCover } from 'src/assets/images';

export const Wrapper = styled(Grid)`
  min-height: 90vh;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${mainCover});

  @media (max-width: ${breakPoints.sm}px) {
    margin: 0;
    min-height: 100vh;
    background-position-x: center;
    background-image: url(${mobileMainCover});
  }
`;

export const WrapperContent = styled(Grid)`
  border-radius: 10px;
  background: ${palette.white};
  box-shadow: 0px 3px 5px #00000029;
`;
