import { StyledMenuItem } from './styles';
import MenuList from '@mui/material/MenuList';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsChevronRight } from 'react-icons/bs';
import Typography from '@mui/material/Typography';
import AlertModal from 'src/components/modals/AlertModal';
import useDeleteMonth from 'src/hooks/mutations/useDeleteMonth';
import { ReactComponent as DeleteIcon } from 'src/assets/icons/delete.svg';

interface Props {
  months: {
    id: number;
    isDisabled: boolean;
    monthTitle: string;
  }[];
  detChapId: number;
  isAdmin?: boolean;
  selectedMonth: number;
  switchMonthHandler: (indx: number) => void;
}

const CourseMonthList = ({
  months,
  detChapId,
  selectedMonth,
  isAdmin = false,
  switchMonthHandler
}: Props) => {
  const { t } = useTranslation();
  const [monthId, setMonthId] = useState<number>(0);
  const { mutate: deleteMonth, isLoading } = useDeleteMonth();
  const [deleteAlertModalShown, setDeleteAlertModalShown] = useState(false);

  const deleteMonthHandler = () => {
    deleteMonth(
      {
        month: monthId,
        chapter_id: detChapId
      },
      { onSettled: () => setDeleteAlertModalShown(false) }
    );
  };

  return (
    <MenuList sx={{ padding: 0 }}>
      {months.map((month, indx) => (
        <StyledMenuItem
          key={month.id}
          selected={indx === selectedMonth}
          onClick={() => switchMonthHandler(indx)}>
          <Typography
            paddingY={1.5}
            fontSize={20}
            textTransform="capitalize"
            fontWeight={indx === selectedMonth ? 700 : 400}>
            {`${t('common.month')} ${month.monthTitle}`}
          </Typography>

          {!isAdmin && <BsChevronRight />}
          {isAdmin && (
            <DeleteIcon
              onClick={(e) => {
                if (isLoading) return;
                e.stopPropagation();
                setMonthId(month.id);
                setDeleteAlertModalShown(true);
              }}
            />
          )}
        </StyledMenuItem>
      ))}
      <AlertModal
        open={deleteAlertModalShown}
        onConfirm={deleteMonthHandler}
        close={() => setDeleteAlertModalShown(false)}
        confirmationText={t('modal.monthConfirmationText')}
      />
    </MenuList>
  );
};

export default memo(CourseMonthList);
