import React, { memo, useState, FC, useRef, useEffect } from 'react';
import AddFile from '../forms/AddFile';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import { SUBJECT_FILE_STATUS } from 'src/enums';
import Button from '@mui/material/Button';
import { Box, styled, useTheme } from '@mui/system';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CourseLecture from '../lists/CourseLecture';
import { useAuth } from 'src/context/authContext';
import { Subject } from 'src/models/general';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EditCourseSubject from '../forms/EditCourseSubject';
import EditFileModal from 'src/components/modals/EditFileModal';
import FormControlLabel from '@mui/material/FormControlLabel';
import AlertModal from 'src/components/modals/AlertModal';
import { useTranslation } from 'react-i18next';
import useProfile from 'src/hooks/queries/useProfile';
import useDoneSubjects from 'src/hooks/queries/useDoneSubjects';
import useDeleteFile from 'src/hooks/mutations/useDeleteFile';
import useFinishSubject from 'src/hooks/mutations/useFinishSubject';
import useDeleteSubject from 'src/hooks/mutations/useDeleteSubject';
import { ReactComponent as PlusIcon } from 'src/assets/icons/plus.svg';
import { ReactComponent as AddPlus } from 'src/assets/icons/addPlus.svg';
import { ReactComponent as MinusIcon } from 'src/assets/icons/minus.svg';
import { ReactComponent as CheckboxChecked } from 'src/assets/icons/success.svg';
import { ReactComponent as CheckboxUnChecked } from 'src/assets/icons/unChecked.svg';
import { debounce } from 'lodash';
import palette from 'src/theme/overrides/palette';

export const Divider = styled(Box)`
  width: 100%;
  height: 1px;
  margin: 12px 0px;
  background: ${palette.border};
`;

const ListWrapper = styled('div')({
  width: 0,
  height: 0,
  opacity: 0,
  transition: 'opacity 0.35s ease-out',
  overflow: 'hidden',
  '&.show-list': {
    width: '100%',
    height: 'auto',
    opacity: '1'
  }
});

interface Props {
  lecture: Subject;
  initialOpen?: boolean;
}

const LectureDetails: FC<Props> = ({ lecture, initialOpen }) => {
  const { t } = useTranslation();
  const { data: user } = useProfile();
  const { isAdmin } = useAuth();
  const { data: doneSubjects } = useDoneSubjects();
  const { mutate: deleteSubjectFile } = useDeleteFile();
  const { mutate: finishSubject } = useFinishSubject();
  const { mutate: deleteSubjectItem } = useDeleteSubject();
  const [courseTitleCardOffset, setCourseTitleCardOffset] = useState(0);
  const [showDetails, setShowDetails] = useState(initialOpen);
  const [showAddFiles, setShowAddFiles] = useState(false);
  const [editInputShown, setEditInputShown] = useState(false);
  const [deleteFile, setDeleteFile] = useState(false);
  const [deleteSubject, setDeleteSubject] = useState(false);
  const [fileToDelete, setFileToDelete] = useState<number>();
  const [editFileModalOpen, setEditFileModalOpen] = useState<any>({ open: false, file: null });
  const subject = doneSubjects.find((sub) => sub.subject_id === lecture.id);

  const theme = useTheme();

  const { id: userId = '' } = user || {};
  const { title = '', id: lectureId, week_id: weekId, files = [] } = lecture || {};

  const preFiles = files.filter((file) => file.status === SUBJECT_FILE_STATUS.PRE);
  const postFiles = files.filter((file) => file.status === SUBJECT_FILE_STATUS.POST);

  const boxRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleEllipsesText = debounce(() => {
      if (boxRef.current) {
        const { offsetWidth } = boxRef.current;
        setCourseTitleCardOffset(offsetWidth);
      }
    }, 300);

    window.addEventListener('resize', handleEllipsesText);

    handleEllipsesText();

    return () => {
      window.removeEventListener('resize', handleEllipsesText);
      handleEllipsesText.cancel();
    };
  }, []);

  const handleLectureDoneChange = async () => {
    finishSubject(
      {
        payload: {
          user_id: userId,
          subject_id: lecture.id,
          is_done: subject?.is_done ? 0 : 1
        },
        doneId: subject?.id
      },
      {
        onSuccess: ({ success }) => {
          if (success) {
            setShowDetails((prev) => !prev);
          }
        }
      }
    );
  };

  const handleFileDelete = async () => {
    if (!fileToDelete) {
      setDeleteFile(false);
      return;
    }
    try {
      deleteSubjectFile(
        {
          fileId: fileToDelete
        },
        {
          onSuccess: ({ success }) => {
            if (success) {
              setDeleteFile(false);
              setFileToDelete(undefined);
            }
          }
        }
      );
    } catch (err) {
      throw new Error(err);
    }
  };

  const handleSubjectDelete = async () => {
    deleteSubjectItem(
      { subjectId: lecture.id },
      {
        onSuccess: ({ success }) => {
          if (success) {
            setDeleteSubject(false);
          }
        }
      }
    );
  };

  const lectureTitleLabel = (
    <Box display="flex" gap={2} ml={1} justifyContent="space-between">
      {boxRef.current && (
        <Typography
          fontSize={16}
          fontWeight={700}
          variant="body1"
          sx={
            !showDetails
              ? {
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: courseTitleCardOffset - 60,
                  overflow: 'hidden'
                }
              : {}
          }>
          {title}
        </Typography>
      )}
      {showDetails && isAdmin && (
        <Box display="flex" gap={0.5}>
          <EditIcon
            onClick={(e) => {
              e.preventDefault();
              setEditInputShown((shown) => !shown);
            }}
          />
          <DeleteIcon
            onClick={(e) => {
              e.preventDefault();
              setDeleteSubject(true);
            }}
          />
        </Box>
      )}
    </Box>
  );

  return (
    <Card variant="outlined">
      <CardContent
        sx={{
          [`${theme.breakpoints.down('sm')}`]: {
            margin: `0 ${theme.spacing(2)}`
          }
        }}>
        <Box paddingX={2} py={1}>
          <Box
            ref={boxRef}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position="relative"
            height={showDetails ? 'auto' : 24}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid maxWidth={{ l: 580, md: 370, mb2: 360, xs: 250 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disableRipple
                      icon={<CheckboxUnChecked />}
                      checkedIcon={<CheckboxChecked />}
                      checked={Boolean(subject?.is_done)}
                      onChange={() => setShowDetails((prev) => !prev)}
                    />
                  }
                  label={lectureTitleLabel}
                />
              </Grid>
              {!showDetails && (
                <PlusIcon
                  style={{ cursor: 'pointer', marginRight: '3px' }}
                  onClick={() => setShowDetails((prev) => !prev)}
                />
              )}
              {showDetails && (
                <Grid item gap={1} display="flex" alignItems="center">
                  <Grid display={{ md: 'block', xs: 'none' }}>
                    {subject?.is_done && (
                      <Typography
                        color="green"
                        fontSize="14px"
                        display={{ sm: 'block', xs: ' none' }}>
                        {t('course.done')}
                      </Typography>
                    )}
                  </Grid>
                  <MinusIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => setShowDetails((prev) => !prev)}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
        <ListWrapper className={(showDetails && initialOpen) || showDetails ? 'show-list' : ''}>
          <Grid container columnSpacing={1} paddingX={1}>
            <Grid item xs={12}>
              {editInputShown && (
                <EditCourseSubject close={() => setEditInputShown(false)} lecture={lecture} />
              )}
            </Grid>
            {isAdmin && (
              <>
                <Grid item xs={12} paddingBottom={2} ml={4}>
                  <Button variant="filled" onClick={() => setShowAddFiles((prev) => !prev)}>
                    <Typography fontWeight="bold" textTransform="uppercase" fontSize={14}>
                      {t('button.addFile')}
                      <AddPlus />
                    </Typography>
                  </Button>
                </Grid>
                {showAddFiles && (
                  <Grid item xs={12} marginBottom={2}>
                    <AddFile close={() => setShowAddFiles(false)} subjectId={lectureId} />
                  </Grid>
                )}
              </>
            )}
            {!(editFileModalOpen.open && isAdmin) && (
              <Grid container px={6} ml={1} mt={2}>
                {preFiles.length > 0 && (
                  <Grid item xs={12}>
                    <Typography
                      component="div"
                      variant="subtitle3"
                      color="text.primary"
                      fontWeight={700}>
                      {t('course.lectureMaterials')}
                    </Typography>
                    <CourseLecture
                      onEditFile={(file) => {
                        setEditFileModalOpen({ open: true, file: file });
                      }}
                      onDeleteFile={(id) => {
                        setFileToDelete(id);
                        setDeleteFile(true);
                      }}
                      files={preFiles}
                    />
                  </Grid>
                )}
                {preFiles.length > 0 && postFiles.length > 0 && <Divider />}
                {postFiles.length > 0 && (
                  <Grid item xs={12} mt={preFiles.length > 0 && postFiles.length > 0 ? 1 : 0}>
                    <Typography
                      component="div"
                      variant="subtitle3"
                      fontWeight={700}
                      color="text.primary">
                      {t('course.postLectureMaterials')}
                    </Typography>
                    <CourseLecture
                      onDeleteFile={(id) => {
                        setFileToDelete(id);
                        setDeleteFile(true);
                      }}
                      onEditFile={(file) => {
                        setEditFileModalOpen({ open: true, file: file });
                      }}
                      files={postFiles}
                    />
                  </Grid>
                )}
              </Grid>
            )}
            {!isAdmin && !postFiles?.length && !preFiles?.length && (
              <Typography pl={6} marginBottom={2} ml={1} variant="subtitle3" color="text.primary">
                {t('course.noFiles')}
              </Typography>
            )}
            {!isAdmin && (
              <Grid item xs={12} display="flex" justifyContent="center" marginTop={3} paddingY={1}>
                <form
                  onSubmit={(e) => e.preventDefault()}
                  style={{
                    width: '100%',
                    justifyContent: 'flex-end',
                    display: 'flex'
                  }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disableRipple
                          name="lectureDone"
                          checked={Boolean(subject?.is_done)}
                          onChange={handleLectureDoneChange}
                          checkedIcon={<CheckboxChecked />}
                          icon={<CheckboxUnChecked />}
                        />
                      }
                      label={
                        <Typography color="black" variant="subtitle2">
                          {t('labels.mark')}
                        </Typography>
                      }
                    />
                  </FormGroup>
                </form>
              </Grid>
            )}
          </Grid>
        </ListWrapper>
      </CardContent>
      <EditFileModal
        file={editFileModalOpen.file}
        open={editFileModalOpen.open}
        close={() => setEditFileModalOpen({ open: false, file: null })}
      />
      <AlertModal
        open={deleteFile}
        onConfirm={handleFileDelete}
        close={() => setDeleteFile(false)}
        confirmationText={t('modal.deleteConfirmation', {
          name: t('common.file')
        })}
      />
      <AlertModal
        open={deleteSubject}
        onConfirm={handleSubjectDelete}
        close={() => setDeleteSubject(false)}
        confirmationText={t('modal.deleteConfirmation', {
          name: t('common.subject')
        })}
      />
    </Card>
  );
};

export default memo(LectureDetails);
