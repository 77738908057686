import React, { FC } from 'react';
import { ContactCardContainer, StyledIcon } from './styles';
import { Box, Typography } from '@mui/material';

interface Props {
  label: string;
  value?: string;
  icon: string;
}

const ContactCard: FC<Props> = ({ label, value, icon }) => {
  return (
    <ContactCardContainer>
      <Box
        width={{ xs: '48px', sm: '56px' }}
        height={{ xs: '48px', sm: '56px' }}
        borderRadius={'8px'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{
          background: '#F8F8F8'
        }}>
        <StyledIcon src={icon} />
      </Box>
      <Box>
        <Typography variant="body2" fontSize={{ xs: '14px', sm: '16px' }}>
          {label}
        </Typography>
        <Typography variant="body1" fontWeight={'bold'} fontSize={{ xs: '16px', sm: '20px' }}>
          {value}
        </Typography>
      </Box>
    </ContactCardContainer>
  );
};

export default ContactCard;
