import React, { memo } from 'react';
import styled from '@mui/system/styled';
import useProfile from 'src/hooks/queries/useProfile';
import { PositionedRating } from 'src/models/general';
import { List, ListItem, Stack, Typography } from '@mui/material';

const LectureItem = styled(ListItem)<{ active?: boolean }>`
  margin-bottom: 4px;
  position: relative;
  padding: 8px 12px !important;
  border-radius: 8px !important;
  background: ${(props) => (props.active ? '#e5e5e5' : '')};
`;

interface Props {
  list: PositionedRating[];
}

const KahootRating = ({ list }: Props) => {
  const { data: user } = useProfile();
  const { firstName = '', lastName = '' } = user || {};

  return (
    <List sx={{ padding: 0 }}>
      {list.map((val, i) => (
        <LectureItem key={val.username} active={`${firstName}.${lastName}` === val.username}>
          <Typography
            display="block"
            variant="listItem"
            fontSize={16}
            fontWeight={500}
            color="text.primary">
            {val.position}.
          </Typography>
          <Stack
            marginLeft={1.5}
            justifyContent="space-between"
            direction="row"
            width="100%"
            spacing={1.5}
            alignItems="center">
            <Typography variant="listItem" color="text.primary">
              {val.username.replace('.', ' ')}
            </Typography>
            <Typography variant="listItem" color="text.primary" fontWeight={500}>
              {val.total_score}
            </Typography>
          </Stack>
        </LectureItem>
      ))}
    </List>
  );
};

export default memo(KahootRating);
