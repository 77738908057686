import { Divider, Grid } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from 'src/context/languageContext';
import { useAuth } from 'src/context/authContext';
import { Box, Typography } from '@mui/material';
import palette from 'src/theme/overrides/palette';
import Loader from 'src/components/common/Loader';
import useToggle from 'src/hooks/useToggle';
import AlertModal from 'src/components/modals/AlertModal';
import { INewsOrBlog } from 'src/models/service';
import OceanNewsCard from 'src/components/common/oceanNewsCard';
import useBlogs from 'src/hooks/queries/useBlogs';
import useRemoveBlog from 'src/hooks/mutations/useRemoveBlog';
import AssignCard from 'src/components/common/assignCard';

const BlogList: React.FC = () => {
  const { t } = useTranslation();
  const { locale } = useLanguage();
  const { isAdmin } = useAuth();
  const [id, setId] = useState<number>();
  const { data: { blogs = [] } = {}, isLoading } = useBlogs();
  const { mutate } = useRemoveBlog();
  const [showAlertModal, setShowAlertModal] = useToggle();
  const navigate = useNavigate();

  const deleteBlog = () => {
    mutate({ id: `${id}` });
    setShowAlertModal();
  };

  if (isLoading) {
    return (
      <Box height={'75vh'}>
        <Loader />
      </Box>
    );
  }

  return (
    <>
      <AlertModal
        open={showAlertModal}
        close={setShowAlertModal}
        onConfirm={deleteBlog}
        confirmationText={t('modal.removeBlog')}
      />

      <Grid container justifyContent="center" maxWidth="1920px" margin="auto">
        <Grid
          item
          xs={12}
          marginTop={{ xs: 3, m: 5, l: 6 }}
          marginBottom={{ xs: 4, l: 6 }}
          paddingX={{ xs: 2, sm: 10, l: 18 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height={{ xs: 56, mb2: '64px' }}
            paddingLeft={{ xs: '16px', mb2: '135px' }}
            paddingRight={{ xs: '16px', mb2: '135px', m: 0 }}
            maxWidth="1632px"
            margin="auto"
            sx={{
              borderTopRightRadius: 8,
              borderTopLeftRadius: 8
            }}
            bgcolor="black">
            <Box
              width={{ xs: '100%', m: 'auto' }}
              display="flex"
              gap={{ sx: 2, sm: 8 }}
              alignItems="center"
              zIndex={100}
              justifyContent="space-between">
              <Typography color={palette.white} fontSize={{ sx: 16, sm: 24 }}>
                {t('labels.blogList')}
              </Typography>
            </Box>
          </Box>

          <Box
            padding={{ xs: '16px', mb2: '48px 135px' }}
            textAlign={{ xs: 'center', md: 'start' }}
            sx={{
              border: '1px solid #DEDEDE',
              borderBottomRightRadius: 8,
              borderBottomLeftRadius: 8
            }}>
            <Grid container spacing={1} justifyContent={{ xs: 'center', md: 'flex-start' }}>
              {!isAdmin && blogs.length === 0 && (
                <Box textAlign={'center'} width={'100%'} mt={2}>
                  <Typography fontSize={{ sx: 16, sm: 24 }}>{t('labels.noHomework')}</Typography>
                </Box>
              )}

              {isAdmin && (
                <Grid item width={{ xs: 225, mb2: 250 }}>
                  <AssignCard
                    setShowAlertModal={setShowAlertModal}
                    setId={setId}
                    isAdmin={isAdmin}
                    isTemplate={true}
                    clickHandler={() => {
                      navigate(`/${locale}/ocean-news/add-news`);
                    }}
                    title={'Add Blog'}
                  />
                </Grid>
              )}

              {blogs?.map((n: INewsOrBlog) => {
                const { id, images, translations } = n;
                const trans = translations.find((tr) => tr.locale === locale)!;
                return (
                  <Grid item key={n.id} width={{ xs: 225, mb2: 250 }}>
                    <OceanNewsCard
                      thumbnail={n.thumbnailImage?.url || '/no_img.webp'}
                      id={id}
                      setId={setId}
                      images={images}
                      translations={trans}
                      onChooseCourse={() => navigate(`/${locale}/ocean-news/blogs/${id}`)}
                      setShowAlertModal={setShowAlertModal}>
                      <Divider sx={{ margin: '12px 12px 0px', width: '90%' }} />
                    </OceanNewsCard>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default BlogList;
