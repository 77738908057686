import React, { Fragment, ReactNode, useState } from 'react';
import { Typography } from '@mui/material';
import { MenuListHead, StyledMenuList, StyledSubMenuTitle } from './styled';
import { ReactComponent as PlusIcon } from 'src/assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from 'src/assets/icons/minus.svg';

interface Props {
  title: string;
  children?: ReactNode;
  isSubMenu?: boolean;
}

const MenuList = ({ title, isSubMenu, children }: Props) => {
  const [showList, setShowList] = useState(false);
  const toggleListhandler = () => setShowList((prevState) => !prevState);

  return (
    <StyledMenuList>
      <MenuListHead isSubMenu={isSubMenu} onClick={toggleListhandler}>
        {isSubMenu ? (
          <StyledSubMenuTitle>
            <Typography fontWeight={400} fontSize={20} textTransform="uppercase" mr={2}>
              {title}
            </Typography>
          </StyledSubMenuTitle>
        ) : (
          <Typography fontWeight={900} fontSize={24} textTransform="uppercase" mr={2}>
            {title}
          </Typography>
        )}

        {children && (
          <Fragment>
            {showList && <MinusIcon />}
            {!showList && <PlusIcon style={{ marginRight: '3px' }} />}
          </Fragment>
        )}
      </MenuListHead>
      {showList && children}
    </StyledMenuList>
  );
};

export default MenuList;
