import React from 'react';
import { CardContainer } from './styles';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'src/context/languageContext';
import assignImage from 'src/assets/icons/menu-career-care.svg';
interface ContentCardProps {
  id: number;
  title: string;
  dueDate: string;
}

const ContentCard = ({ id, title, dueDate }: ContentCardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <CardContainer>
      <Box display="flex" flexDirection="column" alignItems="center">
        <img src={assignImage} alt="career-care" style={{ marginBottom: '20px' }} />
        <Typography
          fontSize={18}
          color="violet"
          lineHeight={1.5}
          fontWeight="bold"
          overflow="hidden"
          textAlign="center"
          variant="subtitle2"
          height={{ sm: 64, xs: 38 }}
          maxWidth={{ xs: 120, l: 180 }}>
          {title}
        </Typography>
        {dueDate && (
          <>
            <Typography fontSize={{ xs: 14, md: 15 }}>{t('labels.due_date_card')}</Typography>
            <Typography fontSize={{ xs: 14, md: 15 }} fontWeight={400}>
              {dueDate}
            </Typography>
          </>
        )}
      </Box>
      <Button
        variant="primary"
        sx={{ height: '48px !important', width: '138px' }}
        onClick={() => navigate(`/career-content?contentId=${id}`)}>
        <Typography textTransform="uppercase">{t('button.details')}</Typography>
      </Button>
    </CardContainer>
  );
};

export default ContentCard;
