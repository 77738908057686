import moment from 'moment';
import { USER_ROLES } from 'src/enums';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { PositionedRating, Rating } from 'src/models/general';

type ExtendedJwtPayload = JwtPayload & { role: USER_ROLES };

type SupportedLanguage = 'en' | 'ge' | 'az';

export const transformDate = (date: string, type?: string) => {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  if (type === 'dashed') {
    return [year, month, day].join('-');
  } else return [month, day, year].join('.');
};

export const getInitialMonthDate = () => {
  const date = new Date();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();

  return `${month} ${year}`;
};

export const isBeforeDate = (date1: Date, date2: Date) => date1 < date2;

export const decodeUserRole = (token: string) => {
  const { role } = jwtDecode<ExtendedJwtPayload>(token);
  return role;
};

export const getTabValue = (path: string) => {
  const tabValue = path.split('/')[3];
  if (tabValue === 'specific') {
    return 1;
  }
  return 0;
};

export const getDateDifference = (date1: Date, date2: Date) => {
  const difference = date1.getTime() - date2.getTime();
  const totalDays = Math.ceil(difference / (1000 * 3600 * 24));

  return totalDays || 0;
};

export const generateRating = (index: number, rating: Rating[], student: Rating) => {
  const scores: PositionedRating[] = [];
  if (index > 9) {
    rating.forEach((item, indx) => {
      if (indx < 9) {
        scores.push({
          position: indx + 1,
          username: item.username,
          total_score: item.total_score
        });
      }
    });
    scores.push({
      position: index - 1,
      username: student.username,
      total_score: student.total_score
    });
  } else {
    rating.forEach((item, indx) => {
      if (indx < 10) {
        scores.push({
          position: indx + 1,
          username: item.username,
          total_score: item.total_score
        });
      }
    });
  }
  return scores;
};

export const goToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

export const generateLangRoute = (path: string, newLang: SupportedLanguage, length = 3) =>
  `/${newLang}${path.slice(length)}`;

export const checkUrlLang = (path: string, locale: SupportedLanguage) =>
  path.toLowerCase().startsWith(`/${locale}`);

export const checkStatusByInterval = (score: number, scoreStepsArr: number[]) => {
  const [first, sec, third] = scoreStepsArr;

  if (score >= 0 && score < first) {
    return 'Freshman';
  } else if (score >= first && score < sec) {
    return 'Rookie';
  } else if (score >= sec && score < third) {
    return 'Wizz';
  } else {
    return 'Pro';
  }
};

export const generateStatusByCourse = (courseId: number, score: number) => {
  // B id - 4;
  // C id - 2;

  if (courseId === 4) {
    return checkStatusByInterval(score, [250, 400, 500]);
  } else if (courseId === 2) {
    return checkStatusByInterval(score, [300, 500, 700]);
  } else if (courseId >= 10) {
    if (score >= 0 && score < 120) {
      return 'Newbie';
    } else if (score >= 120 && score < 210) {
      return 'Freshman';
    } else if (score >= 210 && score < 300) {
      return 'Rookie';
    } else if (score >= 300 && score < 450) {
      return 'Wizz';
    } else {
      return 'Jedi';
    }
  } else {
    return checkStatusByInterval(score, [150, 250, 350]);
  }
};

export const generateNextStatus = (courseId: number, currStatus: string) => {
  const batchCourses = ['Freshman', 'Rookie', 'Wizz', 'Pro'];
  const newCourses = ['Newbie', 'Freshman', 'Rookie', 'Wizz', 'Jedi'];

  const list = courseId >= 10 ? newCourses : batchCourses;

  return list[list.indexOf(currStatus) + 1];
};

export const getSafeHtml = (html: string) => {
  return html
    .split('<p></p>')
    .map((str) => str.concat('<br/>'))
    .join('');
};

export const joinWithDashes = (input: string): string => {
  if (input) {
    const regex = /[ \u00A0?]/g;
    return input.replace(/\?/g, '').replace(regex, '-');
  } else {
    return '';
  }
};

export const checkIOS = () => {
  const platform = navigator?.userAgent || navigator?.platform || 'unknown';

  return /iPhone|iPod|iPad/.test(platform);
};

export const actualDate = (dateInput: string | undefined): string => {
  const givenDate = moment(dateInput);
  const previousDay = givenDate.subtract(1, 'days');
  return previousDay.format('YYYY-MM-DD HH:mm:ss');
};
