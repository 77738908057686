import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AssignmentEditForm from 'src/components/UI/forms/AssignmentEditForm';
import { useLanguage } from 'src/context/languageContext';
import Received from './Received';
import { useNavigate, useParams } from 'react-router-dom';
import AuthWrapper from 'src/components/layouts/AuthWrapper';
import useQueryString from 'src/hooks/useQueryString';
import useCourse from 'src/hooks/queries/useCourse';

const AdminHomeWork: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { locale } = useLanguage();
  const { homeworkId } = useParams();

  const { parseQuery } = useQueryString();
  const { course = '', section = '' } = parseQuery();
  const { data } = useCourse(String(course));

  return (
    <AuthWrapper isAssignment>
      <Box
        display="flex"
        bgcolor="black"
        alignItems="center"
        pl={{ sm: 4, xs: 2 }}
        height={{ sm: 64, xs: 51 }}
        justifyContent="space-between"
        sx={{
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10
        }}>
        <Typography fontSize={20} color="white" textTransform="capitalize" fontWeight={700}>
          {section === 'edit' ? (
            t('labels.editHomework')
          ) : (
            <>
              {locale === 'en' && data?.name + ' '}
              {t('labels.receivedHomework')}
            </>
          )}
        </Typography>
      </Box>
      {section === 'edit' ? <AssignmentEditForm /> : <Received />}
    </AuthWrapper>
  );
};

export default AdminHomeWork;
