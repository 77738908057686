import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { editCareerContent } from 'src/services/api';
import useLanguageCode from 'src/hooks/useLanguageCode';
import { useNavigate } from 'src/context/languageContext';

const useEditCareerContent = (id: string) => {
  const navigate = useNavigate();
  const { lang } = useLanguageCode();

  const { mutate, isLoading } = useMutation<{ success?: boolean }, AxiosError, FormData>(
    ['edit-career-content'],
    (payload) => editCareerContent(payload, id, lang),
    { onSuccess: () => navigate('/career-care') }
  );

  return {
    mutate,
    isLoading
  };
};

export default useEditCareerContent;
