import { breakPoints } from 'src/theme/defaultTheme';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingIcon = styled.img`
  animation: rotate 1s linear infinite;
  max-width: 100px;

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @media (max-width: ${breakPoints.mb3}px) {
    max-width: 50px;
  }
`;
