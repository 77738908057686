import React, { FC } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import useDetectDevice from 'src/hooks/useDetectDevice';
import { useLanguage } from 'src/context/languageContext';
import Logo from 'src/components/common/logo/Logo';
import { LangLogo } from './styles';
import { enFlag, geFlag } from 'src/assets/images';
import azFlag from 'src/assets/icons/azFlag.svg';
import { LOCALES } from 'src/enums';
import { CloseIcon } from 'src/components/common/BurgerMenu/styled';
import BurgerMenu from 'src/components/common/BurgerMenu';
import palette from 'src/theme/overrides/palette';
import { useAuth } from 'src/context/authContext';
import { useTranslation } from 'react-i18next';

interface IMobileHeader {
  setIsMenuOpened?: (isOpen: boolean) => void;
}

const MobileHeader: FC<IMobileHeader> = ({ setIsMenuOpened }) => {
  const { t } = useTranslation();
  const { isMedium } = useDetectDevice();
  const { isLoggedIn, isAdmin } = useAuth();
  const { locale, changeLocale } = useLanguage();

  const buyCourseHandler = () => {
    window.open(
      `${process.env.REACT_APP_SKILLWILL_BASE_URL}${locale}/buy-course`,
      '_blank',
      'noopener,noreferrer'
    );
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      width={isMedium ? '100%' : 'auto'}
      alignItems="center"
      sx={
        setIsMenuOpened && {
          backgroundColor: '#fff',
          padding: '16px',
          boxShadow: '10px 5px 5px 0px rgb(0,0,26,0.2);'
        }
      }
      gap={3}>
      <Grid display={{ sm: 'none', xs: 'block' }}>
        <Logo />
      </Grid>
      <Grid item display="flex" gap={3} mt={{ xs: 0, sm: -2.5 }} alignItems="center">
        {!isAdmin && (
          <Button variant="filled" onClick={buyCourseHandler}>
            <Typography>{t('buyCourse.buy')}</Typography>
          </Button>
        )}
        {process.env.REACT_APP_SKILLWILL_COUNTRY === 'ge' && (
          <>
            {locale === LOCALES.GE && (
              <LangLogo onClick={changeLocale}>
                <img src={geFlag} alt="georgian" />
              </LangLogo>
            )}
            {locale === LOCALES.EN && (
              <LangLogo onClick={changeLocale}>
                <img src={enFlag} alt="english" />
              </LangLogo>
            )}
          </>
        )}
        {process.env.REACT_APP_SKILLWILL_COUNTRY === 'az' && (
          <>
            {locale === LOCALES.AZ && (
              <LangLogo onClick={changeLocale}>
                <img src={azFlag} alt="azerbaijan" />
              </LangLogo>
            )}
            {locale === LOCALES.EN && (
              <LangLogo onClick={changeLocale}>
                <img src={enFlag} alt="english" />
              </LangLogo>
            )}
          </>
        )}
        {setIsMenuOpened ? (
          <CloseIcon
            onClick={() => setIsMenuOpened(false)}
            fill={isLoggedIn ? palette.text.primary : ''}
          />
        ) : (
          <BurgerMenu />
        )}
      </Grid>
    </Grid>
  );
};

export default MobileHeader;
