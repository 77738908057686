import './styles.css';
import 'moment/locale/ka';
import moment from 'moment';
import 'moment/locale/en-gb';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import React, { useState, useRef, useEffect } from 'react';
import googleCalendarPlugin from '@fullcalendar/google-calendar';

import { Box, Grid, Typography, useTheme } from '@mui/material';
import { deleteEvent, syncCalendar } from 'src/services/api';
import CalendarHeader from 'src/components/UI/calendar/CalendarHeader';
import EventDetailModal from 'src/components/modals/EventDetailModal';
import { isBeforeDate } from 'src/utils';
import Navigate from 'src/components/common/navigate';
import { useAuth } from 'src/context/authContext';
import { useCalendar } from 'src/context/calendarContext';
import AlertModal from 'src/components/modals/AlertModal';
import { useTranslation } from 'react-i18next';
import { IEvent } from 'src/models/general';
import useProfile from 'src/hooks/queries/useProfile';
import useQueryString from 'src/hooks/useQueryString';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useEvents from 'src/hooks/queries/useEvents';
import { QUERY_KEYS } from 'src/enums';
import Loader from 'src/components/common/Loader';
import useLanguageCode from 'src/hooks/useLanguageCode';
import { useLanguage } from 'src/context/languageContext';
import AuthWrapper from 'src/components/layouts/AuthWrapper';
import useDetectDevice from 'src/hooks/useDetectDevice';
import UpcomingEvent, { IUpEvent } from '../UpcomingEvent';
import useUpcomingEvents from 'src/hooks/queries/useUpcomingEvents';
import CalendarResponsiveHeader from 'src/components/UI/calendar/CalendarResponsiveHeader';

const MAX_EVENT_SHOW_IN_DAY = 4;

export default function Calendar() {
  const theme = useTheme();

  const calendarRef = useRef<FullCalendar>(null!);
  const [event, setEvent] = useState<IEvent>();
  const [eventId, setEventId] = useState<string | undefined>();
  const [alertModalShown, setAlertModalShown] = useState<boolean>(false);
  const [calendarValue, setCalendarValue] = useState<string>('dayGridMonth');

  const client = useQueryClient();

  const { mutate } = useMutation(['sync-calendar'], (code: string) => syncCalendar(code));

  const { parseQuery } = useQueryString();
  const { code = '' } = parseQuery();
  const { role, isAdmin } = useAuth();
  const { t } = useTranslation();
  const { locale } = useLanguage();
  const { isSmall } = useDetectDevice();
  const { lang } = useLanguageCode();
  const { setEventDetailModalShown } = useCalendar();

  const { upEvents } = useUpcomingEvents();
  const { userEvents, isLoading, isSynced = true } = useEvents();
  const { data: user, myCourses = [] } = useProfile();
  const { startDate = '' } = myCourses[0] || {};
  const { donePayment } = user?.myCourses[0] || {};

  const isCourseStarted = isBeforeDate(new Date(startDate), new Date());

  useEffect(() => {
    if (code && !isSynced) {
      mutate(code.toString(), {
        onSuccess: () => {
          client.invalidateQueries([QUERY_KEYS.EVENTS]);
        }
      });
    }
  }, [code, mutate, client, isSynced]);

  useEffect(() => {
    calendarRef?.current?.getApi().changeView(calendarValue);
    if (calendarValue === 'timeGridWeek') {
      const circle = document.querySelectorAll('.date_day');
      circle.forEach((c) => c.classList.add('.custom-day'));
    }
  }, [calendarValue]);

  const getWeekDay = (date: Date) => {
    return moment(date)
      .locale(locale === 'en' ? 'en-gb' : 'ka')
      .format('ddd');
  };

  const onEventDelete = () => {
    setEventDetailModalShown(false);
    setAlertModalShown(true);
    setEventId(event?.id);
  };

  const onDetailsModalClose = () => {
    setEventDetailModalShown(false);
    setEvent(undefined);
  };

  const eventDeleteHandler = async () => {
    try {
      await deleteEvent(eventId, lang);
      client.invalidateQueries([QUERY_KEYS.EVENTS]);
      setAlertModalShown(false);
    } catch (err) {
      // console.log(err);
    }
  };

  if (isLoading) {
    return <Loader containerStyle={{ height: '100vh' }} />;
  }

  if (!donePayment && role !== 'Admin') {
    return <Navigate to="" />;
  }

  if (!isCourseStarted && role !== 'Admin') {
    return <Navigate to="/buy-course/success" />;
  }

  return (
    <>
      <AuthWrapper calendar={calendarValue} action={setCalendarValue}>
        <Grid container marginBottom={{ xs: 4, l: 6 }} paddingBottom={16} spacing={3}>
          <Grid item xs={12} md={isAdmin ? 12 : 9}>
            <CalendarResponsiveHeader calendarRef={calendarRef} isSynced={isSynced} />
            <CalendarHeader
              calendarValue={calendarValue}
              setCalendarValue={setCalendarValue}
              calendarRef={calendarRef}
              isSynced={isSynced}
            />
            <FullCalendar
              firstDay={1}
              height="980px"
              weekends={true}
              ref={calendarRef}
              allDaySlot={false}
              events={userEvents}
              eventClassNames={'course-event'}
              slotEventOverlap={false}
              initialView="dayGridMonth"
              dayMaxEvents={MAX_EVENT_SHOW_IN_DAY}
              slotLabelFormat={{
                hour: 'numeric',
                meridiem: 'short',
                minute: '2-digit',
                omitZeroMinute: true
              }}
              slotMinTime="01:00:00"
              slotMaxTime="24:00:00"
              eventClick={(arg) => {
                const foundEvent = userEvents?.find(
                  (ev: IEvent) => ev.id.toString() === arg.event.id.toString()
                );
                setEvent(foundEvent);
                setEventDetailModalShown(true);
              }}
              dayCellContent={(param) => {
                return (
                  <div className="date_cell">
                    <p className="date_number">{param.dayNumberText}</p>
                  </div>
                );
              }}
              dayHeaderContent={(param) => {
                return (
                  <>
                    <p className="date_weekday">
                      {isSmall ? getWeekDay(param?.date).slice(0, 1) : getWeekDay(param?.date)}
                    </p>
                    <p className={`date_day ${param.isToday ? 'active' : ''}`}>
                      {param.date.getDate()}
                    </p>
                  </>
                );
              }}
              slotLabelContent={(param) => {
                return param.text.replace('am', ' am').replace('pm', ' pm');
              }}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, googleCalendarPlugin]}
            />
          </Grid>

          <Grid
            item
            xs={!isAdmin && 3}
            flexBasis="max-content"
            display={{ xs: 'none', md: !isAdmin ? 'flex' : 'none' }}>
            <Box width={{ l: '315px' }}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                position="relative"
                overflow="hidden"
                height={{ xs: 56, mb2: 72 }}
                paddingLeft={{ xs: theme.spacing(2) }}
                paddingRight={{ xs: theme.spacing(2) }}
                maxWidth="1632px"
                margin="auto"
                sx={{
                  borderTopRightRadius: 8,
                  borderTopLeftRadius: 8
                }}
                bgcolor="black">
                <Typography color="white" fontSize={{ md: 16, l: 18 }}>
                  {t('calendar.upcoming')}
                </Typography>
              </Box>

              <Box
                bgcolor={'white'}
                padding={'16px'}
                sx={{
                  borderTopLeftRadius: 8,
                  borderTopRightRadius: 8
                }}>
                {upEvents?.map((event: IUpEvent) => (
                  <UpcomingEvent key={event.id} event={event} />
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>

        {event && (
          <EventDetailModal
            event={event}
            title={event.title.slice(6)}
            onEventDelete={onEventDelete}
            onClose={onDetailsModalClose}
          />
        )}
        <AlertModal
          open={alertModalShown}
          onConfirm={eventDeleteHandler}
          close={() => setAlertModalShown(false)}
          confirmationText={t('modal.deleteConfirmation', {
            name: t('common.event')
          })}
        />
      </AuthWrapper>
    </>
  );
}
