import React from 'react';
import { Navigate, NavigateProps } from 'react-router-dom';
import { useLanguage } from 'src/context/languageContext';

const NavigateCompo: React.FC<NavigateProps> = ({ to }) => {
  const { locale } = useLanguage();

  return <Navigate to={'/' + locale + to} />;
};

export default NavigateCompo;
