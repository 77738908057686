import React from 'react';
import { goToTop } from 'src/utils';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { GridContainer, NewLandingLink } from './styles';
import { ReactComponent as FacebookLogo } from 'src/assets/icons/fb.svg';
import { SkillWillLogo, Wrapper } from 'src/components/common/logo/styles';
import { ReactComponent as TicktockLogo } from 'src/assets/icons/tiktok.svg';
import { ReactComponent as YoutubeLogo } from 'src/assets/icons/youtube.svg';
import { ReactComponent as LinkedinLogo } from 'src/assets/icons/linkedin.svg';
import { ReactComponent as InstagramLogo } from 'src/assets/icons/instagram.svg';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <GridContainer container py={{ sm: 3, xs: 2 }} px={{ l: 10, mb2: 12, xs: 3 }}>
      <Grid item order={{ xs: 0 }}>
        <Wrapper>
          <SkillWillLogo fill={'white'} onClick={() => goToTop()} />
        </Wrapper>
      </Grid>

      <Grid
        item
        gap={3}
        display="flex"
        mt={{ xs: 2, sm: 0 }}
        order={{ xs: 1, sm: 2 }}
        width={{ sm: 'auto', xs: '100%' }}
        justifyContent={{ sm: 'center', xs: 'flex-start' }}>
        <Typography
          component="a"
          color="white"
          variant="body1"
          textTransform="uppercase"
          href={`${process.env.REACT_APP_SKILLWILL_BASE_URL}/terms-and-conditions`}
          fontSize={{ sm: 16, xs: 12 }}>
          {t('newLanding.terms')}
        </Typography>
      </Grid>

      <Grid item display="flex" gap={1} mt={{ xs: 2, sm: 0 }} order={{ xs: 0, sm: 2 }}>
        <NewLandingLink href="https://www.facebook.com/skillwillacademy/" target="_blank">
          <FacebookLogo style={{ transform: 'rotate(180deg)' }} />
        </NewLandingLink>

        <NewLandingLink href="https://www.instagram.com/skillwill_academy/" target="_blank">
          <InstagramLogo style={{ transform: 'rotate(180deg)' }} />
        </NewLandingLink>

        <NewLandingLink href="https://www.linkedin.com/company/skillwillacademy/" target="_blank">
          <LinkedinLogo style={{ transform: 'rotate(180deg)' }} />
        </NewLandingLink>

        <NewLandingLink href="https://www.youtube.com/@skillwill658" target="_blank">
          <YoutubeLogo />
        </NewLandingLink>

        <NewLandingLink href="https://www.tiktok.com/@skillwill.academy" target="_blank">
          <TicktockLogo />
        </NewLandingLink>
      </Grid>
    </GridContainer>
  );
};

export default Footer;
