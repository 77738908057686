import React from 'react';
import { Wrapper, SkillWillLogo } from './styles';
import palette from 'src/theme/overrides/palette';

const Logo = () => {
  return (
    <Wrapper>
      <a href={`${process.env.REACT_APP_SKILLWILL_BASE_URL}`} target="_blank" rel="noreferrer">
        <SkillWillLogo fill={palette.text.primary} />
      </a>
    </Wrapper>
  );
};

export default Logo;
