import styled from '@mui/system/styled';
import { MenuItem } from '@mui/material';

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  gap: '52px',
  height: '56px',
  maxWidth: '182px',
  '&.Mui-selected': {
    backgroundColor: '#E5E5E5',
    borderRadius: 8,
    ':hover': {
      background: theme.palette.lightBlue
    }
  },
  ':hover': {
    background: 'transparent'
  }
}));
