import { Box } from '@mui/system';
import React, { FC, useCallback } from 'react';
import { useFeedbackModal } from 'src/context/feedbackModalContext';
import { Button, Dialog, DialogContent, Grid, Typography } from '@mui/material';

const FeedbackModal: FC = () => {
  const { modalProps, resetModal } = useFeedbackModal();
  const { btnText = '', title = '', subtitle = '', type = '', action } = modalProps;

  const handleAction = useCallback(() => {
    if (action) action();

    resetModal();
  }, [resetModal, action]);

  const color = type === 'success' ? '#00CE7C' : type === 'error' ? '#FE3B1F' : '#000';

  return (
    <Dialog
      disableAutoFocus
      disableEscapeKeyDown
      open={Boolean(title.length)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') resetModal();
      }}
      PaperProps={{
        style: {
          borderRadius: '16px',
          boxShadow: '5px 5px 15px #0000001A'
        }
      }}>
      <DialogContent sx={{ py: { xs: 5, sm: 6 }, px: { xs: 4, sm: 11 } }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} display="flex" alignItems="center" flexDirection="column">
            <Box textAlign="center" mb={4} maxWidth={276}>
              <Typography
                color={color}
                lineHeight="28px"
                textTransform="uppercase"
                fontSize={{ sm: 24, xs: 20 }}
                sx={{ fontFeatureSettings: "'case' on" }}>
                {subtitle}
              </Typography>
            </Box>

            <Button variant="primary" onClick={handleAction}>
              <Typography>{btnText}</Typography>
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default FeedbackModal;
