import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import React, { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import palette from 'src/theme/overrides/palette';
import { useLanguage } from 'src/context/languageContext';
import { AssignContent, AssignUpload, UploadForm } from './style';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import useToggle from 'src/hooks/useToggle';
import AlertModal from 'src/components/modals/AlertModal';
import useDeleteAssignment from 'src/hooks/mutations/useDeleteAssignment';
import Loader from 'src/components/common/Loader';
import { ReactComponent as PlusIcon } from 'src/assets/icons/addPlus.svg';

import useProfile from 'src/hooks/queries/useProfile';
import useCourse from 'src/hooks/queries/useCourse';
import useAssignment from 'src/hooks/queries/useAssignment';
import { HiArrowSmLeft } from 'react-icons/hi';
import AuthWrapper from 'src/components/layouts/AuthWrapper';
import { actualDate } from 'src/utils';
import { CardHeaderBlack, StyledCardContent } from '../courseDetails/styles';
import KahootRating from 'src/components/UI/lists/KahootRating';
import RankingStatus from 'src/components/UI/rankingStatus';
import ProgressBar from 'src/components/common/progressBar';
import { useAuth } from 'src/context/authContext';
import useProgress from 'src/hooks/queries/useProgress';
import useRating from 'src/hooks/queries/useRating';

const UserHomeWork: React.FC = () => {
  const { t } = useTranslation();
  const { locale } = useLanguage();
  const { homeworkId } = useParams();
  const navigate = useNavigate();
  const { isAdmin } = useAuth();

  const [showAlertModal, setShowAlertModal] = useToggle();
  const qs = new URLSearchParams(window.location.search);
  const courseId = qs.get('course');

  const { mutate, isLoading: deleteLoading } = useDeleteAssignment();
  const { data: profile, myCourses = [] } = useProfile();

  const { data: course } = useCourse(courseId!);
  const { data: assignment, isLoading } = useAssignment(homeworkId!);

  const groupId = myCourses?.find((crs) => String(crs.courseId) === courseId)?.groupId;

  const { progress } = useProgress({
    chapter_id: String(courseId)
  });

  const { scores } = useRating({ group_id: groupId });

  const { title, due_date, description, points, assignments } = assignment?.homework || {};
  const studentAssignment = assignments?.find((a) => a.user_email === profile?.email);

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    navigate(
      `/${locale}/assignments/add-assignment/${homeworkId}?course=${courseId}&segment=${
        studentAssignment ? 'edit' : 'attach'
      }`
    );
  };

  const deleteAssignment = () => {
    mutate({ id: String(studentAssignment?.id) });
    setShowAlertModal();
  };

  return (
    <AuthWrapper userPage isAssignment>
      <AlertModal
        open={showAlertModal}
        close={setShowAlertModal}
        onConfirm={deleteAssignment}
        confirmationText={t('modal.deleteAssignment')}
      />

      <Grid container mb={4} justifyContent="space-between">
        <Grid item xs={12} display={{ sm: 'none', xs: 'block' }} mt={-2}>
          <Typography fontWeight="bold" fontSize={24} fontFamily="basenji-variable">
            / {course?.name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8.8} mt={{ sm: 0, xs: 2 }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            pl={{ sm: 4, xs: 2 }}
            height={{ sm: 64, xs: 51 }}
            sx={{
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10
            }}
            bgcolor="black">
            <Box minHeight="24px">
              <Typography color={palette.white} fontSize={20} fontWeight={700}>
                {locale === 'ge' && (course?.name || '') + '- ის '}
                {locale === 'en' && (course?.name || '') + ' '}
                {t('labels.chooseAssignment')}
              </Typography>
            </Box>
          </Box>

          <Box
            padding={{ xs: '16px', md: '16px 28px 40px 28px' }}
            textAlign={{ xs: 'center', md: 'start' }}
            sx={{
              border: '1px solid #DEDEDE',
              borderBottomRightRadius: 10,
              borderBottomLeftRadius: 10,
              background: 'white 0% 0% no-repeat padding-box'
            }}>
            {isLoading && (
              <Grid container minHeight="400px" alignItems="center">
                <Loader />
              </Grid>
            )}
            {!isLoading && (
              <AssignUpload>
                <Button
                  onClick={() => navigate(-1)}
                  sx={{
                    paddingRight: 2,
                    paddingLeft: 0,
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '25px',
                    color: '#2A7DE1'
                  }}>
                  <HiArrowSmLeft size={'24px'} fontWeight={'bold'} />
                  <Typography marginLeft={1} color={'#2A7DE1'} fontWeight={'regular'}>
                    {t('careerCare.backToList')}
                  </Typography>
                </Button>

                <AssignContent>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={{ sm: 1, xs: 0 }}>
                    <Typography fontWeight={'bold'} mr={2.5} fontSize={{ xs: 16, sm: 20 }}>
                      {title}
                    </Typography>
                    <Typography
                      fontWeight={'regular'}
                      color={studentAssignment ? '#3BCC51' : '#F7D51B'}
                      fontSize={{ xs: 14 }}>
                      {studentAssignment ? (
                        <>({t('labels.submitted')})</>
                      ) : (
                        <>({t('labels.toDo')})</>
                      )}
                    </Typography>
                  </Box>

                  <Typography
                    fontWeight={'regular'}
                    mb={2}
                    mt={{ xs: 2, sm: 0 }}
                    fontSize={{ xs: 14, sm: 16 }}>
                    {description}
                  </Typography>

                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                    gap={2}
                    flexDirection={{ sm: 'row', xs: 'column' }}
                    mb={1.5}>
                    <Box display={'flex'}>
                      <Typography fontWeight={'medium'} fontSize={{ xs: 14, sm: 16 }}>
                        {t('labels.due_date_card')}:
                      </Typography>
                      <Typography ml={0.5} fontWeight={400} fontSize={{ xs: 14, sm: 16 }}>
                        {actualDate(due_date!).split(' ')[0] + ' 23:59:00'}
                      </Typography>
                    </Box>

                    <Box display={'flex'}>
                      <Typography fontWeight={'medium'} fontSize={{ xs: 14, sm: 16 }} mr={1}>
                        {t('labels.your_points')}:
                      </Typography>
                      <Typography fontWeight={'regular'} fontSize={{ xs: 14, sm: 16 }}>
                        <span style={{ fontWeight: 500, color: '#2A7DE1' }}>
                          <span style={{ color: '#2A7DE1' }}>{studentAssignment?.score ?? 0}</span>/
                          {points}
                        </span>
                      </Typography>
                    </Box>
                  </Box>

                  <div
                    style={{
                      width: '100%',
                      marginTop: '5px',
                      background: '#cec3c3',
                      height: '1px'
                    }}
                  />

                  <Box mb={3} sx={{ width: '100%', marginTop: '20px' }}>
                    <Typography fontSize={{ xs: 14, sm: 18 }} fontWeight={'bold'} mb={'16px'}>
                      {t('labels.assignmentLabel')}
                    </Typography>

                    <a
                      href={assignment?.homework.attachment}
                      rel="noreferrer"
                      target="_blank"
                      download
                      style={{ textDecoration: 'underline', color: '#2A7DE1' }}>
                      <Typography fontSize={'16px'} fontWeight={'regular'} color={'#2A7DE1'}>
                        {t('labels.attached_file')}
                      </Typography>
                    </a>
                    <div
                      style={{
                        width: '100%',
                        marginTop: '25px',
                        background: '#cec3c3',
                        height: '1px'
                      }}
                    />
                  </Box>

                  {studentAssignment && (
                    <>
                      <Typography fontSize={{ xs: 14, sm: 18 }} fontWeight={'bold'} mb={'16px'}>
                        {t('labels.myAssignmentLabel')}
                      </Typography>

                      <Typography fontSize={'16px'} fontWeight={'regular'} mt={1}>
                        {studentAssignment?.title ?? '-'}
                      </Typography>

                      <Box display={'flex'} my={'8px'}>
                        <a
                          href={studentAssignment?.link}
                          style={{ textDecoration: 'underline', color: '#2A7DE1' }}>
                          <Typography fontSize={'16px'} fontWeight={'regular'} color={'#007FFF'}>
                            {studentAssignment?.link
                              ? t('table.assignmentLink')
                              : t('table.assignmentLink')}
                          </Typography>
                        </a>
                      </Box>
                      {studentAssignment?.attachment ? (
                        <a
                          href={studentAssignment?.attachment}
                          rel="noreferrer"
                          target="_blank"
                          download
                          style={{ textDecoration: 'underline', color: '#2A7DE1' }}>
                          <Typography fontSize={'16px'} fontWeight={'regular'} color={'#007FFF'}>
                            {t('labels.uploaded_file')}
                          </Typography>
                        </a>
                      ) : (
                        ''
                      )}
                    </>
                  )}

                  {deleteLoading && <Loader />}
                  {assignment?.homework.status === 'finished' && (
                    <Typography mt={1} mb={-2} color={'red'} fontSize={16}>
                      {t('labels.overdue')}
                    </Typography>
                  )}

                  <UploadForm onSubmit={submit}>
                    <Button
                      disabled={assignment?.homework.status === 'finished'}
                      type="submit"
                      variant="filled">
                      <Typography
                        fontSize={15}
                        fontWeight={450}
                        textTransform="uppercase"
                        sx={{
                          opacity: assignment?.homework.status === 'finished' ? 0.7 : 1
                        }}>
                        {studentAssignment ? t('labels.edit') : t('labels.upload')}
                      </Typography>
                      {!studentAssignment && (
                        <PlusIcon
                          style={{ opacity: assignment?.homework.status === 'finished' ? 0.25 : 1 }}
                        />
                      )}
                    </Button>
                  </UploadForm>
                </AssignContent>
              </AssignUpload>
            )}
          </Box>
        </Grid>
        <Grid item display={{ xs: 'none', sm: 'block' }} sm={2.8}>
          <Card variant="outlined" sx={{ background: '#f8f8f8 !important' }}>
            <CardHeaderBlack sx={{ height: '64px' }}>
              <Box width="100%">
                <Typography fontSize={20} color="white" fontWeight={700}>
                  {t('course.kahoot')}
                </Typography>
              </Box>
            </CardHeaderBlack>
            <StyledCardContent>
              <Box padding="24px 24px 24px 16px">
                <Box>
                  <KahootRating list={scores} />
                </Box>
              </Box>
            </StyledCardContent>
            {!isAdmin && (
              <>
                <RankingStatus list={scores} />
                <StyledCardContent>
                  <CardContent
                    sx={{
                      border: 'none !important',
                      marginTop: '24px',
                      borderRadius: '16px'
                    }}>
                    <CardHeaderBlack sx={{ height: '64px' }}>
                      <Box width="100%">
                        <Typography fontSize={20} color="white" fontWeight={700}>
                          {t('course.progress')}
                        </Typography>
                      </Box>
                    </CardHeaderBlack>
                    <Box
                      pb={4}
                      display="flex"
                      justifyContent="center"
                      marginTop={{ xs: 3, l: 5 }}
                      marginBottom={{ xs: 4, l: 8 }}>
                      <ProgressBar progress={progress} />
                    </Box>
                  </CardContent>
                </StyledCardContent>
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

export default UserHomeWork;
