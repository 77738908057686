import React, { useState } from 'react';
import useToggle from 'src/hooks/useToggle';
import { useTranslation } from 'react-i18next';
import { CourseInfo } from 'src/models/service';
import Loader from 'src/components/common/Loader';
import { useAuth } from 'src/context/authContext';
import Navigate from 'src/components/common/navigate';
import { useNavigate } from 'src/context/languageContext';
import AlertModal from 'src/components/modals/AlertModal';
import { NewContentWrapper, ContentCircle } from './styles';
import AuthWrapper from 'src/components/layouts/AuthWrapper';
import { Box, Dialog, Grid, Typography } from '@mui/material';
import useCareerContent from 'src/hooks/queries/useCareerContent';
import CareerCareCard from 'src/components/common/careerCareCard';
import RecipientsModal from 'src/components/modals/RecipientsModal';
import useExtendedCourses from 'src/hooks/queries/useExtendedCourses';
import PublishContentForm from 'src/components/UI/forms/PublishContent';
import { ReactComponent as PlusIcon } from 'src/assets/icons/content.svg';
import useDeleteCareerContent from 'src/hooks/mutations/useDeleteCareerContent';

const getRecipients = (recipientIds: number[], courses: any[]) => {
  const recipients: CourseInfo[] = [];
  recipientIds?.forEach((id) => recipients?.push(courses?.find((rec) => rec.id === id)));

  return recipients;
};

const CareerCare = () => {
  const [recModalShown, setRecModalShown] = useToggle();
  const [alertModalShown, setAlertModalShown] = useToggle();
  const [publishModalShown, setPublishModalShown] = useToggle();
  const [editableCourseId, setEditableCourseId] = useState<any>();

  const { courses = [] } = useExtendedCourses();

  const { isAdmin } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [contentInfo, setContentInfo] = useState<any>();

  const { mutate } = useDeleteCareerContent();
  const { contents, isLoading } = useCareerContent();

  if (!isAdmin) return <Navigate to="/career-content" />;

  const recipientIds = contentInfo?.content_courses?.map((course: any) => course.course_id);
  const recipients = getRecipients(recipientIds, courses);

  return (
    <AuthWrapper isCareerCare>
      <Box
        display="flex"
        bgcolor="black"
        alignItems="center"
        justifyContent="space-between"
        pl={{ sm: 4, xs: 2 }}
        height={{ sm: 64, xs: 51 }}
        sx={{
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10
        }}>
        <Typography fontSize={{ sm: 20, xs: 16 }} color="white" fontWeight={700} minHeight="24px">
          {t('careerCare.availableContent')}
        </Typography>
      </Box>
      <Grid gap={3} container xs={12} sx={{ padding: '24px', background: '#fff' }} mb={24}>
        <Grid item xs={12} display="flex" flexWrap="wrap" gap={3} minHeight="300px">
          {isLoading && <Loader />}
          {!isLoading && (
            <>
              <NewContentWrapper onClick={() => navigate('/career-care/create-content')}>
                <ContentCircle>
                  <PlusIcon />
                </ContentCircle>
                <Typography textTransform="uppercase">{t('button.newContent')}</Typography>
              </NewContentWrapper>
              {contents?.map((cnt) => (
                <CareerCareCard
                  key={cnt.id}
                  content={cnt}
                  onShowRecipients={() => {
                    setContentInfo(cnt);
                    setRecModalShown();
                  }}
                  onDeleteContent={() => {
                    setContentInfo(cnt);
                    setAlertModalShown();
                  }}
                  onPublishContent={() => {
                    setEditableCourseId(null);
                    setPublishModalShown();
                    setContentInfo(cnt);
                  }}
                />
              ))}
            </>
          )}
        </Grid>
        <RecipientsModal
          editRecipientHandler={(idx) => {
            setEditableCourseId(idx);
            setRecModalShown();
            setPublishModalShown();
          }}
          open={recModalShown}
          recipients={recipients}
          close={setRecModalShown}
          title={contentInfo?.title}
          content={contentInfo?.content_courses}
        />
        <AlertModal
          open={alertModalShown}
          close={setAlertModalShown}
          onConfirm={() => {
            mutate({ id: contentInfo?.id }, { onSuccess: () => setAlertModalShown() });
          }}
          confirmationText={t('modal.deleteConfirmation', { name: t('common.content') })}
        />
        <Dialog
          open={publishModalShown}
          onClose={setPublishModalShown}
          PaperProps={{ style: { maxWidth: '540px', borderRadius: '16px' } }}>
          <PublishContentForm
            contentInfo={contentInfo}
            close={setPublishModalShown}
            editableId={editableCourseId}
          />
        </Dialog>
      </Grid>
    </AuthWrapper>
  );
};

export default CareerCare;
