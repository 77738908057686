import React, { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useAuth } from 'src/context/authContext';
import Loader from 'src/components/common/Loader';
import { getTabValue } from 'src/utils';
import useProfile from 'src/hooks/queries/useProfile';
import TabPanel from 'src/components/UI/tabPanel/TabPanel';
import useExtendedCourses from 'src/hooks/queries/useExtendedCourses';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { useLanguage, useNavigate } from 'src/context/languageContext';
import AuthWrapper from 'src/components/layouts/AuthWrapper';
import { COURSE_TABS } from 'src/constants';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const adminTabsObj = {
  specific: {
    ge: 'სპეციალიზაცია',
    en: 'specialization'
  },
  general: {
    en: 'general',
    ge: 'ზოგადი'
  }
};

const Course: FC = () => {
  const { t } = useTranslation();
  const { locale } = useLanguage();
  const navigate = useNavigate();
  const { isAdmin } = useAuth();
  const [src] = useSearchParams();
  const { pathname } = useLocation();
  const [value, setValue] = useState(0);
  const { courses = [] } = useExtendedCourses();
  const { myCourses = [], isLoading } = useProfile();

  const adminTab = pathname.split('/').pop();
  const courseId = src.get('course');
  const chapterId = src.get('chapterId');
  const userCourse = courses?.find((course) => course.id === Number(courseId));

  useEffect(() => {
    const tabValue = getTabValue(pathname);

    setValue(tabValue);
  }, [pathname]);

  useEffect(() => {
    if (!isAdmin && myCourses[0]?.courseId) {
      navigate(`/my-course/general?course=${myCourses[0]?.courseId}`);
    } else if (isAdmin && courses[courses.length - 1]?.id && !chapterId) {
      navigate(`/my-course/general?chapterId=${courses[courses.length - 1].id}`);
    }
  }, [isAdmin, myCourses[0]?.courseId, chapterId]); // eslint-disable-line

  if (isLoading) return <Loader containerStyle={{ height: '100vh' }} />;

  return (
    <Grid container paddingBottom={{ xs: 4, l: 10 }} sx={{ background: '#f8f8f8' }}>
      <AuthWrapper tabs={COURSE_TABS} userPage={!isAdmin} isCourses>
        <Grid item xs={12} sx={{ marginBottom: { xs: 20, sm: 12 } }}>
          <Grid
            item
            height={'64px'}
            xs={isAdmin && !chapterId ? 12 : 8.8}
            sx={{
              background: 'black',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              alignItems: 'center',
              paddingLeft: '32px'
            }}
            display={{ xs: 'none', sm: 'flex' }}>
            <Typography
              fontSize={{ sm: 20, xs: 16 }}
              lineHeight="24px"
              color="white"
              fontWeight={700}>
              {adminTab && isAdmin && userCourse
                ? adminTabsObj[adminTab][locale]
                : `${userCourse?.name || ''} ${t('course.content')}`}
            </Typography>
          </Grid>

          <Typography
            color="text.primary"
            fontFamily="basenji-variable"
            fontSize={24}
            display={{ xs: 'block', sm: 'none' }}>
            /{' '}
            {adminTab && isAdmin && userCourse
              ? adminTabsObj[adminTab][locale]
              : `${userCourse?.name || ''} ${t('course.content')}`}
          </Typography>

          <Box sx={{ width: '100%' }}>
            <TabPanel value={value} index={value}>
              <Outlet />
            </TabPanel>
          </Box>
        </Grid>
      </AuthWrapper>
    </Grid>
  );
};

export default Course;
