import React, { FC } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { SpecHeader, StyledCard, SpecContent } from './styles';
import webImage from 'src/assets/icons/web-development.svg';
import { SubCourseInfo } from 'src/models/service';

interface Props {
  spec: SubCourseInfo;
  clickHandler?: (spec: SubCourseInfo) => void;
  width?: string;
  isSelected?: boolean;
  disabled?: boolean;
  isGeneral?: boolean;
  image?: string;
}

const SpecCard: FC<Props> = ({
  spec,
  width,
  clickHandler,
  disabled = false,
  isGeneral,
  isSelected = false,
  image = ''
}) => {
  const { name = '', description = '' } = spec || {};
  return (
    <StyledCard
      width={width}
      selected={isSelected}
      disabled={disabled}
      onClick={() => clickHandler && clickHandler(spec)}>
      <SpecHeader width={width}>
        <img src={image ? image : webImage} alt={name} />
      </SpecHeader>
      <SpecContent>
        <Typography
          color="text.primary"
          variant="subtitle4"
          fontWeight="fontWeightBold"
          component={Box}>
          {name}
        </Typography>
        {!isGeneral && <Typography variant="descriptionExtraSmall">{description}</Typography>}
      </SpecContent>
    </StyledCard>
  );
};

export default SpecCard;
