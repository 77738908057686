import { LOCALES } from 'src/enums';
import { useLanguage } from '../context/languageContext';

const useLanguageCode = () => {
  const { locale } = useLanguage();

  let lang;
  let langCode;

  switch (locale) {
    case LOCALES.EN:
      langCode = '1';
      lang = 'en';
      break;
    case LOCALES.GE:
      langCode = '2';
      lang = 'ka';
      break;
    default:
      langCode = '1';
      lang = 'en';
      break;
  }

  return { langCode, lang };
};

export default useLanguageCode;
