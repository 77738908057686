import React, { FC } from 'react';
import { CareerCard } from './styles';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { Box, Button, Grid } from '@mui/material';
import { CareerContentItem } from 'src/models/service';
import { useNavigate } from 'src/context/languageContext';
import contentIcon from 'src/assets/icons/menu-career-care.svg';
import { ReactComponent as DeleteIcon } from 'src/assets/icons/delete.svg';
interface CareerCareCardProps {
  content: CareerContentItem;
  onDeleteContent: () => void;
  onShowRecipients: () => void;
  onPublishContent: () => void;
}

const CareerCareCard = ({
  content,
  onDeleteContent,
  onShowRecipients,
  onPublishContent
}: CareerCareCardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { title, publish_date: date, id, content_courses, assignments } = content || {};

  return (
    <CareerCard item padding={3} display="flex" flexDirection="column" gap={2}>
      <Grid display="flex" justifyContent="space-between">
        <img src={contentIcon} style={{ width: '40px', height: '40px' }} />
        <DeleteIcon onClick={onDeleteContent} style={{ cursor: 'pointer' }} />
      </Grid>
      <Grid gap={1} display="flex" flexDirection="column">
        <Typography
          fontSize={20}
          color="violet"
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate(`/career-content?contentId=${id}`)}>
          {title}
        </Typography>
        <Typography fontSize={16} fontWeight="900" mt={1}>
          {t('careerCare.publishDate')}:
        </Typography>
        <Typography fontSize={16} fontWeight="400" mt={-1} height="16px">
          {date?.slice(0, 16)}
        </Typography>
        <Typography
          fontSize={16}
          variant="subtitle3"
          sx={{ cursor: 'pointer' }}
          mt={1}
          color={content_courses.length > 0 ? 'violet' : '#ACACAC'}
          onClick={() => {
            if (content_courses.length > 0) onShowRecipients();
          }}>
          {content_courses.length > 0 ? t('careerCare.recipients') : t('careerCare.noRecipients')}
        </Typography>

        <Typography
          mt={-1}
          fontSize={16}
          color="violet"
          sx={{ cursor: 'pointer', opacity: assignments.length > 0 ? 1 : 0.5 }}
          onClick={() => {
            if (assignments.length > 0) {
              navigate(`/career-list/${id}`);
            }
          }}>
          {assignments.length > 0 ? 'See All' : 'No'} Assignments
        </Typography>

        <Grid item display="flex" gap={1} mt={3}>
          <Button variant="primary" sx={{ height: '48px !important' }} onClick={onPublishContent}>
            <Typography textTransform="uppercase" fontSize="14px !important">
              {t('button.publish')}
            </Typography>
          </Button>
          <Button
            variant="secondary"
            sx={{ height: '48px !important' }}
            onClick={() => navigate(`/career-care/create-content?id=${id}`)}>
            <Typography fontSize="14px !important" textTransform="uppercase">
              {t('common.edit')}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </CareerCard>
  );
};

export default CareerCareCard;
