import React, { ChangeEvent, FormEvent, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled
} from '@mui/material';
import { useLanguage } from 'src/context/languageContext';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { axiosSyllabusInstance } from 'src/services/api';
import * as XLSX from 'xlsx';
import Toast from 'src/components/modals/Toast';
import ArrowBack from '@mui/icons-material/ArrowBack';
import useToggle from 'src/hooks/useToggle';
import useAssignment from 'src/hooks/queries/useAssignment';
import useCourse from 'src/hooks/queries/useCourse';
import { ReactComponent as CheckboxChecked } from 'src/assets/icons/success.svg';
import { ReactComponent as CheckboxUnChecked } from 'src/assets/icons/unChecked.svg';

const TabCell = styled(TableCell)`
  border-right: 1px solid #e4e4e4;
`;

interface SubData {
  id: number;
  score: number;
}

const Received: React.FC = () => {
  const { locale } = useLanguage();
  const { t } = useTranslation();
  const { homeworkId } = useParams();
  const navigate = useNavigate();

  const [srch] = useSearchParams();
  const [showAlertModal, setShowAlertModal] = useToggle();
  const { data: homework } = useAssignment(homeworkId!);
  const { data: course } = useCourse(srch.get('course')!);
  const [subData, setSubData] = useState<SubData[]>([] as SubData[]);

  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>, id: number) => {
    const points = e.target.checked ? homework?.homework.points : 0;
    const exists = subData.find((s) => s.id === id) as SubData;

    if (exists) {
      exists.score = points!;
    } else {
      setSubData([...subData, { id, score: points! }]);
    }
  };

  const submit = async (e: FormEvent) => {
    e.preventDefault();

    const sub = subData.map((d) =>
      axiosSyllabusInstance.post(`api/assignments/grade/${d.id}`, { score: d.score })
    );
    const res = await Promise.all(sub);
    if (res.length > 0) {
      setShowAlertModal();
    }
  };

  const data = homework?.homework.assignments.map((data) => ({
    Student: data.user_name,
    Email: data.user_email,
    'Submission date': data.submission_date,
    Score: data.score,
    Link: data.link,
    Attachment: data.attachment,
    Text: String(data.title)
  }));

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data!);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'assignments.xlsx');
  };

  return (
    <Grid pb={20}>
      <Toast
        open={showAlertModal}
        close={setShowAlertModal}
        confirmationText="Assignment grading updated"
      />
      <Box
        padding="24px"
        textAlign={{ sm: 'center', md: 'start' }}
        sx={{
          background: '#fff',
          border: '1px solid #DEDEDE',
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8
        }}>
        <Grid
          gap={1}
          container
          alignItems="center"
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate(-1)}>
          <ArrowBack sx={{ color: '#2A7DE1' }} />
          <Typography variant="body1" color="violet" fontSize={16}>
            {t('careerCare.backToList')}
          </Typography>
        </Grid>
        <TableContainer component={Paper} variant="outlined" sx={{ marginTop: '32px' }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TabCell sx={{ fontSize: 16 }}>{t('table.student')}</TabCell>
                <TabCell sx={{ fontSize: 16 }}>{t('table.email')}</TabCell>
                <TabCell sx={{ fontSize: 16 }}>{t('table.submissionDate')}</TabCell>
                <TabCell sx={{ fontSize: 16 }}>{t('table.score')}</TabCell>
                <TabCell sx={{ fontSize: 16 }}>{t('table.link')}</TabCell>
                <TabCell sx={{ fontSize: 16 }}>{t('table.file')}</TabCell>
                <TabCell sx={{ fontSize: 16 }}>{t('table.text')}</TabCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {homework?.homework.assignments
                ?.sort((a: any, b: any) => (a.id > b.id ? 1 : -1))
                .map((row: any) => (
                  <TableRow key={row.id}>
                    <TabCell component="th" scope="row" sx={{ borderRight: '1px solid #e4e4e4' }}>
                      {row.user_name} {row.user_lastname}
                    </TabCell>
                    <TabCell>
                      <Typography
                        fontWeight="normal"
                        fontSize={14}
                        sx={{ textDecoration: 'underline' }}>
                        {row.user_email}
                      </Typography>
                    </TabCell>
                    <TabCell>{row.submission_date}</TabCell>

                    <TabCell>
                      <Checkbox
                        defaultChecked={row.score > 0}
                        icon={<CheckboxUnChecked />}
                        checkedIcon={<CheckboxChecked />}
                        onChange={(e) => handleCheckbox(e, row.id)}
                        sx={{
                          marginLeft: '-3px'
                        }}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </TabCell>

                    <TabCell>
                      <a
                        href={row.link}
                        rel="noreferrer"
                        target="_blank"
                        download
                        style={{ textDecoration: 'underline', textDecorationColor: '#007FFF' }}>
                        <Typography
                          fontSize={'15px'}
                          color={row.link ? '#007FFF' : '#c6c8cc'}
                          fontWeight="normal">
                          Assignment URL
                        </Typography>
                      </a>
                    </TabCell>
                    <TabCell>
                      <a
                        href={row.attachment}
                        rel="noreferrer"
                        target="_blank"
                        download
                        style={{
                          pointerEvents: !row.attachment ? 'none' : 'all'
                        }}>
                        <Typography
                          fontSize={'15px'}
                          color={row.attachment ? '#007FFF' : '#c6c8cc'}
                          fontWeight="normal">
                          Uploaded File
                        </Typography>
                      </a>
                    </TabCell>
                    <TabCell sx={{ maxWidth: '275px' }}>
                      <Typography fontSize={'15px'} fontWeight={'regular'}>
                        {row.title}
                      </Typography>
                    </TabCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box display="flex" alignItems="center" gap={1} mt={6} justifyContent="flex-end">
          {subData.length > 0 && (
            <Button onClick={submit} variant="secondary">
              <Typography>{t('button.submit')}</Typography>
            </Button>
          )}

          <Button onClick={exportToExcel} variant="primary">
            <Typography>export excel</Typography>
          </Button>
        </Box>
      </Box>
    </Grid>
  );
};

export default Received;
