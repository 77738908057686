import React from 'react';
import { Box, Typography } from '@mui/material';

export interface IUpEvent {
  id: number;
  end: string;
  start: string;
  title: string;
}

const UpcomingEvent = ({ event }: { event: IUpEvent }) => {
  const { start, end, title } = event;

  return (
    <Box
      borderRadius={'8px'}
      bgcolor={'#CDDB0080'}
      minHeight={'120px'}
      padding={'16px'}
      marginBottom={'16px'}>
      <Typography fontSize={'24px'} fontWeight={'bold'}>
        {start} - {end}
      </Typography>
      <Typography fontSize={'16px'}>{title}</Typography>
    </Box>
  );
};

export default UpcomingEvent;
