import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import React, { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { homeworkSchema } from 'src/helpers/validations';
import useUploadHomework from 'src/hooks/mutations/useUploadHomework';
import { ErrorMessages } from 'src/models/service';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Loader from 'src/components/common/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import useMonth from 'src/hooks/queries/useMonth';
import { useLanguage } from 'src/context/languageContext';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as PlusIcon } from 'src/assets/icons/addPlus.svg';
import useCategories from 'src/hooks/queries/useCategories';

const AssignmentForm: React.FC = () => {
  const { t } = useTranslation();
  const { mutate, isLoading } = useUploadHomework();
  const navigate = useNavigate();
  const { locale } = useLanguage();
  const [errors] = useState<ErrorMessages | null>(null);
  const ref = createRef<HTMLInputElement>();
  const [file, setFile] = useState<File | null>();
  const { courseId } = useParams();
  const { data } = useMonth(courseId!, 'general');

  const { categories = [] } = useCategories();

  const [week, setWeek] = useState<number | null>(null);
  const [month, setMonth] = useState<number | null>(null);
  interface FormData {
    title: string;
    description: string;
    attachment: File | null;
    points: number | null;
    due_date: Date | null;
    status: string;
    estimation: string;
    subject_id: number | null;
    category: number[];
  }

  const formik = useFormik<FormData>({
    initialValues: {
      title: '',
      description: '',
      attachment: null,
      points: null,
      due_date: null,
      status: 'active',
      subject_id: null,
      estimation: '',
      category: []
    },
    validationSchema: homeworkSchema(t),
    validateOnMount: true,
    onSubmit: async ({
      title,
      description,
      attachment,
      points,
      status,
      due_date,
      subject_id,
      estimation,
      category
    }) => {
      mutate(
        {
          title,
          description,
          attachment,
          points,
          status,
          due_date,
          subject_id,
          chapter_id: courseId!,
          estimation,
          evaluation_criteria: category
        },
        {
          onSuccess: () => {
            navigate(`/${locale}/assignments/${courseId}`);
          }
        }
      );
    },

    validateOnBlur: false,
    validateOnChange: true
  });

  useEffect(() => {
    if (file && file instanceof File) {
      formik.setFieldValue('attachment', file);
    }
  }, [file]); // eslint-disable-line

  if (isLoading) {
    return (
      <Grid container justifyContent="center" maxWidth="650px" height="550px" margin="auto">
        <Loader />;
      </Grid>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Grid
            gap={1}
            container
            alignItems="center"
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate(-1)}>
            <ArrowBack sx={{ color: '#2A7DE1' }} />
            <Typography variant="body1" color="violet" fontSize={16}>
              {t('careerCare.backToList')}
            </Typography>
          </Grid>
          <Typography variant="body1" fontSize={20} mt={4} mb={2}>
            {t('labels.assignment')}
          </Typography>
          <Divider />
          <Box mt={2}>
            <Grid container alignItems="center" rowGap={0.4} spacing={2}>
              <Grid item xs={4}>
                <TextField
                  sx={{ marginTop: '6px' }}
                  name="description"
                  variant="outlined"
                  onBlur={formik.handleBlur}
                  label={t('labels.description')}
                  onChange={formik.handleChange}
                  value={formik.values.description}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="estimation"
                  variant="outlined"
                  sx={{ marginTop: '28px' }}
                  label={t('labels.estimation')}
                  onChange={formik.handleChange}
                  value={formik.values.estimation}
                  defaultValue={formik.values.estimation}
                  helperText={'Format: 1m, 2h, 3d'}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl>
                  <InputLabel>{t('labels.category')}</InputLabel>
                  <Select
                    multiple
                    name="category"
                    value={formik.values.category}
                    sx={{ height: '51px' }}
                    variant="outlined"
                    label={t('labels.category')}
                    onChange={formik.handleChange}>
                    {categories.map((ctgr: any) => {
                      return (
                        <MenuItem key={ctgr} value={ctgr.id}>
                          {ctgr.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl>
                  <InputLabel id="month">{t('labels.chooseMonth')}</InputLabel>
                  <Select
                    name="month"
                    label={t('labels.chooseMonth')}
                    onChange={(e) => {
                      setMonth(Number(e.target.value));
                      setWeek(null);
                    }}
                    sx={{ height: '51px' }}>
                    {data?.data.map((m: any) => (
                      <MenuItem key={m.id} value={m.id}>
                        Month {m.month}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {month && (
                <Grid item xs={4}>
                  <FormControl>
                    <InputLabel id="month">{t('labels.week')}</InputLabel>
                    <Select
                      value={week}
                      name="month"
                      label={t('labels.week')}
                      onChange={(e) => setWeek(Number(e.target.value))}
                      sx={{ height: '51px' }}>
                      {data?.data
                        .find((m: any) => m.id === month)
                        .week.map((w: any) => (
                          <MenuItem key={w.id} value={w.id}>
                            week {w.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={4}>
                <FormControl>
                  <InputLabel id="subject_id">{t('labels.chooseSubject')}</InputLabel>
                  <Select
                    name="subject_id"
                    onBlur={formik.handleBlur}
                    label={t('labels.chooseSubject')}
                    onChange={formik.handleChange}
                    disabled={!month || !week}
                    value={formik.values.subject_id}
                    sx={{
                      height: '51px',
                      textAlign: 'start',
                      borderRadius: '8px',
                      border:
                        formik.touched.subject_id && Boolean(formik.errors.subject_id)
                          ? '1px solid red'
                          : ''
                    }}>
                    {week &&
                      data?.data
                        .find((m: any) => m.id === month)
                        .week.find((w: any) => w.id === week)
                        .subjects.map((s: any) => (
                          <MenuItem
                            onClick={() => formik.setFieldValue('title', s.title)}
                            key={s.id}
                            value={s.id}>
                            {s.title}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  type="date"
                  name="due_date"
                  variant="outlined"
                  sx={{ height: '51px' }}
                  label={t('labels.due_date')}
                  onChange={formik.handleChange}
                  value={formik.values.due_date as Date}
                  InputLabelProps={{ shrink: true }}
                  helperText={
                    formik.touched.due_date && Boolean(formik.errors.due_date) && 'Date is required'
                  }
                  error={formik.touched.due_date && Boolean(formik.errors.due_date)}
                  FormHelperTextProps={{
                    error:
                      formik.touched.due_date && Boolean(formik.errors.due_date || errors?.due_date)
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl>
                  <InputLabel id="status">{t('labels.status')}</InputLabel>
                  <Select
                    name="status"
                    label={t('labels.status')}
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.status}
                    error={formik.touched.status && Boolean(formik.errors.status)}
                    sx={{
                      height: '51px',
                      textAlign: 'start',
                      borderRadius: '8px'
                    }}>
                    <MenuItem value={'active'}>active</MenuItem>
                    <MenuItem value={'finished'}>finished</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name="points"
                  variant="outlined"
                  sx={{ height: '51px' }}
                  onBlur={formik.handleBlur}
                  label={t('labels.points')}
                  value={formik.values.points}
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.points && formik.errors.points && 'Max points required'
                  }
                  error={formik.touched.points && Boolean(formik.errors.points)}
                  FormHelperTextProps={{
                    error: formik.touched.points && Boolean(formik.errors.points)
                  }}
                />
              </Grid>
            </Grid>

            <Grid mt={6}>
              <Box display="flex" gap={2} alignItems="center">
                <Button
                  onClick={() => {
                    if (!file) {
                      ref.current?.click();
                    }
                  }}
                  variant="filled">
                  <input
                    ref={ref}
                    type="file"
                    hidden
                    onChange={(e) => {
                      if (e.target.files) {
                        setFile(e.target.files[0]);
                      }
                    }}
                  />
                  <Typography
                    fontSize={16}
                    color="darkblue"
                    fontWeight={450}
                    textTransform="uppercase">
                    {t('button.upFile')}
                    <PlusIcon />
                  </Typography>
                </Button>
                {file && (
                  <Box display="flex" gap="6px">
                    <Typography variant="listItem" fontSize={16} color="black">
                      {file.name.substring(0, 20)}
                    </Typography>
                    <CloseIcon
                      sx={{ cursor: 'pointer', background: '#00000029', fontSize: 20 }}
                      onClick={() => {
                        setFile(null);
                      }}
                    />
                  </Box>
                )}
              </Box>
              {!formik.values.attachment && formik.submitCount > 0 && (
                <FormHelperText sx={{ margin: '16px 0px 0px 8px' }} error>
                  Attachment is required
                </FormHelperText>
              )}
            </Grid>

            <Box
              mt={15}
              display="flex"
              gap={{ sm: 2, xs: 0.5 }}
              justifyContent={{ sm: 'flex-end', xs: 'center' }}
              alignItems="center">
              <Button
                type="submit"
                variant="primary"
                sx={{
                  height: '56px !important',
                  minWidth: '170px'
                }}>
                <Typography fontSize={'16px !important'} mr={1.5}>
                  {t('button.submit')}
                </Typography>
              </Button>

              <Button sx={{ height: '54px' }} variant="secondary">
                <Typography fontSize={'15px'} mr={1.5}>
                  {t('button.cancel')}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default AssignmentForm;
