import React from 'react';
import { useTranslation } from 'react-i18next';
import palette from 'src/theme/overrides/palette';
import useDetectDevice from 'src/hooks/useDetectDevice';
import { useNavigate } from 'src/context/languageContext';
import { Grid, Typography, Box, Button } from '@mui/material';

const PaymentFail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useDetectDevice();

  return (
    <Grid container rowGap={4} px={3} py={3}>
      <Typography
        color="red"
        fontWeight="bold"
        textTransform="uppercase"
        fontSize={{ sm: 32, xs: 24 }}>
        {t('course.paymentFail')}
      </Typography>
      <Typography variant="h4" fontSize={{ l: 22, xs: 18 }}>
        {t('course.payFailSubtitle')}
      </Typography>
      <Box display="flex" gap={{ sm: 3, xs: 1 }} alignItems="center" flexWrap="wrap">
        <Button
          variant="containedPrimary"
          sx={{
            height: '50px',
            borderRadius: '30px',
            background: palette.text.primary
          }}
          onClick={() => navigate('/register')}>
          <Typography color="white" textTransform="uppercase" fontSize={16}>
            {t('course.tryAgain')}
          </Typography>
        </Button>
        <Button
          variant="text"
          fullWidth={isMobile}
          sx={{ borderRadius: '30px', border: '1px solid #000', height: '48px' }}
          onClick={() => navigate('')}>
          <Typography fontSize={16} textTransform="uppercase">
            {t('course.home')}
          </Typography>
        </Button>
      </Box>
    </Grid>
  );
};

export default PaymentFail;
