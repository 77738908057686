import React, { FC } from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import EditWeek from '../forms/EditWeek';
import Button from '@mui/material/Button';
import useToggle from 'src/hooks/useToggle';
import NewSubject from '../forms/NewSubject';
import LectureDetails from './LectureDetails';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/context/authContext';
import CardContent from '@mui/material/CardContent';
import { useMonth } from 'src/context/monthContext';
import { CourseMonth, Week } from 'src/models/general';
import AlertModal from 'src/components/modals/AlertModal';
import useDeleteWeek from 'src/hooks/mutations/useDeleteWeek';
import { Dialog, DialogContent, Typography } from '@mui/material';
import DropDownContainer from 'src/components/common/dropDownContainer';
import { ReactComponent as PlusIcon } from 'src/assets/icons/addPlus.svg';
interface Props {
  week: Week;
  weekOpen?: boolean;
  monthId: number;
  months: CourseMonth[];
}

const CourseWeek = ({ week, weekOpen, monthId, months }: Props) => {
  const { isAdmin } = useAuth();
  const { t } = useTranslation();
  const { activeSubject } = useMonth();

  const { mutate: deleteWeek } = useDeleteWeek();
  const [showAlertModal, setShowAlertModal] = useToggle();
  const [showAddSubject, setShowAddSubject] = useToggle();
  const [editWeekModalShown, setEditWeekModalShown] = useToggle();
  const { title, subjects = [], group_week: groupWeek = [] } = week;

  const isDisabled =
    groupWeek.some((group) => group.is_disabled === true && group.week_id === week.id) && !isAdmin;

  const subs = isAdmin
    ? subjects
    : subjects.filter(
        (sub) =>
          !(
            sub?.title?.split(' ')[0] === 'ნაწილი' ||
            sub?.title?.toLowerCase().split(' ')[0] === 'block'
          )
      );

  const deleteWeekHandler = () => {
    deleteWeek({ week_id: week.id, month_id: monthId });
    setShowAlertModal();
  };

  return (
    <Card variant="outlined">
      <DropDownContainer
        initialOpenState={weekOpen}
        renderChildren={!isDisabled}
        onEditWeek={(e) => {
          e.stopPropagation();
          setEditWeekModalShown();
        }}
        onRemoveWeek={(e) => {
          e.stopPropagation();
          setShowAlertModal();
        }}
        title={`${t('common.week')} ${title}`}>
        <CardContent className="border-top-0" sx={{ border: 'none !important' }}>
          <Grid
            container
            paddingTop={3}
            paddingBottom={2}
            justifyContent="center"
            rowSpacing={isAdmin ? 2 : 0}>
            {isAdmin && (
              <>
                <Grid
                  item
                  xs={12}
                  display="flex"
                  px={{ xs: 2, l: 5 }}
                  flexDirection="column"
                  justifyContent="center">
                  <Grid display="flex" justifyContent="center">
                    <Button variant="filled" onClick={setShowAddSubject}>
                      <Typography
                        fontSize={16}
                        color="darkblue"
                        fontWeight="bold"
                        textTransform="uppercase">
                        {t('button.addSubject')}
                        <PlusIcon />
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
                {showAddSubject && (
                  <Grid
                    item
                    px={2}
                    mt={2}
                    l={11.5}
                    xs={11.2}
                    borderRadius="8px"
                    border="1px solid #C5CAD3">
                    <NewSubject weekId={week.id} close={setShowAddSubject} />
                  </Grid>
                )}
              </>
            )}
            <Grid item xs={12} px={{ sm: 2, xs: 0 }}>
              <Stack direction="column" spacing={2}>
                {!isDisabled
                  ? subs.map((sub) => {
                      return (
                        <LectureDetails
                          key={sub.id}
                          lecture={sub}
                          initialOpen={sub.id == activeSubject?.id}
                        />
                      );
                    })
                  : null}
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </DropDownContainer>
      <Dialog open={editWeekModalShown} onClose={setEditWeekModalShown}>
        <DialogContent sx={{ width: { sm: 436, l: 546 } }}>
          <EditWeek close={setEditWeekModalShown} data={months} weekId={week.id} />
        </DialogContent>
      </Dialog>
      <AlertModal
        open={showAlertModal}
        close={setShowAlertModal}
        onConfirm={deleteWeekHandler}
        confirmationText={t('modal.weekConfirmation')}
      />
    </Card>
  );
};

export default CourseWeek;
