import { QUERY_KEYS } from 'src/enums';
import { useQuery } from '@tanstack/react-query';
import { getNews } from 'src/services/api';
import { NewsResponse } from 'src/models/service';

const useNews = () => {
  const { data, isLoading } = useQuery<NewsResponse>([QUERY_KEYS.NEWS], () => getNews());

  return {
    data,
    isLoading
  };
};

export default useNews;
