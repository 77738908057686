import { createContext, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LOCALES, LOCALSTORAGE_KEYS } from 'src/enums';
import { checkUrlLang, generateLangRoute } from 'src/utils';
import React, { useContext, useEffect, useState } from 'react';
import { To, Outlet, useParams, useNavigate as useNavigation, useLocation } from 'react-router-dom';

export type SupportedLanguage = 'en' | 'ge' | 'az';
interface LanguageContextIterface {
  locale: SupportedLanguage;
  changeLocale: () => void;
}

const LanguageContext = createContext<LanguageContextIterface | null>(null);

const LanguageContextProvider = () => {
  const navigate = useNavigation();
  const { i18n } = useTranslation();
  const { pathname, search } = useLocation();
  const { locale: langParam } = useParams<{ locale: SupportedLanguage }>();
  const [locale, setLocale] = useState<any>(langParam);

  const parsedLocale = Object.values(LOCALES).includes(locale)
    ? locale
    : localStorage.getItem(LOCALSTORAGE_KEYS.LOCALE) ?? 'en';

  useEffect(() => {
    i18n.changeLanguage(parsedLocale);
    localStorage.setItem(LOCALSTORAGE_KEYS.LOCALE, parsedLocale);

    const langExistInUrl = checkUrlLang(pathname, parsedLocale);

    let newRoute;

    if (!langExistInUrl) {
      newRoute = generateLangRoute(pathname.concat(search), parsedLocale, 0);
    } else {
      newRoute = generateLangRoute(
        pathname.concat(search),
        parsedLocale,
        pathname.split('/')[1].length + 1
      );
    }

    setLocale(parsedLocale);
    navigate(newRoute);
  }, [parsedLocale]); // eslint-disable-line

  const changeLocale = useCallback(() => {
    let newLocale: SupportedLanguage;
    if (process.env.REACT_APP_SKILLWILL_COUNTRY === 'az') {
      newLocale = locale === 'en' ? 'az' : 'en';
    } else {
      newLocale = locale === 'en' ? 'ge' : 'en';
    }

    setLocale(newLocale);
    i18n.changeLanguage(newLocale);
    localStorage.setItem(LOCALSTORAGE_KEYS.LOCALE, newLocale);

    navigate(generateLangRoute(pathname + search, newLocale));
  }, [i18n, locale, navigate, search, pathname]);

  const contextValues = useMemo(
    () => ({
      locale,
      changeLocale
    }),
    [locale, changeLocale]
  );

  return (
    <LanguageContext.Provider value={contextValues}>
      <Outlet />
    </LanguageContext.Provider>
  );
};

export default LanguageContextProvider;

export const useLanguage = () => {
  const value = useContext(LanguageContext);

  if (!value) {
    throw new Error('Language Context Provider is not defined');
  }

  return value;
};

export const useNavigate = () => {
  const navigate = useNavigation();

  const { locale } = useLanguage();

  return function navigationFn(to: To) {
    return navigate('/' + locale + to);
  };
};
