import React, { FC, ReactNode } from 'react';
import { Typography } from '@mui/material';
import { UserActionsContainer, Head, HeadBackground } from './styles';

import palette from 'src/theme/overrides/palette';

interface Props {
  title: string;
  button?: boolean;
  children: ReactNode;
}

const UserActionsCard: FC<Props> = ({ title, children }) => {
  return (
    <UserActionsContainer>
      <Head>
        <HeadBackground src={''} />
        <Typography variant="body1" color={palette.white}>
          {title}
        </Typography>
      </Head>
      {children}
    </UserActionsContainer>
  );
};

export default UserActionsCard;
