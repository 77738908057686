import useLanguageCode from 'src/hooks/useLanguageCode';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { QUERY_KEYS } from 'src/enums';
import { deleteWeek } from 'src/services/api';

const useDeleteWeek = () => {
  const client = useQueryClient();
  const { lang } = useLanguageCode();

  const { mutate, isLoading } = useMutation<
    { success?: boolean },
    AxiosError,
    { week_id: number; month_id: number }
  >(['delete-week'], ({ week_id, month_id }) => deleteWeek(week_id, { month_id }, lang), {
    onSuccess: () => {
      client.invalidateQueries([QUERY_KEYS.SYLLABUS]);
    }
  });

  return {
    mutate,
    isLoading
  };
};

export default useDeleteWeek;
