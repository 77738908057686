import { TFunction } from 'i18next';
import * as Yup from 'yup';

Yup.addMethod(Yup.string, 'integer', function () {
  return this.matches(/^\d+$/, 'The field should have digits only');
});

export const oceanNewsSchema = (translate: TFunction) => {
  return Yup.object({
    title_en: Yup.string().required(
      translate('validations.required', { field: translate('labels.title') })
    ),
    tags: Yup.array().min(1),
    kind: Yup.mixed().oneOf(['blogs', 'news']).required()
  });
};

export const tagSchema = (translate: TFunction) => {
  return Yup.object({
    name: Yup.string().required(
      translate('validations.required', { field: translate('labels.name') })
    )
  });
};

export const homeworkSchema = (translate: TFunction) => {
  return Yup.object({
    title: Yup.string().optional(),
    description: Yup.string().optional(),
    attachment: Yup.string().required(
      translate('validations.required', { field: translate('labels.attachment') })
    ),
    points: Yup.number().required(
      translate('validations.required', { field: translate('labels.points') })
    ),
    due_date: Yup.date().required(
      translate('validations.required', { field: translate('labels.date') })
    ),
    status: Yup.string().required(
      translate('validations.required', { field: translate('labels.status') })
    ),
    subject_id: Yup.number().required(
      translate('validations.required', { field: translate('labels.subject') })
    )
  });
};

export const careerAssignmentSchema = (translate: TFunction) => {
  return Yup.object({
    description: Yup.string().required(
      translate('validations.required', { field: translate('labels.description') })
    ),
    link: Yup.string().required(
      translate('validations.required', { field: translate('labels.link') })
    )
  });
};

export const registerSchema = (translate: TFunction) =>
  Yup.object({
    firstName: Yup.string()
      .test('noWhiteSpace', translate('validations.noWhiteSpace'), (val: string) => !/\s/.test(val))
      .test('noDigits', translate('validations.noDigits'), (val: string) => !/\d/.test(val))
      .test(
        'noSpecialCharacters',
        translate('validations.noSpecialCharacters'),
        (val: string) => !/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(val) // eslint-disable-line no-useless-escape
      )
      .required(translate('validations.required', { field: translate('labels.firstName') })),
    lastName: Yup.string()
      .test('noWhiteSpace', translate('validations.noWhiteSpace'), (val: string) => !/\s/.test(val))
      .test('noDigits', translate('validations.noDigits'), (val: string) => !/\d/.test(val))
      .test(
        'noSpecialCharacters',
        translate('validations.noSpecialCharacters'),
        (val: string) => !/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(val) // eslint-disable-line no-useless-escape
      )
      .required(translate('validations.required', { field: translate('labels.lastName') })),
    email: Yup.string()
      .required(translate('validations.required', { field: translate('labels.emailAdress') }))
      .email(translate('validations.invalidEmail')),
    // password: Yup.string()
    //   .required(translate('validations.required', { field: translate('labels.password') }))
    //   .min(6, translate('validations.minLength', { count: 6, field: translate('labels.password') }))
    //   .matches(/[a-zA-Z]/g, translate('validations.passwordLatinSymbols'))
    //   .matches(/\d/, translate('validations.passwordDigits')),
    phoneNumber: Yup.string()
      .required(translate('validations.required', { field: translate('labels.phoneNumber') }))
      .matches(/^\d+$/, translate('validations.phoneNumberMatches'))
      .min(3, translate('validations.phoneNumberLength')),
    // .max(20, translate('validations.phoneNumberMaxLength')),
    agreeTerms: Yup.bool().oneOf([true], translate('validations.agreeTermsRequired')),
    course: Yup.string().required(
      translate('validations.required', { field: translate('labels.course') })
    )
  });

export const tutorSchema = (translate: TFunction) =>
  Yup.object({
    firstname: Yup.string()
      .test('noWhiteSpace', translate('validations.noWhiteSpace'), (val: string) => !/\s/.test(val))
      .test('noDigits', translate('validations.noDigits'), (val: string) => !/\d/.test(val))
      .test(
        'noSpecialCharacters',
        translate('validations.noSpecialCharacters'),
        (val: string) => !/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(val) // eslint-disable-line no-useless-escape
      )
      .min(
        2,
        translate('validations.minLength', {
          count: 2,
          field: translate('labels.firstName')
        })
      )
      .max(
        30,
        translate('validations.maxLength', {
          count: 30,
          field: translate('labels.firstName')
        })
      )
      .required(translate('validations.required', { field: translate('labels.firstName') })),
    lastname: Yup.string()
      .test('noWhiteSpace', translate('validations.noWhiteSpace'), (val: string) => !/\s/.test(val))
      .test('noDigits', translate('validations.noDigits'), (val: string) => !/\d/.test(val))
      .test(
        'noSpecialCharacters',
        translate('validations.noSpecialCharacters'),
        (val: string) => !/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(val) // eslint-disable-line no-useless-escape
      )
      .min(
        2,
        translate('validations.minLength', {
          count: 2,
          field: translate('labels.firstName')
        })
      )
      .max(
        30,
        translate('validations.maxLength', {
          count: 30,
          field: translate('labels.firstName')
        })
      )
      .required(translate('validations.required', { field: translate('labels.lastName') })),
    email: Yup.string()
      .required(translate('validations.required', { field: translate('labels.emailAdress') }))
      .email(translate('validations.invalidEmail')),
    phoneNumber: Yup.string()
      .required(translate('validations.required', { field: translate('labels.phoneNumber') }))
      .matches(/^\d+$/, translate('validations.phoneNumberMatches'))
      .min(3, translate('validations.phoneNumberLength'))
    // .max(20, translate('validations.phoneNumberMaxLength'))
  });

export const partnerSchema = (translate: TFunction) =>
  Yup.object({
    company: Yup.string().required(
      translate('validations.required', { field: translate('labels.companyName') })
    )
  });

export const loginSchema = (translate: TFunction) =>
  Yup.object({
    email: Yup.string()
      .required(translate('validations.required', { field: translate('labels.emailAdress') }))
      .email(translate('validations.invalidEmail')),
    password: Yup.string().required(
      translate('validations.required', { field: translate('labels.password') })
    )
  });

export const restorePasswordSchema = (translate: TFunction) =>
  Yup.object({
    email: Yup.string()
      .required(translate('validations.required', { field: translate('labels.emailAdress') }))
      .email(translate('validations.invalidEmail'))
  });

export const resetPasswordSchema = (translate: TFunction) =>
  Yup.object({
    password: Yup.string()
      .required(translate('validations.required', { field: translate('labels.password') }))
      .min(6, translate('validations.passwordMinLength', { count: 6 }))
      .matches(/[a-zA-Z]/g, translate('validations.passwordLatinSymbols'))
      .matches(/\d/, translate('validations.passwordDigits')),
    confirmPassword: Yup.string()
      .required(translate('validations.required', { field: translate('labels.confirmPassword') }))
      .oneOf([Yup.ref('password'), null], translate('validations.passwordsMustMatch'))
  });

export const EditProfileSchema = (translate: TFunction) =>
  Yup.object({
    firstName: Yup.string()
      .test('noWhiteSpace', translate('validations.noWhiteSpace'), (val: string) => !/\s/.test(val))
      .test('noDigits', translate('validations.noDigits'), (val: string) => !/\d/.test(val))
      .test(
        'noSpecialCharacters',
        translate('validations.noSpecialCharacters'),
        (val: string) => !/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(val) // eslint-disable-line no-useless-escape
      )
      .required(translate('validations.required', { field: translate('labels.firstName') })),

    lastName: Yup.string()
      .test('noWhiteSpace', translate('validations.noWhiteSpace'), (val: string) => !/\s/.test(val))
      .test('noDigits', translate('validations.noDigits'), (val: string) => !/\d/.test(val))
      .test(
        'noSpecialCharacters',
        translate('validations.noSpecialCharacters'),
        (val: string) => !/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(val) // eslint-disable-line no-useless-escape
      )
      .required(translate('validations.required', { field: translate('labels.lastName') })),

    email: Yup.string()
      .required(translate('validations.required', { field: translate('labels.emailAdress') }))
      .email(translate('validations.invalidEmail')),

    phoneNumber: Yup.string()
      .required(translate('validations.required', { field: translate('labels.phoneNumber') }))
      .matches(/^\d+$/, translate('validations.phoneNumberMatches'))
      .min(9, translate('validations.phoneNumberLength'))
  });

export const changePasswordSchema = (translate: TFunction) =>
  Yup.object({
    currentPassword: Yup.string().required(
      translate('validations.required', { field: translate('labels.password') })
    ),
    // .min(6, translate('validations.passwordMinLength', { count: 6 }))
    // .matches(/[a-zA-Z]/g, translate('validations.passwordLatinSymbols'))
    // .matches(/\d/, translate('validations.passwordDigits')),
    // Does not match the validation generated in backend
    newPassword: Yup.string()
      .required(translate('validations.required', { field: translate('labels.password') }))
      .min(
        6,
        translate('validations.minLength', {
          count: 6,
          field: translate('labels.password')
        })
      )
      .matches(/[a-zA-Z]/g, translate('validations.passwordLatinSymbols'))
      .matches(/\d/, translate('validations.passwordDigits')),
    confirmPassword: Yup.string()
      .required(translate('validations.required', { field: translate('labels.confirmPassword') }))
      .oneOf([Yup.ref('newPassword'), null], translate('validations.passwordsMustMatch'))
  });

export const addFilesSchema = (translate: TFunction) =>
  Yup.object({
    titleEn: Yup.string().required(
      translate('validations.required', { field: translate('labels.titleEn') })
    ),

    titleKa: Yup.string().required(
      translate('validations.required', { field: translate('labels.titleGe') })
    ),
    url: Yup.string()
    // .required(translate('validations.required', { field: translate('labels.url') }))
    // .min(
    //   3,
    //   translate('validations.minLength', {
    //     count: 3,
    //     field: translate('labels.url')
    //   })
    // )
  });

export const addEventsSchema = (translate: TFunction) =>
  Yup.object({
    eventNameKa: Yup.string()
      .required(translate('validations.required', { field: translate('labels.eventName') }))
      .min(
        3,
        translate('validations.minLength', {
          count: 3,
          field: translate('labels.eventName')
        })
      ),
    startTime: Yup.mixed().required(
      translate('validations.required', { field: translate('labels.startTime') })
    ),
    endTime: Yup.mixed().required(
      translate('validations.required', { field: translate('labels.endTime') })
    ),
    eventNameEn: Yup.string()
      .required(translate('validations.required', { field: translate('labels.eventName') }))
      .min(
        3,
        translate('validations.minLength', {
          count: 3,
          field: translate('labels.eventName')
        })
      ),
    eventDate: Yup.date().required(
      translate('validations.required', { field: translate('labels.eventDate') })
    )
    // .min(new Date(Date.now() - 86400000), translate('validations.eventDatePast'))
  });

export const addCriteriaSchema = (translate: TFunction) =>
  Yup.object({
    criteriaDescEn: Yup.mixed().required(
      translate('validations.required', { field: translate('labels.criteriaDescEn') })
    ),
    criteriaDescKa: Yup.mixed().required(
      translate('validations.required', { field: translate('labels.criteriaDescKa') })
    ),
    criteriaTitleKa: Yup.mixed().required(
      translate('validations.required', { field: translate('labels.criteriaTitleKa') })
    ),
    criteriaTitleEn: Yup.mixed().required(
      translate('validations.required', { field: translate('labels.criteriaTitleEn') })
    )
  });

export const addEventsExtraSchema = (translate: TFunction) =>
  Yup.object({
    course: Yup.string().required(
      translate('validations.required', { field: translate('labels.course') })
    ),
    group: Yup.string().required(
      translate('validations.required', { field: translate('labels.group') })
    )
  });

export const registerEventSchema = (translate: TFunction) =>
  Yup.object({
    eventName: Yup.string()
      .required(translate('validations.required', { field: translate('labels.eventName') }))
      .min(
        3,
        translate('validations.minLength', {
          count: 3,
          field: translate('labels.eventName')
        })
      ),
    startTime: Yup.mixed().required(
      translate('validations.required', { field: translate('labels.startTime') })
    ),
    endTime: Yup.mixed().required(
      translate('validations.required', { field: translate('labels.endTime') })
    ),
    eventDate: Yup.date().required(
      translate('validations.required', { field: translate('labels.eventDate') })
    )
  });

export const publishContentSchema = (translate: TFunction) =>
  Yup.object({
    recipients: Yup.mixed().required(
      translate('validations.required', { field: translate('labels.recipients') })
    ),
    publishDate: Yup.string().required(
      translate('validations.required', { field: translate('labels.publishDate') })
    ),
    startTime: Yup.mixed().required(
      translate('validations.required', { field: translate('labels.startTime') })
    )
  });
