import { useState } from 'react';
import { QUERY_KEYS } from 'src/enums';
import { IEvent } from 'src/models/general';
import { getEvents } from 'src/services/api';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'src/context/authContext';
import useLanguageCode from 'src/hooks/useLanguageCode';

const useEvents = () => {
  const { isLoggedIn } = useAuth();
  const { lang } = useLanguageCode();
  const [userEvents, setUserEvents] = useState<IEvent[]>();

  const { data, isLoading, isError } = useQuery([QUERY_KEYS.EVENTS, lang], () => getEvents(lang), {
    enabled: isLoggedIn,
    onSuccess: async (data) => {
      const events = await data.events;
      const modifiedEvents = events.map((ev: any) => ({
        id: ev.id,
        locale: ev.locale,
        summary: ev.summary,
        comment: ev.comment,
        end: ev.end_date_time,
        start: ev.start_date_time,
        description: ev.description,
        estimation: ev.estimate_time,
        translations: ev.translations,
        calendarGroup: ev.calendar_group[0],
        createdByAdmin: ev.created_by_admin,
        date: ev.start_date_time.slice(0, 10),
        title: `${ev.start_date_time.slice(11, 16)} ${ev.summary || 'No title'}`
      }));

      setUserEvents(modifiedEvents);
    }
  });

  return {
    data,
    isError,
    isLoading,
    userEvents,
    isSynced: data?.sync
  };
};

export default useEvents;
