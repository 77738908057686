import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteHomework } from 'src/services/api';

const useDeleteHomework = () => {
  const client = useQueryClient();

  const { mutate, isLoading } = useMutation<{ success?: boolean }, AxiosError, { id: string }>(
    ['delete-homework'],
    ({ id }) => deleteHomework(id),
    {
      onSuccess: () => {
        client.invalidateQueries(['get-homeworks']);
      }
    }
  );

  return {
    mutate,
    isLoading
  };
};

export default useDeleteHomework;
