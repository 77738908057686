import React, { FC } from 'react';
import { ActionsCardInnerContainer } from './styles';
import ContactCard from './ContactCard';
import { useTranslation } from 'react-i18next';
import profileIcon from 'src/assets/icons/menu-profile.svg';
import mailIcon from 'src/assets/icons/profile-mail.svg';
import phoneIcon from 'src/assets/icons/profile-phone.svg';
import calendarIcon from 'src/assets/icons/menu-callendar.svg';
import locationIcon from 'src/assets/icons/profile-pin.svg';
import { transformDate } from 'src/utils';
import useDetectDevice from 'src/hooks/useDetectDevice';
import useProfile from 'src/hooks/queries/useProfile';
import { Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'src/context/languageContext';

const UserContactInfo: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: user } = useProfile();
  const { isMobile } = useDetectDevice();

  let date;
  if (user?.birthDate) {
    date = transformDate(new Date(user.birthDate).toString());
  }

  return (
    <ActionsCardInnerContainer>
      {user && (
        <>
          <ContactCard
            label={`${t('labels.name')}, ${t('labels.surname')}`}
            value={`${user?.firstName} ${user?.lastName}`}
            icon={profileIcon}
          />
          <ContactCard
            label={t('labels.emailAdress')}
            value={
              isMobile && user?.email && user?.email.length > 20
                ? user?.email.substring(0, 15) + '...'
                : user?.email
            }
            icon={mailIcon}
          />
          <ContactCard
            label={t('labels.phoneNumber')}
            value={`+995 ${user?.phoneNumber}`}
            icon={phoneIcon}
          />
          <ContactCard
            label={t('labels.birthDate')}
            value={date ? date : 'mm/dd/yyyy'}
            icon={calendarIcon}
          />
          <ContactCard label={t('labels.city')} value={user?.residenceCity} icon={locationIcon} />
        </>
      )}
      <Grid container justifyContent="flex-end" px={3}>
        <Button variant="filled" onClick={() => navigate('/profile/edit')}>
          <Typography>{t('common.edit')}</Typography>
        </Button>
      </Grid>
    </ActionsCardInnerContainer>
  );
};

export default UserContactInfo;
