import { Card, CardContent } from '@mui/material';
import styled from '@mui/system/styled';
import stl from 'styled-components';

export const AssignHeader = styled('div')<{ width?: string }>(({ theme, width = `150px` }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '55%',
  marginBottom: '12px',
  width: '100%',
  [theme.breakpoints.down(460)]: {
    height: '35%',
    marginTop: '10px',
    marginBottom: '40px'
  }
}));

export const StyledCard = styled(Card)<{ width?: string; selected?: boolean; disabled?: boolean }>(
  ({ theme, width = `225px`, selected = false, disabled = false }) => ({
    minHeight: '100%',
    paddingTop: '50px',
    paddingBottom: '30px',
    opacity: disabled ? 0.5 : 1,
    boxShadow: selected ? `0 0 0 2px ${theme.palette.primary.main}` : 'none',
    borderRadius: 8,
    position: 'relative',
    cursor: 'pointer',
    border: `1px solid ${theme.palette.border}`,
    [theme.breakpoints.down(576)]: {
      width: '100% !important'
    },

    ':hover': {
      boxShadow: !selected && `0px 3px 6px ${theme.palette.headerShadow}`
    }
  })
);

export const AssignContent = styled(CardContent)(({ theme }) => ({
  paddingInline: 14,
  paddingBlock: 22,
  padding: 8,
  minHeight: 130,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',

  borderTop: 'none',
  [theme.breakpoints.down(460)]: {
    paddingInline: 12,
    paddingBlock: 5,
    marginTop: -30,
    minHeight: 127
  }
}));

export const Dot = stl.div`
  border-radius: 50px;
  height: 5px;
  width: 5px;
  background-color: #00CE7C;
  margin-right: 6px;
`;

export const Status = stl.div`
  position: absolute;
  top: 10px;
  left: 15px;
  display: flex;
  align-items: center;
`;

export const StatusBox = styled(Card)<{ color?: string; opacity: string }>(
  ({ color, opacity }) => ({
    color,
    display: 'flex',
    boxShadow: 'none',
    fontSize: '14px',
    marginBottom: '8px',
    justifyContent: 'center',
    alignItems: 'center',
    opacity
  })
);

export const AssignTrashBox = stl.button`
  border: 1px solid #AFA8EF; 
  width: 60px;
  height: 32px;
  border-radius: 8px;
  display: flex;
  align-items:center;
  justify-content: center;
  background-color: white;
  margin-right: 14px;
  fill: #afa8ef;

  &:hover {
    border: 1px solid #5946D4;
    fill: #5946D4;
    cursor: pointer;
  }

  @media (max-width: 500px){
    width: 40px;
  }
`;

export const AssignButton = stl.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  border-radius: 50px;
  padding: 0 32px;
  font-size: 14px;
  text-transform: uppercase;
  height: 38px;
  font-feature-settings: 'case' on;
`;

export const AssignDetails = stl.div`
  position: absolute;
  left: 12px;
  right: 12px;
  bottom: 18px;
  display: flex;
  justify-content: center;
  `;
