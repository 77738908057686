import styled from 'styled-components';
import { breakPoints } from 'src/theme/defaultTheme';

export const Container = styled.div`
  border-radius: 8px;
  width: 100% !important;
  box-sizing: border-box;
  height: unset;
`;

export const Head = styled.div<{ isOpen: boolean }>`
  width: 100%;
  cursor: pointer;
  height: 56px;
  padding: 0 34px;
  border-radius: ${(props) => (props.isOpen ? `8px 8px 0 0` : `8px`)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #e5e5e5;
  position: relative;
  overflow: hidden;

  @media (max-width: ${breakPoints.mb3}px) {
    height: 56px;
    padding: 0 24px;
    background: #000;
  }
`;

export const Background = styled.img`
  position: absolute;
  width: 1296px;
  transform: translateX(-10%);
  opacity: 0.2;
  object-fit: cover;
`;

export const Title = styled.h3`
  font-size: 20px !important;
  line-height: 30px;

  @media (max-width: ${breakPoints.mb3}px) {
    color: #fff;
  }
`;
