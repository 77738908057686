import { QUERY_KEYS } from 'src/enums';
import { removeBlog } from 'src/services/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

const useRemoveBlog = () => {
  const client = useQueryClient();
  const { mutate, error, isLoading } = useMutation<void, AxiosError, { id: string }>(
    ['remove-blog'],
    ({ id }) => removeBlog(id),
    {
      onSuccess: () => {
        client.invalidateQueries([QUERY_KEYS.BLOGS]);
      }
    }
  );

  return {
    mutate,
    isLoading,
    error
  };
};

export default useRemoveBlog;
