import { AxiosError } from 'axios';
import { QUERY_KEYS } from 'src/enums';
import { editContentInfo } from 'src/services/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useLanguageCode from '../useLanguageCode';
import { EditCourseContentPayload } from 'src/models/service';

const useEditCourseContent = () => {
  const client = useQueryClient();
  const { lang } = useLanguageCode();

  const { mutate, isLoading } = useMutation<
    { success: boolean },
    AxiosError,
    { id: number; payload: EditCourseContentPayload }
  >(['update-course-content'], ({ id, payload }) => editContentInfo(id, payload, lang), {
    onSuccess: () => {
      client.invalidateQueries([QUERY_KEYS.CAREER_CONTENT]);
    }
  });

  return {
    mutate,
    isLoading
  };
};

export default useEditCourseContent;
