import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { tagSchema } from 'src/helpers/validations';
import palette from 'src/theme/overrides/palette';
import { useLanguage } from 'src/context/languageContext';
import useAddTag from 'src/hooks/mutations/useAddTag';
import Loader from 'src/components/common/Loader';
import { useFeedbackModal } from 'src/context/feedbackModalContext';

const AddTagForm: React.FC = () => {
  const { t } = useTranslation();
  const { mutate, isLoading } = useAddTag();
  const { locale } = useLanguage();
  const { constructModal } = useFeedbackModal();

  interface FormData {
    name: string;
  }

  const formik = useFormik<FormData>({
    initialValues: {
      name: ''
    },
    validationSchema: tagSchema(t),
    validateOnMount: true,
    onSubmit: async ({ name }) => {
      mutate(
        { name },

        {
          onSuccess: () => {
            constructModal({
              title: t('common.success'),
              btnText: t('modal.close'),
              locale: locale,
              subtitle: t('modal.becomeTutor')
            });
          }
        }
      );
    },

    validateOnBlur: false,
    validateOnChange: true
  });

  if (isLoading) {
    return (
      <Grid container justifyContent="center" maxWidth="650px" height="550px" margin="auto">
        <Loader />;
      </Grid>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: '600px' }}>
      <Grid container justifyContent="center" maxWidth="650px" margin="auto">
        <Grid item xs={12} marginBottom={{ xs: 4, l: 6 }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={{ xs: 56, mb2: '50px' }}
            margin="auto"
            sx={{
              borderTopRightRadius: 8,
              borderTopLeftRadius: 8
            }}
            bgcolor="black">
            <Box
              width={{ xs: '100%', m: 'auto' }}
              display="flex"
              gap={{ sx: 2, sm: 8 }}
              alignItems="center"
              zIndex={100}
              justifyContent="center">
              <Typography color={palette.white} fontSize={{ sx: 16, sm: 20 }}>
                {t('labels.addTag')}
              </Typography>
            </Box>
          </Box>

          <Box
            padding={{ xs: '16px', mb2: '24px' }}
            textAlign={{ xs: 'center' }}
            sx={{
              border: '1px solid #DEDEDE',
              borderBottomRightRadius: 8,
              borderBottomLeftRadius: 8
            }}>
            {/* NAME */}
            <Grid container mt={'24px'} justifyContent={{ xs: 'center' }}>
              <Grid item sm={8} xs={12}>
                <TextField
                  name="name"
                  variant="outlined"
                  onBlur={formik.handleBlur}
                  label={t('labels.name')}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  FormHelperTextProps={{
                    error: formik.touched.name && Boolean(formik.errors.name),
                    style: {
                      whiteSpace: 'normal'
                    }
                  }}
                />
              </Grid>
            </Grid>

            <Grid container mt={'24px'} justifyContent={'center'}>
              <Grid item xs={8}>
                <Button
                  type="submit"
                  onClick={() => {
                    formik.submitForm();
                  }}
                  variant="containedPrimary"
                  sx={{
                    border: '1px solid #D5D2F3',
                    borderRadius: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'black'
                  }}
                  fullWidth>
                  <Typography
                    color="white"
                    fontWeight="400"
                    textTransform="uppercase"
                    fontSize={{ xs: 16, l: 18 }}>
                    {t('button.submit')}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddTagForm;
