import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import { ErrorMessages, ErrorHandler } from 'src/models/service';
import { Normalize, useTranslation } from 'react-i18next';
import { EditProfileSchema } from 'src/helpers/validations';
import { Container, StyledForm, StyledRow, StyledAdornment } from './styles';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { getCities } from 'src/services/api';
import { useFeedbackModal } from 'src/context/feedbackModalContext';
import { transformDate } from 'src/utils';
import Loader from 'src/components/common/Loader';
import useProfile from 'src/hooks/queries/useProfile';
import useUpdateProfile from 'src/hooks/mutations/useUpdateProfile';
import { useNavigate } from 'src/context/languageContext';
import useDetectDevice from 'src/hooks/useDetectDevice';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  birthDate: string | null;
  residenceCity: string;
}

const EditProfileForm = () => {
  const { t } = useTranslation();
  const { isMedium } = useDetectDevice();
  const [errors, setErrors] = useState<ErrorMessages | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [cities, setCities] = useState<{ id: string; cityName: string }[]>([]);
  const { data: user } = useProfile();
  const { mutate } = useUpdateProfile();
  const navigate = useNavigate();
  const { constructModal } = useFeedbackModal();

  let initialDateValue = '';
  if (user?.birthDate) {
    initialDateValue = transformDate(new Date(user.birthDate).toString(), 'dashed');
  }

  const formik = useFormik<FormData>({
    initialValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      phoneNumber: user?.phoneNumber || '',
      birthDate: initialDateValue,
      residenceCity: user?.residenceCity || ''
    },
    validationSchema: EditProfileSchema(t),
    onSubmit: async (data) => {
      const { firstName, lastName, email, birthDate, phoneNumber, residenceCity } = data;

      let transformedDate;
      if (birthDate) {
        transformedDate = new Date(birthDate).toISOString();
      } else {
        transformedDate = null;
      }
      mutate(
        {
          firstName,
          lastName,
          email,
          birthDate: transformedDate,
          phoneNumber: phoneNumber.substring(0, 9),
          residenceCity
        },
        {
          onSuccess: () => {
            constructModal({
              title: ' ',
              btnText: t('modal.close'),
              type: 'success',
              subtitle: t('messages.dataChangedSuccessfully'),
              action: () => {
                navigate('/profile');
              }
            });
          },
          onError: (err) => {
            if (err.response?.status === 400) {
              const errors = new ErrorHandler(err.response.data).transformMessages();
              setErrors(errors);
            }
          }
        }
      );
    },
    validateOnBlur: false,
    validateOnChange: false
  });

  useEffect(() => {
    setIsLoading(true);
    const getCityData = async () => {
      try {
        const { data } = await getCities();
        setIsLoading(false);
        setCities(data);
      } catch (err) {
        setIsLoading(false);
      }
    };

    getCityData();

    return () => {
      setCities([]);
    };
  }, []);

  return (
    <Container>
      {isLoading ? (
        <Box
          mt={15}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          width={'100%'}>
          <Loader />
        </Box>
      ) : (
        <StyledForm onSubmit={formik.handleSubmit}>
          <StyledRow>
            <TextField
              disabled
              name="firstName"
              label={t('labels.firstName')}
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.firstName}
              error={Boolean(formik.errors.firstName)}
              helperText={formik.errors.firstName}
              FormHelperTextProps={{
                error: Boolean(formik.errors.firstName || errors?.firstName)
              }}
            />

            <TextField
              disabled
              label={t('labels.lastName')}
              name="lastName"
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.lastName}
              error={Boolean(formik.errors.lastName)}
              helperText={formik.errors.lastName}
              FormHelperTextProps={{
                error: Boolean(formik.errors.lastName || errors?.lastName)
              }}
            />
          </StyledRow>

          <TextField
            disabled
            label={t('labels.emailAdress')}
            variant="outlined"
            type="email"
            name="email"
            onChange={formik.handleChange}
            error={Boolean(formik.errors.email || errors?.email)}
            value={formik.values.email}
            helperText={
              formik.errors.email || (errors?.email && t(`errors.${errors.email}` as Normalize<{}>)) // eslint-disable-line @typescript-eslint/ban-types
            }
            inputProps={{
              autoComplete: 'nofill'
            }}
            FormHelperTextProps={{
              error: Boolean(formik.errors.email || errors?.email)
            }}
          />

          <TextField
            name="phoneNumber"
            type="tel"
            value={
              formik.values.phoneNumber.length > 9
                ? formik.values.phoneNumber.substring(0, 9)
                : formik.values.phoneNumber
            }
            onChange={formik.handleChange}
            placeholder={t('labels.phoneNumber')}
            error={Boolean(formik.errors.phoneNumber || errors?.phoneNumber)}
            helperText={
              formik.errors.phoneNumber ||
              (errors?.phoneNumber && t(`errors.${errors.phoneNumber}` as Normalize<{}>)) // eslint-disable-line @typescript-eslint/ban-types
            }
            FormHelperTextProps={{
              error: Boolean(formik.errors.phoneNumber || errors?.phoneNumber)
            }}
            InputProps={{
              className: 'with-select-profile',
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{
                    height: isMedium ? '38px !important' : '50px !important',
                    marginBottom: '5px',
                    background: '#F3F4F6',
                    marginRight: '20px',
                    paddingTop: '6px',
                    borderTopRightRadius: '0px !important',
                    borderBottomRightRadius: '0px !important'
                  }}>
                  <StyledAdornment>+995</StyledAdornment>
                </InputAdornment>
              )
            }}
          />

          <TextField
            name="birthDate"
            label={t('labels.birthDate')}
            type="date"
            InputLabelProps={{
              shrink: true
            }}
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.birthDate}
            error={Boolean(formik.errors.birthDate)}
            helperText={formik.errors.birthDate}
            FormHelperTextProps={{
              error: Boolean(formik.errors.birthDate || errors?.birthDate)
            }}
          />

          <FormControl>
            <InputLabel id="demo-simple-select-label">{t('labels.city')}</InputLabel>
            <Select
              MenuProps={{
                sx: {
                  maxWidth: 200,
                  maxHeight: 200
                }
              }}
              name="residenceCity"
              label={t('labels.city')}
              value={formik.values.residenceCity}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.residenceCity)}>
              {cities.map((city) => {
                return (
                  <MenuItem key={city.id} value={city.cityName}>
                    {city.cityName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField sx={{ visibility: 'hidden' }} />
        </StyledForm>
      )}
      {!isLoading && (
        <Grid
          container
          alignItems={{ xs: 'center', sm: 'flex-end' }}
          gap={1}
          justifyContent={{ xs: 'center', sm: 'flex-end' }}>
          <Button
            sx={{
              padding: '20px !important',
              background: 'black',
              width: '150px',
              borderRadius: '26px',
              fontFeatureSettings: "'case' on",
              '&:hover': {
                background: 'black'
              }
            }}
            onClick={() => formik.handleSubmit()}
            disabled={formik.isSubmitting}>
            <Typography
              color={'white'}
              fontWeight={'regular'}
              textTransform="uppercase"
              fontSize={16}>
              {t('button.save')}
            </Typography>
          </Button>
          <Button
            sx={{
              padding: '20px !important',
              width: '150px',
              borderRadius: '26px',
              border: '1px solid black',
              color: 'black',
              fontFeatureSettings: "'case' on"
            }}
            disabled={formik.isSubmitting}
            onClick={() => navigate('/profile')}>
            <Typography fontWeight={'regular'} fontSize={16} textTransform="uppercase">
              {t('button.cancel')}
            </Typography>
          </Button>
        </Grid>
      )}
    </Container>
  );
};

export default EditProfileForm;
