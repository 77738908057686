import useLanguageCode from 'src/hooks/useLanguageCode';
import { AxiosError } from 'axios';
import { createCareerContent } from 'src/services/api';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'src/context/languageContext';

const useCreateCareerContent = () => {
  const navigate = useNavigate();
  const { lang } = useLanguageCode();

  const { mutate, isLoading } = useMutation<{ success?: boolean }, AxiosError, FormData>(
    (payload) => createCareerContent(payload, lang),
    { onSuccess: () => navigate('/career-care') }
  );

  return {
    mutate,
    isLoading
  };
};

export default useCreateCareerContent;
