import React, { FC, useState } from 'react';
import { Box } from '@mui/system';
import { checkIOS } from 'src/utils';
import List from '@mui/material/List';
import { LectureItem } from './styles';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import { SubjectFile } from 'src/models/general';
import { useAuth } from 'src/context/authContext';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog, DialogContent } from '@mui/material';
import useDetectDevice from 'src/hooks/useDetectDevice';
interface CourseLectureProps {
  files: SubjectFile[];
  onDeleteFile: (id: number) => void;
  onEditFile: (file: SubjectFile) => void;
}

const CourseLecture: FC<CourseLectureProps> = ({ files, onEditFile, onDeleteFile }) => {
  const { t } = useTranslation();
  const { isAdmin } = useAuth();
  const { isMobile } = useDetectDevice();
  const [descModalShown, setDescModalShown] = useState(false);
  const [filteredFile, setFilteredFile] = useState<SubjectFile>();

  const isDrive = filteredFile?.url?.includes('drive.google.com');

  const onFileDownload = (file: SubjectFile) => {
    if (file.attachment) {
      window.open(file.attachment, '_blank', 'noreferrer');
    } else if (
      (file.description && file.description !== '<p></p>') ||
      file.url.includes('drive.google.com')
    ) {
      if (checkIOS()) {
        window.open(file.url, '_blank', 'noreferrer');
      } else {
        setDescModalShown(true);
        setFilteredFile(files.find((f) => f.id === file.id));
      }
    } else if (!file.url.includes('drive.google.com')) {
      window.open(file.url, '_blank', 'noreferrer');
    }
  };

  return (
    <List>
      {files?.map((file) => (
        <LectureItem key={file.id}>
          <Box display="flex" gap={2} alignItems="center">
            <Typography
              onClick={() => onFileDownload(file)}
              sx={{ cursor: 'pointer', minWidth: '100px' }}>
              <Typography color="violet" fontSize={14} fontWeight="normal">
                {file.title}
                <span style={{ marginLeft: '5px', color: '#000', fontSize: '14px' }}>
                  {isAdmin && file.estimate_time && `(${file.estimate_time})`}
                </span>
              </Typography>
            </Typography>
            {isAdmin && (
              <Box>
                <IconButton sx={{ padding: '0' }} onClick={() => onEditFile(file)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => onDeleteFile(file.id)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        </LectureItem>
      ))}
      <Dialog
        disableAutoFocus
        open={descModalShown}
        onClose={() => setDescModalShown(false)}
        PaperProps={{
          style: {
            borderRadius: '8px',
            boxShadow: '5px 5px 15px #0000001A'
          }
        }}>
        <DialogContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: { l: 2, xs: 0 },
            minWidth: { sm: 416, l: 516 },
            marginBottom: { xs: 0, l: 2 }
          }}>
          {isDrive && (
            <iframe
              allow="autoplay"
              allowFullScreen={true}
              width={isMobile ? '100%' : '1680px'}
              height={isMobile ? '100%' : '580px'}
              sandbox="allow-scripts allow-same-origin"
              style={{ borderRadius: '6px', border: 'none' }}
              src={filteredFile?.url?.replace('view', 'preview')}></iframe>
          )}
          {filteredFile?.description && (
            <div dangerouslySetInnerHTML={{ __html: filteredFile.description }} />
          )}
        </DialogContent>
      </Dialog>
    </List>
  );
};

export default CourseLecture;
