import React, { ChangeEvent, FormEvent, useState } from 'react';
import * as XLSX from 'xlsx';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import Loader from 'src/components/common/Loader';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { axiosSyllabusInstance } from 'src/services/api';
import { useNavigate, useParams } from 'react-router-dom';
import AuthWrapper from 'src/components/layouts/AuthWrapper';
import useContentDetail from 'src/hooks/queries/useContentDetail';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  styled,
  Button
} from '@mui/material';
import { ReactComponent as CheckboxChecked } from 'src/assets/icons/success.svg';
import { ReactComponent as CheckboxUnChecked } from 'src/assets/icons/unChecked.svg';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'src/enums';

const TabCell = styled(TableCell)`
  border-right: 1px solid #e4e4e4;
`;

interface SubData {
  id: number;
  score: number;
}

const CareerList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [subData, setSubData] = useState<SubData[]>([] as SubData[]);
  const client = useQueryClient();

  const { contentId } = useParams();
  const { details, isLoading } = useContentDetail(String(contentId));

  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>, id: number) => {
    const points = e.target.checked ? 1 : 0;
    const exists = subData.find((s) => s.id === id);

    if (exists) {
      exists.score = points!;
    } else {
      setSubData([...subData, { id, score: points! }]);
    }
  };

  const submit = async (e: FormEvent) => {
    e.preventDefault();

    const sub = subData.map((d) =>
      axiosSyllabusInstance.post(`api/assignments/grade/${d.id}`, { score: d.score })
    );
    const res = await Promise.all(sub);
    client.invalidateQueries([QUERY_KEYS.CAREER_CONTENT_DETAIL]);
    // if (res.length > 0) {
    //   setShowAlertModal();
    // }
  };

  const data = details?.assignments.map((data: any) => ({
    Student: data.user_name,
    Email: data.user_email,
    'Submission date': data.submission_date,
    Score: data.score,
    Link: data.link,
    Attachment: data.attachment,
    Text: String(data.title)
  }));

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data!);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'assignments.xlsx');
  };

  return (
    <Grid marginBottom={{ xs: 10, sm: 20 }}>
      <AuthWrapper isCareerCare userPage>
        <Grid
          container
          mt={{ sm: 0, xs: 2 }}
          flexDirection="column"
          sx={{
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            border: '1px solid #DEDEDE'
          }}>
          <Box
            display="flex"
            bgcolor="black"
            alignItems="center"
            justifyContent="space-between"
            pl={{ sm: 4, xs: 2 }}
            height={{ sm: 64, xs: 51 }}
            sx={{
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10
            }}>
            <Typography
              fontSize={{ sm: 20, xs: 16 }}
              color="white"
              fontWeight={700}
              minHeight="24px">
              {t('button.list')}
            </Typography>
          </Box>
          <Box
            padding="24px"
            textAlign={{ sm: 'center', md: 'start' }}
            sx={{
              border: '1px solid #DEDEDE',
              borderBottomRightRadius: 8,
              borderBottomLeftRadius: 8,
              background: '#fff'
            }}>
            <Grid minHeight="30vh">
              {isLoading && <Loader />}
              {!isLoading && (
                <>
                  <Grid
                    gap={1}
                    container
                    alignItems="center"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => navigate(-1)}>
                    <ArrowBack sx={{ color: '#2A7DE1' }} />
                    <Typography variant="body1" color="violet" fontSize={16}>
                      {t('careerCare.backToList')}
                    </Typography>
                  </Grid>
                  <TableContainer component={Paper} variant="outlined" sx={{ marginTop: '32px' }}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TabCell sx={{ fontSize: 16 }}>{t('table.student')}</TabCell>
                          <TabCell sx={{ fontSize: 16 }}>{t('table.email')}</TabCell>
                          <TabCell sx={{ fontSize: 16 }}>{t('table.submissionDate')}</TabCell>
                          <TabCell sx={{ fontSize: 16 }}>{t('table.score')}</TabCell>
                          <TabCell sx={{ fontSize: 16 }}>{t('table.link')}</TabCell>
                          <TabCell sx={{ fontSize: 16 }}>{t('table.file')}</TabCell>
                          <TabCell sx={{ fontSize: 16 }}>{t('table.text')}</TabCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {details?.assignments
                          .sort((a: any, b: any) => (a.id > b.id ? 1 : -1))
                          .map((row: any) => (
                            <TableRow key={row.id}>
                              <TabCell
                                component="th"
                                scope="row"
                                sx={{ borderRight: '1px solid #e4e4e4' }}>
                                {row.user_name} {row.user_lastname}
                              </TabCell>
                              <TabCell>
                                <Typography
                                  fontWeight="normal"
                                  fontSize={14}
                                  sx={{ textDecoration: 'underline' }}>
                                  {row.user_email}
                                </Typography>
                              </TabCell>
                              <TabCell>{row.submission_date}</TabCell>

                              <TabCell>
                                <Checkbox
                                  defaultChecked={row.score > 0}
                                  icon={<CheckboxUnChecked />}
                                  checkedIcon={<CheckboxChecked />}
                                  onChange={(e) => handleCheckbox(e, row.id)}
                                  sx={{
                                    marginLeft: '-3px'
                                  }}
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                              </TabCell>

                              <TabCell>
                                <a
                                  href={row.link}
                                  rel="noreferrer"
                                  target="_blank"
                                  download
                                  style={{
                                    textDecoration: 'underline',
                                    textDecorationColor: '#007FFF'
                                  }}>
                                  <Typography
                                    fontSize={'15px'}
                                    color={row.link ? '#007FFF' : '#c6c8cc'}
                                    fontWeight="normal">
                                    Assignment URL
                                  </Typography>
                                </a>
                              </TabCell>
                              <TabCell>
                                <a
                                  href={row.attachment}
                                  rel="noreferrer"
                                  target="_blank"
                                  download
                                  style={{
                                    pointerEvents: !row.attachment ? 'none' : 'all'
                                  }}>
                                  <Typography
                                    fontSize={'15px'}
                                    color={row.attachment ? '#007FFF' : '#c6c8cc'}
                                    fontWeight="normal">
                                    Uploaded File
                                  </Typography>
                                </a>
                              </TabCell>
                              <TabCell sx={{ maxWidth: '275px' }}>
                                <Typography fontSize={'15px'} fontWeight={'regular'}>
                                  {row.title}
                                </Typography>
                              </TabCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
              <Box display="flex" alignItems="center" gap={1} mt={6} justifyContent="flex-end">
                {subData?.length > 0 && (
                  <Button onClick={submit} variant="secondary">
                    <Typography>{t('button.submit')}</Typography>
                  </Button>
                )}

                <Button onClick={exportToExcel} variant="primary">
                  <Typography>export excel</Typography>
                </Button>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </AuthWrapper>
    </Grid>
  );
};

export default CareerList;
