import { styled } from '@mui/system';
import { Box, Button, Grid } from '@mui/material';
import { breakPoints } from 'src/theme/defaultTheme';

export const PrimaryWrapper = styled(Grid)`
  top: 0;
  z-index: 10;
  background: #fff;
  position: absolute;
  align-items: center;
  justify-content: space-between;
  box-shadow: #e4e4e4 0px 2px 18px;

  @keyframes identifier {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &.over {
    top: 50px;
    z-index: 1000;
    animation: 'identifier .4s ease-in-out';

    @media (max-width: 876px) {
      margin-top: 38px;
    }

    &.fixed {
      position: fixed;
    }
  }
`;

export const Navigation = styled(Box)`
  display: flex;
  align-items: center;
  gap: 138px;
  @media (max-width: ${breakPoints.l}px) {
    gap: 60px;
  }
`;

export const LangLogo = styled(Box)`
  margin-top: 4px;
  cursor: pointer;
  animation: identifier 0.5s ease-in-out;

  @keyframes identifier {
    0% {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

export const StyledButton = styled(Button)`
  @media (max-width: ${breakPoints.mb3}px) {
    display: none;
  }
`;

export const LogoContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 16px;
  @media (max-width: ${breakPoints.mb3}px) {
    gap: 8px;
  }
`;

export const SelectLanguage = styled(Box)`
  cursor: pointer;

  img {
    border-radius: 5px;
  }
`;
