import React from 'react';
import { Box, Typography } from '@mui/material';
import AuthWrapper from 'src/components/layouts/AuthWrapper';
import AssignmentForm from 'src/components/UI/forms/AssignmentForm';
import { useAuth } from 'src/context/authContext';
import { useLocation } from 'react-router-dom';
import useCourse from 'src/hooks/queries/useCourse';
import { useLanguage } from 'src/context/languageContext';
import { useTranslation } from 'react-i18next';

const AddHomework: React.FC = () => {
  const { isAdmin } = useAuth();
  const { pathname } = useLocation();
  const courseId = pathname.split('/').at(-1);
  const { t } = useTranslation();
  const { locale } = useLanguage();

  const { data: course } = useCourse(courseId!);

  return (
    <AuthWrapper isAssignment userPage={!isAdmin}>
      <Box
        display="flex"
        bgcolor="black"
        alignItems="center"
        pl={{ sm: 4, xs: 2 }}
        height={{ sm: 64, xs: 51 }}
        justifyContent="space-between"
        sx={{
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10
        }}>
        <Box minHeight="24px">
          {course?.name && (
            <Typography fontSize={20} color="white" fontWeight={700}>
              {locale === 'ge' && course?.name + '- ის '}
              {locale === 'en' && course?.name + ' '}
              {t('labels.assignment')}
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        padding="24px 60px 60px 24px"
        sx={{
          background: '#fff',
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
          border: '1px solid #DEDEDE'
        }}>
        <AssignmentForm />
      </Box>
    </AuthWrapper>
  );
};

export default AddHomework;
