import React, { useState, useEffect } from 'react';
import MenuList from './MenuList';
import { HEADER_ROUTES } from 'src/constants';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Typography } from '@mui/material';
import palette from 'src/theme/overrides/palette';
import { geFlag, enFlag } from 'src/assets/images';
import LogoutModal from 'src/components/modals/LogoutModal';
import {
  Menu,
  Container,
  CloseIcon,
  BurgerIcon,
  StyledMenuListItem,
  OceanContainer,
  StyledCourseListItem
} from './styled';
import { LOCALES } from 'src/enums';
import { useAuth } from 'src/context/authContext';
import useProfile from 'src/hooks/queries/useProfile';
import useDetectDevice from 'src/hooks/useDetectDevice';
import { useLocation } from 'react-router-dom';
import Link from 'src/components/common/link';
import Footer from 'src/components/layouts/footer/Footer';
import useExtendedCourses from 'src/hooks/queries/useExtendedCourses';
import { useLanguage, useNavigate } from 'src/context/languageContext';
import { LangLogo, SelectLanguage } from 'src/components/layouts/header/styles';
import Logo from '../logo/Logo';
import MobileHeader from 'src/components/layouts/header/MobileHeader';

const MobileMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { locale, changeLocale } = useLanguage();

  const { pathname, search } = useLocation();
  const { myCourses = [] } = useProfile();
  const { isLoggedIn, isAdmin } = useAuth();

  const [openModal, setOpenModal] = useState(false);
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  useEffect(() => {
    if (isMenuOpened) {
      document.body.classList.add('scroll-lock');
    }

    return () => document.body.classList.remove('scroll-lock');
  }, [isMenuOpened]);

  useEffect(() => {
    setIsMenuOpened(false);
  }, [pathname, search]);

  const { isMobile } = useDetectDevice();
  const { courses } = useExtendedCourses();
  const courseIdx = isMobile && myCourses?.length > 1 ? 1 : 0;
  const crsId = myCourses[courseIdx]?.courseId;
  const courseName = courses?.find((course) => course.id === crsId)?.name;
  const commonLabel = !isAdmin ? courseName : t('labels.common');
  const subCourseExist = myCourses.some((course) => course.courseId === 2 || course.courseId === 4);
  const isBatchOneStudent = myCourses?.find(
    (course) => course.courseId === 3 || course.courseId === 4 || course.courseId === 2
  );

  const ids = myCourses.map((course) => course.courseId);
  const myFilteredCourses = courses?.filter((c) => ids?.includes(c.id));

  const menuBackground = isLoggedIn ? palette.bgGray : palette.text.primary;

  return (
    <Container>
      <BurgerIcon onClick={() => setIsMenuOpened(true)} fill={palette.text.primary} />

      <Menu isOpened={isMenuOpened} background={menuBackground}>
        <MobileHeader setIsMenuOpened={setIsMenuOpened} />
        {isLoggedIn ? (
          <Grid
            sx={{ padding: '24px 32px' }}
            mt={8}
            container
            flexDirection="column"
            alignItems="space-between">
            <Grid item minHeight="70vh">
              <MenuList title={t('common.profile')}>
                <StyledMenuListItem>
                  <Link to="/profile">{t('profile.contactInfo')}</Link>
                </StyledMenuListItem>
                <StyledMenuListItem>
                  <Link to="/profile/change-password">{t('button.changePassword')}</Link>
                </StyledMenuListItem>
                <StyledMenuListItem>
                  <Link to="/profile/status">{t('profile.status')}</Link>
                </StyledMenuListItem>
              </MenuList>

              <MenuList title={t('profile.myCourse')}>
                <StyledCourseListItem>
                  <MenuList title={commonLabel || ''} isSubMenu>
                    <Box
                      onClick={() => setIsMenuOpened(false)}
                      className="course-wrapper"
                      display="flex"
                      flexDirection="column">
                      <Link to={`/my-course/general?course=${crsId}`}>
                        <Typography fontSize={16} fontWeight="400" textTransform="uppercase">
                          {t('syllabus.syllabus')}
                        </Typography>
                      </Link>
                      <Link to={`/my-course/general?course=${crsId}&scroll=true`}>
                        <Typography fontSize={16} fontWeight="400" textTransform="uppercase">
                          {t('course.rankingsAndBadge')}
                        </Typography>
                      </Link>
                    </Box>
                  </MenuList>
                </StyledCourseListItem>
                {(isAdmin || subCourseExist) && !isBatchOneStudent && (
                  <StyledMenuListItem onClick={() => setIsMenuOpened(false)}>
                    <Link to="/my-course/specific">{t('labels.specialization')}</Link>
                  </StyledMenuListItem>
                )}
              </MenuList>

              <Link to="/calendar">
                <MenuList title={t('common.calendar')} />
              </Link>
              <MenuList title={t('labels.assignments')}>
                {myFilteredCourses?.map((course) => {
                  return (
                    <StyledMenuListItem key={course.id}>
                      <Link to={`/assignments/${course.id}`}>{course.name}</Link>
                    </StyledMenuListItem>
                  );
                })}
              </MenuList>
              <MenuList title={t('labels.career')}>
                {myFilteredCourses?.map((course) => {
                  return (
                    <StyledMenuListItem key={course.id}>
                      <Link to={`/career-content?courseId=${course.id}`}>{course.name}</Link>
                    </StyledMenuListItem>
                  );
                })}
              </MenuList>

              {isAdmin && (
                <Link to="/ocean-news?section=news">
                  <MenuList title={t('labels.news')} />
                </Link>
              )}
            </Grid>
            <Grid item margin={'0 auto'}>
              <Button
                variant="primary"
                sx={{
                  fontFeatureSettings: "'case' on",
                  paddingInline: '40px',
                  paddingBlock: '15px'
                }}
                onClick={() => setOpenModal(true)}>
                <Typography>{t('common.logout')}</Typography>
              </Button>
            </Grid>
          </Grid>
        ) : (
          <>
            <Box mt={10} height="100vh">
              <Box gap={2} display="flex" flexDirection="column">
                {HEADER_ROUTES.map((route) => {
                  return (
                    <Typography
                      variant="h1"
                      color="white"
                      key={route.id}
                      fontSize="20px !important"
                      textTransform="uppercase">
                      <Link to={route.path}>{route.translations[locale]}</Link>
                    </Typography>
                  );
                })}
              </Box>

              <Box mt={3}>
                <SelectLanguage onClick={changeLocale}>
                  {process.env.REACT_APP_SKILLWILL_COUNTRY === 'ge' && (
                    <>
                      {locale === LOCALES.GE && (
                        <LangLogo onClick={changeLocale}>
                          <img src={geFlag} alt="georgian" />
                        </LangLogo>
                      )}
                      {locale === LOCALES.EN && (
                        <LangLogo onClick={changeLocale}>
                          <img src={enFlag} alt="english" />
                        </LangLogo>
                      )}
                    </>
                  )}
                </SelectLanguage>
              </Box>

              <OceanContainer>
                <Button variant="filled" size="medium" onClick={() => navigate('/login')}>
                  <Typography>{t('newLanding.oceanButton')}</Typography>
                </Button>
              </OceanContainer>
              <Box
                sx={{
                  left: 0,
                  bottom: 0,
                  width: '100%',
                  position: 'fixed'
                }}>
                <Footer />
              </Box>
            </Box>
          </>
        )}
      </Menu>

      <LogoutModal open={openModal} close={() => setOpenModal(false)} />
    </Container>
  );
};

export default MobileMenu;
