import React, { FC } from 'react';
import { Container } from './styles';
import { Dialog } from '@mui/material';
import { useAuth } from 'src/context/authContext';
import AddEventForm from 'src/components/UI/forms/AddEventForm';
import RegisterEvent from 'src/components/UI/forms/RegisterEvent';

interface Props {
  open: boolean;
  close: () => void;
}

const EventModal: FC<Props> = ({ open, close }) => {
  const { isAdmin } = useAuth();

  return (
    <Dialog onClose={close} open={open}>
      <Container>
        {isAdmin ? <AddEventForm close={close} /> : <RegisterEvent close={close} />}
      </Container>
    </Dialog>
  );
};

export default EventModal;
