import { useState } from 'react';
import { QUERY_KEYS } from 'src/enums';
import { useQuery } from '@tanstack/react-query';
import { getAllCourses } from 'src/services/api';
import { AllCourseResponse, CourseInfo } from 'src/models/service';

const useCourses = () => {
  const [course, setCourse] = useState<CourseInfo | null>(null);

  const { data, isLoading } = useQuery<AllCourseResponse>(
    [QUERY_KEYS.COURSES],
    () => getAllCourses(),
    {
      onSuccess: (courses) => {
        setCourse(courses.data[0]);
      }
    }
  );

  return {
    data,
    courses: data?.data,
    isLoading,
    course
  };
};

export default useCourses;
