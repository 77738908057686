import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import { QUERY_KEYS } from 'src/enums';
import Parser from 'html-react-parser';
import useToggle from 'src/hooks/useToggle';
import { useNavigate } from 'react-router-dom';
import { TextArea } from '../assignment/style';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import Loader from 'src/components/common/Loader';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from 'src/context/authContext';
import { getCareerContent } from 'src/services/api';
import ArrowBack from '@mui/icons-material/ArrowBack';
import useQueryString from 'src/hooks/useQueryString';
import { CareerContentItem } from 'src/models/service';
import useLanguageCode from 'src/hooks/useLanguageCode';
import { ContentCardWrapper } from '../careerCare/styles';
import ContentCard from 'src/components/common/contentCard';
import AuthWrapper from 'src/components/layouts/AuthWrapper';
import useContentDetail from 'src/hooks/queries/useContentDetail';
import { careerAssignmentSchema } from 'src/helpers/validations';
import useExtendedCourses from 'src/hooks/queries/useExtendedCourses';
import { ReactComponent as PlusIcon } from 'src/assets/icons/addPlus.svg';
import useUploadAssignment from 'src/hooks/mutations/useUploadAssignment';
import useUpdateAssignment from 'src/hooks/mutations/useUpdateAssignment';
import { Button, Divider, FormHelperText, Grid, TextField, Typography } from '@mui/material';

const CareerContent = () => {
  const { isAdmin } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { langCode } = useLanguageCode();
  const [file, setFile] = useState<File | null>();

  const [formShown, setFormShown] = useToggle();
  const ref = useRef<HTMLInputElement>(null);

  const { parseQuery } = useQueryString();
  const { contentId = '', courseId = '' } = parseQuery();

  const { mutate: edit, isLoading: updateLoading } = useUpdateAssignment();
  const { mutate: upload, isLoading: uploadLoading } = useUploadAssignment();

  const { data, isLoading } = useQuery([QUERY_KEYS.CAREER_ITEM, String(courseId)], () =>
    getCareerContent(String(courseId), langCode)
  );

  const {
    refetch,
    isFetching,
    files = [],
    details = {},
    assignment: { attachment = '', id = '', link = '', title = '' } = {}
  } = useContentDetail(contentId.toString());

  const formik = useFormik<{ link: string; description: string }>({
    initialValues: {
      link: link || '',
      description: title || ''
    },
    validationSchema: careerAssignmentSchema(t),
    onSubmit: async ({ link, description }) => {
      const fd = new FormData();

      if (file) fd.append('attachment', file);
      if (link) fd.append('link', link);
      if (description) fd.append('title', description);
      fd.append('content_id', String(contentId));

      const invalidateContent = () => {
        setFile(null);
        setFormShown();
        refetch();
      };

      if (attachment) {
        edit({ id: Number(id), fileData: fd }, { onSuccess: () => invalidateContent() });
      } else {
        upload({ fileData: fd }, { onSuccess: () => invalidateContent() });
      }
    },
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true
  });

  useEffect(() => {
    return () => {
      setFormShown();
      formik.resetForm();
    };
  }, []); // eslint-disable-line

  const { courses = [] } = useExtendedCourses();
  const course = courses.find((course) => course.id === +courseId);

  return (
    <Grid marginBottom={{ xs: 10, sm: 20 }}>
      <AuthWrapper isCareerCare userPage>
        <Grid item xs={12} display={{ sm: 'none', xs: 'block' }} mt={-2}>
          <Typography fontWeight="bold" fontSize={24} fontFamily="basenji-variable">
            / {course?.name}
          </Typography>
        </Grid>
        <Grid
          container
          mt={{ sm: 0, xs: 2 }}
          flexDirection="column"
          sx={{
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            border: '1px solid #DEDEDE'
          }}>
          <Box
            display="flex"
            bgcolor="black"
            alignItems="center"
            justifyContent="space-between"
            pl={{ sm: 4, xs: 2 }}
            height={{ sm: 64, xs: 51 }}
            sx={{
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10
            }}>
            <Typography
              fontSize={{ sm: 20, xs: 16 }}
              color="white"
              fontWeight={700}
              minHeight="24px">
              {contentId ? details?.title : `${course?.name} ${t('careerCare.availableContent')}`}
            </Typography>
          </Box>
          <ContentCardWrapper>
            {contentId ? (
              <Fragment>
                {isFetching && <Loader />}
                {!isFetching && details.title && (
                  <Fragment>
                    <Grid
                      gap={1}
                      container
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        setFormShown();
                        navigate(-1);
                      }}>
                      <ArrowBack sx={{ color: '#2A7DE1' }} />
                      <Typography variant="body1" color="violet" fontSize={16}>
                        {t('careerCare.backToList')}
                      </Typography>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} mt={2}>
                        {isAdmin && details.estimate_time && (
                          <Typography fontSize={16} fontWeight="normal" mb={2}>
                            {t('labels.estimation')}: {details.estimate_time}
                          </Typography>
                        )}
                        {details.content && <Box>{Parser(details.content)}</Box>}
                      </Grid>

                      {!isAdmin && files.length > 0 && (
                        <Divider sx={{ margin: '12px 0px 24px 0px', width: '75%' }} />
                      )}

                      <Grid item xs={12} mb={1}>
                        {files.length > 0 && (
                          <>
                            <Typography fontWeight={900}>
                              {t('careerCare.uploadedFiles')}
                            </Typography>
                            <Box display="flex" flexDirection="column" mt={2}>
                              {files.map((file: any) => {
                                return (
                                  <Typography
                                    component="a"
                                    color="violet"
                                    fontSize={16}
                                    target="_blank"
                                    fontWeight={400}
                                    key={file.attachment}
                                    href={file.attachment}
                                    sx={{ textDecoration: 'underline' }}>
                                    {file?.attachment_name || 'File'}
                                  </Typography>
                                );
                              })}
                            </Box>
                          </>
                        )}
                      </Grid>

                      {!isAdmin && <Divider sx={{ margin: '12px 0px 24px 0px', width: '75%' }} />}

                      <Grid item xs={12}>
                        {attachment && !isAdmin && (
                          <Box mb={2}>
                            <Typography fontSize={{ xs: 14, sm: 18 }} fontWeight="bold" mb={1}>
                              {t('labels.assignmentLabel')}
                            </Typography>
                            <a
                              download
                              target="_blank"
                              rel="noreferrer"
                              href={attachment}
                              style={{ textDecoration: 'underline', color: '#2A7DE1' }}>
                              <Typography
                                color="violet"
                                fontWeight="regular"
                                fontSize={{ xs: 14, sm: 16 }}>
                                {t('labels.myAssignmentLabel')}
                              </Typography>
                            </a>
                          </Box>
                        )}
                      </Grid>
                      {!formShown && !isAdmin && (
                        <Grid item xs={12}>
                          <Button
                            variant="filled"
                            onClick={setFormShown}
                            disabled={!details?.active}
                            sx={{ opacity: details.active ? 1 : 0.6 }}>
                            <Typography>
                              {attachment ? t('common.edit') : t('button.upFile')}
                            </Typography>
                            {!attachment && <PlusIcon />}
                          </Button>
                        </Grid>
                      )}
                      {formShown && !isAdmin && (
                        <Grid item xs={12}>
                          {attachment && (
                            <Divider sx={{ margin: '12px 0px 24px 0px', width: '75%' }} />
                          )}
                          <form onSubmit={formik.handleSubmit}>
                            <Grid item xs={9} mt={2}>
                              <TextField
                                name="link"
                                variant="outlined"
                                label={t('labels.link')}
                                value={formik.values.link}
                                onChange={formik.handleChange}
                                helperText={formik.errors.link}
                                error={Boolean(formik.errors.link)}
                                FormHelperTextProps={{
                                  error: Boolean(formik.errors.link)
                                }}
                              />
                            </Grid>
                            <Grid item xs={9}>
                              <TextArea
                                rows={8}
                                name="description"
                                onChange={formik.handleChange}
                                value={formik.values.description}
                                placeholder={t('labels.description')}
                              />
                              <FormHelperText error sx={{ margin: '-16px 0px 24px 12px' }}>
                                {formik.errors.description}
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={12} display="flex" alignItems="center" gap={1}>
                              <Box display="flex" flexDirection="column" gap={1}>
                                <Button
                                  variant="filled"
                                  onClick={() => {
                                    if (!file) ref.current?.click();
                                  }}>
                                  <input
                                    hidden
                                    ref={ref}
                                    type="file"
                                    onChange={(e) => {
                                      if (e.target.files) {
                                        setFile(e.target.files[0]);
                                      }
                                    }}
                                  />
                                  <Typography
                                    fontSize={16}
                                    color="darkblue"
                                    fontWeight={450}
                                    textTransform="uppercase">
                                    {t('button.upFile')}
                                    <PlusIcon />
                                  </Typography>
                                </Button>
                              </Box>
                              <Box>
                                {file && (
                                  <Box display="flex" gap="6px">
                                    <Typography variant="listItem" fontSize={16} color="black">
                                      {file.name.substring(0, 20)}
                                    </Typography>
                                    <CloseIcon
                                      sx={{
                                        cursor: 'pointer',
                                        background: '#00000029',
                                        fontSize: 20
                                      }}
                                      onClick={() => {
                                        setFile(null);
                                        ref.current!.value = null as any;
                                      }}
                                    />
                                  </Box>
                                )}
                              </Box>
                            </Grid>
                            <Grid
                              item
                              xs={9}
                              display="flex"
                              justifyContent="flex-end"
                              gap={1}
                              mt={4}>
                              <Button
                                type="submit"
                                variant="primary"
                                disabled={uploadLoading || updateLoading || (!file && !attachment)}>
                                <Typography>{t('button.submit')}</Typography>
                              </Button>
                              <Button
                                variant="secondary"
                                onClick={() => {
                                  setFormShown();
                                  formik.resetForm();
                                }}>
                                <Typography>{t('button.cancel')}</Typography>
                              </Button>
                            </Grid>
                          </form>
                        </Grid>
                      )}
                    </Grid>
                  </Fragment>
                )}
              </Fragment>
            ) : (
              <Fragment>
                {isLoading && <Loader />}
                {data?.contents?.map((ctn: CareerContentItem) => (
                  <ContentCard id={ctn.id} key={ctn.id} title={ctn.title} dueDate={ctn.due_date} />
                ))}
                {!data?.contents?.length && !isLoading && (
                  <Typography variant="h6">{t('careerCare.shouldAdd')}</Typography>
                )}
              </Fragment>
            )}
          </ContentCardWrapper>
        </Grid>
      </AuthWrapper>
    </Grid>
  );
};

export default CareerContent;
