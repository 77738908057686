import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '../theme/defaultTheme';

const useDetectDevice = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('mb2'));
  const isSmall = useMediaQuery(theme.breakpoints.down('mb3'));
  const isMedium = useMediaQuery(theme.breakpoints.down('sm'));
  const isLarge = useMediaQuery(theme.breakpoints.down('l'));

  return { isMobile, isSmall, isMedium, isLarge };
};

export default useDetectDevice;
