import React, { useState, FormEvent } from 'react';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { uploadKahootRating } from 'src/services/api';
import useSubCourses from 'src/hooks/queries/useSubCourses';
import useExtendedCourses from 'src/hooks/queries/useExtendedCourses';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

interface Props {
  close: () => void;
}

const UploadRating: React.FC<Props> = ({ close }) => {
  const { t } = useTranslation();
  const { courses = [] } = useExtendedCourses();
  const { subCourses = [] } = useSubCourses();
  const [type, setType] = useState<'courses' | 'subCourses'>('courses');
  const [courseId, setCourseId] = useState<number>();
  const [groupId, setGroupId] = useState<number>();
  const [file, setFile] = useState<File>();
  const { mutate: uploadRating, isLoading } = useMutation(['upload-rating'], (payload: FormData) =>
    uploadKahootRating(payload)
  );

  const chosenCourse = courses.find((course) => course.id === courseId);
  const chosenSubCourse = subCourses.find((sub) => sub.id === courseId);

  const handleRatingUpload = async (e: FormEvent) => {
    e.preventDefault();
    if (!file || !groupId) {
      return;
    }
    const key = type === 'courses' ? 'group_id' : 'subgroup_id';
    try {
      const rating = new FormData();
      rating.append('scores_excel', file);
      rating.append(key, groupId.toString());
      uploadRating(rating, {
        onSuccess: () => {
          close();
        }
      });
    } catch (err) {
      // console.log(err);
    }
  };

  return (
    <form onSubmit={handleRatingUpload}>
      <Grid container rowSpacing={2.4} marginTop={2}>
        <Grid item xs={12}>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            value={type}
            name="type"
            onChange={(e) => {
              setCourseId(undefined);
              setGroupId(undefined);
              setType(e.target.value as 'courses' | 'subCourses');
            }}>
            <FormControlLabel value="courses" control={<Radio />} label="Course" />
            <FormControlLabel value="subCourses" control={<Radio />} label="Specialization" />
          </RadioGroup>
        </Grid>
        {type === 'courses' ? (
          <>
            <Grid item xs={12}>
              <FormControl>
                <TextField
                  select
                  name="course"
                  label="Course"
                  value={courseId}
                  onChange={(e) => {
                    setGroupId(undefined);
                    setCourseId(parseInt(e.target.value, 10));
                  }}>
                  {courses.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <TextField
                  select
                  name="group"
                  label="Group"
                  value={groupId}
                  onChange={(e) => setGroupId(parseInt(e.target.value, 10))}>
                  {chosenCourse?.groups.map((course: any) => {
                    return (
                      <MenuItem key={course?.id} value={course?.id}>
                        {course?.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </FormControl>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <FormControl>
                <TextField
                  select
                  name="course"
                  label="Course"
                  value={courseId}
                  onChange={(e) => {
                    setGroupId(undefined);
                    setCourseId(parseInt(e.target.value, 10));
                  }}>
                  {subCourses.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <TextField
                  select
                  name="group"
                  label="Group"
                  value={groupId}
                  onChange={(e) => setGroupId(parseInt(e.target.value, 10))}>
                  {chosenSubCourse?.subGroups.map((sub) => {
                    return (
                      <MenuItem key={sub.id} value={sub.id}>
                        {sub.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </FormControl>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} alignItems="center">
            <div style={{ flexShrink: 0 }}>
              <Button variant="filled" aria-label="upload kahoot" component="label">
                <input
                  hidden
                  onChange={(e) => {
                    const filesList = e.currentTarget.files;
                    if (!filesList) {
                      return;
                    }
                    setFile(filesList[0]);
                  }}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  type="file"
                />
                <Typography>{t('button.uploadRating')}</Typography>
              </Button>
            </div>
            {file && (
              <Typography
                sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {file.name}
              </Typography>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button variant="primary" disabled={isLoading} type="submit">
            <Typography textTransform="uppercase">{t('button.submit')}</Typography>
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default UploadRating;
