import React, { useState } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { Normalize, useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import palette from 'src/theme/overrides/palette';
import { resetPassword } from 'src/services/api';
import { ErrorMessages } from 'src/models/service';
import { restorePasswordSchema } from 'src/helpers/validations';
import { useLanguage, useNavigate } from 'src/context/languageContext';

interface FormData {
  email: string;
}

const RestorePasswordForm = () => {
  const [errors, setErrors] = useState<ErrorMessages | null>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { locale } = useLanguage();

  const formik = useFormik<FormData>({
    initialValues: {
      email: ''
    },
    validationSchema: restorePasswordSchema(t),
    onSubmit: async ({ email }) => {
      const lang = locale === 'en' ? '1' : '2';
      try {
        await resetPassword({ email }, lang);

        navigate(`/restore-password/resend?email=${email}`);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status === 400) {
          setErrors({
            email: 'emailNotFound'
          });
        }
      }
    },
    validateOnBlur: false,
    validateOnChange: true
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container rowSpacing={3} justifyContent="center">
        <Grid item xs={12} textAlign="center">
          <Typography variant="body1" color="violet" fontSize={22} textAlign="center">
            {t('headings.login')}
          </Typography>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Typography variant="subtitle3" sx={{ opacity: 0.6 }}>
            {t('messages.resetPasswordSubtitle')}
          </Typography>
        </Grid>

        <Grid item xs={12} marginTop={3}>
          <FormControl>
            <TextField
              variant="outlined"
              label={t('labels.emailAdress')}
              type="email"
              id="field1212"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              inputProps={{
                autoComplete: 'nothing'
              }}
              error={Boolean(formik.errors.email || errors?.email)}
              helperText={
                formik.errors.email ||
                (errors?.email && t(`errors.${errors.email}` as Normalize<{}>)) // eslint-disable-line @typescript-eslint/ban-types
              }
              FormHelperTextProps={{
                error: Boolean(formik.errors.email || errors?.email)
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} mt={{ sm: 3, xs: 0 }} display="flex" justifyContent="center">
          <Button
            type="submit"
            size="extraLarge"
            variant="primary"
            disabled={formik.isSubmitting || !(formik.isValid && formik.dirty)}>
            <Typography>{t('button.sendLink')}</Typography>
          </Button>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            size="auto"
            variant="text"
            sx={{ color: palette.violet }}
            style={{ fontSize: '16px' }}
            onClick={() => navigate('/login')}>
            {t('button.backToLogin')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default RestorePasswordForm;
