import React, { CSSProperties, FC } from 'react';
import { Container, LoadingIcon } from './styles';
import loading from 'src/assets/icons/loader.svg';

interface ILoader {
  containerStyle?: CSSProperties;
}

const Loader: FC<ILoader> = ({ containerStyle }) => {
  return (
    <Container style={containerStyle}>
      <LoadingIcon src={loading} />
    </Container>
  );
};

export default Loader;
