import { QUERY_KEYS } from 'src/enums';
import { useQuery } from '@tanstack/react-query';
import useLanguageCode from '../useLanguageCode';
import { getSubjects } from './../../services/api';
import { SubjectParams } from 'src/models/service';
import { useAuth } from 'src/context/authContext';

const useSubjects = (params: SubjectParams) => {
  const { isAdmin } = useAuth();
  const { lang } = useLanguageCode();

  const { data, isLoading, isError } = useQuery(
    [QUERY_KEYS.SUBJECTS, params, lang],
    () => getSubjects(params, lang),
    { enabled: isAdmin }
  );

  const subjects = data?.data?.subjects;

  return {
    subjects,
    isLoading,
    isError
  };
};

export default useSubjects;
