import { QUERY_KEYS } from 'src/enums';
import { removeTag } from 'src/services/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

const useRemoveTag = () => {
  const client = useQueryClient();
  const { mutate, error, isLoading } = useMutation<void, AxiosError, { id: string }>(
    ['remove-tag'],
    ({ id }) => removeTag(id),
    {
      onSuccess: () => {
        client.invalidateQueries([QUERY_KEYS.TAGS]);
      }
    }
  );

  return {
    mutate,
    isLoading,
    error
  };
};

export default useRemoveTag;
