import { QUERY_KEYS } from 'src/enums';
import { useQuery } from '@tanstack/react-query';
import useLanguageCode from '../useLanguageCode';
import { useAuth } from 'src/context/authContext';
import { getCategoriesList } from 'src/services/api';

const useCategories = () => {
  const { isAdmin } = useAuth();
  const { lang } = useLanguageCode();

  const { data, isLoading, isError } = useQuery(
    [QUERY_KEYS.CRITERIA, lang],
    () => getCategoriesList(lang),
    { enabled: isAdmin, staleTime: Infinity }
  );

  return {
    isError,
    isLoading,
    categories: data?.evaluationCriteria
  };
};

export default useCategories;
