import { AxiosError } from 'axios';
import { QUERY_KEYS } from 'src/enums';
import { addSubjectFiles } from 'src/services/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const useAddFile = () => {
  const client = useQueryClient();

  const { mutate, isLoading } = useMutation<{ success?: boolean }, AxiosError, FormData>(
    ['add-files'],
    (payload) => addSubjectFiles(payload),
    {
      onSettled: () => {
        client.invalidateQueries([QUERY_KEYS.SYLLABUS]);
      }
    }
  );

  return {
    mutate,
    isLoading
  };
};

export default useAddFile;
