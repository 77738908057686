import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'src/enums';
import { CourseResponse } from 'src/models/service';
import { getCourse } from 'src/services/api';
import useLanguageCode from '../useLanguageCode';
import useProfile from './useProfile';

const useUserCourse = () => {
  const { langCode } = useLanguageCode();
  const { myCourses = [] } = useProfile();
  const courseId = myCourses[0]?.courseId || 0;

  const { data, isLoading, isError } = useQuery<CourseResponse>(
    [QUERY_KEYS.COURSES, courseId],
    () => getCourse(courseId.toString(), langCode),
    {
      enabled: Boolean(courseId)
    }
  );

  return {
    data,
    isLoading,
    isError
  };
};

export default useUserCourse;
