import { AxiosError } from 'axios';
import { updateHomework } from 'src/services/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UploadHomeworkPayload } from './../../models/service';
import { QUERY_KEYS } from 'src/enums';

const useUpdateHomework = () => {
  const client = useQueryClient();

  const { mutate, isLoading } = useMutation<void, AxiosError, UploadHomeworkPayload>(
    ['update-homework'],
    (payload: UploadHomeworkPayload) => updateHomework(payload),
    {
      onSuccess: () => {
        client.invalidateQueries([QUERY_KEYS.ASSIGNMENT]);
      }
    }
  );

  return {
    mutate,
    isLoading
  };
};

export default useUpdateHomework;
