import { AxiosError } from 'axios';
import { QUERY_KEYS } from 'src/enums';
import { editSubjectFile } from 'src/services/api';
import { EditSubjectFile } from 'src/models/service';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const useEditFile = () => {
  const client = useQueryClient();
  const { mutate, isLoading, isError } = useMutation<
    { success?: boolean },
    AxiosError,
    EditSubjectFile
  >(['edit-file'], ({ fileId, payload }) => editSubjectFile(fileId, payload), {
    onSettled: () => {
      client.invalidateQueries([QUERY_KEYS.SYLLABUS]);
    }
  });

  return {
    mutate,
    isError,
    isLoading
  };
};

export default useEditFile;
