import { QUERY_KEYS } from 'src/enums';
import { useQuery } from '@tanstack/react-query';
import { getBlogs } from 'src/services/api';
import { BlogsResponse } from 'src/models/service';

const useBlogs = () => {
  const { data, isLoading } = useQuery<BlogsResponse>([QUERY_KEYS.BLOGS], () => getBlogs());

  return {
    data,
    isLoading
  };
};

export default useBlogs;
