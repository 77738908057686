import palette from './palette';
import theme from '../defaultTheme';
import { Theme } from '@mui/system';
import { Components } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const components: Components<Omit<Theme, 'components'>> = {
  MuiTypography: {
    variants: [
      {
        props: {
          variant: 'caption'
        },
        style: {
          color: palette.text.primary,
          fontSize: 24,
          opacity: 0.6,
          lineHeight: '42px',
          [theme.breakpoints.down('l')]: {
            fontSize: 20,
            lineHeight: '30px'
          },
          [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            lineHeight: '27px'
          }
        }
      },
      {
        props: {
          variant: 'descriptionSmall'
        },
        style: {
          color: palette.text.primary,
          fontSize: 20,
          opacity: 0.6,
          lineHeight: '35px',
          [theme.breakpoints.down('l')]: {
            fontSize: 16,
            lineHeight: '25px'
          }
        }
      },
      {
        props: {
          variant: 'descriptionExtraSmall'
        },
        style: {
          color: palette.text.primary,
          fontSize: 12,
          fontWeight: 200,
          lineHeight: '18px'
        }
      },
      {
        props: {
          variant: 'subtitle1'
        },
        style: {
          color: palette.text.primary,
          fontSize: 24,
          opacity: 1,
          lineHeight: '35px',
          fontWeight: '700',
          [theme.breakpoints.down('l')]: {
            fontSize: 20,
            lineHeight: '30px'
          },
          [theme.breakpoints.down('sm')]: {
            fontSize: 14,
            lineHeight: '21px'
          }
        }
      },
      {
        props: {
          variant: 'body1'
        },
        style: {
          color: palette.text.primary,
          fontSize: 18,
          opacity: 1,
          fontWeight: '500'
        }
      },
      {
        props: {
          variant: 'body2'
        },
        style: {
          color: palette.secondary.light,
          fontSize: 16
        }
      },
      {
        props: {
          variant: 'listItem'
        },
        style: {
          color: palette.listPrimary,
          fontSize: 14,
          opacity: 1,
          fontWeight: 400,
          [theme.breakpoints.down('l')]: {
            fontSize: 16
          }
        }
      },
      {
        props: {
          variant: 'h1'
        },
        style: {
          color: palette.white,
          fontSize: 95,
          lineHeight: '114px',
          fontWeight: 900,
          [theme.breakpoints.down('l')]: {
            fontSize: 60,
            lineHeight: '70px'
          },
          [theme.breakpoints.down('sm')]: {
            fontSize: 40,
            lineHeight: '60px'
          },
          [theme.breakpoints.down('mb3')]: {
            fontSize: 30,
            lineHeight: '40px'
          },
          [theme.breakpoints.down('mb2')]: {
            fontSize: 25,
            lineHeight: '30px'
          }
        }
      },
      {
        props: {
          variant: 'h3'
        },
        style: {
          color: palette.white,
          fontSize: 32,
          opacity: 1,
          lineHeight: '32px',
          fontWeight: '900',
          [theme.breakpoints.down('mb3')]: {
            fontSize: 20
          },
          [theme.breakpoints.down('mb2')]: {
            fontSize: 18
          }
        }
      },
      {
        props: {
          variant: 'subtitle2'
        },
        style: {
          color: palette.text.primary,
          fontSize: 14,
          lineHeight: '21px'
        }
      },
      {
        props: {
          variant: 'titleBold'
        },
        style: {
          color: palette.primary.main,
          fontSize: 24,
          lineHeight: '35px',
          fontWeight: 700,
          [theme.breakpoints.down('mb2')]: {
            fontSize: 18
          }
        }
      },
      {
        props: {
          variant: 'subtitle3'
        },
        style: {
          color: palette.secondary.contrastText,
          fontSize: 16,
          lineHeight: '25px',
          fontWeight: '500'
        }
      },
      {
        props: {
          variant: 'subtitle4'
        },
        style: {
          color: palette.secondary.light,
          fontSize: 20,
          lineHeight: '30px',
          fontWeight: 400,
          [theme.breakpoints.down('l')]: {
            fontSize: 16,
            lineHeight: '28px'
          }
        }
      },
      {
        props: {
          variant: 'btnBold'
        },
        style: {
          color: palette.primary.main,
          fontSize: 16,
          lineHeight: '25px',
          fontWeight: 500
        }
      },
      {
        props: {
          variant: 'pageTitle'
        },
        style: {
          color: palette.text.primary,
          fontSize: 32,
          lineHeight: '48px',
          fontWeight: 600,
          [theme.breakpoints.down('l')]: {
            fontSize: 24
          },
          [theme.breakpoints.down('mb2')]: {
            fontSize: 18,
            lineHeight: '26px'
          }
        }
      }
    ]
  },
  MuiButton: {
    variants: [
      {
        props: {
          variant: 'primary'
        },
        style: {
          color: palette.white,
          borderRadius: '30px !important',
          background: palette.text.primary,
          border: `1px solid ${palette.text.primary}`,
          height: '56px !important',
          ':hover': {
            borderColor: palette.primary.light,
            backgroundColor: palette.btnBackground.primary
          },
          [theme.breakpoints.down('mb2')]: {
            height: '48px !important'
          },
          '> p': {
            fontWeight: 400,
            padding: '10px',
            fontSize: '16px',
            color: palette.white,
            fontFeatureSettings: "'case' on",
            textTransform: 'uppercase',
            whiteSpace: 'nowrap',
            [theme.breakpoints.down('mb2')]: {
              fontSize: '14px'
            }
          },
          ':disabled': {
            opacity: 0.6
          }
        }
      },
      {
        props: {
          variant: 'secondary'
        },
        style: {
          background: palette.white,
          borderRadius: '30px !important',
          border: `1px solid ${palette.text.primary}`,
          height: '56px !important',
          ':hover': {
            boxShadow: 'none',
            transition: '0.3s ease-in-out',
            backgroundColor: palette.btnBackground.secondary,
            border: `1px solid ${palette.white}`
          },
          [theme.breakpoints.down('mb2')]: {
            width: '100%',
            height: '48px !important'
          },
          '> p': {
            padding: '10px',
            fontSize: '16px',
            textTransform: 'uppercase',
            fontFeatureSettings: "'case' on"
          }
        }
      },
      {
        props: {
          size: 'xl'
        },
        style: {
          height: '56px',
          paddingBlock: '22px',
          paddingInline: '168px',
          borderRadius: '8px',
          fontSize: '1rem',
          lineHeight: '25px',
          fontWeight: '700',
          textTransform: 'capitalize',
          [theme.breakpoints.down('mb2')]: {
            width: '100%',
            height: '40px',
            fontSize: '14px',
            paddingInline: 'unset'
          }
        }
      },
      {
        props: {
          variant: 'containedPrimary'
        },
        style: {
          textTransform: 'none',
          background: palette.primary.main,
          color: palette.white,
          boxShadow: 'none',
          whiteSpace: 'nowrap',
          ':hover': {
            backgroundColor: palette.violet,
            boxShadow: 'none'
          },
          ':disabled': {
            opacity: 0.6
          },
          [theme.breakpoints.down('mb2')]: {
            width: '100%',
            height: '48px',
            fontSize: '14px',
            fontWeight: 400
          }
        }
      },
      {
        props: {
          variant: 'filled'
        },
        style: {
          color: palette.white,
          borderRadius: '30px !important',
          background: palette.green,
          boxShadow: 'none',
          whiteSpace: 'nowrap',
          '& svg': {
            marginLeft: '5px'
          },
          ':hover': {
            backgroundColor: '#4CDDA3',
            boxShadow: 'none'
          },
          ':clicked': {
            backgroundColor: '#1AD389',
            boxShadow: 'none'
          },
          [theme.breakpoints.down('mb2')]: {
            height: '48px',
            fontSize: '14px',
            fontWeight: 400
          },
          '> p': {
            padding: '10px',
            fontSize: '16px',
            fontWeight: 'normal',
            fontFeatureSettings: "'case' on",
            color: palette.white,
            textTransform: 'uppercase'
          }
        }
      },
      {
        props: {
          variant: 'text'
        },
        style: {
          background: 'transparent',
          color: palette.primary.main,
          boxShadow: 'none',
          textTransform: 'none',
          height: 'auto',
          fontWeight: 500,
          ':hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }
        }
      },
      {
        props: {
          size: 'medium'
        },
        style: {
          height: '48px',
          paddingBlock: '21px',
          paddingInline: 42,
          borderRadius: '8px',
          fontSize: '1rem',
          lineHeight: '21px',
          fontWeight: '700',
          textTransform: 'capitalize'
        }
      },
      {
        props: {
          size: 'large'
        },
        style: {
          height: '52px',
          paddingBlock: '21px',
          paddingInline: '56px',
          borderRadius: '6px',
          fontSize: '1rem',
          lineHeight: '25px',
          fontWeight: '700',
          textTransform: 'capitalize',
          [theme.breakpoints.down('mb2')]: {
            width: '100%',
            height: '48px',
            fontSize: '14px'
          }
        }
      },
      {
        props: {
          size: 'extraLarge'
        },
        style: {
          height: '56px',
          paddingBlock: 21,
          paddingInline: 109,
          borderRadius: 8,
          fontSize: '1rem',
          lineHeight: '25px',
          fontWeight: '700'
        }
      },
      {
        props: {
          size: 'auto'
        },
        style: {
          height: 'auto',
          padding: 0
        }
      }
    ]
  },

  MuiTextField: {
    defaultProps: {
      fullWidth: true,
      size: 'medium'
    },
    variants: [
      {
        props: {
          variant: 'outlined'
        },
        style: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              height: '100%',
              borderColor: '#DEDEDE !important'
            },
            '&:hover fieldset': {
              borderColor: palette.primary.light
            },
            '&.Mui-focused fieldset': {
              border: '1px solid #2B7DE1 !important'
            }
          },
          '& .MuiInputLabel-root': {
            color: palette.text.secondary
          },
          input: {
            WebkitBoxShadow: '0 0 0 1000px white inset'
          },
          '& .with-code': {
            padding: '0',
            input: {
              padding: '10px',
              '&::placeholder': {
                fontSize: 16,
                color: palette.text.secondary,
                opacity: 1,
                fontWeight: 400
              }
            },
            '& .MuiOutlinedInput-root': {
              marginTop: '-5px',
              background: '#F3F4F6',
              borderRadius: '4px',
              height: '52px !important',

              '& fieldset': {
                border: 'none !important'
              },
              '&:hover fieldset': {
                border: 'none !important'
              },
              '&.Mui-focused fieldset': {
                border: 'none !important'
              }
            },
            '& .MuiInputLabel-root': {
              color: palette.text.secondary
            }
          },
          '& .with-select-profile': {
            padding: 0,
            width: '100%',
            input: {
              '&::placeholder': {
                fontSize: 16,
                color: palette.text.secondary,
                opacity: 1,
                fontWeight: 400
              }
            },
            '.MuiInputAdornment-positionStart': {
              maxHeight: '100%',
              borderRadius: 8,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              border: `1px solid ${palette.primary.light}`,
              background: palette.lightBlue,
              height: '100%'
            }
          },
          '& .with-select': {
            padding: 0,
            width: '100px',
            input: {
              '&::placeholder': {
                fontSize: 16,
                color: palette.text.secondary,
                opacity: 1,
                fontWeight: 400
              }
            },
            '.MuiInputAdornment-positionStart': {
              maxHeight: '100%',
              borderRadius: 8,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              border: `1px solid ${palette.primary.light}`,
              background: palette.lightBlue,
              height: 'calc(100% - 6px)'
            }
          }
        }
      },
      {
        props: {
          size: 'medium'
        },
        style: {
          input: {
            fontSize: 14,
            [theme.breakpoints.up('sm')]: {
              fontSize: 16
            }
          },
          '& .MuiSelect-select': {
            textAlign: 'left'
          },
          '& .MuiInputBase-root': {
            height: 56,
            borderRadius: 8,
            [theme.breakpoints.up('sm')]: {
              height: 56
            }
          },
          '& .MuiInputLabel-root': {
            // top: -5,
            fontWeight: 400,
            color: '#B2B2B2 !important',
            [theme.breakpoints.up('sm')]: {
              top: 0
            }
          },
          '& .Mui-focused': {
            top: 0
          },
          '& .MuiFormLabel-filled': {
            top: 0
          }
        }
      }
    ]
  },

  MuiInputLabel: {
    variants: [
      {
        props: {},
        style: {}
      }
    ]
  },

  MuiSelect: {
    defaultProps: {
      IconComponent: KeyboardArrowDownIcon,
      fullWidth: true
    },
    variants: [
      {
        props: {},
        style: {
          borderRadius: '8px',
          borderColor: '#DEDEDE !important',
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #2B7DE1'
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #ACACAC !important'
          },
          [theme.breakpoints.down('sm')]: {
            height: '56px'
          }
        }
      }
    ]
  },
  MuiFormControl: {
    defaultProps: {
      fullWidth: true
    }
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        '&.Mui-error': {
          whiteSpace: 'nowrap'
        }
      }
    }
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          backgroundColor: '#E5E5E5',
          fontWeight: 'bold'
        }
      }
    },
    variants: [
      {
        props: {
          hidden: true
        },
        style: {
          display: 'none'
        }
      }
    ]
  },
  MuiCard: {
    variants: [
      {
        props: {
          variant: 'outlined'
        },
        style: {
          width: '100%',
          border: 'none',
          '& .MuiCardHeader-root': {
            borderRadius: 8
          },
          '& .MuiCardContent-root': {
            border: `1.2px solid ${palette.primary.light}`,
            borderRadius: 8,
            padding: 0,
            '&:last-child': {
              paddingBottom: 0
            }
          }
        }
      }
    ]
  },

  MuiCardContent: {
    styleOverrides: {
      root: {
        '&.border-top-0': {
          borderTop: '0 !important',
          borderTopLeftRadius: '0 !important',
          borderTopRightRadius: '0 !important'
        }
      }
    }
  },

  MuiAccordion: {
    variants: [
      {
        props: {
          variant: 'elevation'
        },
        style: {
          fontSize: '16px',
          boxShadow: 'none',
          marginTop: '24px',
          fontWeight: 'bold',
          color: palette.darkLilac,
          textTransform: 'capitalize',
          borderRadius: '2px 2px 0px 0px',
          [theme.breakpoints.up('sm')]: {
            fontSize: '18px',
            marginTop: '32px'
          },

          '& .Mui-expanded': {
            color: palette.white,
            background: palette.text.primary,

            [theme.breakpoints.up('sm')]: {
              maxHeight: '60px'
            }
          }
        }
      }
    ]
  },

  MuiAccordionDetails: {
    variants: [
      {
        props: {},
        style: {
          fontSize: '14px',
          fontWeight: 500,
          color: palette.text.primary,
          opacity: 0.7,
          borderTop: 'none',
          [theme.breakpoints.up('sm')]: {
            fontSize: '16px'
          }
        }
      }
    ]
  },

  MuiAccordionSummary: {
    variants: [
      {
        props: {},
        style: {
          padding: '4px 32px',
          background: palette.grampus
        }
      }
    ]
  },

  MuiTabs: {
    variants: [
      {
        props: {
          variant: 'scrollable'
        },
        style: {
          '& .MuiTabs-flexContainer': {
            flexWrap: 'nowrap'
          },
          '& .MuiTab-root': {
            fontSize: 14,
            fontWeight: 500,
            padding: '0px 32px',
            marginRight: '16px',
            borderRadius: '50px',
            marginBottom: '12px',
            maxWidth: 'max-content',
            textTransform: 'capitalize',
            color: palette.text.primary,
            background: palette.grampus,
            [theme.breakpoints.down('mb2')]: {
              fontSize: 12,
              maxWidth: 'none'
            }
          },
          '& .Mui-selected': {
            fontWeight: 500,
            background: palette.violet,
            color: `${palette.white} !important`
          },
          '& .MuiTabs-indicator': {
            display: 'none'
          }
        }
      },
      {
        props: {
          variant: 'standard'
        },
        style: {
          '& .MuiTab-root': {
            textTransform: 'capitalize',
            fontSize: 22,
            fontWeight: 500,
            lineHeight: 1.5,
            color: palette.darkLilac,
            paddingBottom: 16,
            [theme.breakpoints.down('mb2')]: {
              fontSize: 18
            }
          },
          '& .Mui-selected': {
            color: `${palette.text.primary} !important`
          },
          '& .MuiTabs-indicator': {
            height: 4,
            borderRadius: 5,
            background: palette.text.primary
          }
        }
      },
      {
        props: {
          variant: 'fullWidth'
        },
        style: {
          '& .MuiTab-root': {
            fontSize: 14,
            fontWeight: 500,
            padding: '0px 32px',
            marginRight: '16px',
            borderRadius: '50px',
            marginBottom: '12px',
            maxWidth: 'max-content',
            textTransform: 'capitalize',
            color: palette.text.primary,
            background: palette.grampus,
            [theme.breakpoints.down('mb2')]: {
              fontSize: 12,
              maxWidth: 'none'
            }
          },
          '& .Mui-disabled': {
            opacity: 0.5
          },
          '& .Mui-selected': {
            fontWeight: 500,
            background: palette.violet,
            color: `${palette.white} !important`
          },
          '& .MuiTabs-indicator': {
            display: 'none'
          }
        }
      }
    ]
  },
  MuiCheckbox: {
    styleOverrides: {
      colorSecondary: {
        color: 'white',
        '&.Mui-checked': {
          color: 'white'
        }
      }
    }
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        color: palette.text.primary,
        svg: {
          fontSize: 15
        }
      }
    }
  }
};

export default components;
