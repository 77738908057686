import React, { MutableRefObject, FC, useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import moment from 'moment';
import { NavigationButton, StyledStack } from './styles';
import { Button, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { CardImage } from 'src/views/courseDetails/styles';
import ArrowBack from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForward from '@mui/icons-material/ArrowForwardIosRounded';
import EventModal from 'src/components/modals/AddEventModal';
import AlertModal from 'src/components/modals/AlertModal';
import palette from 'src/theme/overrides/palette';
import { useTranslation } from 'react-i18next';
import { googleSyncRedirect } from 'src/services/api';
import { useCalendar } from 'src/context/calendarContext';
import { useLanguage } from 'src/context/languageContext';
import { ReactComponent as PlusIcon } from 'src/assets/icons/addPlus.svg';
interface CalendarHeaderProps {
  calendarRef: MutableRefObject<FullCalendar>;
  isSynced?: boolean;
  calendarValue?: string;
  setCalendarValue: React.Dispatch<React.SetStateAction<string>>;
}

const CalendarHeader: FC<CalendarHeaderProps> = ({
  calendarRef,
  isSynced,
  calendarValue,
  setCalendarValue
}) => {
  const [title, setTitle] = useState<Date | undefined>();
  const { locale } = useLanguage();
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const { t } = useTranslation();

  const { addEventModalShown, setAddEventModalShown, setEventDetailModalShown } = useCalendar();

  useEffect(() => {
    if (calendarRef.current) {
      setTitle(calendarRef.current.getApi()?.currentDataManager?.getCurrentData().currentDate);
    }
  }, [calendarRef]);

  const handleNext = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().next();
      setTitle(calendarRef.current.getApi()?.currentDataManager?.getCurrentData().currentDate);
    }
  };

  const handlePrev = () => {
    const currentCalendarMonth = calendarRef.current
      .getApi()
      .currentDataManager?.getCurrentData().currentDate;

    if (currentCalendarMonth && currentCalendarMonth < new Date('September 1, 2022')) {
      return;
    }

    if (calendarRef.current) {
      calendarRef.current.getApi().prev();
      setTitle(calendarRef.current.getApi().currentDataManager?.getCurrentData().currentDate);
    }
  };
  const changeValueHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCalendarValue(e.target.value);
    calendarRef.current.getApi().changeView(e.target.value);
  };

  const closeModalHandler = () => {
    setEventDetailModalShown(false);
    setOpenAlertModal(false);
  };

  const googleSyncHandler = async () => {
    const { data } = await googleSyncRedirect();
    setOpenAlertModal(false);
    window.open(data.authUrl, '_blank', 'noopener,noreferrer');
  };

  const calendarTime = moment(title)
    .locale(locale === 'en' ? 'en-gb' : 'ka')
    .format('LL')
    .slice(2);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
      overflow="hidden"
      height={{ xs: 56, mb2: 72 }}
      paddingLeft={{ xs: '16px', mb2: 4 }}
      paddingRight={{ xs: '16px', mb2: 4, m: 0 }}
      maxWidth="1632px"
      margin="auto"
      sx={{
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8
      }}
      bgcolor="black">
      <Box
        width={{ xs: '100%', m: 'auto' }}
        display="flex"
        gap={{ sx: 2, sm: 8 }}
        alignItems="center"
        zIndex={100}
        justifyContent="space-between">
        <Typography color={palette.white} mr={-4} fontSize={{ sx: 16, sm: 20 }}>
          {calendarTime}
        </Typography>

        <Stack direction="row" spacing={{ xs: 1, mb2: 3 }}>
          <NavigationButton onClick={handlePrev}>
            <ArrowBack style={{ width: '16px' }} />
          </NavigationButton>
          <NavigationButton onClick={handleNext}>
            <ArrowForward style={{ width: '16px' }} />
          </NavigationButton>
        </Stack>
      </Box>

      <StyledStack direction="row" spacing={3} paddingRight={3} zIndex={100} id="calendar_head">
        <EventModal open={addEventModalShown} close={() => setAddEventModalShown(false)} />
        <Button variant="filled" onClick={() => setAddEventModalShown(true)}>
          <Typography fontWeight={500} color="inherit">
            {t('calendar.addEvent')}
          </Typography>
          <PlusIcon />
        </Button>

        <AlertModal
          open={openAlertModal}
          close={closeModalHandler}
          onConfirm={googleSyncHandler}
          confirmationText={t('modal.syncConfirmationText')}
        />

        {/* {!isSynced && (
          <StyledButton onClick={() => setOpenAlertModal(true)}>
            <Typography fontWeight={500} color="inherit">
              {t('calendar.sync')}
            </Typography>
          </StyledButton>
        )} */}

        {/* <StyledButton onClick={() => setOpenAlertModal(true)}>
          <Typography fontWeight={500} color="inherit">
            {t('calendar.sync')}
          </Typography>
        </StyledButton> */}
      </StyledStack>
      <CardImage />
    </Box>
  );
};

export default CalendarHeader;
