import { QUERY_KEYS } from 'src/enums';
import { addSubject } from 'src/services/api';
import { AddSubjectPayload } from 'src/models/service';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const useAddSubject = () => {
  const client = useQueryClient();
  const { mutate, isLoading } = useMutation(
    ['add-subject'],
    (payload: AddSubjectPayload) => addSubject(payload),
    {
      onSuccess: () => {
        client.invalidateQueries([QUERY_KEYS.SYLLABUS]);
      }
    }
  );

  return {
    mutate,
    isLoading
  };
};

export default useAddSubject;
