import { DefaultTheme as StyledDefaultTheme } from 'styled-components';

const DefaultTheme: StyledDefaultTheme = {
  borderRadius: '4px',
  palette: {
    common: {
      black: '#2C2E2F',
      white: '#ffffff'
    },
    primary: {
      main: '#5946D4',
      contrastText: '#ffffff',
      light: '#DEDEDE'
    }
  }
};

export default DefaultTheme;
