import React, { useEffect, useState, Dispatch, SetStateAction, useRef } from 'react';
import * as Yup from 'yup';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { getSafeHtml } from 'src/utils';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { uploadEditorImage } from 'src/services/api';
import { CareerContentItem } from 'src/models/service';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import useCategories from 'src/hooks/queries/useCategories';
import { ReactComponent as PlusIcon } from 'src/assets/icons/addPlus.svg';
import useEditCareerContent from 'src/hooks/mutations/useEditCareerContent';
import useCreateCareerContent from 'src/hooks/mutations/useCreateCareerContent';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js';

export const EditorWrapper = styled(Box)`
  label {
    width: 150px;
    overflow: hidden;
  }
`;
interface Props {
  id: string;
  details: CareerContentItem;
}
interface FormData {
  title: string;
  dueDate: string;
  mailText: string;
  estimation: string;
  description: string;
  category: number[];
  files: File | null;
}

const uploadImage = async (file: File, setImages: Dispatch<SetStateAction<string[]>>) => {
  const fd = new FormData();
  fd.append('image', file);
  const info = await uploadEditorImage(fd);

  setImages((prevImages) => [...prevImages, info?.image.url]);

  return Promise.resolve({ data: { link: info?.image.url } });
};

const CreateCareerCareModule = ({ details, id }: Props) => {
  const { t } = useTranslation();
  const [images, setImages] = useState([]);
  const ref = useRef<HTMLInputElement | null>(null);
  const [files, setFiles] = useState<File[]>([]);

  const { categories } = useCategories();

  const [cntState, setCntState] = useState(EditorState.createEmpty());
  const [mailState, setMailState] = useState(EditorState.createEmpty());
  const onContentChange = (editorState: EditorState) => setCntState(editorState);
  const onEmailTextChange = (editorState: EditorState) => setMailState(editorState);

  const content = draftToHtml(convertToRaw(cntState.getCurrentContent()));
  const mailText = draftToHtml(convertToRaw(mailState.getCurrentContent()));

  const { mutate: editContent } = useEditCareerContent(String(id));
  const { mutate: createContent, isLoading } = useCreateCareerContent();

  const {
    title = '',
    due_date = '',
    description = '',
    content: ctn = '',
    estimate_time = '',
    files: careerFiles = [],
    evaluation_criteria = []
  } = details || {};

  useEffect(() => {
    const contentHtml = convertFromHTML(ctn || '');
    const mailHtml = convertFromHTML(description || '');

    const initialContent = ContentState.createFromBlockArray(
      contentHtml.contentBlocks,
      contentHtml.entityMap
    );

    const initialMail = ContentState.createFromBlockArray(
      mailHtml.contentBlocks,
      mailHtml.entityMap
    );

    setCntState(EditorState.createWithContent(initialContent));
    setMailState(EditorState.createWithContent(initialMail));
  }, [ctn, description, id]);

  const formik = useFormik<FormData>({
    initialValues: {
      files: null,
      title: title || '',
      description: content || '',
      mailText: description || '',
      estimation: estimate_time || '',
      dueDate: due_date?.slice(0, 10) || '',
      category: evaluation_criteria.map((el) => el.id)
    },
    validationSchema: Yup.object({
      title: Yup.string().required(t('validations.required', { field: t('labels.title') }))
    }),
    onSubmit: async ({ title, dueDate, estimation, category }) => {
      const fd = new FormData();

      images.forEach((image, i) => fd.append(`images[${i}]`, image));
      category.forEach((crt, i) => fd.append(`evaluation_criteria[${i}]`, String(crt)));
      fd.append('title', title);
      fd.append('content', getSafeHtml(content));
      fd.append('description', getSafeHtml(mailText));
      if (estimation) fd.append('estimate_time', estimation);
      if (dueDate) fd.append('due_date', `${dueDate} 00:00:00`);
      if (files.length > 0) {
        files.forEach((file, i) => fd.append(`files[${i}][attachment]`, file));
      }

      if (id) editContent(fd);
      else createContent(fd);
    },
    validateOnBlur: false,
    validateOnChange: false
  });

  const contentError = !cntState.getCurrentContent().hasText() && formik.submitCount > 0;
  const mailTextError = !mailState.getCurrentContent().hasText() && formik.submitCount > 0;

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid py={3} gap={3} container mt={7}>
        <Grid item xs={9}>
          <Grid item xs={12}>
            <TextField
              name="title"
              variant="outlined"
              label={t('labels.title')}
              onChange={formik.handleChange}
              value={formik.values.title}
              defaultValue={formik.values.title}
              helperText={formik.touched.title && formik.errors.title}
              error={formik.touched.title && Boolean(formik.errors.title)}
              FormHelperTextProps={{
                error: formik.touched.title && Boolean(formik.errors.title)
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={9}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                name="estimation"
                variant="outlined"
                label={t('labels.estimation')}
                onChange={formik.handleChange}
                value={formik.values.estimation}
                helperText={'Format: 1m, 2h, 3d...'}
                defaultValue={formik.values.estimation}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="date"
                name="dueDate"
                variant="outlined"
                label={t('labels.due_date')}
                onChange={formik.handleChange}
                value={formik.values.dueDate}
                InputLabelProps={{ shrink: true }}
                helperText={formik.errors.dueDate}
                error={Boolean(formik.errors.dueDate)}
                FormHelperTextProps={{
                  error: Boolean(formik.errors.dueDate)
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={9}>
          <FormControl>
            <InputLabel>{t('labels.category')}</InputLabel>
            <Select
              multiple
              name="category"
              value={formik.values.category}
              variant="outlined"
              label={t('labels.category')}
              onChange={formik.handleChange}>
              {categories?.map((ctgr: any) => {
                return (
                  <MenuItem key={ctgr} value={ctgr.id}>
                    {ctgr.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={9}>
          <Typography fontSize={14} fontWeight="normal" mb={1}>
            {t('labels.mailText')}
          </Typography>
          <EditorWrapper sx={{ border: '1px solid #e4e4e4' }}>
            <Editor
              editorState={mailState}
              onEditorStateChange={onEmailTextChange}
              toolbar={{
                image: {
                  uploadCallback: (file: File) => uploadImage(file, setImages),
                  urlEnabled: true,
                  uploadEnabled: true,
                  defaultSize: {
                    width: '100%',
                    height: '100%'
                  }
                }
              }}
              editorStyle={{ paddingLeft: '15px', minHeight: '200px' }}
            />
            <textarea disabled hidden value={mailText} />
          </EditorWrapper>
          {mailTextError && (
            <FormHelperText error sx={{ margin: '6px 12px' }}>
              {t('validations.required', { field: t('labels.mailText') })}
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={9}>
          <Typography fontSize={14} fontWeight="normal" mb={1}>
            {t('labels.contentDescription')}
          </Typography>
          <EditorWrapper sx={{ border: '1px solid #e4e4e4' }}>
            <Editor
              editorState={cntState}
              onEditorStateChange={onContentChange}
              toolbar={{
                image: {
                  uploadCallback: (file: File) => uploadImage(file, setImages),
                  urlEnabled: true,
                  uploadEnabled: true,
                  defaultSize: {
                    width: '100%',
                    height: '100%'
                  }
                }
              }}
              editorStyle={{ paddingLeft: '15px', minHeight: '200px' }}
            />
            <textarea disabled hidden value={content} />
          </EditorWrapper>
          {contentError && (
            <FormHelperText error sx={{ margin: '6px 12px' }}>
              {t('validations.required', { field: t('labels.contentDescription') })}
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          {careerFiles.length > 0 && (
            <Box>
              <Typography>{t('careerCare.uploadedFiles')}:</Typography>
              <Box display="flex" flexDirection="column" mt={2}>
                {careerFiles.map((file) => {
                  return (
                    <Typography
                      component="a"
                      color="violet"
                      fontSize={16}
                      target="_blank"
                      fontWeight={400}
                      key={file.attachment}
                      href={file.attachment}
                      sx={{ textDecoration: 'underline' }}>
                      {file?.attachment_name || 'File'}
                    </Typography>
                  );
                })}
              </Box>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} display="flex" gap={2}>
          <Button onClick={() => ref.current?.click()} variant="filled">
            <input
              hidden
              ref={ref}
              type="file"
              onChange={(e) => {
                if (e.target.files) {
                  setFiles([...files, e.target.files[0]]);
                }
              }}
            />
            <Typography fontSize={16} color="darkblue" fontWeight={450} textTransform="uppercase">
              {t('button.upFile')}
              <PlusIcon />
            </Typography>
          </Button>
          {files.length > 0 && (
            <Box display="flex" gap="6px" alignItems="center">
              {files.map((file) => {
                return (
                  <>
                    <Typography variant="listItem" fontSize={16} color="black">
                      {file.name.substring(0, 20)}
                    </Typography>
                    <CloseIcon
                      sx={{ cursor: 'pointer', background: '#00000029', fontSize: 20 }}
                      onClick={() => {
                        setFiles(files.filter((item) => item.name !== file.name));
                      }}
                    />
                  </>
                );
              })}
            </Box>
          )}
        </Grid>
        <Grid item xs={9} display="flex" justifyContent="flex-end" gap={1.7} mt={5}>
          <Button variant="primary" type="submit" disabled={isLoading}>
            <Typography>{id ? t('common.edit') : t('button.submit')}</Typography>
          </Button>
          <Button variant="secondary" onClick={formik.handleReset}>
            <Typography>{t('button.cancel')}</Typography>
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateCareerCareModule;
