import React from 'react';
import Invoice from 'src/components/UI/invoice';
import 'src/App.css';
import styled from 'styled-components';
import { breakPoints } from 'src/theme/defaultTheme';
import { getInvoicePdf } from 'src/services/api';
import Loader from 'src/components/common/Loader';
import { useParams } from 'react-router-dom';
import useCourse from 'src/hooks/queries/useCourse';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'src/enums';
import { Grid } from '@mui/material';
import { COURSES } from '../buyCourse/courses';
import { useLanguage } from 'src/context/languageContext';

const InvoiceWrapper = styled.div`
  padding-top: 56px;
  margin-top: 48px;
  box-shadow: 0px 0px 5px #00000029;
  border-radius: 8px;

  @media (max-width: ${breakPoints.m}px) {
    border: none;
    padding-top: 0;
    margin-top: 32px;
  }
`;

const CourseInvoice = () => {
  const { locale } = useLanguage();
  const { courseId = '' } = useParams();
  const { isLoading, isError } = useCourse(courseId);
  // const { data: invoice } = useQuery([QUERY_KEYS.INVOICE_PDF], () => getInvoicePdf());

  const course = COURSES.filter(({ id }) => String(id) === courseId)[0];

  // const downloadInvoice = async () => {
  //   if (!invoice) return;

  //   const url = window.URL.createObjectURL(invoice);
  //   const link = document.createElement('a');

  //   link.href = url;
  //   link.setAttribute('download', `Invoice - skillwill Academy`);

  //   document.body.appendChild(link);

  //   link.click();
  //   link.parentNode?.removeChild(link);
  // };

  if (isLoading) return <Loader />;
  if (isError) return <div>Course Not found</div>;

  return (
    <Grid
      container
      justifyContent="center"
      marginTop={{ xs: 2, l: 6 }}
      marginBottom={{ xs: 2, sm: 8 }}
      paddingBottom={{ xs: '64px' }}
      paddingLeft={{ xs: '10px', mb3: '50px' }}
      paddingRight={{ xs: '10px', mb3: '50px' }}>
      <Grid item xs={12} maxWidth={{ sm: 1080 }}>
        {/* <Typography variant="pageTitle" lineHeight="15px" fontWeight="fontWeightBold">
          {t('course.buyCourse')}
        </Typography> */}
        <InvoiceWrapper>
          <Invoice fee={course.fee} name={course.title[locale]} />
        </InvoiceWrapper>
        {/* <InvoiceDetails>
          <Button variant="containedPrimary" className="details-button" onClick={downloadInvoice}>
            {t('course.download')}
          </Button>
          <Box width={'100%'} paddingTop={'12px'}>
            <Typography
              display="block"
              variant="titleBold"
              lineHeight="5px"
              fontSize={'15px'}
              marginBottom={{ xs: '16px' }}>
              {t('course.orderDetails')}
            </Typography>
            <Typography variant="subtitle4" fontSize={'14px'} color="text.primary">
              {t('course.desc')}
            </Typography>
          </Box>
        </InvoiceDetails> */}
      </Grid>
    </Grid>
  );
};

export default CourseInvoice;
