import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { QUERY_KEYS } from 'src/enums';
import { deleteSubjectFile } from 'src/services/api';
import useLanguageCode from '../useLanguageCode';

const useDeleteFile = () => {
  const client = useQueryClient();
  const { lang } = useLanguageCode();
  const { mutate, isLoading } = useMutation<{ success?: boolean }, AxiosError, { fileId: number }>(
    ['delete-file'],
    ({ fileId }) => deleteSubjectFile(fileId, lang),
    {
      onSettled: () => {
        client.invalidateQueries([QUERY_KEYS.SYLLABUS]);
      }
    }
  );

  return {
    mutate,
    isLoading
  };
};

export default useDeleteFile;
