import { QUERY_KEYS } from 'src/enums';
import { useQuery } from '@tanstack/react-query';
import useLanguageCode from '../useLanguageCode';
import { getContentDetails } from 'src/services/api';

const useContentDetail = (id: string) => {
  const { langCode } = useLanguageCode();

  const { data, isLoading, isFetching, isError, refetch } = useQuery(
    [QUERY_KEYS.CAREER_CONTENT_DETAIL, langCode],
    () => getContentDetails(id, langCode),
    { enabled: Boolean(id) }
  );

  return {
    refetch,
    isError,
    isLoading,
    isFetching,
    details: data?.content,
    files: data?.content?.files,
    assignment: data?.content?.assignments[0]
  };
};

export default useContentDetail;
