import { TypeText } from '@mui/material/styles/createPalette';
import { PaletteOptions, SimplePaletteColorOptions } from '@mui/material';

interface DefaultPaletteOptions extends PaletteOptions {
  primary: SimplePaletteColorOptions;
  secondary: SimplePaletteColorOptions;
  error: SimplePaletteColorOptions;
  text: TypeText;
  lightYellow?: string;
  darkPurple?: string;
  blue?: string;
  lightBlue: string;
  white: string;
  shadowGray: string;
  lightBorder: string;
  headerShadow: string;
  textBlack: string;
  listPrimary: string;
  listSubheader: string;
  lightGray: string;
  border: string;
  bgGray: string;
  slatyBlue: string;
  swarthy: string;
  grampus: string;
  darkLilac: string;
  orange: string;
  green: string;
  violet: string;
  red: string;
  btnBackground: {
    primary: string;
    secondary: string;
  };
}

const palette: DefaultPaletteOptions = {
  primary: {
    main: '#5946D4',
    light: '#DEDEDE',
    contrastText: '#F7D51B'
  },
  secondary: {
    main: '#7162D0',
    contrastText: '#170738',
    light: '#979DA2'
  },
  error: {
    main: '#F73E48'
  },
  text: {
    primary: '#000000',
    secondary: '#B2B2B2',
    disabled: ''
  },
  grey: {
    '400': '#ACACAC'
  },
  lightYellow: '#F8E57C',
  darkPurple: '#36297D',
  blue: '#4267B2',
  lightBlue: '#F3F4F6',
  white: '#ffffff',
  shadowGray: '#2B2B2B1A',
  lightBorder: '#FAFAFA',
  headerShadow: '#00000029',
  textBlack: '#242424',
  listPrimary: '#007FFF',
  listSubheader: '#707070',
  lightGray: '#D5D2F3',
  border: '#E4E4E4',
  bgGray: '#F8F8F8',
  slatyBlue: '#00A3E0',
  swarthy: '#1C1831',
  grampus: '#f2f4f7',
  darkLilac: '#283A50',
  orange: '#FF600A',
  green: '#00ce7c',
  violet: '#2A7DE1',
  red: '#FE3B1F',
  btnBackground: {
    primary: '#153E70',
    secondary: '#F4F8FD'
  }
};

export default palette;
