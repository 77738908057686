import { useQuery } from '@tanstack/react-query';
import { HomeworkResponse } from 'src/models/service';
import { getHomeWork } from 'src/services/api';

const useHomeWork = (chapterId: string) => {
  const { data, isLoading, isError, isFetching } = useQuery<HomeworkResponse>(
    ['get-homeworks', chapterId],
    () => getHomeWork(chapterId),
    {
      enabled: Boolean(chapterId)
    }
  );
  return {
    data,
    isLoading,
    isError,
    isFetching
  };
};

export default useHomeWork;
