import { QUERY_KEYS } from 'src/enums';
import { copySyllabus } from './../../services/api';
import useLanguageCode from 'src/hooks/useLanguageCode';
import { CopySyllabusPayload } from './../../models/service';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const useCopySyllabus = () => {
  const client = useQueryClient();
  const { lang } = useLanguageCode();

  const { mutate, isLoading } = useMutation(
    ['copy-syllabus'],
    (payload: CopySyllabusPayload) => copySyllabus(lang, payload),
    {
      onSuccess: () => {
        client.invalidateQueries([QUERY_KEYS.SYLLABUS]);
      }
    }
  );

  return {
    mutate,
    isLoading
  };
};

export default useCopySyllabus;
