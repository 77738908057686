import React, { FC, useMemo, useState } from 'react';
import { Dialog, DialogContent, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { scroller } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ProgressBar from 'src/components/common/progressBar';
import CourseWeek from 'src/components/UI/course/CourseWeek';
import CopySyllabusForm from 'src/components/UI/forms/CopySyllabus';
import UploadRating from 'src/components/UI/forms/UploadRating';
import CourseMonthList from 'src/components/UI/lists/CourseMonthList';
import KahootRating from 'src/components/UI/lists/KahootRating';
import RankingStatus from 'src/components/UI/rankingStatus';
import { useAuth } from 'src/context/authContext';
import { useMonth } from 'src/context/monthContext';
import useAddWeek from 'src/hooks/mutations/useAddWeek';
import useToggle from 'src/hooks/useToggle';
import useProfile from 'src/hooks/queries/useProfile';
import useProgress from 'src/hooks/queries/useProgress';
import useDetectDevice from 'src/hooks/useDetectDevice';
import useQueryString from 'src/hooks/useQueryString';
import useAddMonth from 'src/hooks/mutations/useAddMonth';
import { CardHeaderBlack, StyledCardContent } from './styles';
import { CourseMonth, PositionedRating } from 'src/models/general';
import useExtendedCourses from 'src/hooks/queries/useExtendedCourses';
import { ReactComponent as PlusIcon } from 'src/assets/icons/addPlus.svg';

type Weeks = 1 | 2 | 3 | 4;
interface CourseDetailsProps {
  months: CourseMonth[];
  rating?: PositionedRating[];
}

const CourseDetails: FC<CourseDetailsProps> = ({ months = [], rating = [] }) => {
  const theme = useTheme();
  const { isAdmin } = useAuth();
  const { t } = useTranslation();
  const { parseQuery } = useQueryString();
  const { courses } = useExtendedCourses();
  const { pathname } = useLocation();
  const { isMedium } = useDetectDevice();

  const [uploadModalOpen, setUploadModalOpen] = useToggle();
  const { activeSubject, activeMonth = 0 } = useMonth();
  const { mutate: addMonth, isLoading } = useAddMonth();
  const { mutate: addWeek, isLoading: isWeekAdding } = useAddWeek();

  const { chapterId, course, scroll = '' } = parseQuery();
  const specId = pathname.split('/').pop();
  const detChapId = Number(chapterId) || Number(specId);

  const { myCourses = [] } = useProfile();

  const defSelectedMonth = activeMonth + 1 > months?.length ? 0 : activeMonth;

  const courseIdx = isMedium && myCourses?.length > 1 ? 1 : 0;
  const [selectedMonth, setSelectedMonth] = useState(defSelectedMonth);

  const { progress } = useProgress({
    chapter_id: chapterId?.toString() || course?.toString() || myCourses[0]?.courseId.toString()
  });

  const monthList = useMemo(
    () =>
      months.map((month) => ({
        id: month.id,
        isDisabled: month.is_disabled,
        monthTitle: month.month
      })),
    [months]
  );

  React.useEffect(() => {
    if (isMedium && monthList && scroll) {
      scroller.scrollTo('ranking', {
        duration: 1200,
        delay: 300,
        smooth: 'easeInOutQuint'
      });
    }
  }, [isMedium, monthList, scroll]);

  const month = months[selectedMonth] || {};
  const { week: monthWeeks = [] } = month;

  const switchMonthHandler = (indx: number) => setSelectedMonth(indx);

  const courseName = courses?.find((course) => course.id === myCourses[courseIdx]?.courseId)?.name;

  const addMonthHandler = () => {
    addMonth({
      status: chapterId ? 'general' : 'specific',
      chapter_id: detChapId,
      is_disabled: false
    });
  };

  const addWeekHandler = () => {
    addWeek({
      month_id: month.id,
      title: (month.week.length + 1) as Weeks,
      is_disabled: false
    });
  };

  return (
    <React.Fragment>
      {isMedium ? (
        <React.Fragment>
          <Grid item xs={12} mb3 mb2 sm md l>
            <Stack
              gap={1}
              direction="row"
              marginY={3}
              flexWrap="nowrap"
              width={'90vw'}
              sx={{
                overflowX: 'scroll',
                '&::-webkit-scrollbar': {
                  height: theme.spacing(0.5)
                }
              }}>
              {monthList?.map((month) => {
                return (
                  <Chip
                    key={month.id}
                    label={`${t('common.month')} ${month.monthTitle}`}
                    onClick={() => setSelectedMonth(Number(month.monthTitle) - 1)}
                    color="primary"
                    sx={{
                      textTransform: 'capitalize',
                      padding: '8px',
                      height: '40px',
                      margin: theme.spacing(0.5),
                      background:
                        Number(month.monthTitle) === selectedMonth + 1 ? '#2A7DE1' : '#E5E5E5',
                      color: Number(month.monthTitle) === selectedMonth + 1 ? '#fff' : '#000',
                      fontSize: '16px',
                      '&:hover': {
                        background: theme.palette.grey[400]
                      },
                      borderRadius: '52px'
                    }}
                  />
                );
              })}
            </Stack>
            <Stack direction="column" spacing={3}>
              {monthWeeks.map((week) => {
                return (
                  <CourseWeek
                    week={week}
                    key={week.id}
                    months={months}
                    monthId={month.id}
                    weekOpen={week.id === activeSubject?.week_id}
                  />
                );
              })}
            </Stack>
            <Card
              variant="outlined"
              sx={{ background: '#f8f8f8 !important', marginTop: '32px' }}
              className="ranking">
              <CardHeaderBlack>
                <Box width="100%">
                  <Typography fontSize={20} color="white" fontWeight={700}>
                    {t('course.kahoot')}
                  </Typography>
                </Box>
              </CardHeaderBlack>
              <StyledCardContent>
                <Box padding="24px 24px 24px 16px">
                  <Box>
                    {isAdmin ? (
                      <Button fullWidth variant="text" onClick={setUploadModalOpen}>
                        {t('button.uploadRating')}
                      </Button>
                    ) : (
                      <KahootRating list={rating} />
                    )}
                  </Box>
                </Box>
              </StyledCardContent>
              {!isAdmin && (
                <>
                  <RankingStatus list={rating} />
                  <StyledCardContent>
                    <CardContent
                      sx={{
                        border: 'none !important',
                        marginTop: '24px',
                        borderRadius: '16px'
                      }}>
                      <CardHeaderBlack>
                        <Box width="100%">
                          <Typography color="white" fontSize={20} fontWeight={700}>
                            {t('course.progress')}
                          </Typography>
                        </Box>
                      </CardHeaderBlack>
                      <Box
                        pb={4}
                        display="flex"
                        justifyContent="center"
                        marginTop={{ xs: 3, l: 5 }}
                        marginBottom={{ xs: 4, l: 8 }}>
                        <ProgressBar progress={progress} />
                      </Box>
                    </CardContent>
                  </StyledCardContent>
                </>
              )}
            </Card>
          </Grid>
        </React.Fragment>
      ) : (
        <Grid container justifyContent="space-between" paddingBottom={8} gap={{ sm: 2, l: 3 }}>
          <Grid item xs={8.8}>
            <Grid container sx={{ background: '#fff', paddingBottom: '32px' }}>
              <Grid display={{ mb2: 'none', mb3: 'block' }} item xs={2.5} minWidth={{ sm: 140 }}>
                <Stack direction="column" spacing={3} pt={3}>
                  <Box paddingX={1}>
                    {isAdmin && (
                      <Box
                        display="flex"
                        justifyContent="center"
                        mb={isAdmin ? 3 : 0}
                        onClick={addMonthHandler}>
                        <Button variant="filled" fullWidth disabled={isLoading}>
                          <Typography fontSize="15px !important">
                            {t('button.addMonth')}
                            <PlusIcon />
                          </Typography>
                        </Button>
                      </Box>
                    )}
                    <CourseMonthList
                      isAdmin={isAdmin}
                      months={monthList}
                      detChapId={detChapId}
                      selectedMonth={selectedMonth}
                      switchMonthHandler={switchMonthHandler}
                    />
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={9} mt={3}>
                <Stack direction="column" spacing={3}>
                  {isAdmin && months?.length !== 0 && (
                    <Box>
                      <Button
                        variant="filled"
                        onClick={addWeekHandler}
                        disabled={month?.week?.length === 4 || isWeekAdding}>
                        <Typography fontSize="15px !important">
                          {t('button.addWeek')}
                          <PlusIcon />
                        </Typography>
                      </Button>
                    </Box>
                  )}
                  {monthWeeks.map((week) => {
                    return (
                      <CourseWeek
                        week={week}
                        key={week.id}
                        months={months}
                        monthId={month.id}
                        weekOpen={week.id === activeSubject?.week_id}
                      />
                    );
                  })}
                  {months?.length === 0 && isAdmin && <CopySyllabusForm courses={courses} />}
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2.8} mt={-8}>
            <Card variant="outlined" sx={{ background: '#f8f8f8 !important' }}>
              <CardHeaderBlack sx={{ height: '64px' }}>
                <Box width="100%">
                  <Typography fontSize={20} color="white" fontWeight={700}>
                    {t('course.kahoot')}
                  </Typography>
                </Box>
              </CardHeaderBlack>
              <StyledCardContent>
                <Box padding="24px 24px 24px 16px">
                  <Box>
                    {isAdmin ? (
                      <Box display="flex" justifyContent="center">
                        <Button variant="filled" sx={{ width: '80%' }} onClick={setUploadModalOpen}>
                          <Typography fontSize="15px !important">
                            {t('button.uploadRating')}
                          </Typography>
                        </Button>
                      </Box>
                    ) : (
                      <KahootRating list={rating} />
                    )}
                  </Box>
                </Box>
              </StyledCardContent>
              {!isAdmin && (
                <>
                  <RankingStatus list={rating} />
                  <StyledCardContent>
                    <CardContent
                      sx={{
                        border: 'none !important',
                        marginTop: '24px',
                        borderRadius: '16px'
                      }}>
                      <CardHeaderBlack>
                        <Box width="100%">
                          <Typography
                            variant="subtitle4"
                            fontSize={20}
                            color="white"
                            fontWeight={700}>
                            {t('course.progress')}
                          </Typography>
                        </Box>
                      </CardHeaderBlack>
                      <Box
                        pb={4}
                        display="flex"
                        justifyContent="center"
                        marginTop={{ xs: 3, l: 5 }}
                        marginBottom={{ xs: 4, l: 8 }}>
                        <ProgressBar progress={progress} />
                      </Box>
                    </CardContent>
                  </StyledCardContent>
                </>
              )}
            </Card>
          </Grid>
        </Grid>
      )}

      <Dialog
        open={uploadModalOpen}
        onClose={setUploadModalOpen}
        PaperProps={{
          style: {
            borderRadius: '8px',
            boxShadow: '5px 5px 15px #0000001A'
          }
        }}>
        <DialogContent sx={{ width: { sm: 416, l: 516 }, my: { xs: 1, l: 5 } }}>
          <UploadRating close={setUploadModalOpen} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default CourseDetails;
