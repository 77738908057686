import styled from 'styled-components';
import palette from 'src/theme/overrides/palette';
import { breakPoints } from 'src/theme/defaultTheme';

export const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  font-family: 'Helvetica';
  @media (max-width: ${breakPoints.m}px) {
    max-width: 100%;
  }
`;

export const LogoContainer = styled.div`
  max-width: 176px;
  display: flex;
  img {
    width: 100%;
  }

  @media (max-width: ${breakPoints.mb3}px) {
    max-width: 98px;
    margin: 20px;
    margin-bottom: 0;
  }
`;

export const TopBar = styled.div`
  display: flex;
  align-items: center;
  padding-left: 100px;
  @media (max-width: ${breakPoints.m}px) {
    padding-inline: 50px;
  }
  @media (max-width: ${breakPoints.mb3}px) {
    padding-inline: 0;
  }
`;

export const TopBarRightSide = styled.div`
  background-color: ${palette.primary.contrastText};
  height: 60px;
  width: 100%;
  margin-left: 20px;
  position: relative;
  ::before {
    content: '';
    position: absolute;
    width: 25px;
    height: 70px;
    background-color: white;
    transform: rotate(10deg);
    left: -5px;
    top: -5px;
  }

  @media (max-width: ${breakPoints.mb3}px) {
    height: 34px;
    ::before {
      height: 50px;
    }
  }
`;

export const Info = styled.div`
  padding: 0 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${breakPoints.m}px) {
    padding-inline: 50px;
  }
  @media (max-width: ${breakPoints.mb3}px) {
    padding-inline: 20px;
    p:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Title = styled.p`
  margin-top: 24px;
  font-size: 20px;
  color: #2a7de1;
  margin-bottom: -20px;
  font-weight: 900;
  font-family: inherit;

  @media (max-width: ${breakPoints.mb3}px) {
    font-size: 16px;
  }
`;

export const Subtitle = styled.p`
  margin-top: 20px;
  font-size: 18px;
  color: ${palette.text.primary};
  font-weight: 700;
  font-family: inherit;

  @media (max-width: ${breakPoints.mb3}px) {
    font-size: 14px;
    margin-bottom: 0px;
  }
`;

export const TableWrapper = styled.div`
  padding-inline: 100px;

  @media (max-width: ${breakPoints.m}px) {
    padding-inline: 50px;
  }
  @media (max-width: ${breakPoints.mb3}px) {
    padding-inline: 0;
  }
`;

export const InvoiceTable = styled.table`
  width: 100%;
  border-spacing: 0;
  padding: 0;
  margin: 0;
  margin-top: 40px;

  th {
    background-color: black;
    padding-block: 15px;
    font-size: 18px;
    color: white;
  }
  th:first-child {
    border-radius: 6px 0 0 0;
  }
  th:last-child {
    border-radius: 0 6px 0 0;
  }
  td {
    text-align: center;
    padding-block: 13px;
    font-size: 18px;
    border: 2.5px solid black;
  }
  td {
    border-top: none;
  }
  td:not(:first-child) {
    border-left: none;
  }

  tr:last-child {
    td:first-child {
      border-radius: 0 0 0 6px;
    }
    td:last-child {
      border-radius: 0 0 6px 0;
    }
  }
`;

export const ContactSection = styled.div`
  width: 100%;
  padding: 50px 100px;
  margin-top: 60px;
  background: #f2f4f7 0% 0% no-repeat padding-box;

  p,
  span {
    font-size: 27px;
    font-weight: 600;
    font-variant-caps: unicase;
    text-transform: uppercase;
    color: ${palette.text.primary};
    line-height: 34px;
  }
  span {
    color: #2a7de1;
    opacity: 1;
  }

  .text-ge {
    font-family: 'Mrgvlovani';
    font-size: 18px;
  }
  .text-en {
    font-family: 'Poppins';
  }
  .nino-bold-ge {
    font-family: 'Mrgvlovani';
  }
  .contact {
    margin-top: 27px;
  }

  @media (max-width: ${breakPoints.m}px) {
    padding-inline: 50px;
  }
  @media (max-width: ${breakPoints.mb3}px) {
    padding: 15px 20px;
    margin-top: 25px;
  }
`;

export const MobileTableContainer = styled.div`
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 4px;
  padding: 20px;
`;

export const FlexContainer = styled.div`
  display: flex;
`;

export const MobileTitle = styled.p`
  width: 100px;
  min-width: 100px;
  padding: 5px 8px;
  text-align: center;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
`;

export const MobileValue = styled.p`
  width: 100%;
  padding: 5px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid black;
  text-align: center;
  font-size: 14px;
`;

export const InvoiceDetails = styled.div`
  display: flex;
  padding-left: 100px;
  margin-top: 35px;

  @media (max-width: ${breakPoints.mb3}px) {
    padding-left: 20px;
    align-items: center;
    flex-direction: column;
  }

  .details-button {
    background-color: black;
    width: 350px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 400;
    font-size: 15px;
    border-radius: 2px;
    padding-top: 21px;
    font-family: 'Mrgvlovani';
    margin-right: 26px;
    text-transform: uppercase;
  }

  @media (max-width: ${breakPoints.mb3}px) {
    .details-button {
      margin-top: 10px;
      margin-bottom: 25px;
      width: 300px;
    }
  }
`;
