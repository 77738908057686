import React, { useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import useQueryString from 'src/hooks/useQueryString';
import { verifyEmail } from 'src/services/api';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'src/context/languageContext';

const VerifyEmail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  const { parseQuery } = useQueryString();
  const { email = '', token = '' } = parseQuery();

  const handleVerification = useCallback(async () => {
    try {
      const encodedToken = encodeURIComponent(token.toString());
      await verifyEmail({
        email: email.toString(),
        token: encodedToken
      });
      setSuccess(true);
    } catch (err) {
      // console.log(err);
    }
    setLoading(false);
  }, [email, token]);

  useEffect(() => {
    handleVerification();
  }, [handleVerification]);

  return (
    <Grid container justifyContent="center" textAlign="center" marginTop={3}>
      <Grid item xs={12} marginTop={4} textAlign="center">
        {loading && (
          <Stack color="primary.contrastText" spacing={2}>
            <LinearProgress color="primary" />
            <LinearProgress color="inherit" />
            <LinearProgress color="primary" />
          </Stack>
        )}
        {success && (
          <Typography variant="subtitle3">{t('messages.verificationSuccess')}</Typography>
        )}
      </Grid>
      <Grid item xs={12} marginTop={6}>
        <Button variant="containedPrimary" fullWidth onClick={() => navigate('/login')}>
          {t('button.backToLogin')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default VerifyEmail;
