import { useQuery } from '@tanstack/react-query';
import { getMonth } from 'src/services/api';

const useMonth = (chapter_id: string, status: string) => {
  const { data, isLoading, isError } = useQuery(['get-month'], () => getMonth(chapter_id, status), {
    enabled: Boolean(chapter_id)
  });
  return {
    data,
    isLoading,
    isError
  };
};

export default useMonth;
