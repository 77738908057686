import React from 'react';
import Navigate from 'src/components/common/navigate';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/system/Box';
import SpecCard from 'src/components/common/SpecCard';
import useDetectDevice from 'src/hooks/useDetectDevice';
import { useTranslation } from 'react-i18next';
import { CardHeader, CardImage } from '../courseDetails/styles';
import { getDateDifference } from 'src/utils';
import { useNavigate } from 'src/context/languageContext';
import useProfile from 'src/hooks/queries/useProfile';
import useUserCourse from 'src/hooks/queries/useUserCourse';

const ChoosedSpecialization = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { myCourses = [] } = useProfile();
  const { isMobile } = useDetectDevice();
  const { data: course } = useUserCourse();
  const subGroupId = myCourses[0]?.subGroupId;
  const { subCourses = [], groups = [] } = course || {};
  const { startDate: courseStart = '' } = groups[0] || {};

  const date = new Date(courseStart);
  date.setMonth(date.getMonth() + 4);

  const daysBeforeStart = getDateDifference(date, new Date()) + 5;

  if (!subGroupId) {
    return <Navigate to="/my-course/specific/choose-chapter" />;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <CardHeader sx={{ justifyContent: 'flex-start' }}>
          <Typography
            textAlign="start"
            component="div"
            variant="subtitle4"
            color="white"
            textTransform="uppercase"
            fontWeight={700}>
            {t('course.choosedSpec')}
          </Typography>
          <CardImage sx={{ transform: 'scale(4)' }} />
        </CardHeader>

        <Box border={{ mb2: '1px solid #DEDEDE' }} borderRadius={'0px 0px 8px 8px'}>
          <Grid container justifyContent="center" paddingTop={{ xs: 4, l: 6 }}>
            <Grid item xs={12} paddingX={{ xs: 3, mb2: 17 }} paddingBottom={{ xs: 5, l: 11 }}>
              <Grid container justifyContent="center" columnSpacing={3} rowSpacing={2}>
                <Grid item xs={12} paddingBottom={{ xs: 4, l: 5 }}>
                  <Typography fontWeight={400} fontSize={{ xs: 14, mb2: 18 }} textAlign={'center'}>
                    {t('course.chooseText')}
                  </Typography>
                </Grid>

                {subCourses.map((spec) => (
                  <Grid
                    item
                    key={spec.id}
                    width={{ xs: 160, mb2: 276 }}
                    height={{ xs: 270, mb2: 333 }}>
                    <SpecCard
                      spec={spec}
                      disabled={spec.subGroups[0]?.id !== subGroupId}
                      isSelected={spec.subGroups[0]?.id === subGroupId}
                    />
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={12} paddingTop={{ xs: 2, mb2: 8, l: 13 }}>
                <Grid container justifyContent="space-between" alignItems="center">
                  {isMobile ? (
                    <Typography
                      paddingX={3}
                      textAlign="center"
                      paddingBottom={{ xs: 3, mb2: 0 }}
                      variant="subtitle2">
                      {`სპეციალიზაციის არჩევამდე დარჩენილია: ${daysBeforeStart} დღე`}
                    </Typography>
                  ) : (
                    <Grid item>
                      <Stack direction="row" spacing={2}>
                        <Typography>{t('course.daysBeforeChange')}</Typography>
                        <Typography color="primary">
                          {isNaN(daysBeforeStart)
                            ? t('course.days', { count: 0 })
                            : t('course.days', { count: daysBeforeStart })}
                        </Typography>
                      </Stack>
                    </Grid>
                  )}
                  <Grid
                    item
                    minWidth={{ xs: 220, l: 408 }}
                    width={{ xs: '100%', mb2: 'auto' }}
                    onClick={() => navigate('/my-course/specific/edit-chapter')}>
                    <Button fullWidth variant="containedPrimary">
                      {t('common.edit')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ChoosedSpecialization;
