import Grid from '@mui/material/Grid';
import React, { FC } from 'react';
import { Button, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Subject } from 'src/models/general';
import useUpdateSubject from 'src/hooks/mutations/useUpdateSubject';
import useLanguageCode from 'src/hooks/useLanguageCode';

interface EditSubjectProps {
  lecture: Subject;
  close: () => void;
}

const EditCourseSubject: FC<EditSubjectProps> = ({ lecture, close }) => {
  const { t } = useTranslation();
  const { mutate: updateTitle } = useUpdateSubject();
  const { lang } = useLanguageCode();

  const formik = useFormik({
    initialValues: {
      title: lecture.title,
      position: lecture.position
    },
    onSubmit: (data) => {
      updateTitle(
        {
          subjectId: lecture.id,
          payload: {
            [lang]: {
              title: data.title
            },
            position: data.position
          }
        },
        {
          onSuccess: () => {
            close();
          }
        }
      );
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container mt={1} columnSpacing={2} rowGap={2} pb={3} px={4}>
        <Grid item xs={12}>
          <TextField
            name="title"
            type="text"
            variant="outlined"
            label="Lecture title"
            value={formik.values.title}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="position"
            type="number"
            variant="outlined"
            label="Lecture Position"
            onChange={formik.handleChange}
            value={formik.values.position}
          />
        </Grid>
        <Grid
          item
          xs={12}
          marginTop={2}
          textAlign="center"
          justifyContent="flex-end"
          display="flex">
          <Button variant="primary" type="submit">
            <Typography textTransform="uppercase">{t('button.submit')}</Typography>
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditCourseSubject;
