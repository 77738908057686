import { useQueryClient } from '@tanstack/react-query';
import React, {
  createContext,
  Dispatch,
  FC,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
  useEffect
} from 'react';
import { SKILLWILL_TARGET_ORIGIN } from 'src/constants';
import { LOCALSTORAGE_KEYS, QUERY_KEYS, USER_ROLES } from 'src/enums';

export interface AuthContextInterface {
  token: string;
  setToken: Dispatch<SetStateAction<string>>;
  role: USER_ROLES | null;
  setRole: Dispatch<SetStateAction<USER_ROLES | null>>;
  lmsIframe: React.RefObject<HTMLIFrameElement>;
  isLoggedIn: boolean;
  isAdmin: boolean;
  handleLogout: () => void;
  setLocalTokens: (accessToken: string, refreshToken: string) => void;
  refreshToken: (accessToken: string, refreshToken: string) => void;
}

export const AuthContext = createContext<AuthContextInterface | null>(null);

interface Props {
  children: ReactNode;
}

declare global {
  interface Window {
    FB: { CustomerChat: { hide: () => void } };
  }
}

const AuthContextProvider: FC<Props> = ({ children }): ReactElement => {
  const [token, setToken] = useState(localStorage.getItem(LOCALSTORAGE_KEYS.TOKEN) || '');
  const [role, setRole] = useState<USER_ROLES | null>(null);

  const lmsIframe = useRef<HTMLIFrameElement>(null);
  const isAdmin = role === 'Admin';
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!token) {
      lmsIframe?.current?.contentWindow?.postMessage({ action: 'logout' }, SKILLWILL_TARGET_ORIGIN);
    }
  }, [token]);

  const handleLogout = useCallback(() => {
    localStorage.removeItem(LOCALSTORAGE_KEYS.TOKEN);
    localStorage.removeItem(LOCALSTORAGE_KEYS.REFRESH_TOKEN);
    localStorage.removeItem(LOCALSTORAGE_KEYS.REMEMBER_ME);

    lmsIframe?.current?.contentWindow?.postMessage({ action: 'logout' }, SKILLWILL_TARGET_ORIGIN);

    setToken('');
    setRole(null);
    queryClient.removeQueries({ queryKey: [QUERY_KEYS.PROFILE], exact: true });
  }, [queryClient]);

  const setLocalTokens = useCallback((accessToken: string, refreshToken: string) => {
    localStorage.setItem(LOCALSTORAGE_KEYS.TOKEN, accessToken);
    localStorage.setItem(LOCALSTORAGE_KEYS.REFRESH_TOKEN, refreshToken);
    setToken(accessToken);
  }, []);

  const refreshToken = useCallback((accessToken: string, refreshToken: string) => {
    localStorage.setItem(LOCALSTORAGE_KEYS.TOKEN, accessToken);
    localStorage.setItem(LOCALSTORAGE_KEYS.REFRESH_TOKEN, refreshToken);
    const facebookPlugin = window.FB;
    facebookPlugin && facebookPlugin?.CustomerChat?.hide();
    setToken(accessToken);
  }, []);

  const contextValues = useMemo(
    () => ({
      role,
      token,
      setRole,
      lmsIframe,
      isAdmin,
      setToken,
      handleLogout,
      refreshToken,
      setLocalTokens,
      isLoggedIn: !!token
    }),
    [token, role, setToken, setLocalTokens, handleLogout, refreshToken, isAdmin, lmsIframe]
  );

  return <AuthContext.Provider value={contextValues}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;

export const useAuth = () => {
  const value = useContext(AuthContext);

  if (!value) {
    throw new Error('Auth Context Provider is not defined');
  }
  return value;
};
