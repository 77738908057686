import { mainTheme } from './theme';
import React, { FC, useMemo } from 'react';
import { CssBaseline } from '@mui/material';
import { LOCALES, LOCALSTORAGE_KEYS } from 'src/enums';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';

const ThemeWrapper: FC = ({ children }) => {
  const locale = localStorage.getItem(LOCALSTORAGE_KEYS.LOCALE);

  const theme = useMemo(() => {
    const fontFamily = locale === LOCALES.GE ? ['Helvetica', 'Roboto'] : ['FiraGo', 'Roboto'];
    const fontWeightBold = locale === LOCALES.GE ? 900 : 700;
    return createTheme({
      ...mainTheme,
      typography: {
        fontFamily: fontFamily.join(','),
        fontWeightBold
      }
    });
  }, [locale]);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};

export default ThemeWrapper;
