import { Stack } from '@mui/material';
import useToggle from 'src/hooks/useToggle';
import React, { FC, ReactNode } from 'react';
import { MdChevronRight } from 'react-icons/md';
import { useAuth } from 'src/context/authContext';
import { Container, Head, Title } from './styles';
import useDetectDevice from 'src/hooks/useDetectDevice';
import { useLanguage } from 'src/context/languageContext';
import { CardImage } from 'src/views/courseDetails/styles';
import { ReactComponent as EditIcon } from 'src/assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from 'src/assets/icons/delete.svg';

interface Props {
  title: string;
  children: ReactNode;
  renderChildren?: boolean;
  initialOpenState?: boolean;
  additionalElement?: ReactNode;
  onEditWeek: (e: React.MouseEvent) => void;
  onRemoveWeek: (e: React.MouseEvent) => void;
}

const DropDownContainer: FC<Props> = ({
  title,
  children,
  onEditWeek,
  onRemoveWeek,
  initialOpenState,
  additionalElement,
  renderChildren = true
}) => {
  const { isAdmin } = useAuth();
  const { locale } = useLanguage();
  const { isSmall, isMobile } = useDetectDevice();
  const [isOpen, setIsOpen] = useToggle(initialOpenState);

  return (
    <Container
      style={{
        maxHeight: !isOpen ? (!isSmall ? '67px' : '56px') : 'unset'
      }}>
      <Head isOpen={isOpen} onClick={setIsOpen}>
        <Title style={{ fontWeight: locale === 'en' ? '500' : '900' }}>{title}</Title>
        <Stack direction="row" zIndex={100} alignItems="center" gap={0.5}>
          {isAdmin && (
            <>
              <EditIcon onClick={onEditWeek} />
              <DeleteIcon onClick={onRemoveWeek} />
            </>
          )}
          {additionalElement}
          <MdChevronRight
            style={{
              rotate: '90deg',
              fontSize: '24px',
              color: 'black',
              transition: 'all .2s',
              fill: isMobile ? ' #fff' : '#000',
              transform: isOpen ? 'rotate(-180deg)' : 'rotate(0)'
            }}
          />
        </Stack>
        <CardImage />
      </Head>
      {renderChildren && children}
    </Container>
  );
};

export default DropDownContainer;
