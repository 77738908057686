import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { QUERY_KEYS } from 'src/enums';
import { deleteSubject } from 'src/services/api';
import useLanguageCode from '../useLanguageCode';

const useDeleteSubject = () => {
  const client = useQueryClient();
  const { lang } = useLanguageCode();
  const { mutate, isLoading } = useMutation<
    { success?: boolean },
    AxiosError,
    { subjectId: number }
  >(['delete-subject'], ({ subjectId }) => deleteSubject(subjectId, lang), {
    onSettled: () => {
      client.invalidateQueries([QUERY_KEYS.SYLLABUS]);
    }
  });

  return {
    mutate,
    isLoading
  };
};

export default useDeleteSubject;
