import React, { FC, useState } from 'react';
import { useFormik } from 'formik';
import { TIME_OPTIONS } from 'src/constants';
import { useTranslation } from 'react-i18next';
import { TimeOptions } from '../RegisterEvent';
import { useAuth } from 'src/context/authContext';
import { publishContentSchema } from 'src/helpers/validations';
import useExtendedCourses from 'src/hooks/queries/useExtendedCourses';
import usePublishContent from 'src/hooks/mutations/usePublishContent';
import useEditCourseContent from 'src/hooks/mutations/useEditCourseContent';
import {
  Grid,
  Typography,
  TextField,
  MenuItem,
  FormControl,
  Autocomplete,
  Button,
  InputLabel,
  Select
} from '@mui/material';

export interface IContentInfo {
  content: string;
  description: string;
  id: number;
  publish_date: string;
  published: boolean;
  title: string;
  content_courses: { id: number; course_id: number; send_date: string }[];
}

interface Props {
  close: () => void;
  editableId: number;
  contentInfo: IContentInfo;
}

const PublishContentForm: FC<Props> = ({ close, contentInfo, editableId }) => {
  const { isAdmin } = useAuth();
  const { t } = useTranslation();
  const { courses = [] } = useExtendedCourses(isAdmin);
  const [timeOpts, setTimeOpts] = useState(TIME_OPTIONS);
  const isEditing = typeof editableId === 'number';
  const { id, title, content_courses } = contentInfo;

  const { mutate: editCourseContent } = useEditCourseContent();
  const { mutate: publishContent, isLoading } = usePublishContent();

  const formik = useFormik<any>({
    initialValues: !isEditing
      ? {
          startTime: '',
          recipients: [],
          publishDate: ''
        }
      : {
          startTime: content_courses[editableId]?.send_date?.split(' ')[1],
          publishDate: content_courses[editableId]?.send_date?.split(' ')[0],
          recipients: contentInfo.content_courses?.map((course) => course.course_id)
        },
    onSubmit: async ({ publishDate, recipients, startTime }) => {
      if (isEditing) {
        editCourseContent(
          {
            id: contentInfo.content_courses[editableId].course_id,
            payload: {
              content_id: id,
              course_ids: recipients,
              send_date: `${publishDate} ${startTime.value}:00`
            }
          },
          { onSuccess: () => close() }
        );
      } else {
        publishContent(
          {
            id,
            payload: {
              course_ids: recipients,
              publish_date: `${publishDate} ${startTime.value}:00`
            }
          },
          { onSuccess: () => close() }
        );
      }
    },
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: publishContentSchema(t)
  });

  const eventTimeChangeHandler = (params: any) => {
    if (timeOpts.find((opt: TimeOptions) => opt.value === params.inputProps.value)) return;

    setTimeOpts((prevOpts: TimeOptions[]) => {
      return [...prevOpts, { label: params.inputProps.value, value: params.inputProps.value }];
    });

    formik.setFieldValue('startTime', {
      label: params.inputProps.value as string,
      value: params.inputProps.value as string
    });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container justifyContent="center" rowSpacing={2} px={8} py={6}>
        <Grid item xs={12} textAlign="center" sx={{ fontFeatureSettings: "'case' on" }}>
          <Typography fontSize={{ sm: 24, xs: 18 }} textTransform="uppercase" color="green">
            {!isEditing ? t('careerCare.publishContent') : 'Edit Course Info'}
          </Typography>
        </Grid>
        {!isEditing && (
          <Grid item xs={12} textAlign="center">
            <Typography fontSize={18} fontWeight={100} mt={3}>
              <strong>{t('careerCare.publish', { title })}</strong>
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} mt={4}>
          <TextField
            type="date"
            name="publishDate"
            variant="outlined"
            label={t('labels.publishDate')}
            onChange={formik.handleChange}
            value={formik.values.publishDate}
            InputLabelProps={{ shrink: true }}
            helperText={formik.errors.publishDate}
            error={Boolean(formik.errors.publishDate)}
            FormHelperTextProps={{
              error: Boolean(formik.errors.publishDate)
            }}
          />
        </Grid>
        <Grid item xs={12} mt={1}>
          <Autocomplete
            id="startTime"
            autoHighlight
            fullWidth
            options={timeOpts}
            value={formik.values.startTime}
            noOptionsText={null}
            onChange={(_, time) => formik.setFieldValue('startTime', time)}
            renderInput={(params) => (
              <TextField
                {...params}
                name="startTime"
                label={t('labels.startTime')}
                error={Boolean(formik.errors.startTime)}
                helperText={
                  Boolean(formik.errors.startTime) &&
                  t('validations.required', { field: t('labels.startTime') })
                }
                FormHelperTextProps={{
                  error: Boolean(formik.errors.startTime),
                  style: { whiteSpace: 'normal' }
                }}
                onBlur={() => eventTimeChangeHandler(params)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} mt={1}>
          <FormControl>
            <InputLabel id="recipients">{t('labels.recipients')}</InputLabel>
            <Select
              multiple
              name="recipients"
              aria-multiselectable
              label={t('labels.recipients')}
              onChange={formik.handleChange}
              value={formik.values.recipients}
              MenuProps={{ sx: { maxHeight: 230 } }}>
              {courses.map((course) => (
                <MenuItem key={course.id} value={course.id}>
                  {course.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} mt={6}>
          <Grid container justifyContent="center" columnSpacing={2}>
            <Grid item>
              <Button sx={{ paddingX: 4 }} variant="primary" type="submit" disabled={isLoading}>
                <Typography>{t('button.publish')}</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button variant="secondary" onClick={close}>
                <Typography>{t('modal.cancel')}</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default PublishContentForm;
