import React, { FC, useEffect, useState } from 'react';
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import { useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { TIME_OPTIONS } from 'src/constants/index';
import { useAuth } from 'src/context/authContext';
import { useCalendar } from 'src/context/calendarContext';
import { COURSE_STATUS, QUERY_KEYS } from 'src/enums';
import useProfile from 'src/hooks/queries/useProfile';
import useSubCourses from 'src/hooks/queries/useSubCourses';
import useSubjects from 'src/hooks/queries/useSubjects';
import useLanguageCode from 'src/hooks/useLanguageCode';
import { SpecificCourse } from 'src/models/general';
import palette from 'src/theme/overrides/palette';
import { addEvent, updateEvent } from 'src/services/api';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CourseResponse, ErrorMessages } from 'src/models/service';
import { Actions, Container, StyledForm, StyledRow } from './styles';
import useExtendedCourses from 'src/hooks/queries/useExtendedCourses';
import { FormHelperText, InputLabel, Select, Typography } from '@mui/material';
import { addEventsExtraSchema, addEventsSchema } from 'src/helpers/validations';
import useCategories from 'src/hooks/queries/useCategories';

const getCourseId = (
  courses: CourseResponse[],
  subCourses: SpecificCourse[],
  groupId: number
): number => {
  for (const course of courses) {
    for (const group of course.groups) {
      if (group.id === groupId) {
        return course.id;
      }
    }
  }
  for (const subCourse of subCourses) {
    for (const subGroup of subCourse.subGroups) {
      if (subGroup.id === groupId) {
        return subCourse.id;
      }
    }
  }
  return 0;
};

interface TimeOptions {
  value: string;
  label: string;
}
interface AddEventFormProps {
  close: () => void;
}

const AddEventForm = ({ close }: AddEventFormProps) => {
  const { isAdmin } = useAuth();
  const { t } = useTranslation();
  const client = useQueryClient();
  const { lang } = useLanguageCode();
  const { eventToEdit, setEventToEdit } = useCalendar();
  const { categories } = useCategories();

  const { data: user } = useProfile();
  const [timeOpts, setTimeOpts] = useState(TIME_OPTIONS);
  const [errors, setErrors] = useState<ErrorMessages | null>(null);
  const [eventTimeError, setEventTimeError] = useState<string | null>(null);

  const {
    description = '',
    translations = {},
    calendarGroup = {},
    end: editEventEnd = '',
    date: editEventDate = '',
    start: editEventStart = '',
    estimate_time = ''
  } = eventToEdit || {};

  const { subCourses = [] } = useSubCourses(isAdmin);
  const { courses = [] } = useExtendedCourses(isAdmin);

  const groupId = calendarGroup.group_id;
  const subject = calendarGroup.subject_id;
  const subGroupId = calendarGroup.subgroup_id;
  const eventGroupId = groupId || subGroupId;

  const validationSchema = isAdmin
    ? addEventsSchema(t).concat(addEventsExtraSchema(t))
    : addEventsSchema(t);

  const formik = useFormik<any>({
    initialValues: {
      eventNameEn: translations[0]?.summary,
      eventNameKa: translations[1]?.summary,
      eventLink: description || '',
      eventDate: editEventDate.split(' ')[0] || '',
      estimate_time: estimate_time,
      startTime: editEventStart
        ? {
            value: editEventStart.split(' ')[1].slice(0, 5),
            label: editEventStart.split(' ')[1].slice(0, 5)
          }
        : null,
      endTime: editEventEnd
        ? {
            value: editEventEnd.split(' ')[1].slice(0, 5),
            label: editEventEnd.split(' ')[1].slice(0, 5)
          }
        : null,
      subject,
      eventCommentKa: translations[0]?.comment || '',
      eventCommentEn: translations[1]?.comment || '',
      group: groupId || subGroupId || '',
      eventType: groupId ? 'courses' : subGroupId ? 'subCourses' : 'courses',
      course: eventGroupId ? getCourseId(courses, subCourses, parseInt(eventGroupId, 10)) : 0,
      category: eventToEdit?.evaluation_criteria?.map((crt: any) => crt.id) || []
    },
    validationSchema,
    onSubmit: async (data) => {
      if (eventTimeError) return;

      try {
        setErrors(null);
        if (eventToEdit) {
          await updateEvent(
            eventToEdit.id,
            {
              group_id: isAdmin ? data?.group : undefined,
              user_id: !isAdmin ? user?.id : undefined,
              end_date_time: `${data.eventDate} ${data.endTime?.value}:00`,
              start_date_time: `${data.eventDate} ${data.startTime?.value}:00`,
              subject_id: isAdmin ? data?.subject || undefined : undefined,
              estimate_time: data?.estimation || undefined,
              en: {
                summary: data.eventNameEn,
                comment: data.eventCommentEn || undefined,
                description: data.eventLink || undefined
              },
              ka: {
                summary: data.eventNameKa,
                comment: data.eventCommentKa || undefined,
                description: data.eventLink || undefined
              },
              evaluation_criteria: data.category
            },
            lang
          );
          client.invalidateQueries([QUERY_KEYS.EVENTS]);
        } else {
          await addEvent(
            {
              group_id: isAdmin ? data?.group : undefined,
              user_id: !isAdmin ? user?.id : undefined,
              end_date_time: `${data.eventDate} ${data.endTime?.value}:00`,
              start_date_time: `${data.eventDate} ${data.startTime?.value}:00`,
              subject_id: isAdmin ? data?.subject || undefined : undefined,
              estimate_time: data?.estimation,
              en: {
                summary: data.eventNameEn,
                comment: data.eventCommentEn || undefined,
                description: data.eventLink || undefined
              },
              ka: {
                summary: data.eventNameKa,
                comment: data.eventCommentKa || undefined,
                description: data.eventLink || undefined
              },
              evaluation_criteria: data.category
            },
            lang
          );
          client.invalidateQueries([QUERY_KEYS.EVENTS]);
        }
        close();
      } catch (err) {
        if (axios.isAxiosError(err)) {
          err.response?.data?.errors && setErrors(err.response?.data?.errors);
        }
      }
    },
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true
  });

  const end = formik.values?.endTime?.value;
  const start = formik.values?.startTime?.value;

  const { subjects = [] } = useSubjects({
    status: COURSE_STATUS.GENERAL,
    chapter_id: formik.values.course
  });

  useEffect(() => {
    if (start && end) {
      if (start >= end) {
        setEventTimeError(t('validations.endTime'));
      } else {
        setEventTimeError(null);
      }
    }
  }, [start, end, t]);

  const chosenCourse = courses.find((course) => course.id === formik.values.course);
  const chosenSubCourse = subCourses.find((sub) => sub.id === formik.values.course);

  const eventTimeChangeHandler = (type: 'startTime' | 'endTime', params: any) => {
    if (timeOpts.find((opt: TimeOptions) => opt.value === params.inputProps.value)) return;

    setTimeOpts((prevOpts: TimeOptions[]) => {
      return [...prevOpts, { label: params.inputProps.value, value: params.inputProps.value }];
    });
    formik.setFieldValue(type, {
      label: params.inputProps.value as string,
      value: params.inputProps.value as string
    });
  };

  useEffect(() => {
    return () => {
      setEventToEdit(undefined);
    };
  }, [setEventToEdit]);

  return (
    <Container>
      <Typography
        variant="subtitle1"
        color={'#00CE7C'}
        textTransform={'uppercase'}
        sx={{ fontFeatureSettings: "'case' on" }}
        marginBottom={{ xs: 2, mb2: 4 }}>
        {eventToEdit ? t('calendar.editEvent') : t('calendar.addEvent')}
      </Typography>
      <StyledForm onSubmit={formik.handleSubmit}>
        <StyledRow>
          <TextField
            name="eventNameKa"
            variant="outlined"
            label={`${t('labels.eventName')} (GE)`}
            onChange={formik.handleChange}
            value={formik.values.eventNameKa || ''}
            helperText={formik.errors.eventNameKa}
            error={Boolean(formik.errors.eventNameKa)}
            FormHelperTextProps={{
              error: Boolean(formik.errors.eventNameKa || errors?.eventNameKa),
              style: {
                whiteSpace: 'normal'
              }
            }}
          />

          <TextField
            name="eventNameEn"
            variant="outlined"
            label={`${t('labels.eventName')} (EN)`}
            onChange={formik.handleChange}
            value={formik.values.eventNameEn || ''}
            helperText={formik.errors.eventNameEn}
            error={Boolean(formik.errors.eventNameEn)}
            FormHelperTextProps={{
              error: Boolean(formik.errors.eventNameEn || errors?.eventNameEn),
              style: {
                whiteSpace: 'normal'
              }
            }}
          />
        </StyledRow>

        <TextField
          type="date"
          name="eventDate"
          variant="outlined"
          label={t('labels.eventDate')}
          onChange={formik.handleChange}
          value={formik.values.eventDate}
          InputLabelProps={{ shrink: true }}
          helperText={formik.errors.eventDate}
          error={Boolean(formik.errors.eventDate)}
          FormHelperTextProps={{
            error: Boolean(formik.errors.eventDate || errors?.eventDate)
          }}
        />

        <StyledRow>
          <Autocomplete
            id="startTime"
            autoHighlight
            fullWidth
            options={timeOpts}
            value={formik.values.startTime}
            noOptionsText={null}
            onChange={(_, time) => formik.setFieldValue('startTime', time)}
            renderInput={(params) => (
              <TextField
                {...params}
                name="startTime"
                label={t('labels.startTime')}
                error={Boolean(formik.errors.startTime)}
                helperText={
                  Boolean(formik.errors.endTime) &&
                  t('validations.required', { field: t('labels.startTime') })
                }
                FormHelperTextProps={{
                  error: Boolean(formik.errors.startTime),
                  style: {
                    whiteSpace: 'normal'
                  }
                }}
                onBlur={() => eventTimeChangeHandler('startTime', params)}
              />
            )}
          />
          <Autocomplete
            id="endTime"
            autoHighlight
            fullWidth
            options={TIME_OPTIONS}
            value={formik.values.endTime}
            noOptionsText={null}
            onChange={(_, time) => formik.setFieldValue('endTime', time)}
            renderInput={(params) => (
              <TextField
                {...params}
                name="endTime"
                label={t('labels.endTime')}
                error={Boolean(formik.errors.endTime)}
                helperText={
                  (Boolean(formik.errors.endTime) &&
                    t('validations.required', { field: t('labels.endTime') })) ||
                  eventTimeError
                }
                FormHelperTextProps={{
                  error: Boolean(formik.errors.endTime || eventTimeError),
                  style: {
                    whiteSpace: 'normal'
                  }
                }}
                onBlur={() => eventTimeChangeHandler('endTime', params)}
              />
            )}
          />
        </StyledRow>

        <StyledRow>
          <TextField
            name="estimation"
            variant="outlined"
            label={t('labels.estimation')}
            onChange={formik.handleChange}
            value={formik.values.estimation}
            defaultValue={formik.values.estimation}
            helperText={formik.touched.estimation && formik.errors.estimation}
            error={formik.touched.estimation && Boolean(formik.errors.estimation)}
            FormHelperTextProps={{
              error: formik.touched.estimation && Boolean(formik.errors.estimation)
            }}
          />
        </StyledRow>

        <StyledRow>
          <TextField
            variant="outlined"
            name="eventCommentKa"
            label={`${t('labels.eventComment')} (GE)`}
            onChange={formik.handleChange}
            value={formik.values.eventCommentKa}
            helperText={formik.errors.eventCommentKa}
            error={Boolean(formik.errors.eventCommentKa)}
            FormHelperTextProps={{
              error: Boolean(formik.errors.eventCommentKa)
            }}
          />

          <TextField
            variant="outlined"
            name="eventCommentEn"
            label={`${t('labels.eventComment')} (EN)`}
            onChange={formik.handleChange}
            value={formik.values.eventCommentEn}
            helperText={formik.errors.eventCommentEn}
            error={Boolean(formik.errors.eventCommentEn)}
            FormHelperTextProps={{
              error: Boolean(formik.errors.eventCommentEn)
            }}
          />
        </StyledRow>

        <TextField
          name="eventLink"
          variant="outlined"
          label={t('labels.eventLink')}
          onChange={formik.handleChange}
          value={formik.values.eventLink}
          helperText={formik.errors.eventLink}
          error={Boolean(formik.errors.eventLink)}
          FormHelperTextProps={{
            error: Boolean(formik.errors.eventLink || errors?.eventLink)
          }}
        />

        <FormControl>
          <InputLabel>{t('labels.category')}</InputLabel>
          <Select
            multiple
            name="category"
            value={formik.values.category}
            variant="outlined"
            label={t('labels.category')}
            onChange={formik.handleChange}>
            {categories?.map((el: any) => {
              return (
                <MenuItem key={el} value={el.id}>
                  {el.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {isAdmin && (
          <>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              value={formik.values.eventType}
              name="eventType"
              onChange={(e) => {
                formik.handleChange(e);
                formik.setFieldValue('course', 0);
                formik.setFieldValue('group', '');
              }}>
              <FormControlLabel value="courses" control={<Radio />} label={t('labels.course')} />
              <FormControlLabel
                value="subCourses"
                control={<Radio />}
                label={t('labels.specialization')}
              />
            </RadioGroup>

            {formik.values.eventType === 'courses' ? (
              <>
                <FormControl>
                  <TextField
                    select
                    name="course"
                    onChange={formik.handleChange}
                    label={t('labels.chooseCourse')}
                    value={formik.values.course || ''}
                    helperText={
                      formik.errors.group &&
                      t('validations.required', {
                        field: t('labels.course')
                      })
                    }
                    FormHelperTextProps={{
                      error: Boolean(formik.errors.group || errors?.group)
                    }}
                    error={Boolean(formik.errors.group)}>
                    {courses.map((course) => {
                      return (
                        <MenuItem key={course.id} value={course.id}>
                          {course.name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </FormControl>

                <FormControl>
                  <TextField
                    select
                    name="group"
                    value={formik.values.group}
                    onChange={formik.handleChange}
                    label={t('labels.chooseGroup')}
                    helperText={formik.errors.group}
                    FormHelperTextProps={{
                      error: Boolean(formik.errors.group || errors?.group)
                    }}
                    error={Boolean(formik.errors.group)}>
                    {chosenCourse?.groups.map((group) => {
                      return (
                        <MenuItem key={group.id} value={group.id}>
                          {group.name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </FormControl>

                <FormControl>
                  <InputLabel
                    id="subject"
                    sx={{ color: formik.errors.subject && palette.error.main }}>
                    {t('labels.chooseSubject')}
                  </InputLabel>
                  <Select
                    name="subject"
                    label={t('labels.chooseSubject')}
                    value={formik.values.subject}
                    onChange={(e) => {
                      const selected = subjects?.find((subj: any) => subj.id == e.target.value);
                      const ge = selected.translation.find((tr: any) => tr.locale === 'ka').title;
                      const en = selected.translation.find((tr: any) => tr.locale === 'en').title;

                      formik.handleChange(e);
                      formik.setFieldValue('eventNameKa', ge);
                      formik.setFieldValue('eventNameEn', en);
                    }}
                    inputProps={{
                      sx: {
                        textAlign: 'left',
                        border: '1px solid inherit'
                      }
                    }}
                    MenuProps={{
                      sx: {
                        maxWidth: 200,
                        maxHeight: 200
                      }
                    }}
                    // error={Boolean(formik.errors.subject)}
                  >
                    {subjects?.map((subject: any) => {
                      const info = subject.translation.find((tr: any) => tr.locale === lang);

                      return (
                        <MenuItem key={info.subject_id} value={info.subject_id}>
                          {info.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText sx={{ color: palette.error.main }}>
                    {formik.errors.subject}
                  </FormHelperText>
                </FormControl>
              </>
            ) : (
              <>
                <FormControl>
                  <TextField
                    select
                    name="course"
                    onChange={formik.handleChange}
                    label={t('labels.chooseCourse')}
                    value={formik.values.course || ''}
                    helperText={
                      formik.errors.group &&
                      t('validations.required', {
                        field: t('labels.course')
                      })
                    }
                    FormHelperTextProps={{
                      error: Boolean(formik.errors.group || errors?.group)
                    }}
                    error={Boolean(formik.errors.group)}>
                    {subCourses.map((sub) => {
                      return (
                        <MenuItem key={sub.id} value={sub.id}>
                          {sub.name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </FormControl>

                <FormControl>
                  <TextField
                    select
                    name="group"
                    label={t('labels.chooseGroup')}
                    value={formik.values.group}
                    onChange={formik.handleChange}
                    helperText={formik.errors.group}
                    FormHelperTextProps={{
                      error: Boolean(formik.errors.group || errors?.group)
                    }}
                    error={Boolean(formik.errors.group)}>
                    {chosenSubCourse?.subGroups.map((subGroup) => {
                      return (
                        <MenuItem key={subGroup.id} value={subGroup.id}>
                          {subGroup.name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </FormControl>

                <FormControl>
                  <InputLabel
                    id="subject"
                    sx={{ color: formik.errors.subject && palette.error.main }}>
                    {t('labels.chooseSubject')}
                  </InputLabel>
                  <Select
                    name="subject"
                    label={t('labels.chooseSubject')}
                    value={formik.values.subject}
                    onChange={(e) => {
                      const selected = subjects?.find((subj: any) => subj.id == e.target.value);
                      const ge = selected.translation.find((tr: any) => tr.locale === 'ka').title;
                      const en = selected.translation.find((tr: any) => tr.locale === 'en').title;

                      formik.handleChange(e);
                      formik.setFieldValue('eventNameKa', ge);
                      formik.setFieldValue('eventNameEn', en);
                    }}
                    inputProps={{
                      sx: {
                        textAlign: 'left',
                        border: '1px solid inherit'
                      }
                    }}
                    MenuProps={{
                      sx: {
                        maxWidth: 200,
                        maxHeight: 200
                      }
                    }}
                    error={Boolean(formik.errors.subject)}>
                    {subjects?.map((subject: any) => {
                      const info = subject.translation.find((tr: any) => tr.locale === lang);

                      return (
                        <MenuItem key={info.subject_id} value={info.subject_id}>
                          {info.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText sx={{ color: palette.error.main }}>
                    {formik.errors.subject}
                  </FormHelperText>
                </FormControl>
              </>
            )}
          </>
        )}

        <Actions>
          <Button
            sx={{
              height: '56px',
              textTransform: 'uppercase',
              fontWeight: 'regular',
              fontFeatureSettings: "'case' on",
              '&:hover': {
                background: 'black',
                color: 'white'
              }
            }}
            fullWidth
            variant="primary"
            onClick={close}>
            {t('modal.cancel')}
          </Button>

          <Button
            sx={{
              height: '56px',
              textTransform: 'uppercase',
              fontWeight: 'regular',
              background: 'white',
              color: 'black',
              fontFeatureSettings: "'case' on",
              '&:hover': {
                background: 'black',
                color: 'white'
              }
            }}
            type="submit"
            disabled={formik.isSubmitting}
            variant="primary"
            fullWidth>
            {eventToEdit ? t('modal.edit') : t('modal.add')}
          </Button>
        </Actions>
      </StyledForm>
    </Container>
  );
};

export default AddEventForm;
