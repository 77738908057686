import styled from 'styled-components';
import { breakPoints } from 'src/theme/defaultTheme';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  padding: 56px 64px;

  @media (max-width: ${breakPoints.mb3}px) {
    flex-direction: column;
    padding: 32px 19px;
  }
`;

export const StyledForm = styled.form`
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: ${breakPoints.mb3}px) {
    width: auto;
    margin: auto;
    max-width: 400px;
    margin-bottom: -26px;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  gap: 24px;
`;

export const StyledAdornment = styled.span`
  width: 89px;
  text-align: center;

  @media (max-width: ${breakPoints.sm}px) {
    width: 60px;
    font-size: 14px;
  }
`;
