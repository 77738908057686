import React from 'react';
import Box, { BoxProps } from '@mui/system/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import useDetectDevice from 'src/hooks/useDetectDevice';
import { Typography } from '@mui/material';
import ExpandIcon from 'src/components/common/expandIcon';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  boxProps?: BoxProps;
  t?: any;
  isPartner?: boolean;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, boxProps, t, isPartner = false, ...other } = props;
  const { isSmall } = useDetectDevice();

  return (
    <React.Fragment>
      {isSmall && isPartner ? (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`course-tabpanel-${index}`}
          aria-labelledby={`course-tab-${index}`}
          {...other}>
          <Accordion
            sx={{
              boxShadow: 'none !important',
              justifyContent: 'center',
              marginBottom: '-50px !important'
            }}>
            <AccordionSummary
              expandIcon={<ExpandIcon />}
              sx={{
                backgroundColor: 'transparent',
                boxShadow: 'none !important',
                outline: 'none',
                height: '25px',
                padding: '0px !important',
                border: 'none !important',
                maxWidth: '400px',
                margin: 'auto'
              }}>
              <Typography
                fontFamily={'FiraGo'}
                sx={{
                  fontSize: '22px'
                }}>
                {t.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: '0'
              }}>
              <Typography
                fontFamily={'FiraGo'}
                sx={{
                  fontSize: '16px',
                  maxWidth: '400px'
                }}>
                {t.description}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      ) : (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`course-tabpanel-${index}`}
          aria-labelledby={`course-tab-${index}`}
          {...other}>
          {value === index && <Box {...boxProps}>{children}</Box>}
        </div>
      )}
    </React.Fragment>
  );
};

export default TabPanel;
