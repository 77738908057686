import styled from 'styled-components';
import { breakPoints } from 'src/theme/defaultTheme';
import { ReactComponent as SkillWillSvg } from 'src/assets/icons/skillwill-logo.svg';

export const Wrapper = styled.div`
  width: 230px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
  }

  @media (max-width: ${breakPoints.l}px) {
    width: 145px;
  }
`;

export const SkillWillLogo = styled(SkillWillSvg)<{ fill?: string }>`
  & path {
    fill: ${(props) => props.fill};
  }
  width: 140px;
`;
