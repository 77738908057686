import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CourseInfo } from 'src/models/service';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Dialog, Grid, Typography } from '@mui/material';
import { ReactComponent as DeleteIcon } from 'src/assets/icons/delete.svg';
import useCancelCourseContent from 'src/hooks/mutations/useCancelCourseContent';

interface IContent {
  id: number;
  course: number;
  send_date: string;
}
interface Props {
  open: boolean;
  title: string;
  close: () => void;
  content: IContent[];
  recipients: CourseInfo[];
  editRecipientHandler: (idx: number) => void;
}

export const CancelButton = styled(Button)`
  height: 52px;
  width: 200px;
  font-size: 16px;
  font-weight: 400;
`;

const RecipientsModal = ({
  open,
  close,
  title,
  content,
  recipients = [],
  editRecipientHandler
}: Props) => {
  const { t } = useTranslation();
  const { mutate } = useCancelCourseContent();

  const deleteRecipientHandler = (idx: number) => {
    mutate({ id: content[idx].id }, { onSuccess: () => close() });
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      PaperProps={{
        style: { borderRadius: '16px' }
      }}>
      <Grid container sx={{ width: { sm: 448, l: 546 }, paddingX: 8, paddingY: 6 }}>
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center">
          <Typography fontSize={{ sm: 24, xs: 18 }} fontWeight="bold" textTransform="uppercase">
            {t('careerCare.recipientsFor')}:
          </Typography>
          <Typography fontSize={18} fontWeight="bold" mt={3}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12} display="flex" flexDirection="column" gap={1} mt={5}>
          {recipients.map((recipient, idx) => {
            return (
              <Grid key={recipient.id} container justifyContent="space-between">
                <Typography fontSize={16} fontWeight="normal">
                  {idx + 1}. {recipient.name} - {content[idx]?.send_date}
                </Typography>
                <Grid display="flex" gap={0.5}>
                  <EditIcon
                    onClick={() => editRecipientHandler(idx)}
                    sx={{ fontSize: '20px', cursor: 'pointer' }}
                  />
                  <DeleteIcon
                    onClick={() => deleteRecipientHandler(idx)}
                    style={{ marginTop: '-2px', cursor: 'pointer' }}
                  />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center" mt={8}>
          <Button variant="primary" onClick={close}>
            <Typography>{t('button.close')}</Typography>
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default RecipientsModal;
