import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SpecCard from 'src/components/common/SpecCard';
import useExtendedCourses from 'src/hooks/queries/useExtendedCourses';
import { useAuth } from 'src/context/authContext';
import useProfile from 'src/hooks/queries/useProfile';
import { useLanguage } from 'src/context/languageContext';
import AuthWrapper from 'src/components/layouts/AuthWrapper';

const Assignments: React.FC = () => {
  const { t } = useTranslation();
  const { courses } = useExtendedCourses();
  const { locale } = useLanguage();
  const navigate = useNavigate();

  const { isAdmin } = useAuth();
  const { data: profile, myCourses = [] } = useProfile();

  const coursesData = isAdmin
    ? courses
    : courses?.filter((c) => myCourses?.map((ci) => ci.courseId).includes(c.id));

  useEffect(() => {
    const single = myCourses?.length === 1;
    if (!isAdmin && single) {
      navigate(`/${locale}/assignments/${String(myCourses[0]?.courseId)}`);
    }
  }, [profile]); // eslint-disable-line

  return (
    <>
      <AuthWrapper>
        <Box
          display="flex"
          bgcolor="black"
          alignItems="center"
          justifyContent="space-between"
          pl={{ sm: 4, xs: 2 }}
          height={{ sm: 64, xs: 51 }}
          sx={{
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10
          }}>
          <Typography fontSize={{ sm: 20, xs: 16 }} color="white" fontWeight={700} minHeight="24px">
            {t('labels.chooseDirection')}
          </Typography>
        </Box>

        <Box
          padding={{ xs: '16px', mb2: '48px 135px' }}
          textAlign={{ xs: 'center', md: 'start' }}
          sx={{
            border: '1px solid #DEDEDE',
            borderBottomRightRadius: 8,
            borderBottomLeftRadius: 8
          }}>
          <Typography fontWeight={400} fontSize={{ sm: 20, xs: 16 }}>
            {t('labels.chooseDirectionText')}
          </Typography>

          <Grid
            container
            mt={'20px'}
            spacing={2}
            justifyContent={{ xs: 'center', md: 'flex-start' }}>
            {coursesData?.map((spec: any) => {
              return (
                <Grid
                  item
                  key={spec.id}
                  width={{ xs: 160, mb2: 250 }}
                  height={{ xs: 270, mb2: 350 }}>
                  <SpecCard
                    isGeneral={false}
                    spec={spec}
                    clickHandler={() => {
                      navigate(`/${locale}/assignments/${String(spec.id)}`);
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </AuthWrapper>
    </>
  );
};

export default Assignments;
