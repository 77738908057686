import { AxiosError, AxiosRequestConfig } from 'axios';
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { useAuth } from 'src/context/authContext';
import { LOCALSTORAGE_KEYS } from 'src/enums';
import { axiosInstance, refreshAccessToken, axiosSyllabusInstance } from 'src/services/api';

interface Props {
  children: ReactNode;
}

const AxiosInterceptor: FC<Props> = ({ children }) => {
  const { token, handleLogout, refreshToken } = useAuth();
  const [isSet, setIsSet] = useState(false);

  /** Refresh and Set new token */
  const refreshTokenHandler = useCallback(
    async (originalRequest: AxiosRequestConfig) => {
      const refreshAccess = localStorage.getItem(LOCALSTORAGE_KEYS.REFRESH_TOKEN) || '';
      const shouldRefresh = localStorage.getItem(LOCALSTORAGE_KEYS.REMEMBER_ME);
      if (refreshAccess && shouldRefresh) {
        try {
          const { data } = await refreshAccessToken({
            refresh_token: refreshAccess
          });

          refreshToken(data.access_token, data.refresh_token);
          return axiosInstance(originalRequest);
        } catch {
          handleLogout();
        }
      } else {
        handleLogout();
      }
      return null;
    },
    [refreshToken, handleLogout]
  );

  useEffect(() => {
    /** Add token to request */
    const requestSuccessInterceptor = (config: AxiosRequestConfig) => {
      if (config.headers) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
      return config;
    };

    const requestErrorInterceptor = (error: AxiosError) => {
      return Promise.resolve(error);
    };

    const requestInterceptor = axiosInstance.interceptors.request.use(
      requestSuccessInterceptor,
      requestErrorInterceptor
    );

    const syllabusAxiosInterceptor = axiosSyllabusInstance.interceptors.request.use(
      requestSuccessInterceptor,
      requestErrorInterceptor
    );

    const responseInterceptor = axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        /* eslint no-underscore-dangle: 0 */
        if (error?.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          /* eslint no-underscore-dangle: 0 */
          refreshTokenHandler(originalRequest);
        }
        return Promise.reject(error);
      }
    );
    const syllabusResponseIntersceptor = axiosSyllabusInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        /* eslint no-underscore-dangle: 0 */
        if (error?.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          /* eslint no-underscore-dangle: 0 */
          refreshTokenHandler(originalRequest);
        }
        return Promise.reject(error);
      }
    );
    return () => {
      /** Eject interceptors */
      axiosInstance.interceptors.request.eject(requestInterceptor);
      axiosInstance.interceptors.response.eject(responseInterceptor);
      axiosSyllabusInstance.interceptors.request.eject(syllabusAxiosInterceptor);
      axiosSyllabusInstance.interceptors.response.eject(syllabusResponseIntersceptor);
    };
  }, [token, refreshTokenHandler]);

  useEffect(() => {
    setIsSet(true);
  }, [isSet]);

  return <>{isSet && children}</>;
};

export default AxiosInterceptor;
