import { AxiosError } from 'axios';
import { QUERY_KEYS } from 'src/enums';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'src/context/authContext';
import { getUserProfile } from 'src/services/api';
import { UserProfileResponse } from 'src/models/service';

const useProfile = () => {
  const { isLoggedIn, handleLogout } = useAuth();
  const { data, isLoading } = useQuery<UserProfileResponse>(
    [QUERY_KEYS.PROFILE],
    () => getUserProfile(),
    {
      onError: (error: AxiosError) => {
        if (error?.request?.status === 401) {
          handleLogout();
        }
      },
      enabled: isLoggedIn
    }
  );

  const myCourses = data?.myCourses?.filter((course) => !course.courseCompleted);

  return { data, isLoading, myCourses };
};

export default useProfile;
