import { AxiosError } from 'axios';
import { login } from 'src/services/api';
import { useMutation } from '@tanstack/react-query';
import { LoginPayload, LoginResponse } from 'src/models/service';

const useLogin = () => {
  const { mutate, isLoading } = useMutation<LoginResponse, AxiosError, LoginPayload>(
    ['login'],
    (payload) => login(payload)
  );

  return {
    mutate,
    isLoading
  };
};

export default useLogin;
