import { changeWeekPosition } from './../../services/api';
import { AddWeekPayload } from './../../models/service';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { QUERY_KEYS } from 'src/enums';
import useLanguageCode from '../useLanguageCode';

const useChangeWeekPosition = () => {
  const client = useQueryClient();
  const { lang } = useLanguageCode();

  const { mutate, isLoading, isError } = useMutation<
    { success?: boolean },
    AxiosError,
    { weekId: number; payload: AddWeekPayload }
  >(['change-week-position'], ({ weekId, payload }) => changeWeekPosition(weekId, payload, lang), {
    onSuccess: () => {
      client.invalidateQueries([QUERY_KEYS.SYLLABUS]);
    }
  });

  return {
    mutate,
    isError,
    isLoading
  };
};

export default useChangeWeekPosition;
