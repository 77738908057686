import React from 'react';
import { QUERY_KEYS } from 'src/enums';
import { getCriteria } from 'src/services/api';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import useQueryString from 'src/hooks/useQueryString';
import { Box, Grid, Typography } from '@mui/material';
import useLanguageCode from 'src/hooks/useLanguageCode';
import { useNavigate } from 'src/context/languageContext';
import useCategories from 'src/hooks/queries/useCategories';
import AuthWrapper from 'src/components/layouts/AuthWrapper';
import useDeleteCriteria from 'src/hooks/mutations/useDeleteCriteria';
import AddCategoryForm from 'src/components/UI/forms/AddCategoryForm';
import { ReactComponent as EditIcon } from 'src/assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from 'src/assets/icons/delete.svg';

const Categories = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { lang } = useLanguageCode();

  const { parseQuery } = useQueryString();
  const { criteriaId = '' } = parseQuery();

  const { categories } = useCategories();
  const { mutate } = useDeleteCriteria();

  const { data } = useQuery(
    [QUERY_KEYS.COURSES, criteriaId, lang],
    () => getCriteria(String(criteriaId), lang),
    { enabled: Boolean(criteriaId) }
  );

  return (
    <AuthWrapper>
      <Grid
        mt={{ sm: 0, xs: 2 }}
        sx={{
          background: '#fff',
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
          border: '1px solid #DEDEDE'
        }}>
        <Box
          padding="24px"
          display="flex"
          bgcolor="black"
          alignItems="center"
          justifyContent="space-between"
          height={{ sm: 64, xs: 51 }}
          sx={{
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10
          }}>
          <Typography fontSize={{ sm: 20, xs: 16 }} color="white" fontWeight={700} minHeight="24px">
            {t('labels.criteriaList')}
          </Typography>
        </Box>
        <Grid container pb={10} mt={3}>
          <Grid item mb={5} xs={6} gap={1} padding={3}>
            <Grid gap={1} display="flex" flexDirection="column" pr={10}>
              {categories?.map((criteria: any) => {
                return (
                  <Grid
                    item
                    gap={2}
                    padding={2}
                    display="flex"
                    borderRadius={2}
                    key={criteria.id}
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ background: '#eeeeee' }}>
                    <Typography fontSize={{ sx: 16, sm: 18 }}>{criteria.name}</Typography>
                    <Box display="flex" alignItems="center" gap={1}>
                      <EditIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate(`/content-categories?criteriaId=${criteria.id}`)}
                      />
                      <DeleteIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => mutate(criteria.id)}
                      />
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item xs={5} py={4} px={2} border="1px solid #e4e4e4">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={56}
              margin="auto"
              sx={{
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8
              }}
              bgcolor="black">
              <Box
                display="flex"
                gap={{ sx: 2, sm: 8 }}
                alignItems="center"
                zIndex={100}
                justifyContent="center">
                <Typography color="white" textTransform="uppercase" fontSize={{ sx: 16, sm: 20 }}>
                  {criteriaId ? t('labels.editCtgr') : t('labels.addCtgr')}
                </Typography>
              </Box>
            </Box>
            <AddCategoryForm criteria={data?.evaluation_criterion} crtId={String(criteriaId)} />
          </Grid>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

export default Categories;
