import styled from '@mui/system/styled';
import { CardContent, Chip } from '@mui/material';
import { ReactComponent as SquaresBg } from 'src/assets/icons/angle_icon.svg';

export const CardHeader = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxHeight: 67,
  paddingBlock: 20,
  paddingInline: 24,
  borderRadius: 8,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  backgroundColor: theme.palette.primary.main,
  overflow: 'hidden'
}));

export const CardHeaderBlack = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxHeight: 67,
  paddingBlock: 20,
  paddingInline: 24,
  borderRadius: 8,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  backgroundColor: 'black',
  overflow: 'hidden',
  height: '64px'
}));

export const CardImage = styled(SquaresBg)(() => ({
  position: 'absolute',
  width: '100%',
  transform: 'scale(2)',
  '& path': {
    fill: '#2c1f82',
    opacity: 0.2
  }
}));

export const StyledCardContent = styled(CardContent)(() => ({
  borderTop: '0 !important',
  borderTopLeftRadius: '0 !important',
  borderTopRightRadius: '0 !important',
  borderBottomLeftRadius: '16px !important',
  borderBottomRightRadius: '16px !important',
  border: 'none !important',
  background: '#fff'
}));
