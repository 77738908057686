import React from 'react';
import { useAuth } from 'src/context/authContext';
import SubCourses from 'src/components/UI/subCourses';
import { getDateDifference } from 'src/utils';
import Navigate from 'src/components/common/navigate';
import { COURSE_STATUS } from 'src/enums';
import CourseDetails from '../courseDetails';
import useProfile from 'src/hooks/queries/useProfile';
import useSyllabus from 'src/hooks/queries/useSyllabus';
import useRating from 'src/hooks/queries/useRating';
import useUserCourse from 'src/hooks/queries/useUserCourse';
import useQueryString from 'src/hooks/useQueryString';
import useDetectDevice from 'src/hooks/useDetectDevice';
import { Box } from '@mui/system';

const Specialization = () => {
  const { isAdmin } = useAuth();
  const { data: course } = useUserCourse();
  const { myCourses = [] } = useProfile();

  const { parseQuery } = useQueryString();
  const { course: chaptCourse = '' } = parseQuery();
  const { isMobile } = useDetectDevice();

  const subGroupId = myCourses[0]?.subGroupId;
  const { subCourses = [], groups = [] } = course || {};
  const { startDate: courseStart = '' } = groups[0] || {};
  const choosedSubGroup = subCourses.find((sub) => sub.subGroups[0]?.id === subGroupId);
  const date = new Date(courseStart);
  date.setMonth(date.getMonth() + 4);
  const daysBeforeStart = getDateDifference(date, new Date()) + 5;

  const courseIdx = isMobile && myCourses?.length > 1 ? 1 : 0;

  const activeCourse = myCourses?.find((crs: any) => crs?.courseId.toString() === chaptCourse);
  const groupId = activeCourse?.groupId || myCourses?.[courseIdx]?.groupId;

  const { data } = useSyllabus(
    {
      status: COURSE_STATUS.SPECIFIC,
      chapter_id: choosedSubGroup?.id.toString()
    },
    choosedSubGroup && daysBeforeStart < 0
  );

  const { scores } = useRating({ group_id: groupId });

  if (isAdmin) {
    return (
      <Box pt={4} pb={10}>
        <SubCourses />
      </Box>
    );
  }

  if (!subGroupId) {
    return <Navigate to="/my-course/specific/choose-chapter" />;
  }

  if (daysBeforeStart > 0 && choosedSubGroup) {
    return <Navigate to="/my-course/specific/choosed-chapter" />;
  }

  return <CourseDetails months={data} rating={scores} />;
};

export default Specialization;
