import { AxiosError } from 'axios';
import { deleteCriteria } from 'src/services/api';
import { QUERY_KEYS } from 'src/enums';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const useDeleteCriteria = () => {
  const client = useQueryClient();

  const { mutate, isLoading } = useMutation<{ success?: boolean }, AxiosError, number>(
    (payload) => deleteCriteria(payload),
    {
      onSuccess: () => {
        client.invalidateQueries([QUERY_KEYS.CRITERIA]);
      }
    }
  );

  return {
    mutate,
    isLoading
  };
};

export default useDeleteCriteria;
