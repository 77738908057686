import './oceanForm.css';
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SvgIcon,
  TextField,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useState, Dispatch, SetStateAction, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { oceanNewsSchema } from 'src/helpers/validations';
import palette from 'src/theme/overrides/palette';
import { ReactComponent as DragIcon } from 'src/assets/icons/assign-drag.svg';
import Loader from 'src/components/common/Loader';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from 'src/context/languageContext';
import { INewsOrBlog, INewsTag } from 'src/models/service';
import useTags from 'src/hooks/queries/useTags';
import { ImPlus } from 'react-icons/im';
import NewsUploadFile from './NewsUploadFile';
import OceanFormBtn from './OceanFormBtn';
import { EditorWrapper } from '../CareerCareModule';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { addOceanNews, editNews, uploadEditorImage } from 'src/services/api';
import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import AuthWrapper from 'src/components/layouts/AuthWrapper';
import { QUERY_KEYS } from 'src/enums';

interface FormValues {
  kind: 'news' | 'blogs';
  title_en: string | null;
  body_en: string | null;
  images: { file: File; uuid: string }[];
  imagePreviews: { url: string; uuid: string }[];
  videos: { file: File; uuid: string }[];
  videoPreviews: { url: string; uuid: string }[];
  thumbnailImage: File | null;
  tags: INewsTag[];
  thumbnailPreview: string | null;
}

interface Props {
  news?: INewsOrBlog;
  type?: string;
}

const AddNewsForm: React.FC<Props> = ({ news, type }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { locale } = useLanguage();
  const { mutate: mutateAddNews, isLoading } = useMutation<
    void,
    AxiosError,
    { payload: FormData; slug: string }
  >({
    mutationFn: ({ payload, slug }) => addOceanNews(payload, slug)
  });
  const { data: tagsData } = useTags();
  const [error, setError] = useState<any>({});

  const queryClient = useQueryClient();
  const [tagPanel, setTagPanel] = useState<boolean>(false);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editorState: EditorState) => setEditorState(editorState);
  const content = draftToHtml(convertToRaw(editorState.getCurrentContent()));

  const {
    translations: [
      {
        title: newsTitle = '',
        body: editorBody = '',
        card_description: newsCardDescription = ''
      } = {}
    ] = [],
    tags: newsTags = [],
    images: newsImages = [],
    videos: newsVideos = [],
    thumbnailImage: { url: newsThumbnailUrl = '' } = {}
  } = news || {};

  const { mutate: mutateEditNews } = useMutation<
    unknown,
    AxiosError,
    { payload: FormData; id: number; slug: string }
  >({
    mutationFn: ({ payload, slug, id }) => editNews(payload, { slug, id }),
    onSettled: () => {
      queryClient.invalidateQueries([QUERY_KEYS.NEWS_DETAILS]);
    }
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      kind: (type as 'news' | 'blogs') || null,
      title_en: newsTitle || '',
      body_en: newsCardDescription || '',
      images: [],
      imagePreviews: newsImages?.length
        ? newsImages.map(({ url }) => ({
            url,
            uuid: window.crypto['randomUUID']()
          }))
        : [],
      videos: [],
      videoPreviews: newsVideos?.length
        ? newsVideos.map(({ url }) => ({
            url,
            uuid: window.crypto['randomUUID']()
          }))
        : [],
      thumbnailImage: null,
      thumbnailPreview: newsThumbnailUrl || null,
      tags: newsTags || []
    },
    validationSchema: oceanNewsSchema(t),
    enableReinitialize: false,
    onSubmit: async ({ kind, title_en, thumbnailImage, body_en, tags, images, videos }) => {
      const fileData = new FormData();
      fileData.append('en[title]', `${title_en ?? ''}`);
      if (thumbnailImage) {
        fileData.append('thumbnailImage', thumbnailImage);
      }
      fileData.append('ka[title]', `${title_en ?? ''}`);
      fileData.append('en[body]', `${content ?? ''}`);
      fileData.append('ka[body]', `${content ?? ''}`);
      fileData.append('en[card_description]', `${body_en ?? ''}`);
      fileData.append('ka[card_description]', `${body_en ?? ''}`);

      images.map((img, idx) => fileData.append(`images[${idx}]`, img.file));
      videos?.map((vid, idx) => fileData.append(`videos[${idx}]`, vid.file));
      tags?.map((tag, idx) => fileData.append(`tags[${idx}]`, tag.id.toString()));
      if (news) {
        fileData.append('id', news.id.toString());
        fileData.append('_method', 'PUT');

        mutateEditNews(
          {
            payload: fileData,
            id: news.id,
            slug: kind
          },
          {
            onSuccess: () => {
              navigate(`/${locale}/ocean-news?section=${kind}`);
            }
          }
        );
      } else {
        mutateAddNews(
          {
            payload: fileData,
            slug: kind
          },
          {
            onSuccess: () => {
              navigate(`/${locale}/ocean-news/section=${kind}`);
            }
          }
        );
      }
    },

    validateOnBlur: false,
    validateOnChange: true
  });

  useEffect(() => {
    if (editorBody) {
      const blocksFromHTML = convertFromHTML(editorBody);
      const initialEditorState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(initialEditorState));
    }
  }, [editorBody]);

  if (isLoading) {
    return (
      <Grid container justifyContent="center" maxWidth="650px" height="550px" margin="auto">
        <Loader />;
      </Grid>
    );
  }
  return (
    <AuthWrapper>
      <Grid mb={10}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} marginBottom={{ xs: 4, l: 6 }}>
              <Box
                display="flex"
                bgcolor="black"
                alignItems="center"
                pl={{ sm: 4, xs: 2 }}
                height={{ sm: 64, xs: 51 }}
                justifyContent="space-between"
                sx={{
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10
                }}>
                <Box
                  width={{ xs: '100%', m: 'auto' }}
                  display="flex"
                  gap={{ sx: 2, sm: 8 }}
                  alignItems="center"
                  zIndex={100}
                  justifyContent="center">
                  <Typography color={palette.white} fontSize={{ sx: 16, sm: 20 }}>
                    {newsTitle ? `Edit ${newsTitle}` : t('labels.createNews')}
                  </Typography>
                </Box>
              </Box>

              <Box
                pb={8}
                pt={6}
                sx={{
                  background: '#fff',
                  border: '1px solid #DEDEDE',
                  borderBottomRightRadius: 8,
                  borderBottomLeftRadius: 8
                }}>
                <Grid container justifyContent={{ xs: 'center' }}>
                  <Grid item sm={5} xs={12}>
                    {formik.errors.kind && (
                      <Typography textAlign={'start'} color={'crimson'} fontWeight={'regular'}>
                        Select news type
                      </Typography>
                    )}
                    <Select
                      value={formik.values.kind}
                      name="kind"
                      sx={{
                        border: formik.errors.kind && '1px solid red',
                        borderRadius: '8px'
                      }}
                      onChange={formik.handleChange}>
                      <MenuItem value="news">news</MenuItem>
                      <MenuItem value="blogs">blogs</MenuItem>
                    </Select>
                  </Grid>
                </Grid>

                {/* TITLE  */}
                <Grid container mt={'24px'} justifyContent={{ xs: 'center' }}>
                  <Grid item sm={5} xs={12}>
                    <TextField
                      name="title_en"
                      variant="outlined"
                      onBlur={formik.handleBlur}
                      label={t('labels.title')}
                      onChange={formik.handleChange}
                      value={formik.values.title_en}
                      error={formik.touched.title_en && Boolean(formik.errors.title_en)}
                      helperText={formik.touched.title_en && formik.errors.title_en}
                      FormHelperTextProps={{
                        error: formik.touched.title_en && Boolean(formik.errors.title_en),
                        style: {
                          whiteSpace: 'normal'
                        }
                      }}
                    />
                  </Grid>
                </Grid>

                {/* SHORT DESCRIPTION  */}
                <Grid container mt={'24px'} justifyContent={{ xs: 'center' }}>
                  <Grid item sm={5} xs={12}>
                    <textarea
                      name="body_en"
                      className="ocean_textarea"
                      rows={4}
                      value={formik.values.body_en as string}
                      placeholder={t('labels.description')}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                </Grid>

                {/* BODY EN */}
                <Grid container mt={'24px'} justifyContent={{ xs: 'center' }}>
                  <Grid item sm={5} xs={12}>
                    <EditorWrapper sx={{ border: '1px solid #e4e4e4' }}>
                      <Editor
                        editorState={editorState}
                        onEditorStateChange={onEditorStateChange}
                        editorStyle={{ paddingLeft: '15px', minHeight: '100px' }}
                      />
                      <textarea disabled hidden value={content} />
                    </EditorWrapper>
                  </Grid>
                </Grid>

                {/* TAGS */}
                <Grid
                  container
                  mt={'24px'}
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                  justifyContent={{ xs: 'center' }}>
                  {error.tags && (
                    <Grid item sm={5} xs={12} width={'100%'}>
                      <Typography textAlign={'start'} color={'crimson'} fontWeight={'regular'}>
                        {error.tags}
                      </Typography>
                    </Grid>
                  )}

                  <Grid
                    item
                    sm={5}
                    xs={12}
                    minHeight={50}
                    position={'relative'}
                    border={formik.errors.tags ? '1px solid crimson' : '1px solid #dbdada'}
                    borderRadius={'6px'}
                    display={'flex'}
                    width={'100%'}
                    alignItems={'center'}
                    flexWrap={'wrap'}
                    paddingLeft={1.5}
                    paddingY={1.5}
                    paddingRight={10}>
                    {formik.values.tags.map((tag) => (
                      <Button
                        onClick={() => {
                          setError({});
                          formik.setFieldValue(
                            'tags',
                            formik.values.tags.filter((t) => t.id !== tag.id)
                          );
                        }}
                        key={tag.id}
                        sx={{
                          background: '#eee',
                          padding: '0px 12px',
                          borderRadius: '3px',
                          marginRight: '10px',
                          marginBottom: '10px',
                          height: '40px',
                          cursor: 'pointer'
                        }}>
                        {tag.name}
                      </Button>
                    ))}

                    <OceanFormBtn action={() => setTagPanel(!tagPanel)} Icon={ImPlus} />

                    {tagPanel && (
                      <Box
                        position={'absolute'}
                        borderRadius={3}
                        border={'1px solid gray'}
                        right={'-175px'}
                        top={0}
                        padding={'8px'}
                        minWidth={160}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'start'}
                        sx={{
                          background: 'white'
                        }}>
                        {tagsData?.tags.length === formik.values.tags.length && (
                          <Typography fontSize={18}>NO TAGS</Typography>
                        )}
                        {tagsData?.tags.map((tag: INewsTag) => {
                          if (!formik.values.tags.find((t) => t.id === tag.id)) {
                            return (
                              <Button
                                key={tag.id}
                                onClick={() => {
                                  setError({});
                                  formik.setFieldValue('tags', [...formik.values.tags, tag]);
                                }}
                                sx={{
                                  cursor: 'pointer',
                                  background: '#eee',
                                  marginBottom: '5px',
                                  height: '45px'
                                }}>
                                <Typography fontSize={18} key={tag.id}>
                                  # {tag.name}
                                </Typography>
                              </Button>
                            );
                          }
                          return null;
                        })}
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <Grid container mt={'24px'} justifyContent={{ xs: 'center' }}>
                  <Grid
                    item
                    sm={5}
                    xs={12}
                    position="relative"
                    minHeight={'150px'}
                    justifyContent={'center'}
                    border={'1px solid #DEDEDE'}
                    alignItems="center"
                    borderRadius={'8px'}>
                    <Box position="absolute" right={0}>
                      <IconButton aria-label="upload-thumbnail" component="label">
                        <DragIcon />
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            const selectedFiles = e.target.files;

                            if (selectedFiles && selectedFiles.length > 0) {
                              formik.setFieldValue('thumbnailImage', selectedFiles[0]);
                              formik.setFieldValue(
                                'thumbnailPreview',
                                URL.createObjectURL(selectedFiles[0])
                              );
                            }
                          }}
                          hidden
                        />
                      </IconButton>
                    </Box>

                    <Box mt={3} display="flex" justifyContent="center" alignItems="center">
                      {formik.values.thumbnailPreview ? (
                        <img
                          style={{ height: '135px', width: '47%', objectFit: 'cover' }}
                          alt="thumbnail"
                          src={formik.values.thumbnailPreview}
                        />
                      ) : (
                        <Typography color={'#007FFF'} fontSize={'16px'}>
                          Upload Thumbnail
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                </Grid>

                {/* IMAGES */}
                <Grid container mt={'24px'} justifyContent={{ xs: 'center' }}>
                  <Grid
                    item
                    sm={5}
                    xs={12}
                    minHeight={'150px'}
                    position={'relative'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    border={'1px solid #DEDEDE'}
                    borderRadius={'8px'}>
                    <Box position="absolute" right={0}>
                      <IconButton aria-label="upload-thumbnail" component="label">
                        <DragIcon />
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            if (e.target.files) {
                              const files = Object.values(e.target.files).map((file) => file);

                              const uploaded = files.map((f) => ({
                                uuid: window.crypto['randomUUID'](),
                                file: f
                              }));
                              formik.setFieldValue('images', [
                                ...formik.values.images,
                                ...uploaded
                              ]);

                              formik.setFieldValue('imagePreviews', [
                                ...formik.values.imagePreviews,
                                ...uploaded.map((f) => ({
                                  url: URL.createObjectURL(f.file),
                                  uuid: f.uuid
                                }))
                              ]);
                            }
                          }}
                          hidden
                        />
                      </IconButton>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%'
                      }}>
                      <Typography color={'#007FFF'} fontSize={'16px'}>
                        {!formik.values.images.length && 'Browse Images'}
                      </Typography>

                      {/* MAPPED IMAGES (UPLOAD) */}
                      {formik.values.imagePreviews.map((img) => {
                        return (
                          <NewsUploadFile
                            key={img.uuid}
                            file={img}
                            removeFile={(file) => {
                              formik.setFieldValue(
                                'images',
                                formik.values.images.filter((img) => img.uuid !== file.uuid)
                              );
                              formik.setFieldValue(
                                'imagePreviews',
                                formik.values.imagePreviews.filter((img) => img.uuid !== file.uuid)
                              );
                            }}
                          />
                        );
                      })}
                    </Box>
                  </Grid>
                </Grid>

                {/* VIDEOS */}
                <Grid container mt={'24px'} justifyContent={{ xs: 'center' }}>
                  <Grid
                    item
                    sm={5}
                    xs={12}
                    minHeight={'150px'}
                    position={'relative'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    border={'1px solid #DEDEDE'}
                    borderRadius={'8px'}>
                    <Box position="absolute" right={0}>
                      <IconButton aria-label="upload-thumbnail" component="label">
                        <DragIcon />
                        <input
                          type="file"
                          accept="video/*"
                          onChange={(e) => {
                            if (e.target.files) {
                              const files = Object.values(e.target.files).map((file) => file);

                              const uploaded = files.map((f) => ({
                                uuid: window.crypto['randomUUID'](),
                                file: f
                              }));
                              formik.setFieldValue('videos', [
                                ...formik.values.videos,
                                ...uploaded
                              ]);

                              formik.setFieldValue('videoPrevies', [
                                ...formik.values.videoPreviews,
                                ...uploaded.map((f) => ({
                                  url: URL.createObjectURL(f.file),
                                  uuid: f.uuid
                                }))
                              ]);
                            }
                          }}
                          hidden
                        />
                      </IconButton>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%'
                      }}>
                      <Typography color={'#007FFF'} fontSize={'16px'}>
                        {!formik.values.videos.length && 'Browse Videos'}
                      </Typography>

                      {/* MAPPED VIDEOS (UPLOAD) */}
                      {formik.values.videoPreviews.map((vid) => {
                        return (
                          <NewsUploadFile
                            key={vid.uuid}
                            file={vid}
                            removeFile={(file) => {
                              formik.setFieldValue(
                                'videos',
                                formik.values.videos.filter((img) => img.uuid !== file.uuid)
                              );
                              formik.setFieldValue(
                                'videoPreviews',
                                formik.values.videoPreviews.filter((img) => img.uuid !== file.uuid)
                              );
                            }}
                            type={'video'}
                          />
                        );
                      })}
                    </Box>
                  </Grid>
                </Grid>

                {/* <Grid container justifyContent={{ xs: 'center' }} sx={{ textAlign: 'start' }}>
              <Grid item xs={8}>
                {formik.touched.attachment && Boolean(formik.errors.attachment) && (
                  <small style={{ paddingLeft: '15px', color: '#F73E48' }}>
                    Attachment is required
                  </small>
                )}
              </Grid>
            </Grid> */}

                <Grid container mt={'24px'} justifyContent={'center'}>
                  <Grid item xs={5}>
                    <Button
                      type="submit"
                      variant="containedPrimary"
                      sx={{
                        border: '1px solid #D5D2F3',
                        borderRadius: '8px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#5946D4'
                      }}
                      fullWidth>
                      <Typography
                        color="white"
                        fontWeight="400"
                        textTransform="uppercase"
                        fontSize={{ xs: 16, l: 18 }}>
                        {t('button.submit')}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </AuthWrapper>
  );
};

export default AddNewsForm;
