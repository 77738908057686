import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { CircularProgressBar } from './styles';

interface ProgressBarProps {
  progress: number | undefined;
}

const ProgressBar: FC<ProgressBarProps> = ({ progress }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        color: 'primary.contrastText'
      }}>
      <CircularProgressBar
        size={183}
        className="bottom-circle"
        color="error"
        thickness={5}
        variant="determinate"
        value={100}
      />
      <CircularProgressBar
        size={183}
        className="top-circle"
        color="inherit"
        thickness={5}
        variant="determinate"
        value={progress}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Typography
          fontSize={30}
          color="textBlack"
          lineHeight="40px"
          component="div"
          fontFamily="basenji-variable">
          {progress && `${progress.toFixed()}`}%
        </Typography>
      </Box>
    </Box>
  );
};

export default ProgressBar;
