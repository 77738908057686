import qs from 'qs';
import axios from 'axios';
import { API_ROUTES, NewDetailsEnum } from 'src/enums';
import {
  LoginPayload,
  LoginResponse,
  AddEventPayload,
  RefreshTokenPayload,
  ResendEmailPayload,
  ResetPasswordConfirmPayload,
  ResetPasswordPayload,
  CareerContentPayload,
  PublishContentPayload,
  VerifyEmailPayload,
  CourseStatusResponse,
  AllCourseResponse,
  CourseResponse,
  EditProfilePayload,
  changePasswordPayload,
  CopySyllabusPayload,
  TranslateResponse,
  CourseMonthResponse,
  SpecificCourseResponse,
  RegisterOnSubCoursePayload,
  UploadRatingResponse,
  KahootRatingResponse,
  SubjectDoneResponse,
  SubjectStatusPayload,
  SylabussParams,
  AllCoursesExtendedResponse,
  AddMonthPayload,
  AddWeekPayload,
  RatingParams,
  AddSubjectPayload,
  EditSubjectPayload,
  SubjectParams,
  ProgressPayload,
  HomeworkResponse,
  AssignmentResponse,
  UploadHomeworkPayload,
  UploadAssignmentPayload,
  UpdateAssignmentPaylaod,
  EditCourseContentPayload,
  NewsResponse,
  NewsDetailsResponse,
  SimilarNewsResponse,
  TagsResponse,
  AddTagPayload,
  BlogsResponse,
  AddCriteriaPayload
} from 'src/models/service';

const locale = localStorage.getItem('locale') as string;
const lang = locale === 'ge' ? 'ka' : 'en';

const APPLICATION_JSON = 'application/json';
axios.defaults.headers.common['Content-Type'] = APPLICATION_JSON;
axios.defaults.headers.common.Accept = APPLICATION_JSON;

const baseURL =
  process.env.REACT_APP_SKILLWILL_COUNTRY === 'ge'
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_API_AZ_URL;

const sylabussBaseURL =
  process.env.REACT_APP_SKILLWILL_COUNTRY === 'ge'
    ? process.env.REACT_APP_SYLLABUS_URL
    : process.env.REACT_APP_SYLLABUS_AZ_URL;

export const axiosInstance = axios.create({
  baseURL
});

export const axiosSyllabusInstance = axios.create({
  baseURL: sylabussBaseURL,
  headers: { lang }
});

export const login = async (payload: LoginPayload) => {
  const { data } = await axiosInstance.post<LoginResponse>(
    API_ROUTES.LOGIN,
    qs.stringify({
      ...payload,
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      grant_type: process.env.REACT_APP_GRANT_TYPE,
      scope: process.env.REACT_APP_SCOPE
    })
  );
  return data;
};

export const refreshAccessToken = (payload: RefreshTokenPayload) =>
  axiosInstance.post<LoginResponse>(
    API_ROUTES.LOGIN,
    qs.stringify({
      ...payload,
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      grant_type: process.env.REACT_APP_GRANT_TYPE_REFRESH,
      scope: process.env.REACT_APP_SCOPE
    })
  );

export const readWelcomeMessage = async () => {
  await axiosInstance.post(API_ROUTES.READ_WELCOME_MESSAGE);
};

export const resendVerificationEmail = (payload: ResendEmailPayload, lang: string) =>
  axiosInstance.post<void>(API_ROUTES.RESEND_VERIFICATION_EMAIL, payload, {
    headers: {
      language: lang
    }
  });

export const verifyEmail = (payload: VerifyEmailPayload) =>
  axiosInstance.post<void>(API_ROUTES.VERIFY_EMAIL, payload);

export const resetPassword = (payload: ResetPasswordPayload, lang: string) =>
  axiosInstance.post<void>(API_ROUTES.RESET_PASSWORD, payload, {
    headers: {
      language: `${lang}`
    }
  });

export const resetPasswordConfirm = (payload: ResetPasswordConfirmPayload) =>
  axiosInstance.post<void>(API_ROUTES.RESET_PASSWORD_CONFIRM, payload);

export const changeUserAvatar = async (payload: FormData) => {
  await axiosInstance.patch<void>(API_ROUTES.USER_AVATAR, payload);
};

export const addOceanNews = async (payload: FormData, slug: string) => {
  await axiosSyllabusInstance.post<void>(`/api/${slug}`, payload);
};

export const uploadHomework = async ({
  description,
  due_date,
  points,
  status,
  subject_id,
  title,
  attachment,
  chapter_id,
  estimation,
  evaluation_criteria = []
}: UploadHomeworkPayload) => {
  const fileData = new FormData();
  fileData.append('title', `${title ?? ''}`);
  fileData.append('description', `${description ?? ''}`);
  fileData.append('points', points as any);
  fileData.append('status', `${status}`);
  fileData.append('subject_id', subject_id as any);
  fileData.append('attachment', attachment!);
  fileData.append('estimate_time', estimation!);
  evaluation_criteria.forEach((crt: any, i: any) =>
    fileData.append(`evaluation_criteria[${i}]`, String(crt))
  );

  const date = `${due_date} 00:00:00`;
  fileData.append('due_date', date as any);

  await axiosSyllabusInstance.post<void>(
    `${API_ROUTES.SUBJECT_HOMEWORKS}?chapter_id=${chapter_id}`,
    fileData
  );
};

export const updateHomework = async ({
  description,
  due_date,
  points,
  status,
  subject_id,
  title,
  attachment,
  chapter_id,
  estimation,
  evaluation_criteria
}: UploadHomeworkPayload) => {
  const fileData = new FormData();
  fileData.append('_method', 'PUT');

  title && fileData.append('title', `${title}`);
  attachment && fileData.append('attachment', attachment!);
  description && fileData.append('description', `${description}`);
  points && fileData.append('points', points as any);
  status && fileData.append('status', `${status}`);
  subject_id && fileData.append('subject_id', subject_id as any);
  estimation && fileData.append('estimate_time', estimation);
  evaluation_criteria?.length &&
    evaluation_criteria.forEach((crt: any, i: any) =>
      fileData.append(`evaluation_criteria[${i}]`, String(crt))
    );

  const date = `${due_date} 00:00:00`;
  due_date && fileData.append('due_date', date as any);

  await axiosSyllabusInstance.post<void>(`${API_ROUTES.SUBJECT_HOMEWORKS}/${chapter_id}`, fileData);
};

export const uploadAssignment = async ({ fileData }: UploadAssignmentPayload) => {
  await axiosSyllabusInstance.post<void>(API_ROUTES.SUBJECT_ASSIGNMENTS, fileData);
};

export const updateAssignment = async ({ id, fileData }: UpdateAssignmentPaylaod) => {
  fileData.append('_method', 'PUT');
  await axiosSyllabusInstance.post<void>(`${API_ROUTES.SUBJECT_ASSIGNMENTS}/${id}`, fileData);
};

export const copySyllabus = (language: string, payload: CopySyllabusPayload) =>
  axiosSyllabusInstance.post(API_ROUTES.COPY_SYLLABUS, payload, {
    headers: {
      lang: `${language}`
    }
  });

export const getUserProfile = async () => {
  const { data } = await axios.get(`${baseURL}/api/UserInfo/SkillwillUserInfo`, {
    headers: {
      'Accept-language': '1',
      'Content-Type': 'application/json',
      authorization: `Bearer ${localStorage.getItem('token')}` || ''
    }
  });

  return data;
};

export const getAllCourses = async () => {
  const { data } = await axiosInstance.get<AllCourseResponse>(API_ROUTES.COURSES, {
    params: {
      pageSize: 50
    }
  });

  return data;
};

export const getCourse = async (id: string, language: string) => {
  const { data } = await axiosInstance.get<CourseResponse>(
    `${API_ROUTES.COURSES}${id}/${language}`
  );
  return data;
};

export const getSimilarNews = async (id: number, type: NewDetailsEnum) => {
  const { data } = await axiosSyllabusInstance.get<SimilarNewsResponse>(
    `${API_ROUTES.SIMILAR_POSTS}/${type}/${id}`
  );
  return data;
};

export const getTags = async () => {
  const { data } = await axiosSyllabusInstance.get<TagsResponse>(`${API_ROUTES.TAGS}`);
  return data;
};

export const getNews = async () => {
  const { data } = await axiosSyllabusInstance.get<NewsResponse>(`${API_ROUTES.NEWS}`);
  return data;
};

export const getBlogs = async () => {
  const { data } = await axiosSyllabusInstance.get<BlogsResponse>(`${API_ROUTES.BLOGS}`);
  return data;
};

export const getNewsDetails = async (id: number, type: NewDetailsEnum) => {
  const { data } = await axiosSyllabusInstance.get<NewsDetailsResponse>(`/api/${type}/${id}`);
  return data;
};

// This function is for getting details about the courses that are bought by studCard/solo
export const getCourseStatus = (payload: { orderId: string }, language: string, uri: string) =>
  axiosInstance.post<CourseStatusResponse>(uri, payload, {
    headers: {
      'Accept-language': language,
      'Content-Type': 'application/json'
    }
  });

export const getMonth = async (chapter_id: string, status: string) => {
  const { data } = await axiosSyllabusInstance.get(
    `${API_ROUTES.SUBJECT_MONTH}?status=${status}&chapter_id=${chapter_id}`
  );
  return data;
};

export const getHomeWork = async (id: string) => {
  const { data } = await axiosSyllabusInstance.get<HomeworkResponse>(
    `${API_ROUTES.SUBJECT_HOMEWORKS}?chapter_id=${id}`
  );
  return data;
};

export const getAssignment = async (id: string) => {
  const { data } = await axiosSyllabusInstance.get<AssignmentResponse>(
    `${API_ROUTES.SUBJECT_HOMEWORKS}/${id}`
  );
  return data;
};

export const deleteAssignment = async (id: string) => {
  const { data } = await axiosSyllabusInstance.delete<AssignmentResponse>(
    `${API_ROUTES.SUBJECT_ASSIGNMENTS}/${id}`
  );
  return data;
};

export const deleteHomework = async (id: string) => {
  const { data } = await axiosSyllabusInstance.delete<AssignmentResponse>(
    `${API_ROUTES.SUBJECT_HOMEWORKS}/${id}`
  );
  return data;
};

export const editProfileInfo = async (payload: EditProfilePayload) => {
  await axiosInstance.patch<void>(API_ROUTES.USER_INFO, payload);
};

export const changePassword = (payload: changePasswordPayload) =>
  axiosInstance.post(API_ROUTES.CHANGE_PASSWORD, payload);

export const getCities = () => axiosInstance.get(API_ROUTES.CITIES);

export const getTranslations = async (page: string, language: string) => {
  const { data } = await axiosInstance.get<TranslateResponse[]>(
    `${API_ROUTES.TRANSLATIONS}/${page}/${language}`
  );
  return data;
};

export const editNews = async (payload: FormData, { slug, id }: { slug: string; id: number }) => {
  const { data } = await axiosSyllabusInstance.post(`/api/${slug}/${id}`, payload);
  return data;
};

export const getCourses = async (language: string) => {
  const { data } = await axiosInstance.get<AllCoursesExtendedResponse>(
    `${API_ROUTES.ALL_COURSES}`,
    {
      params: {
        language,
        pageSize: 50
      }
    }
  );
  return data;
};

export const getSubCourses = async (language: string) => {
  const { data } = await axiosInstance.get<SpecificCourseResponse>(
    `${API_ROUTES.ALL_SUB_COURSES}/${language}`,
    { params: { pageSize: 50 } }
  );
  return data;
};

export const addEvent = async (payload: AddEventPayload, lang: string) =>
  axiosSyllabusInstance.post(API_ROUTES.CALENDAR, payload, {
    headers: {
      lang
    }
  });

export const updateEvent = async (eventId: number, params: AddEventPayload, lang: string) =>
  axiosSyllabusInstance.put(`${API_ROUTES.CALENDAR}/${eventId}`, params, {
    headers: {
      lang
    }
  });

export const deleteEvent = async (eventId: any, lang: string) => {
  const { data } = await axiosSyllabusInstance.delete(`${API_ROUTES.CALENDAR}/${eventId}`, {
    headers: {
      lang
    }
  });
  return data;
};

export const getEvents = async (lang: string) => {
  const { data } = await axiosSyllabusInstance.get(`${API_ROUTES.CALENDAR}`, {
    headers: {
      lang
    }
  });
  return data;
};

export const getUpcomingEvents = async (lang: string) => {
  const { data } = await axiosSyllabusInstance.get(`${API_ROUTES.UPCOMING_EVENTS}`, {
    headers: {
      lang
    }
  });
  return data;
};

export const googleSyncRedirect = () => axiosSyllabusInstance.get(`${API_ROUTES.O_AUTH}`);

export const getStudentCourse = async (params: SylabussParams, lang: string) => {
  const { data } = await axiosSyllabusInstance.get<CourseMonthResponse>(API_ROUTES.COURSE_DETAILS, {
    params,
    headers: {
      lang
    }
  });
  return data;
};

export const getProgress = async (lang: string, params: ProgressPayload) => {
  const { data } = await axiosSyllabusInstance.get<number>(API_ROUTES.PROGRESS, {
    params,
    headers: {
      lang
    }
  });
  return data;
};

export const getCurrentSubject = async (lang: string) => {
  const { data } = await axiosSyllabusInstance.get(API_ROUTES.CURRENT_SUBJECT, {
    headers: {
      lang
    }
  });
  return data;
};

export const registerUserOnSubGroup = async (payload: RegisterOnSubCoursePayload) => {
  await axiosInstance.post<void>(API_ROUTES.REGISTER_USER_ON_SUBGROUP, payload);
};

export const getInvoicePdf = async () => {
  const { data } = await axiosInstance.get('/api/Account/DownloadInvoice', {
    headers: {
      accept: 'application/pdf'
    },
    responseType: 'blob'
  });
  return data;
};

export const uploadKahootRating = async (payload: FormData) => {
  const { data } = await axiosSyllabusInstance.post<UploadRatingResponse>(
    API_ROUTES.UPLOAD_RATING,
    payload
  );
  return data;
};

export const getKahootRating = async (params: RatingParams) => {
  const { data } = await axiosSyllabusInstance.get<KahootRatingResponse>(API_ROUTES.KAHOOT_RATING, {
    params
  });
  return data;
};

export const getDoneSubjects = async () => {
  const { data } = await axiosSyllabusInstance.get<SubjectDoneResponse>(API_ROUTES.SUBJECT_DONE);
  return data;
};

export const updateSubjectTitle = async (subjectId: number, payload: any) => {
  await axiosSyllabusInstance.put<void>(`${API_ROUTES.SUBJECT_DETAILS}/${subjectId}`, payload);
};

export const updateSubjectStatus = async (payload: SubjectStatusPayload, doneId?: number) => {
  const { data } = await axiosSyllabusInstance.post<{ success?: boolean }>(
    `${API_ROUTES.SUBJECT_STATUS}${doneId ? `/${doneId}` : ''}`,
    payload
  );
  return data;
};

export const editSubjectFile = async (fileId: number, payload: EditSubjectPayload) => {
  const { data } = await axiosSyllabusInstance.post(
    `${API_ROUTES.SUBJECT_FILES}/${fileId}`,
    payload
  );
  return data;
};

export const addSubjectFiles = async (payload: FormData) => {
  const { data } = await axiosSyllabusInstance.post<{ success?: boolean }>(
    API_ROUTES.SUBJECT_FILES,
    payload
  );
  return data;
};

export const deleteSubjectFile = async (fileId: number, lang: string) => {
  const { data } = await axiosSyllabusInstance.delete(`${API_ROUTES.SUBJECT_FILES}/${fileId}`, {
    headers: {
      lang
    }
  });
  return data;
};

export const syncCalendar = async (code: string) => {
  const data = await axiosSyllabusInstance.get(API_ROUTES.CALENDAR_SYNC, {
    params: {
      code
    }
  });
  return data;
};

export const addTag = async (payload: AddTagPayload) => {
  await axiosSyllabusInstance.post(API_ROUTES.TAGS, payload);
};

export const removeTag = async (id: string) => {
  await axiosSyllabusInstance.delete(`${API_ROUTES.TAGS}/${id}`);
};
export const removeBlog = async (id: string) => {
  await axiosSyllabusInstance.delete(`${API_ROUTES.BLOGS}/${id}`);
};
export const removeNews = async (id: string) => {
  await axiosSyllabusInstance.delete(`${API_ROUTES.NEWS}/${id}`);
};

export const addSubject = async (payload: AddSubjectPayload) => {
  await axiosSyllabusInstance.post(API_ROUTES.SUBJECT_DETAILS, payload);
};

export const getSubjects = async (params: SubjectParams, lang: string) => {
  const data = await axiosSyllabusInstance.get(API_ROUTES.SUBJECT_DETAILS, {
    params,
    headers: {
      lang
    }
  });
  return data;
};

export const deleteSubject = async (subjectId: number, lang: string) => {
  const { data } = await axiosSyllabusInstance.delete(
    `${API_ROUTES.SUBJECT_DETAILS}/${subjectId}`,
    {
      headers: {
        lang
      }
    }
  );

  return data;
};

export const addMonth = async (payload: AddMonthPayload, lang: string) => {
  const { data } = await axiosSyllabusInstance.post(API_ROUTES.COURSE_DETAILS, payload, {
    headers: {
      lang
    }
  });

  return data;
};

export const deleteMonth = async (month: number, params: { chapter_id: number }, lang: string) => {
  const { data } = await axiosSyllabusInstance.delete(`${API_ROUTES.COURSE_DETAILS}/${month}`, {
    params,
    headers: {
      lang
    }
  });
  return data;
};

export const addWeek = async (payload: AddWeekPayload, lang: string) => {
  const { data } = await axiosSyllabusInstance.post(API_ROUTES.SUBJECT_WEEK, payload, {
    headers: {
      lang
    }
  });

  return data;
};

export const deleteWeek = async (week: number, params: { month_id: number }, lang: string) => {
  const { data } = await axiosSyllabusInstance.delete(`${API_ROUTES.SUBJECT_WEEK}/${week}`, {
    params,
    headers: {
      lang
    }
  });
  return data;
};

export const changeWeekPosition = async (weekId: number, params: AddWeekPayload, lang: string) => {
  const { data } = await axiosSyllabusInstance.put(`${API_ROUTES.SUBJECT_WEEK}/${weekId}`, params, {
    headers: {
      lang
    }
  });

  return data;
};

export const getCareerContent = async (course_id: string | null, lang: string) => {
  const { data } = await axiosSyllabusInstance.get<CareerContentPayload>(API_ROUTES.CAREER_CARE, {
    params: { course_id },
    headers: { lang }
  });

  return data;
};

export const getContentDetails = async (id: string, lang: string) => {
  const { data } = await axiosSyllabusInstance.get(`${API_ROUTES.CAREER_CARE}/${id}`, {
    headers: {
      lang
    }
  });

  return data;
};

export const createCareerContent = async (payload: FormData, lang: string) => {
  const { data } = await axiosSyllabusInstance.post(API_ROUTES.CAREER_CARE, payload, {
    headers: {
      lang
    }
  });

  return data;
};

export const editCareerContent = async (payload: FormData, id: string, lang: string) => {
  const { data } = await axiosSyllabusInstance.put(`${API_ROUTES.CAREER_CARE}/${id}`, payload, {
    headers: {
      lang
    }
  });

  return data;
};

export const deleteCareerContent = async (id: number) => {
  const { data } = await axiosSyllabusInstance.delete<{ success?: boolean }>(
    `${API_ROUTES.CAREER_CARE}/${id}`
  );

  return data;
};

export const cancelCourseContent = async (id: number) => {
  const { data } = await axiosSyllabusInstance.delete<{ success?: boolean }>(
    `${API_ROUTES.COURSE_CONTENT}/${id}`
  );

  return data;
};

export const publishCareerContent = async (
  id: number,
  payload: PublishContentPayload,
  lang: string
) => {
  const { data } = await axiosSyllabusInstance.post(`${API_ROUTES.CAREER_PUBLISH}/${id}`, payload, {
    headers: {
      lang
    }
  });

  return data;
};

export const editContentInfo = async (
  id: number,
  payload: EditCourseContentPayload,
  lang: string
) => {
  const { data } = await axiosSyllabusInstance.put(`${API_ROUTES.COURSE_CONTENT}/${id}`, payload, {
    headers: {
      lang
    }
  });

  return data;
};

export const uploadEditorImage = async (image: FormData) => {
  const { data } = await axiosSyllabusInstance.post(API_ROUTES.UPLOAD_IMAGE, image);

  return data;
};

export const addCriteria = async (payload: AddCriteriaPayload) => {
  const { data } = await axiosSyllabusInstance.post(API_ROUTES.CRITERIA, payload);

  return data;
};

export const deleteCriteria = async (id: number) => {
  const { data } = await axiosSyllabusInstance.delete(`${API_ROUTES.CRITERIA}/${id}`);

  return data;
};

export const editCriteria = async (payload: AddCriteriaPayload, id: string) => {
  const { data } = await axiosSyllabusInstance.put(`${API_ROUTES.CRITERIA}/${id}`, payload);

  return data;
};

export const getCategoriesList = async (lang: string) => {
  const { data } = await axiosSyllabusInstance.get(`${API_ROUTES.CRITERIA}`, {
    headers: { lang },
    params: { per_page: 50 }
  });

  return data;
};

export const getCriteria = async (id: string, lang: string) => {
  const { data } = await axiosSyllabusInstance.get(`${API_ROUTES.CRITERIA}/${id}/edit`, {
    headers: { lang }
  });

  return data;
};
