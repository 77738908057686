import { breakPoints } from 'src/theme/defaultTheme';
import styled from 'styled-components';

export const Container = styled.div`
  width: 516px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 70px 32px 70px;
  max-width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  @media (max-width: ${breakPoints.mb3}px) {
    padding: 32px 16px 16px 16px;
  }
`;

export const ModalActions = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  @media (max-width: ${breakPoints.mb2}px) {
    button {
      height: 44px;
    }
  }
`;
