import React, { FC } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import {
  AssignHeader,
  StyledCard,
  AssignContent,
  Dot,
  Status,
  StatusBox,
  AssignButton,
  AssignDetails
} from './styles';
import assignImage from 'src/assets/icons/redesign-card.svg';
import { Homework } from 'src/models/service';
import useDetectDevice from 'src/hooks/useDetectDevice';
import useProfile from 'src/hooks/queries/useProfile';
import { useTranslation } from 'react-i18next';
import { actualDate } from 'src/utils';
import { ReactComponent as PlusIcon } from 'src/assets/icons/content.svg';
import { ContentCircle } from 'src/views/careerCare/styles';
import { ReactComponent as DeleteIcon } from 'src/assets/icons/delete.svg';
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
interface Props {
  clickHandler?: () => void;
  width?: string;
  homework?: Homework;
  isAdmin?: boolean;
  isTemplate?: boolean;
  setShowAlertModal: () => void;
  setId: (id: number) => void;
  title?: string;
  recievedLink?: string;
}

const AssignCard: FC<Props> = ({
  width,
  clickHandler,
  isAdmin,
  isTemplate,
  homework,
  setShowAlertModal,
  setId,
  title,
  recievedLink = ''
}) => {
  const { t } = useTranslation();
  const { isMobile } = useDetectDevice();
  const { data: profile } = useProfile();
  const navigate = useNavigate();

  const myHomework = homework?.assignments.find((a) => a.user_email === profile?.email);

  if (isTemplate) {
    return (
      <StyledCard width={width} onClick={() => clickHandler && clickHandler()}>
        <Box
          position="absolute"
          top="50%"
          left="50%"
          display="flex"
          sx={{ transform: 'translate(-50%, -50%)' }}
          justifyContent="center"
          flexDirection="column"
          alignItems="center">
          <ContentCircle>
            <PlusIcon />
          </ContentCircle>
          <Typography fontWeight="bold" textTransform="uppercase" mt={3} textAlign="center">
            {t('button.newAssign')}
          </Typography>
        </Box>
      </StyledCard>
    );
  }

  return (
    <StyledCard width={width} onClick={() => clickHandler && clickHandler()}>
      <AssignHeader width={width}>
        <img
          style={{ width: isMobile ? '25%' : 'auto' }}
          src={assignImage}
          alt="assign card logo"
        />
      </AssignHeader>
      <AssignContent>
        <Typography
          overflow="hidden"
          width={'95%'}
          variant="subtitle4"
          fontSize={20}
          fontWeight="bold"
          color={'#CDDB00'}
          maxWidth="300px"
          // height="64px"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          mb={1}
          sx={{ WebkitLineClamp: 3 }}>
          {homework?.subject}
        </Typography>

        {!isAdmin &&
          (myHomework ? (
            <StatusBox color="#00CE7C" opacity={'0.75'}>
              ({t('labels.submitted')})
            </StatusBox>
          ) : (
            <StatusBox color="#FE3B1F" opacity={'0.75'}>
              ({t('labels.toDo')})
            </StatusBox>
          ))}

        {!isAdmin && homework && homework?.points > 0 && (
          <Typography
            position={'absolute'}
            top={'15px'}
            right={'15px'}
            fontWeight={'regular'}
            color={'black'}
            ml={0.5}
            mt={-0.5}
            fontSize={{ xs: 15, sm: 16 }}>
            <span style={{ fontWeight: 500 }}>
              {myHomework?.score ?? 0} / {homework?.points}
            </span>
          </Typography>
        )}

        <Typography
          color="text.primary"
          variant="subtitle4"
          fontSize={{ xs: '14px', sm: '16px' }}
          fontWeight={'medium'}
          mt={{ xs: -0.5, sm: 1 }}
          component={Box}>
          {t('labels.due_date_card')}
        </Typography>
        <Typography
          color="text.primary"
          variant="subtitle4"
          fontSize={{ xs: '13px', sm: '16px' }}
          mt={-1}
          mb={6}
          component={Box}>
          {homework && actualDate(homework.due_date!).split(' ')[0] + ' 23:59:00'}
        </Typography>
        {isAdmin && homework?.estimate_time && (
          <Typography fontWeight="normal" fontSize={16} mt={-4} component={Box}>
            <strong>{t('labels.estimation')}:</strong> {homework?.estimate_time}
          </Typography>
        )}
      </AssignContent>

      <AssignDetails>
        {isAdmin ? (
          <>
            <AssignButton
              onClick={(e) => {
                e.stopPropagation();
                navigate(recievedLink);
              }}>
              {t('button.list')}
            </AssignButton>
            <Button
              sx={{
                height: '38px',
                padding: '0px 24px',
                border: '1px solid black',
                borderRadius: '50px',
                marginLeft: '8px'
              }}>
              <Typography
                fontSize="14px !important"
                textTransform="uppercase"
                sx={{ fontFeatureSettings: "'case' on" }}>
                {t('button.details')}
              </Typography>
            </Button>
          </>
        ) : (
          <AssignButton>{t('button.details')}</AssignButton>
        )}
      </AssignDetails>

      <Status>
        <Dot />
        <Typography
          textTransform={'capitalize'}
          fontSize={'14px'}
          color={'#00CE7C'}
          fontWeight="regular">
          {homework?.status === 'active' ? t('labels.active') : t('labels.finished')}
        </Typography>
      </Status>
      {isAdmin && (
        <Box position="absolute" top="10px" right="15px">
          <DeleteIcon
            onClick={(e) => {
              e.stopPropagation();
              setId(homework!.id!);
              setShowAlertModal();
            }}
          />
        </Box>
      )}
    </StyledCard>
  );
};

export default AssignCard;
