import React from 'react';
import { useAuth } from 'src/context/authContext';
import AdminHomeWork from './AdminHomeWork';
import UserHomeWork from './UserHomeWork';

const Homework: React.FC = () => {
  const { isAdmin } = useAuth();

  return <>{isAdmin ? <AdminHomeWork /> : <UserHomeWork />}</>;
};

export default Homework;
