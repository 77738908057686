import React from 'react';
import useToggle from 'src/hooks/useToggle';
import Logo from 'src/components/common/logo/Logo';
import { Grid } from '@mui/material';
import LogoutModal from 'src/components/modals/LogoutModal';
import { Box } from '@mui/system';

interface Props {
  children: React.ReactNode;
}

const LeftMenu: React.FC<Props> = ({ children }) => {
  const [modalOpen, setModalOpen] = useToggle();

  return (
    <Grid
      item
      py={'35px'}
      display="flex"
      position="relative"
      alignItems="center"
      flexDirection="column"
      justifyContent="space-between"
      sx={{ minHeight: 'calc(100vh - 92px)' }}>
      <Box
        width={'100%'}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        position={'relative'}>
        <Grid>
          <Box display={'flex'} justifyContent={'center'}>
            <Logo />
          </Box>
          <Grid mt={'64px'}>{children}</Grid>
        </Grid>
        <LogoutModal open={modalOpen} close={setModalOpen} />
      </Box>
    </Grid>
  );
};

export default LeftMenu;
