import React, { FC } from 'react';
import { Backdrop, Grid, IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useCalendar } from 'src/context/calendarContext';
import { useAuth } from 'src/context/authContext';
import { IEvent } from 'src/models/general';
import moment from 'moment';
import { DetailsModal } from './styled';
import useExtendedCourses from 'src/hooks/queries/useExtendedCourses';
import { useLanguage } from 'src/context/languageContext';
import { TiTimes } from 'react-icons/ti';
import { ReactComponent as CameraIcon } from 'src/assets/icons/camera.svg';
import { ReactComponent as CloseIcon } from 'src/assets/icons/X.svg';

interface EventDetailModalProps {
  event: IEvent;
  title: string;
  onClose: () => void;
  onEventDelete: () => void;
}

const EventDetailModal: FC<EventDetailModalProps> = ({ event, title, onEventDelete, onClose }) => {
  const { isAdmin } = useAuth();
  const { t } = useTranslation();
  const { locale } = useLanguage();

  const { comment, createdByAdmin, description, start, end, estimation } = event || {};
  const { eventDetailModalShown, setAddEventModalShown, setEventToEdit } = useCalendar();

  const groupId = event?.calendarGroup?.group_id;
  const { courses = [] } = useExtendedCourses();

  const eventCourse = courses.find((course) => course?.groups[0]?.id === Number(groupId));

  const eventEndHour = moment(end).format('LT');
  const date = moment(start)
    .locale(locale === 'en' ? 'en-gb' : 'ka')
    .format('LLLL')
    .concat(` - ${eventEndHour}`);

  const onEditEventHandler = () => {
    setEventToEdit(event);
    setAddEventModalShown(true);
    onClose();
  };

  const canUserEditEvent = isAdmin || !createdByAdmin;

  return (
    <Backdrop open={eventDetailModalShown} onClick={close} sx={{ zIndex: 100 }}>
      <DetailsModal onClick={(e) => e.stopPropagation()}>
        <IconButton sx={{ position: 'absolute', right: '0', zIndex: 1 }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Grid
          gap={1}
          container
          paddingY={4}
          direction="column"
          alignItems="center"
          position={'relative'}
          justifyContent="center"
          sx={{
            alignItems: 'baseline'
          }}
          paddingX={{ sm: '44px', xs: '16px' }}>
          <Typography
            fontFamily={'basenji-variable'}
            color={'#2A7DE1'}
            fontWeight="bold"
            fontSize={24}
            maxWidth={400}
            mb={-1}>
            {title}
          </Typography>

          {eventCourse?.name && (
            <Typography
              fontSize={16}
              fontWeight="bold"
              textTransform="uppercase"
              maxWidth={400}
              mt={{ sm: 3, xs: 2 }}>
              {eventCourse.name}
            </Typography>
          )}
          <Typography fontSize={{ xs: 14, mb3: 16 }} mt={1} fontWeight="bold">
            {date}
          </Typography>
          <Typography marginTop={1} fontSize={14} fontWeight="normal">
            {comment}
          </Typography>
          {isAdmin && estimation && (
            <Typography mt={1} fontSize={14} fontWeight="normal">
              {t('labels.estimation')}: {estimation}
            </Typography>
          )}
          {description && (
            <Box display="flex" alignItems="center" gap={1} marginY={2}>
              <CameraIcon />
              <Typography
                component="a"
                target="_blank"
                color={'#2A7DE1'}
                href={description}
                fontSize={{ xs: 16 }}
                fontWeight={'regular'}
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
                {t('labels.eventLink')}
              </Typography>
            </Box>
          )}
          <Box display="flex" alignItems="center" marginTop={2} gap={1}>
            <Button fullWidth variant="primary" onClick={onClose}>
              <Typography>{t('modal.cancel')}</Typography>
            </Button>
            {canUserEditEvent && (
              <Button variant="secondary" fullWidth onClick={onEventDelete}>
                <Typography>{t('modal.delete')}</Typography>
              </Button>
            )}
          </Box>
        </Grid>
      </DetailsModal>
    </Backdrop>
  );
};

export default EventDetailModal;
