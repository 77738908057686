import React from 'react';
import Navigate from 'src/components/common/navigate';
import { Outlet, Route, Routes } from 'react-router-dom';

import Login from 'src/views/login/Login';
import Profile from 'src/views/profile';
import ResendPasswordPage from 'src/views/resendPasswordPage/ResendPasswordPage';
import ResetPassword from 'src/views/resetPassword/ResetPassword';
import RestorePassword from 'src/views/restorePassword/ResetPassword';
import VerifyEmailPage from 'src/views/verifyEmailPage';

import Course from 'src/components/templates/Course';
import AxiosInterceptor from 'src/helpers/AxiosInterceptor';
import CourseCalendar from 'src/views/courseCalendar';
import CourseInvoice from 'src/views/courseInvoice';

import PageLayout from 'src/components/layouts/PageLayout';
import UnAuthorizedLayout from 'src/components/layouts/UnAuthorizedLayout';
import ChangePasswordForm from 'src/components/UI/forms/ChangePassword';
import EditProfileForm from 'src/components/UI/forms/EditProfile';
import UserContactInfo from 'src/components/UI/profile/UserContactInfo';
import UserStatus from 'src/components/UI/profile/UserStatus';
import CalendarContextProvider from 'src/context/calendarContext';
import MonthContextProvider from 'src/context/monthContext';
import ChoosedSpecialization from 'src/views/choosedSpecialization';
import ChooseSpecialization from 'src/views/chooseSpecialization';
import EditSpecialization from 'src/views/editSpecialization';
import GeneralCourse from 'src/views/generalCourse';
import ModifyGeneral from 'src/views/modifyGeneral';
import Specialization from 'src/views/specialization';
import SpecificChapter from 'src/views/specificChapter';
import LanguageProvider from 'src/context/languageContext';
import PaymentSuccess from 'src/views/paymentSuccess';
import PaymentFail from 'src/views/paymentFail';
import Assignments from 'src/views/assignments';
import AssignmentList from 'src/views/assignmentList';
import Homework from 'src/views/homework';
import AddHomework from 'src/views/addHomework';
import CareerCare from 'src/views/careerCare';
import CreateCareerModule from 'src/views/createCareerModule';
import PaymentInProgress from 'src/views/paymentInProgress';
import Assignment from 'src/views/assignment';
import AuthorizedLayout from 'src/components/layouts/AuthorizedLayout';
import CareerContent from 'src/views/careerContent';
// import News from 'src/views/news';
import BlogAndNewsDetails from 'src/views/blogAndNewsDetails';
import OceanNews from 'src/views/oceanNews';
import AddNewsForm from 'src/components/UI/forms/AddNewsForm';
import DetailedNews from 'src/views/detailedNews/DetailedNews';
import CareerList from 'src/views/careerList';
import News from 'src/views/news';
import Categories from 'src/views/categories';

const AppRouter = () => {
  return (
    <Routes>
      <Route path=":locale" element={<LanguageProvider />}>
        <Route
          element={
            <AxiosInterceptor>
              <Outlet />
            </AxiosInterceptor>
          }>
          <Route element={<UnAuthorizedLayout />}>
            <Route path="" element={<Login />} />
            <Route path="login" element={<Login />} />
            <Route path="verify-email" element={<VerifyEmailPage />} />
            <Route path="restore-password" element={<RestorePassword />} />
            <Route path="restore-password/resend" element={<ResendPasswordPage />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="buy-course/fail" element={<PaymentFail />} />
            <Route path="buy-course/success" element={<PaymentSuccess />} />
            <Route path="bog-payment-redirect" element={<PaymentInProgress />} />
          </Route>
          <Route element={<PageLayout />}>
            <Route path="course-invoice/:courseId" element={<CourseInvoice />} />
          </Route>
          <Route element={<AuthorizedLayout />}>
            <Route
              path="my-course"
              element={
                <MonthContextProvider>
                  <Course />
                </MonthContextProvider>
              }>
              <Route index element={<GeneralCourse />} />
              <Route path="general" element={<ModifyGeneral />} />
              <Route path="specific" element={<Specialization />} />
              <Route path="specific/choose-chapter" element={<ChooseSpecialization />} />
              <Route path="specific/edit-chapter" element={<EditSpecialization />} />
              <Route path="specific/choosed-chapter" element={<ChoosedSpecialization />} />
              <Route path="specific/chapter/:chapterId" element={<SpecificChapter />} />
            </Route>
            <Route path="profile" element={<Profile />}>
              <Route index element={<UserContactInfo />} />
              <Route path="edit" element={<EditProfileForm />} />
              <Route path="change-password" element={<ChangePasswordForm />} />
              <Route path="status" element={<UserStatus />} />
            </Route>
            <Route path="assignments" element={<Assignments />} />
            <Route path="assignments/:courseId" element={<AssignmentList />} />
            <Route path="assignments/homework/:homeworkId" element={<Homework />} />
            <Route path="assignments/add-homework/:courseId" element={<AddHomework />} />
            <Route path="assignments/add-assignment/:homeworkId" element={<Assignment />} />
            <Route path="career-care" element={<CareerCare />} />
            <Route path="career-content" element={<CareerContent />} />
            <Route path="career-care/create-content" element={<CreateCareerModule />} />
            <Route path="career-list/:contentId" element={<CareerList />} />
            <Route path="ocean-news" element={<OceanNews />} />
            <Route path="ocean-news/add-news" element={<AddNewsForm />} />
            <Route path="ocean-news/:newsType/:id" element={<DetailedNews />} />
            <Route path="content-categories" element={<Categories />} />
            <Route
              path="calendar"
              element={
                <CalendarContextProvider>
                  <CourseCalendar />
                </CalendarContextProvider>
              }
            />
          </Route>
        </Route>
        <Route path="health" element={<div>OK</div>} />
        <Route path="*" element={<Navigate to="" replace />} />
      </Route>
    </Routes>
  );
};

export default AppRouter;
