import React, { SyntheticEvent } from 'react';
import { Autocomplete, Grid, Tab, Tabs, TextField } from '@mui/material';
import { To, useLocation, useParams } from 'react-router-dom';
import useProfile from 'src/hooks/queries/useProfile';
import useCourses from 'src/hooks/queries/useCourses';
import { useTranslation } from 'react-i18next';
import MobileHeader from '../header/MobileHeader';
import { useAuth } from 'src/context/authContext';
import { useLanguage, useNavigate } from 'src/context/languageContext';
import useQueryString from 'src/hooks/useQueryString';

export interface AuthWrapperTab {
  title: { en: string; ge: string } | any;
  admin?: boolean;
  user?: boolean;
  path?: string;
}

interface Props {
  children: React.ReactNode;
  tabs?: any[];
  userPage?: boolean;
  calendar?: string;
  isCourses?: boolean;
  isProfile?: boolean;
  isCareerCare?: boolean;
  isAssignment?: boolean;
  action?: React.Dispatch<React.SetStateAction<string>>;
}

const AuthWrapper = ({
  tabs,
  action,
  children,
  userPage,
  calendar,
  isCourses,
  isCareerCare,
  isAssignment
}: Props) => {
  const { isAdmin } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { locale } = useLanguage();
  const { myCourses = [] } = useProfile();
  const { courses = [] } = useCourses();
  const { courseId } = useParams();
  const { pathname, search } = useLocation();

  const { parseQuery } = useQueryString();
  const { chapterId = '' } = parseQuery();

  const tabChangeHandler = (_: SyntheticEvent, val: To) => navigate(val);
  const tabCalendarChangeHandler = (_: SyntheticEvent, val: string) => action!(val);
  const courseChangeHandler = (_: SyntheticEvent, val: To) => navigate(`/my-course/general${val}`);

  const findName = (id: number) => courses?.find((el) => el.id === id)?.name;

  const options =
    courses
      ?.sort((a, b) => b.id - a.id)
      .map((el) => {
        return { label: el.name, value: el.id };
      }) || null;

  const tabStyles = {
    height: '56px',
    fontSize: '20px !important',
    paddingX: '24px !important',
    marginRight: '24px !important',
    background: '#E5E5E5 !important',
    '&.Mui-selected': {
      background: '#2A7DE1 !important'
    }
  };

  return (
    <Grid
      item
      xs={12}
      mt={'22px'}
      px={{ xs: 2, sm: 3 }}
      ml={{ sm: '15px', l: '0px' }}
      minHeight={{ sm: '110vh', xs: '130vh' }}>
      <Grid
        container
        minHeight="64px"
        alignItems="center"
        flexWrap="nowrap"
        justifyContent="space-between">
        <Grid maxWidth={{ md: 830, l: 900 }}>
          {isAssignment && isAdmin && (
            <Autocomplete
              id="course"
              fullWidth
              autoHighlight
              sx={{ width: 400 }}
              options={options || []}
              value={options?.find((item) => item.value === +courseId!) || null}
              noOptionsText={null}
              onChange={(_, course) => {
                if (course?.value) {
                  navigate(`/assignments/${course?.value}`);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  name="course"
                  label={t('labels.chooseCourse')}
                />
              )}
            />
          )}

          {isAssignment && !isAdmin && (
            <Tabs
              variant={isAdmin ? 'scrollable' : 'fullWidth'}
              value={pathname.slice(3) + search}
              onChange={tabChangeHandler}
              sx={{ display: { xs: 'none', sm: 'flex' } }}>
              {myCourses?.map((c) => (
                <Tab
                  key={c.courseId}
                  value={`/assignments/${c.courseId}`}
                  label={findName(c.courseId)}
                />
              ))}
            </Tabs>
          )}

          {isCareerCare && (
            <Tabs
              value={pathname.slice(3) + search}
              variant="fullWidth"
              onChange={tabChangeHandler}
              sx={{ display: { xs: 'none', sm: 'flex' } }}>
              {userPage
                ? myCourses?.map((c) => (
                    <Tab
                      key={c.courseId}
                      value={`/career-content?courseId=${c.courseId}`}
                      label={findName(c.courseId)}
                    />
                  ))
                : tabs?.map((tab) => (
                    <Tab key={tab.path} value={tab.path} label={tab.name} sx={tabStyles} />
                  ))}
            </Tabs>
          )}

          {calendar && (
            <Tabs
              value={calendar}
              variant="fullWidth"
              onChange={tabCalendarChangeHandler}
              sx={{ display: { xs: 'none', sm: 'flex' } }}>
              <Tab value={'timeGridDay'} label={t('calendar.day')} sx={tabStyles} />
              <Tab value={'timeGridWeek'} label={t('calendar.week')} sx={tabStyles} />
              <Tab value={'dayGridMonth'} label={t('calendar.month')} sx={tabStyles} />
            </Tabs>
          )}

          {isCourses && isAdmin && (
            <Autocomplete
              id="course"
              fullWidth
              autoHighlight
              sx={{ width: 400 }}
              options={options || []}
              value={options?.find((item) => item.value === +chapterId) || null}
              noOptionsText={null}
              onChange={(_, course) => {
                if (course?.value) {
                  navigate(`/my-course/general?chapterId=${course?.value}`);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  name="course"
                  label={t('labels.chooseCourse')}
                />
              )}
            />
          )}

          {isCourses && !isAdmin && (
            <Tabs
              value={search}
              scrollButtons="auto"
              allowScrollButtonsMobile
              onChange={courseChangeHandler}
              sx={{ display: { xs: 'none', sm: 'flex' } }}
              variant={isAdmin ? 'scrollable' : 'fullWidth'}>
              {myCourses?.map((c) => (
                <Tab
                  key={c.courseId}
                  label={findName(c.courseId)}
                  value={`?course=${c.courseId}`}
                />
              ))}
            </Tabs>
          )}

          {!isAssignment && !calendar && !isCareerCare && !isCourses && (
            <Tabs
              variant="fullWidth"
              onChange={tabChangeHandler}
              value={pathname.slice(3) + search}
              sx={{ display: { xs: 'none', sm: 'flex' } }}>
              {tabs?.map((tab) => (
                <Tab
                  key={tab.path}
                  value={tab.path}
                  label={tab.title[locale]}
                  disabled={tab.path === '/profile/status' && Boolean(!myCourses[0]?.courseId)}
                  sx={tabStyles}
                />
              ))}
            </Tabs>
          )}
        </Grid>

        <MobileHeader />
      </Grid>

      <Grid mt={4}>{children}</Grid>
    </Grid>
  );
};

export default AuthWrapper;
