import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'src/enums';
import { AllCoursesExtendedResponse } from 'src/models/service';
import { getCourses } from 'src/services/api';
import useLanguageCode from '../useLanguageCode';

const useExtendedCourses = (enabled = true) => {
  const { langCode } = useLanguageCode();
  const { data, isLoading } = useQuery<AllCoursesExtendedResponse>(
    [QUERY_KEYS.COURSES, langCode],
    () => getCourses(langCode),
    { enabled }
  );

  return {
    data,
    courses: data?.data,
    isLoading
  };
};

export default useExtendedCourses;
