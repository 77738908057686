import { Grid, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLanguage } from 'src/context/languageContext';
import TagTab from './TagTab';
import NewsList from './NewsList';
import BlogList from './BlogList';

function a11yProps(index: number) {
  return {
    id: `course-tab-${index}`,
    'aria-controls': `course-tabpanel-${index}`
  };
}

const OceanNews: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { locale } = useLanguage();
  const [value, setValue] = useState(0);
  const [srch] = useSearchParams();

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    const tabName = newValue === 0 ? 'news' : newValue === 1 ? 'blog' : 'tags';
    setValue(newValue);
    navigate(`/${locale}/ocean-news?section=${tabName}`);
  };

  return (
    <React.Fragment>
      {/* TABS */}
      <Grid container justifyContent="start" maxWidth="1920px" margin="auto">
        <Grid item xs={12} marginTop={{ xs: 3, m: 5 }} paddingX={{ xs: 2, sm: 10, l: 18 }}>
          <Tabs value={value} variant="fullWidth" onChange={handleChange} aria-label="course tabs">
            <Tab
              label={t('labels.news')}
              {...a11yProps(0)}
              sx={{
                marginRight: '30px',
                fontWeight: 'semibold !important',
                fontSize: '16px',
                '&.Mui-selected': {
                  background: 'black',
                  color: 'white'
                }
              }}
            />
            <Tab
              label={t('labels.blogs')}
              {...a11yProps(1)}
              sx={{
                marginRight: '30px',
                fontWeight: 'semibold !important',
                fontSize: '16px',
                '&.Mui-selected': {
                  background: 'black',
                  color: 'white'
                }
              }}
            />
            <Tab
              label={t('labels.tags')}
              {...a11yProps(1)}
              sx={{
                marginRight: '30px',
                fontWeight: 'semibold !important',
                fontSize: '16px',
                '&.Mui-selected': {
                  background: 'black',
                  color: 'white'
                }
              }}
            />
          </Tabs>
        </Grid>
      </Grid>

      {srch.get('section') === 'news' ? (
        <NewsList />
      ) : srch.get('section') === 'blog' ? (
        <BlogList />
      ) : (
        <TagTab />
      )}
    </React.Fragment>
  );
};

export default OceanNews;
