import { AxiosError } from 'axios';
import { QUERY_KEYS } from 'src/enums';
import { updateSubjectStatus } from 'src/services/api';
import { SubjectStatusPayload } from 'src/models/service';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const useFinishSubject = () => {
  const client = useQueryClient();
  const { mutate, isLoading } = useMutation<
    { success?: boolean },
    AxiosError,
    { payload: SubjectStatusPayload; doneId?: number }
  >(['finish-subject'], ({ payload, doneId }) => updateSubjectStatus(payload, doneId), {
    onSettled: () => {
      client.invalidateQueries([QUERY_KEYS.DONE_SUBJECTS]);
      client.invalidateQueries([QUERY_KEYS.PROGRESS]);
    }
  });

  return {
    mutate,
    isLoading
  };
};

export default useFinishSubject;
