import React from 'react';
import TopHeader from '../topHeader';
import Header from '../header/Header';
import Grid from '@mui/material/Grid';
import Footer from '../footer/Footer';
import { useAuth } from 'src/context/authContext';
import { Wrapper, WrapperContent } from './styles';
import Navigate from 'src/components/common/navigate';
import { Outlet, useLocation } from 'react-router-dom';

const UnAuthorizedLayout = () => {
  const { isLoggedIn } = useAuth();
  const { pathname } = useLocation();

  const success = pathname.includes('buy-course/success');

  const brPs = {
    xs: 10,
    md: success ? 5 : 4,
    l: success ? 4 : 3.3,
    sm: success ? 5.5 : 4
  };

  if (isLoggedIn) return <Navigate to="/my-course/general" />;

  return (
    <Grid container>
      <Grid item sm={12} display={{ sm: 'block', xs: 'none' }}>
        <TopHeader />
        <Header className="over" />
      </Grid>
      <Grid item xs={12}>
        <Wrapper container mt={12}>
          <WrapperContent item {...brPs} padding={{ sm: 6, xs: 2 }}>
            <Outlet />
          </WrapperContent>
        </Wrapper>
      </Grid>
      <Grid item sm={12} display={{ sm: 'block', xs: 'none' }}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default UnAuthorizedLayout;
