import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Box,
  FormHelperText
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SUBJECT_FILE_STATUS } from 'src/enums';
import useAddFile from 'src/hooks/mutations/useAddFile';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import { addFilesSchema } from 'src/helpers/validations';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import useCategories from 'src/hooks/queries/useCategories';

interface Props {
  close: () => void;
  subjectId: number;
}

const AddFile = ({ close, subjectId }: Props) => {
  const { t } = useTranslation();
  const { mutate } = useAddFile();
  const [fileUploaded, setFileUploaded] = useState(false);
  const [error, setError] = useState(false);

  const { categories } = useCategories();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editorState: any) => setEditorState(editorState);

  const description = draftToHtml(convertToRaw(editorState.getCurrentContent()));
  const hasText = editorState.getCurrentContent().hasText();

  const formik = useFormik<any>({
    initialValues: {
      status: SUBJECT_FILE_STATUS.PRE,
      titleEn: '',
      titleKa: '',
      url: '',
      attachment: null,
      estimation: '',
      category: ''
    },
    validationSchema: addFilesSchema(t),
    onSubmit: async ({ status, titleKa, titleEn, url, attachment, estimation, category }) => {
      if (error) return;

      const fd = new FormData();
      if (attachment) fd.append('file[0][attachment]', attachment);
      if (url) fd.append(`file[0][url]`, url);
      if (hasText) fd.append(`file[0][description]`, description);
      if (estimation) fd.append(`file[0][estimate_time]`, estimation);

      fd.append(`file[0][status]`, status);
      fd.append('file[0][ka][title]', titleKa);
      fd.append('file[0][en][title]', titleEn);
      fd.append('file[0][evaluation_criteria][0]', category);
      // category.forEach((ctr: any, i: any) => {
      //   fd.append(`file[${i}][evaluation_criteria][${i}]`, ctr);
      // });
      fd.append('subject_id', subjectId.toString());

      mutate(fd, { onSuccess: () => close() });
    },
    validateOnBlur: false,
    validateOnChange: false
  });

  useEffect(() => {
    if (
      (formik.values.url && hasText) ||
      (hasText && formik.values.attachment) ||
      (formik.values.url && formik.values.attachment)
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }, [hasText, formik.values.url, formik.values.attachment]);

  return (
    <Grid px={2}>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <Grid container padding={2} spacing={2}>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="titleEn"
              variant="outlined"
              label={t('labels.titleEn')}
              value={formik.values.titleEn}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.titleEn)}
              helperText={formik.errors.titleEn}
              FormHelperTextProps={{
                error: Boolean(formik.errors.titleEn)
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              name="titleKa"
              variant="outlined"
              label={t('labels.titleGe')}
              value={formik.values.titleKa}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.titleKa)}
              helperText={formik.errors.titleKa}
              FormHelperTextProps={{
                error: Boolean(formik.errors.titleKa)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="text"
              name="url"
              variant="outlined"
              label={t('labels.url')}
              value={formik.values.url}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <InputLabel>{t('labels.category')}</InputLabel>
              <Select
                // multiple
                name="category"
                value={formik.values.category}
                variant="outlined"
                label={t('labels.category')}
                onChange={formik.handleChange}>
                {categories?.map((el: any) => {
                  return (
                    <MenuItem key={el} value={el.id}>
                      {el.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <InputLabel id="status">{t('labels.status')}</InputLabel>
              <Select
                variant="outlined"
                value={formik.values.status}
                name="status"
                label="Status"
                labelId={`status`}
                onChange={formik.handleChange}>
                {(Object.keys(SUBJECT_FILE_STATUS) as Array<keyof typeof SUBJECT_FILE_STATUS>).map(
                  (type) => (
                    <MenuItem value={SUBJECT_FILE_STATUS[type]} key={SUBJECT_FILE_STATUS[type]}>
                      {type}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="text"
              name="estimation"
              variant="outlined"
              label={t('labels.estimation')}
              onChange={formik.handleChange}
              value={formik.values.estimation}
              helperText={'Format: 1m, 2h, 3d...'}
            />
          </Grid>
          <Grid item xs={12} my={1} ml={0.5}>
            <Box display="flex" alignItems="center" gap={1}>
              <label htmlFor="attachment">
                <CloudUploadIcon sx={{ cursor: 'pointer' }} />
              </label>
              <Typography variant="listItem" fontSize={16} color="black">
                {fileUploaded ? t('messages.fileSelected') : t('button.upFile')}
              </Typography>
              {fileUploaded && (
                <CloseIcon
                  fontSize="small"
                  sx={{ cursor: 'pointer', marginTop: '-5px' }}
                  onClick={() => {
                    formik.setFieldValue('attachment', null);
                    setFileUploaded(false);
                  }}
                />
              )}
            </Box>
            <input
              hidden
              type="file"
              id="attachment"
              onChange={(e) => {
                if (!e.target.files) return;
                formik.setFieldValue('attachment', e.target.files[0]);
                setFileUploaded(true);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ border: '1px solid #e4e4e4' }}>
              <Editor
                editorState={editorState}
                editorClassName="demo-editor"
                wrapperClassName="demo-wrapper"
                editorStyle={{ paddingLeft: '15px' }}
                onEditorStateChange={onEditorStateChange}
              />
              <textarea disabled hidden value={description} />
            </Box>
          </Grid>
          {error && (
            <FormHelperText sx={{ margin: '18px' }} error>
              გთხოვთ, აირჩიოთ ერთ-ერთი ველი არსებული ველებიდან
            </FormHelperText>
          )}
          <Grid item xs={12} textAlign="center" mt={5} display="flex" justifyContent="flex-end">
            <Button type="submit" variant="primary">
              <Typography textTransform="uppercase">{t('button.submit')}</Typography>
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default AddFile;
