import { AxiosError } from 'axios';
import { updateAssignment } from 'src/services/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UpdateAssignmentPaylaod } from './../../models/service';
import { QUERY_KEYS } from 'src/enums';

const useUpdateAssignment = () => {
  const client = useQueryClient();

  const { mutate, isLoading } = useMutation<void, AxiosError, UpdateAssignmentPaylaod>(
    ['update-assignment'],
    ({ id, fileData }: UpdateAssignmentPaylaod) => updateAssignment({ id, fileData }),
    {
      onSuccess: () => {
        client.invalidateQueries([QUERY_KEYS.ASSIGNMENT]);
      }
    }
  );

  return {
    mutate,
    isLoading
  };
};

export default useUpdateAssignment;
