import styled from 'styled-components';
import { Button } from '@mui/material';
import { breakPoints } from 'src/theme/defaultTheme';
import { ReactComponent as BurgerSvg } from 'src/assets/icons/burger.svg';
import { ReactComponent as CloseSvg } from 'src/assets/icons/closeIcon.svg';

export const Container = styled.div`
  width: 40px;
  height: 40px;
  display: none;

  @media (max-width: ${breakPoints.sm}px) {
    display: block;
  }

  @media (max-width: ${breakPoints.mb2}px) {
    width: 35px;
    height: 35px;
  }
`;

export const MenuButton = styled.img`
  width: 100%;
  cursor: pointer;
`;

export const RegisterButton = styled.img`
  width: 100%;
  cursor: pointer;
`;

export const CloseButton = styled.img`
  width: 100%;
  max-width: 24px;
  position: absolute;
  top: 12px;
  right: 16px;
  margin: 30px 15px;
  cursor: pointer;
`;

export const Menu = styled.div<{ isOpened: boolean; background: string }>`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 101;
  width: 100%;
  height: 100vh;
  transition: all 0.3s;
  background: ${(props) => props.background};
  display: ${(props) => !props.isOpened && 'none'};
  overflow-y: scroll;

  animation: rightToLeft 0.4s ease-in-out;

  @keyframes rightToLeft {
    0% {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0%);
    }
  }

  @media (max-height: 657px) {
    overflow-y: scroll;
  }
`;

export const OceanContainer = styled.div`
  margin-top: 84px;

  @media (max-height: 710px) {
    margin-top: 24px;
  }
`;

export const StyledMenuList = styled.ul`
  list-style-type: none;
  white-space: nowrap;

  @media (max-height: 720px) {
    margin-bottom: 16px;
  }
`;

export const StyledCourseListItem = styled.li`
  margin-bottom: 12px;
  font-size: 20px;
  text-transform: uppercase;
  font-feature-settings: 'case' on;

  @media (max-width: 340px) {
    padding-left: 44px;
  }
`;

export const StyledSubMenuTitle = styled.div`
  display: flex;
  align-items: center;
  &::before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #00ce7c;
    margin-right: 5px;
    vertical-align: middle;
  }
`;

export const StyledMenuListItem = styled.li`
  margin-bottom: 16px;
  font-size: 20px;
  text-transform: uppercase;
  font-feature-settings: 'case' on;

  @media (max-width: 340px) {
    padding-left: 44px;
  }
`;

export const MenuIcon = styled.img`
  max-width: 32px;
`;

export const MenuListHead = styled.div<{ isSubMenu: boolean | undefined }>`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => (props.isSubMenu ? '8px' : '24px')}
  cursor: pointer;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 134px;
  height: 48px;

  @media (max-height: 757px) {
    margin-top: 56px;
  }

  @media (max-height: 657px) {
    margin-top: 32px;
  }
`;

export const BurgerIcon = styled(BurgerSvg)<{ fill?: any }>`
  & path {
    fill: ${(props) => props.fill};
  }
  padding-right: 3px;
  height: 35px;
  width: 35px;
`;

export const CloseIcon = styled(CloseSvg)<{ fill?: any }>`
  & path {
    fill: ${(props) => props.fill};
  }
  float: right;
  height: 20px;
`;
