import React, { FC, ChangeEvent } from 'react';
import { Box } from '@mui/system';
import { AxiosError } from 'axios';
import CustomNavLink from './NavLink';
import { QUERY_KEYS } from 'src/enums';
import { Typography } from '@mui/material';
import useToggle from 'src/hooks/useToggle';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/context/authContext';
import { changeUserAvatar } from 'src/services/api';
import useProfile from 'src/hooks/queries/useProfile';
import useDetectDevice from 'src/hooks/useDetectDevice';
import LogoutModal from 'src/components/modals/LogoutModal';
import { useFeedbackModal } from 'src/context/feedbackModalContext';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useExtendedCourses from 'src/hooks/queries/useExtendedCourses';
import { ReactComponent as MenuIcon } from 'src/assets/icons/menu-news.svg';
import { ReactComponent as PlusIcon } from 'src/assets/icons/plus_icon.svg';
import { ReactComponent as MyCourseIcon } from 'src/assets/icons/menu-hat.svg';
import { ReactComponent as LogoutIcon } from 'src/assets/icons/menu-logout.svg';
import { ReactComponent as ProfileIcon } from 'src/assets/icons/menu-profile.svg';
import { ReactComponent as ProfileLogo } from 'src/assets/icons/profilepurple.svg';
import { ReactComponent as CallendarIcon } from 'src/assets/icons/menu-callendar.svg';
import { ReactComponent as CareerCareIcon } from 'src/assets/icons/menu-career-care.svg';
import { ReactComponent as AssignmentsIcon } from 'src/assets/icons/menu-assignments.svg';
import { UserCardContainer, Navigation, StyledBox, Avatar, NavMenu, StyledLogout } from './styles';

const UserCard: FC = () => {
  const { isAdmin } = useAuth();
  const { t } = useTranslation();
  const client = useQueryClient();
  const { isLarge } = useDetectDevice();
  const { constructModal } = useFeedbackModal();
  const [openModal, setOpenModal] = useToggle();
  const { data: user, myCourses = [] } = useProfile();
  const [contentMenuOpen, setContentMenuOpen] = useToggle();

  const navLinkClickable = isAdmin || Boolean(myCourses[0]?.courseId);

  const { courses = [] } = useExtendedCourses(isAdmin);
  const crsIdx = courses[courses.length - 1]?.id;

  const { mutate: updateUserAvatar } = useMutation<void, AxiosError, FormData>(
    ['update-avatar'],
    (payload) => changeUserAvatar(payload)
  );

  const handleAvatarUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = e.target.files?.[0];
    if (!uploadedFile) {
      return;
    }
    const fd = new FormData();

    fd.append('file', uploadedFile);
    updateUserAvatar(fd, {
      onSuccess: () => {
        client.invalidateQueries([QUERY_KEYS.PROFILE]);
      },
      onError: () => {
        constructModal({
          type: 'error',
          title: t('common.error'),
          btnText: t('modal.close'),
          subtitle: t('errors.networkError'),
          action: () => {
            window.location.reload();
          }
        });
      }
    });
  };

  return (
    <UserCardContainer>
      <StyledBox>
        {user?.imageUrl && <Avatar src={user?.imageUrl} />}
        {!user?.imageUrl && <ProfileLogo style={{ fill: '#ffffff' }} />}
        <input type="file" id="avatar" accept="image/*" hidden onChange={handleAvatarUpload} />
        <label htmlFor="avatar">
          <PlusIcon
            style={{
              position: 'absolute',
              right: isLarge ? -5 : 8,
              bottom: isLarge ? -12 : 2,
              transform: isLarge ? 'translate(0%, 0%)' : 'translate(30%, 30%)',
              cursor: 'pointer'
            }}
          />
        </label>
      </StyledBox>
      <Box minHeight="58px">
        <Typography variant="body1" fontWeight="bold" fontSize={24} mb={{ l: '20px', sm: '10px' }}>
          {user?.firstName.toUpperCase()} {user?.lastName.toUpperCase()}
        </Typography>
      </Box>

      <NavMenu>
        <Navigation>
          <CustomNavLink
            clickable={navLinkClickable}
            to={
              isAdmin
                ? `/my-course/general?chapterId=${crsIdx}`
                : `/my-course/general?course=${myCourses[0]?.courseId}`
            }
            title={t('profile.courses')}
            icon={<MyCourseIcon />}
            active={['/my-course']}
          />
          <CustomNavLink
            clickable={navLinkClickable}
            to="/calendar"
            title={t('profile.callendar')}
            icon={<CallendarIcon />}
            active={['/calendar']}
          />
          <CustomNavLink
            clickable={navLinkClickable}
            to={isAdmin ? `/assignments/${crsIdx}` : `/assignments/${myCourses[0]?.courseId}`}
            title={t('profile.assignments')}
            icon={<AssignmentsIcon />}
            active={['/assignments']}
          />
          <CustomNavLink
            clickable={navLinkClickable}
            to={isAdmin ? '/career-care' : `/career-content?courseId=${myCourses[0]?.courseId}`}
            title={t('profile.careerCare')}
            icon={<CareerCareIcon />}
            active={['/career-care', '/career-content']}
          />

          {isAdmin && (
            <CustomNavLink
              clickable={true}
              to="/ocean-news?section=news"
              title={t('profile.management')}
              clickHandler={setContentMenuOpen}
              icon={<MenuIcon />}
              active={['/']}
            />
          )}

          {contentMenuOpen && isAdmin && (
            <Box px={3} maxWidth={200}>
              <CustomNavLink
                clickable={true}
                to="/ocean-news?section=news"
                title={t('profile.news')}
                icon={<></>}
                active={['/ocean-news']}
              />
              <CustomNavLink
                clickable={true}
                to="/content-categories"
                title={t('profile.criteria')}
                icon={<></>}
                active={['/content-categories']}
              />
            </Box>
          )}
        </Navigation>

        <Box mt={{ xs: 2.5, l: !isAdmin ? 3.5 : 0 }}>
          <CustomNavLink
            clickable
            to="/profile"
            title={t('profile.profile')}
            icon={<ProfileIcon />}
            active={['/profile']}
          />

          <StyledLogout onClick={setOpenModal}>
            <LogoutIcon />
            <Typography fontWeight={'regular'} fontSize={{ md: '18', l: '20px' }} ml={2}>
              {t('common.logout')}
            </Typography>
          </StyledLogout>
        </Box>
      </NavMenu>

      <LogoutModal open={openModal} close={setOpenModal} />
    </UserCardContainer>
  );
};

export default UserCard;
