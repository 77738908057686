import { Grid } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import AddNewsForm from 'src/components/UI/forms/AddNewsForm';
import Loader from 'src/components/common/Loader';
import { NewDetailsEnum } from 'src/enums';
import useNewsDetails from 'src/hooks/queries/useNewsDetails';

const DetailedNews = () => {
  const { id = '', newsType = '' } = useParams();
  const { data, isLoading = true } = useNewsDetails(Number(id), newsType as NewDetailsEnum);

  if (isLoading) {
    return (
      <Grid container justifyContent="center" maxWidth="650px" height="550px" margin="auto">
        <Loader />;
      </Grid>
    );
  }
  return <AddNewsForm news={data?.news} type={newsType} />;
};

export default DetailedNews;
