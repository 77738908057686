import { AxiosResponse } from 'axios';
import { useQuery } from '@tanstack/react-query';
import useLanguageCode from '../useLanguageCode';
import { getCourseStatus } from 'src/services/api';
import { API_ROUTES, LOCALSTORAGE_KEYS } from 'src/enums';
import { CourseStatusResponse } from 'src/models/service';

const useCourseStatus = () => {
  const { langCode } = useLanguageCode();
  const orderId = localStorage.getItem(LOCALSTORAGE_KEYS.ORDER_ID) || '';
  const paymentType = localStorage.getItem(LOCALSTORAGE_KEYS.PAYMENT_TYPE);
  const uri = paymentType === 'solo' ? API_ROUTES.SOLO_STATUS : API_ROUTES.STUDENT_CARD_STATUS;

  const { data, isLoading, isError } = useQuery<AxiosResponse<CourseStatusResponse>>(
    ['course-status', orderId],
    () => getCourseStatus({ orderId }, langCode, uri),
    { enabled: Boolean(orderId) }
  );

  return { data, isLoading, isError };
};

export default useCourseStatus;
