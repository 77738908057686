import React, { FC, useState, useEffect } from 'react';
import axios from 'axios';
import { useFormik } from 'formik';
import { QUERY_KEYS } from 'src/enums';
import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { useAuth } from 'src/context/authContext';
import { ErrorMessages } from 'src/models/service';
import { addEvent, updateEvent } from 'src/services/api';
import { useCalendar } from 'src/context/calendarContext';
import Autocomplete from '@mui/material/Autocomplete';
import { TIME_OPTIONS } from 'src/constants/index';
import useProfile from 'src/hooks/queries/useProfile';
import { useQueryClient } from '@tanstack/react-query';
import useLanguageCode from 'src/hooks/useLanguageCode';
import { registerEventSchema } from 'src/helpers/validations';
import { Container, StyledForm, StyledRow, Actions } from './styles';

export interface TimeOptions {
  value: string;
  label: string;
}

interface RegisterEventProps {
  close: () => void;
}

const RegisterEvent: FC<RegisterEventProps> = ({ close }) => {
  const { isAdmin } = useAuth();
  const { t } = useTranslation();
  const client = useQueryClient();
  const { lang } = useLanguageCode();
  const { eventToEdit, setEventToEdit } = useCalendar();

  const { data: user } = useProfile();
  const [timeOpts, setTimeOpts] = useState(TIME_OPTIONS);
  const [errors, setErrors] = useState<ErrorMessages | null>(null);
  const [eventTimeError, setEventTimeError] = useState<string | null>(null);

  const {
    description = '',
    translations = {},
    end: editEventEnd = '',
    date: editEventDate = '',
    start: editEventStart = ''
  } = eventToEdit || {};

  const formik = useFormik<any>({
    initialValues: {
      eventName: translations[0]?.summary || translations[1]?.summary,
      eventLink: description || '',
      eventDate: editEventDate.split(' ')[0] || '',
      startTime: editEventStart
        ? {
            value: editEventStart.split(' ')[1].slice(0, 5),
            label: editEventStart.split(' ')[1].slice(0, 5)
          }
        : null,
      endTime: editEventEnd
        ? {
            value: editEventEnd.split(' ')[1].slice(0, 5),
            label: editEventEnd.split(' ')[1].slice(0, 5)
          }
        : null,
      eventComment: translations[0]?.comment || translations[1]?.comment || ''
    },
    validationSchema: registerEventSchema(t),
    onSubmit: async (data) => {
      if (eventTimeError) return;

      const eventData = {
        user_id: user?.id,
        end_date_time: `${data.eventDate} ${data.endTime?.value}:00`,
        start_date_time: `${data.eventDate} ${data.startTime?.value}:00`,
        subject_id: isAdmin ? data?.subject || undefined : undefined,
        en: {
          summary: data.eventName,
          comment: data.eventComment || undefined,
          description: data.eventLink || undefined
        },
        ka: {
          summary: data.eventName,
          comment: data.eventComment || undefined,
          description: data.eventLink || undefined
        }
      };

      try {
        setErrors(null);
        if (eventToEdit) {
          await updateEvent(eventToEdit.id, eventData, lang);
          client.invalidateQueries([QUERY_KEYS.EVENTS]);
        } else {
          await addEvent(eventData, lang);
          client.invalidateQueries([QUERY_KEYS.EVENTS]);
        }

        close();
      } catch (err) {
        if (axios.isAxiosError(err)) {
          err.response?.data?.errors && setErrors(err.response?.data?.errors);
        }
      }
    },
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true
  });

  const end = formik.values?.endTime?.value;
  const start = formik.values?.startTime?.value;

  useEffect(() => {
    if (start && end) {
      if (start >= end) {
        setEventTimeError(t('validations.endTime'));
      } else {
        setEventTimeError(null);
      }
    }
  }, [start, end, t]);

  const eventTimeChangeHandler = (type: 'startTime' | 'endTime', params: any) => {
    if (timeOpts.find((opt: TimeOptions) => opt.value === params.inputProps.value)) return;

    setTimeOpts((prevOpts: TimeOptions[]) => {
      return [...prevOpts, { label: params.inputProps.value, value: params.inputProps.value }];
    });
    formik.setFieldValue(type, {
      label: params.inputProps.value as string,
      value: params.inputProps.value as string
    });
  };

  useEffect(() => {
    return () => {
      setEventToEdit(undefined);
    };
  }, [setEventToEdit]);

  return (
    <Container>
      <Typography
        variant="subtitle1"
        color={'#00CE7C'}
        textTransform={'uppercase'}
        sx={{ fontFeatureSettings: "'case' on" }}
        marginBottom={{ xs: 2, mb2: 4 }}>
        {eventToEdit ? t('calendar.editEvent') : t('calendar.addEvent')}
      </Typography>
      <StyledForm onSubmit={formik.handleSubmit}>
        <StyledRow>
          <TextField
            name="eventName"
            variant="outlined"
            label={t('labels.eventName')}
            onChange={formik.handleChange}
            value={formik.values.eventName || ''}
            helperText={formik.errors.eventName}
            error={Boolean(formik.errors.eventName)}
            FormHelperTextProps={{
              error: Boolean(formik.errors.eventName || errors?.eventName),
              style: {
                whiteSpace: 'normal'
              }
            }}
          />
        </StyledRow>

        <TextField
          type="date"
          name="eventDate"
          variant="outlined"
          label={t('labels.eventDate')}
          onChange={formik.handleChange}
          value={formik.values.eventDate}
          InputLabelProps={{ shrink: true }}
          helperText={formik.errors.eventDate}
          error={Boolean(formik.errors.eventDate)}
          FormHelperTextProps={{
            error: Boolean(formik.errors.eventDate || errors?.eventDate)
          }}
        />

        <StyledRow>
          <Autocomplete
            id="startTime"
            autoHighlight
            fullWidth
            options={timeOpts}
            value={formik.values.startTime}
            noOptionsText={null}
            onChange={(_, time) => formik.setFieldValue('startTime', time)}
            renderInput={(params) => (
              <TextField
                {...params}
                name="startTime"
                label={t('labels.startTime')}
                error={Boolean(formik.errors.startTime)}
                helperText={
                  Boolean(formik.errors.endTime) &&
                  t('validations.required', { field: t('labels.startTime') })
                }
                FormHelperTextProps={{
                  error: Boolean(formik.errors.startTime),
                  style: {
                    whiteSpace: 'normal'
                  }
                }}
                onBlur={() => eventTimeChangeHandler('startTime', params)}
              />
            )}
          />
          <Autocomplete
            id="endTime"
            autoHighlight
            fullWidth
            options={TIME_OPTIONS}
            value={formik.values.endTime}
            noOptionsText={null}
            onChange={(_, time) => formik.setFieldValue('endTime', time)}
            renderInput={(params) => (
              <TextField
                {...params}
                name="endTime"
                label={t('labels.endTime')}
                error={Boolean(formik.errors.endTime)}
                helperText={
                  (Boolean(formik.errors.endTime) &&
                    t('validations.required', { field: t('labels.endTime') })) ||
                  eventTimeError
                }
                FormHelperTextProps={{
                  error: Boolean(formik.errors.endTime || eventTimeError),
                  style: {
                    whiteSpace: 'normal'
                  }
                }}
                onBlur={() => eventTimeChangeHandler('endTime', params)}
              />
            )}
          />
        </StyledRow>

        <TextField
          variant="outlined"
          name="eventComment"
          label={t('labels.eventComment')}
          onChange={formik.handleChange}
          value={formik.values.eventComment}
          helperText={formik.errors.eventComment}
          error={Boolean(formik.errors.eventComment)}
          FormHelperTextProps={{
            error: Boolean(formik.errors.eventComment)
          }}
        />

        <TextField
          name="eventLink"
          variant="outlined"
          label={t('labels.eventLink')}
          onChange={formik.handleChange}
          value={formik.values.eventLink}
          helperText={formik.errors.eventLink}
          error={Boolean(formik.errors.eventLink)}
          FormHelperTextProps={{
            error: Boolean(formik.errors.eventLink || errors?.eventLink)
          }}
        />

        <Actions>
          <Button
            sx={{
              height: '56px',
              textTransform: 'uppercase',
              fontWeight: 'regular',
              fontFeatureSettings: "'case' on",
              '&:hover': {
                background: 'black',
                color: 'white'
              }
            }}
            fullWidth
            variant="primary"
            type="submit"
            disabled={formik.isSubmitting}>
            {eventToEdit ? t('modal.edit') : t('modal.add')}
          </Button>

          <Button
            sx={{
              height: '56px',
              textTransform: 'uppercase',
              fontWeight: 'regular',
              background: 'white',
              color: 'black',
              fontFeatureSettings: "'case' on",
              '&:hover': {
                background: 'black',
                color: 'white'
              }
            }}
            variant="primary"
            fullWidth
            onClick={close}>
            {t('modal.cancel')}
          </Button>
        </Actions>
      </StyledForm>
    </Container>
  );
};

export default RegisterEvent;
