import React from 'react';
import { useFormik } from 'formik';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { CourseResponse } from 'src/models/service';
import useQueryString from 'src/hooks/useQueryString';
import useCopySyllabus from 'src/hooks/mutations/useCopySyllabus';
import { Button, FormControl, MenuItem, TextField, Typography } from '@mui/material';

interface Props {
  courses: CourseResponse[] | undefined;
}

const CopySyllabusForm: React.FC<Props> = ({ courses = [] }) => {
  const { t } = useTranslation();
  const { parseQuery } = useQueryString();
  const { chapterId = '' } = parseQuery();
  const { mutate: copySyllabus, isLoading } = useCopySyllabus();

  const formik = useFormik<{ course: number }>({
    initialValues: {
      course: courses[0]?.id
    },
    onSubmit: async ({ course }) => {
      copySyllabus({
        chapter_from_id: Number(course),
        chapter_to_id: Number(chapterId)
      });
    },
    validateOnBlur: false,
    validateOnChange: false
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container padding={4} border="1px solid #dedede" gap={3}>
            <Grid item xs={6}>
              <FormControl>
                <TextField
                  select
                  name="course"
                  value={formik.values.course}
                  onChange={formik.handleChange}
                  label={t('labels.chooseCourse')}>
                  {courses.map((course) => (
                    <MenuItem key={course.id} value={course.id}>
                      {course.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button variant="primary" type="submit" disabled={isLoading}>
                <Typography textTransform="uppercase">{t('syllabus.copySyllabus')}</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default CopySyllabusForm;
