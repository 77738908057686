import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import { IconButton, InputAdornment } from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const InputWithIcon = ({ ...rest }) => {
  const { t } = useTranslation();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handlePasswordVisibility = () => setPasswordVisible((prevState) => !prevState);

  return (
    <TextField
      type={passwordVisible ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label={t('labels.password')} onClick={handlePasswordVisibility}>
              {passwordVisible ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )
      }}
      {...rest}
    />
  );
};

export default InputWithIcon;
