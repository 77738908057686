import styled from 'styled-components';

interface TopHeaderProps {
  over: boolean;
  position: string;
  mobile?: boolean;
  animation?: boolean;
  mobileBg?: string;
}

export const TopHeaderWrapper = styled.div<TopHeaderProps>`
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'FiraGO';
  height: 50px;
  z-index: 10000;
  padding-top: 8px;
  position: ${(props) => props.position};
  animation: ${(props) => props.animation && 'identifier .4s ease-in-out'};
  top: 0;
  left: 0;
  right: 0;

  @media (max-width: 876px) {
    height: 90px;
    flex-direction: column;
    align-items: center;
  }
`;

export const TextWrapper = styled.div`
  @media (max-width: 876px) {
    display: flex;
    width: 315px;
    margin: 4px 0;
  }
`;

export const ContactWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;

  @media (max-width: 876px) {
    margin-left: 0px;
  }
`;

export const PhoneNum = styled.a`
  text-decoration: underline;
  color: #cddb00;
  font-weight: 500;
  margin-left: 5px;
  padding-bottom: 4px;
  font-size: 18px;
  font-family: 'Helvetica';
`;

export const SVGBox = styled.div`
  svg {
    padding-top: 2px;
  }
  @media (max-width: 876px) {
    svg {
      padding-top: 2px;
    }
  }
`;
