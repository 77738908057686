import { AxiosError } from 'axios';
import { QUERY_KEYS } from 'src/enums';
import { editCriteria } from 'src/services/api';
import { AddCriteriaPayload } from 'src/models/service';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const useEditCriteria = (id: string) => {
  const client = useQueryClient();

  const { mutate: editCriteriaMutation, isLoading: isEditing } = useMutation<
    { success?: boolean },
    AxiosError,
    AddCriteriaPayload
  >((payload) => editCriteria(payload, id), {
    onSettled: () => {
      client.invalidateQueries([QUERY_KEYS.CRITERIA]);
    }
  });

  return {
    isEditing,
    editCriteriaMutation
  };
};

export default useEditCriteria;
