import styled from 'styled-components';

export const AssignUpload = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;

  img {
    width: 175px;
    height: 175px;
  }

  @media (max-width: 800px) {
    img {
      width: 150px;
      height: 150px;
    }
  }
`;

export const AssignContent = styled.div`
  display: flex;
  width: 90%;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  text-align: start;

  @media (max-width: 1260px) {
    min-width: 100%;
  }
`;

export const UploadForm = styled.form`
  width: 100%;
  margin-top: 24px;
`;
