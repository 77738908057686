import React, {
  createContext,
  Dispatch,
  FC,
  ReactElement,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import useCurrentSubject from 'src/hooks/queries/useCurrentSubject';

export interface MonthContextInterface {
  activeSubject: any;
  activeMonth: number | undefined;
  setActiveMonth: Dispatch<SetStateAction<number>>;
}

export const MonthContext = createContext<MonthContextInterface | null>(null);

interface Props {
  children: ReactNode;
}

const MonthContextProvider: FC<Props> = ({ children }): ReactElement => {
  const { activeSubject } = useCurrentSubject();
  const [activeMonth, setActiveMonth] = useState<number>(0);

  const monthIdx = activeSubject?.month?.title;

  useEffect(() => {
    if (monthIdx) {
      setActiveMonth(Number(monthIdx) - 1);
    } else {
      setActiveMonth(0);
    }
  }, [monthIdx]);

  const contextValues = useMemo(
    () => ({
      activeMonth,
      activeSubject,
      setActiveMonth
    }),
    [activeMonth, activeSubject]
  );

  return <MonthContext.Provider value={contextValues}>{children}</MonthContext.Provider>;
};

export default MonthContextProvider;

export const useMonth = () => {
  const value = useContext(MonthContext);

  if (!value) {
    throw new Error('Month Context Provider is not defined');
  }
  return value;
};
