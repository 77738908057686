import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { useAuth } from 'src/context/authContext';
import Loader from 'src/components/common/Loader';
import Navigate from 'src/components/common/navigate';
import ArrowBack from '@mui/icons-material/ArrowBack';
import useQueryString from 'src/hooks/useQueryString';
import { useNavigate } from 'src/context/languageContext';
import AuthWrapper from 'src/components/layouts/AuthWrapper';
import useContentDetail from 'src/hooks/queries/useContentDetail';
import CreateCareerCareModule from 'src/components/UI/forms/CareerCareModule';

const CreateCareerModule = () => {
  const { isAdmin } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { parseQuery } = useQueryString();
  const { id = '' } = parseQuery();

  const { details, isFetching } = useContentDetail(String(id));

  if (!isAdmin) return <Navigate to="/career-care" />;
  if (isFetching) return <Loader />;

  return (
    <Grid mb={20}>
      <AuthWrapper isCareerCare>
        <Grid
          container
          mt={{ sm: 0, xs: 2 }}
          flexDirection="column"
          sx={{
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            border: '1px solid #DEDEDE',
            background: '#fff'
          }}>
          <Grid
            display="flex"
            bgcolor="black"
            alignItems="center"
            justifyContent="space-between"
            padding="24px"
            height={{ sm: 64, xs: 51 }}
            sx={{
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10
            }}>
            <Typography
              fontSize={{ sm: 20, xs: 16 }}
              color="white"
              fontWeight={700}
              minHeight="24px">
              Career Care (Add Content)
            </Typography>
          </Grid>
          <Grid item mb={3} gap={1} container padding={3}>
            <Grid
              gap={1}
              container
              alignItems="center"
              sx={{ cursor: 'pointer' }}
              onClick={() => navigate('`/career-care')}>
              <ArrowBack sx={{ color: '#2A7DE1' }} />
              <Typography variant="body1" color="violet" fontSize={16}>
                {t('careerCare.backToList')}
              </Typography>
            </Grid>
            <CreateCareerCareModule details={Boolean(id) && details} id={String(id)} />
          </Grid>
        </Grid>
      </AuthWrapper>
    </Grid>
  );
};

export default CreateCareerModule;
