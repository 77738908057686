/* eslint-disable @typescript-eslint/ban-types */

import { Box } from '@mui/system';
import React from 'react';
import { TiTimes } from 'react-icons/ti';

interface NewsUploadFile {
  removeFile: (file: { uuid: string; url: string }) => void;
  type?: string;
  file: { uuid: string; url: string };
}
const NewsUploadFile: React.FC<NewsUploadFile> = ({ file, removeFile, type }) => {
  const { url } = file;
  return (
    <Box
      style={{
        height: '135px',
        width: '47%',
        borderRadius: '5px',
        backgroundImage: type ? 'url(/videos/video-icon.png)' : `url(${url})`,
        margin: '5px',
        backgroundSize: type ? '50%' : '110%',
        objectFit: 'contain',
        cursor: 'pointer',
        border: '1px solid #d1cbcb',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 50%',
        position: 'relative'
      }}>
      <button
        onClick={() => {
          removeFile(file);
        }}
        style={{
          background: 'white',
          cursor: 'pointer',
          borderRadius: '15px',
          border: '1px solid gray',
          width: '25px',
          height: '25px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          left: 5,
          top: 5
        }}>
        <TiTimes color="crimson" />
      </button>
    </Box>
  );
};

export default NewsUploadFile;
