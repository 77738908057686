import useLanguageCode from 'src/hooks/useLanguageCode';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { QUERY_KEYS } from 'src/enums';
import { AddMonthPayload } from 'src/models/service';
import { addMonth } from 'src/services/api';

const useAddMonth = () => {
  const client = useQueryClient();
  const { lang } = useLanguageCode();

  const { mutate, isLoading } = useMutation<{ success?: boolean }, AxiosError, AddMonthPayload>(
    ['add-month'],
    (payload) => addMonth(payload, lang),
    {
      onSettled: () => {
        client.invalidateQueries([QUERY_KEYS.SYLLABUS]);
      }
    }
  );

  return {
    mutate,
    isLoading
  };
};

export default useAddMonth;
