import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    mb: true;
    mb2: true;
    mb3: true;
    sm: true;
    m: true;
    md: true;
    l: true;
    xl: true;
  }
}

export const breakPoints = {
  xs: 0,
  mb: 375,
  mb2: 450,
  mb3: 775,
  sm: 992,
  m: 1260,
  md: 1440,
  l: 1650,
  xl: 1920
};

const theme = createTheme({
  breakpoints: {
    values: breakPoints
  },

  components: {
    MuiTypography: {
      variants: [
        {
          props: {
            variant: 'inherit'
          },
          style: {
            color: '#a4a4a4',
            fontSize: '1rem',
            lineHeight: '1.5625rem',
            letterSpacing: 0
          }
        }
      ]
    }
  }
});

export default theme;
