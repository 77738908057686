import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import React, { FormEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { SupportedLanguage, useLanguage } from 'src/context/languageContext';
import useUploadAssignment from 'src/hooks/mutations/useUploadAssignment';
import useProfile from 'src/hooks/queries/useProfile';
import { AssignContent, AssignUpload, UploadForm } from '../homework/style';
import useCourse from 'src/hooks/queries/useCourse';
import Loader from 'src/components/common/Loader';
import { AssignInput, TextArea } from './style';
import useUpdateAssignment from 'src/hooks/mutations/useUpdateAssignment';
import useAssignment from 'src/hooks/queries/useAssignment';
import { HiArrowSmLeft } from 'react-icons/hi';
import { ReactComponent as PlusIcon } from 'src/assets/icons/addPlus.svg';
import AuthWrapper from 'src/components/layouts/AuthWrapper';
import CloseIcon from '@mui/icons-material/Close';
import ProgressBar from 'src/components/common/progressBar';
import { CardHeaderBlack, StyledCardContent } from '../courseDetails/styles';
import RankingStatus from 'src/components/UI/rankingStatus';
import KahootRating from 'src/components/UI/lists/KahootRating';
import { useAuth } from 'src/context/authContext';
import useProgress from 'src/hooks/queries/useProgress';
import useRating from 'src/hooks/queries/useRating';
import { actualDate } from 'src/utils';

const Assignment: React.FC = () => {
  const { t } = useTranslation();
  const { locale } = useLanguage();
  const [desc, setDesc] = useState<string | null>(null);
  const [lnk, setLnk] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>();
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { isAdmin } = useAuth();

  const { mutate: upload, isLoading: uploadLoading } = useUploadAssignment();
  const { mutate: edit, isLoading: updateLoading } = useUpdateAssignment();

  const ref = useRef<HTMLInputElement>(null);
  const { homeworkId } = useParams();
  const qs = new URLSearchParams(window.location.search);
  const courseId = qs.get('course');
  const segment = qs.get('segment');

  const { data: course } = useCourse(courseId!);
  const { data: assignment } = useAssignment(homeworkId!);
  const { assignments } = assignment?.homework || {};
  const { data: profile, myCourses = [] } = useProfile();
  const studentAssignment = assignments?.find((a) => a.user_email === profile?.email);
  const { title, link } = studentAssignment || {};

  const { progress } = useProgress({
    chapter_id: String(courseId)
  });

  const groupId = myCourses?.find((crs) => String(crs.courseId) === courseId)?.groupId;

  const { scores } = useRating({ group_id: groupId });

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!file && !lnk && !desc) {
      setError(t('labels.specifyError'));
      return;
    }

    const fileData = new FormData();
    file && fileData.append('attachment', file);
    desc && fileData.append('title', desc);
    lnk && fileData.append('link', lnk);
    fileData.append('homework_id', homeworkId!);

    if (segment === 'attach') {
      upload({ fileData });
    }

    if (segment === 'edit') {
      edit({ id: Number(studentAssignment?.id)!, fileData });
    }
    setFile(null);
    if (!uploadLoading || !updateLoading) {
      navigate(`/${locale}/assignments/homework/${homeworkId}?course=${courseId}&section=edit`);
    }
  };

  if (uploadLoading) {
    return (
      <Box mt={20}>
        <Loader />
      </Box>
    );
  }

  return (
    <AuthWrapper userPage isAssignment>
      <Grid container mb={3} justifyContent="space-between">
        <Grid item xs={12} sm={8.8}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            pl={{ sm: 4, xs: 2 }}
            height={{ sm: 64, xs: 51 }}
            sx={{
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10
            }}
            bgcolor="black">
            <Box minHeight="24px">
              <Typography color="white" fontSize={20} fontWeight={700}>
                {locale === ('ge' as SupportedLanguage) && course?.name + '- ის '}
                {locale === ('en' as SupportedLanguage) && course?.name + ' '}
                {t('labels.chooseAssignment')}
              </Typography>
            </Box>
          </Box>

          <Box
            padding={{ xs: '16px', md: '16px 28px 40px 28px' }}
            textAlign={{ xs: 'center', md: 'start' }}
            mb={20}
            sx={{
              background: '#fff',
              border: '1px solid #DEDEDE',
              borderBottomRightRadius: 10,
              borderBottomLeftRadius: 10
            }}>
            <AssignUpload>
              <Button
                onClick={() => navigate(-1)}
                sx={{
                  paddingRight: 2,
                  paddingLeft: 0,
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '25px',
                  color: '#2A7DE1'
                }}>
                <HiArrowSmLeft size={'24px'} fontWeight={'bold'} />
                <Typography marginLeft={1} color={'#2A7DE1'} fontWeight={'regular'}>
                  {t('careerCare.backToList')}
                </Typography>
              </Button>

              <AssignContent>
                <Box display={'flex'} alignItems={'center'} mb={1}>
                  <Typography fontWeight={'bold'} mr={2.5} fontSize={{ xs: 16, sm: 20 }}>
                    {assignment?.homework?.title}
                  </Typography>
                  <Typography
                    fontWeight={'regular'}
                    color={studentAssignment ? '#3BCC51' : '#F7D51B'}
                    fontSize={{ xs: 14 }}>
                    {studentAssignment ? <>({t('labels.submitted')})</> : <>({t('labels.toDo')})</>}
                  </Typography>
                </Box>

                <Typography fontWeight={'regular'} mb={2} fontSize={{ xs: 14, sm: 16 }}>
                  {assignment?.homework?.description}
                </Typography>

                <Box
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                  gap={2}
                  flexDirection={{ sm: 'row', xs: 'column' }}
                  mb={1.5}>
                  <Box display={'flex'}>
                    <Typography fontWeight={'medium'} fontSize={{ xs: 14, sm: 16 }}>
                      {t('labels.due_date_card')}:
                    </Typography>
                    <Typography fontWeight={400} ml={0.5} fontSize={{ xs: 14, sm: 16 }}>
                      {actualDate(assignment?.homework?.due_date).split(' ')[0] + ' 23:59:00'}
                    </Typography>
                  </Box>

                  <Box display={'flex'}>
                    <Typography fontWeight={'medium'} fontSize={{ xs: 14, sm: 16 }} mr={1}>
                      {t('labels.your_points')}:
                    </Typography>
                    <Typography fontWeight={'regular'} fontSize={{ xs: 14, sm: 16 }}>
                      <span style={{ fontWeight: 500, color: '#2A7DE1' }}>
                        <span style={{ color: '#2A7DE1' }}>{studentAssignment?.score ?? 0}</span>/
                        {assignment?.homework?.points}
                      </span>
                    </Typography>
                  </Box>
                </Box>

                <div
                  style={{
                    width: '100%',
                    marginTop: '5px',
                    background: '#cec3c3',
                    height: '1px'
                  }}
                />

                <Box mb={3} sx={{ width: '100%', marginTop: '20px' }}>
                  <Typography fontSize={{ xs: 14, sm: 18 }} fontWeight={'bold'} mb={'16px'}>
                    {t('labels.assignmentLabel')}
                  </Typography>

                  <a
                    href={assignment?.homework.attachment}
                    rel="noreferrer"
                    target="_blank"
                    download
                    style={{ textDecoration: 'underline', color: '#2A7DE1' }}>
                    <Typography
                      fontSize={{ xs: 14, sm: 16 }}
                      fontWeight={'regular'}
                      color={'#2A7DE1'}>
                      {t('labels.attached_file')}
                    </Typography>
                  </a>

                  <div
                    style={{
                      width: '100%',
                      marginTop: '25px',
                      background: '#cec3c3',
                      height: '1px'
                    }}
                  />

                  {studentAssignment && (
                    <>
                      <Typography
                        fontSize={{ xs: 14, sm: 18 }}
                        fontWeight={'bold'}
                        marginTop={'20px'}
                        mb={'16px'}>
                        {t('labels.myAssignmentLabel')}
                      </Typography>

                      <Typography fontSize={'16px'} fontWeight={'regular'} mt={1}>
                        {studentAssignment.title}
                      </Typography>

                      <Box display={'flex'} mt={'8px'}>
                        <a
                          href={studentAssignment?.link}
                          style={{ textDecoration: 'underline', color: '#2A7DE1' }}>
                          <Typography fontSize={'16px'} fontWeight={'regular'} color={'#007FFF'}>
                            {studentAssignment.link
                              ? t('table.assignmentLink')
                              : t('table.assignmentLink')}
                          </Typography>
                        </a>
                      </Box>
                      <Box mt={1}>
                        {studentAssignment?.attachment && (
                          <a
                            href={studentAssignment?.attachment}
                            rel="noreferrer"
                            target="_blank"
                            download
                            style={{ textDecoration: 'underline', color: '#2A7DE1' }}>
                            <Typography fontSize={'16px'} fontWeight={'regular'} color={'#007FFF'}>
                              {t('labels.uploaded_file')}
                            </Typography>
                          </a>
                        )}
                      </Box>
                    </>
                  )}
                </Box>

                {/* {deleteLoading && <Loader />} */}
                {assignment?.homework.status === 'finished' && (
                  <Typography mt={1} mb={-2} color={'red'} fontSize={16}>
                    {t('labels.overdue')}
                  </Typography>
                )}
              </AssignContent>
            </AssignUpload>

            <AssignUpload>
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                mr={{ xs: '0', sm: '40px' }}></Box>

              <AssignContent>
                <AssignInput
                  placeholder="Link"
                  defaultValue={link!}
                  onChange={(e) => {
                    setLnk(e.target.value);
                    setError(null);
                  }}
                />

                <TextArea
                  rows={8}
                  defaultValue={title}
                  placeholder="Description"
                  onChange={(e) => {
                    setDesc(e.target.value);
                    setError(null);
                  }}
                />

                <Grid container justifyContent={{ xs: 'center' }}>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    alignItems="center"
                    flexDirection={{ sm: 'row', xs: 'column' }}
                    gap="24px">
                    <Grid order={{ sm: 1, xs: 2 }}>
                      <Button
                        onClick={() => {
                          if (!file) {
                            ref.current?.click();
                          }
                        }}
                        variant="filled">
                        <input
                          ref={ref}
                          type="file"
                          hidden
                          onChange={(e) => {
                            if (e.target.files) {
                              setFile(e.target.files[0]);
                              setError(null);
                            }
                          }}
                        />
                        <Typography
                          fontSize={16}
                          color="darkblue"
                          fontWeight={450}
                          textTransform="uppercase">
                          {t('button.upFile')}
                          <PlusIcon />
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid order={{ sm: 2, xs: 1 }}>
                      {studentAssignment?.attachment && !file && (
                        <Typography variant="listItem" fontSize={16} color="black">
                          {t('messages.fileSelected')}
                        </Typography>
                      )}
                      {file && (
                        <Box display="flex" gap="6px">
                          <Typography variant="listItem" fontSize={16} color="black">
                            {file.name.substring(0, 20)}
                          </Typography>
                          <CloseIcon
                            sx={{ cursor: 'pointer', background: '#00000029', fontSize: 20 }}
                            onClick={() => {
                              setFile(null);
                              setError(null);
                              ref.current!.value = null as any;
                            }}
                          />
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                {error && (
                  <Typography mt={1} color={'red'} fontSize={'16px'}>
                    {error}
                  </Typography>
                )}

                <UploadForm onSubmit={submit}>
                  <Box
                    display="flex"
                    mt={5}
                    justifyContent={{ sm: 'flex-end', xs: 'center' }}
                    gap={{ sm: 2, xs: 0.5 }}
                    alignItems="center">
                    <Button
                      type="submit"
                      disabled={assignment?.homework.status === 'finished'}
                      variant="primary"
                      sx={{
                        height: '56px !important',
                        minWidth: '170px',
                        opacity: assignment?.homework.status === 'finished' ? 0.25 : 1
                      }}>
                      <Typography fontSize={'16px !important'} mr={1.5}>
                        {studentAssignment?.attachment ? t('labels.edit') : t('labels.upload')}
                      </Typography>
                    </Button>

                    <Button
                      sx={{ height: '54px' }}
                      onClick={() => navigate(-1)}
                      disabled={assignment?.homework.status === 'finished'}
                      variant="secondary">
                      <Typography fontSize={'15px'} mr={1.5}>
                        {t('button.cancel')}
                      </Typography>
                    </Button>
                  </Box>
                </UploadForm>
              </AssignContent>
            </AssignUpload>
          </Box>
        </Grid>
        <Grid item display={{ xs: 'none', sm: 'block' }} sm={2.8}>
          <Card variant="outlined" sx={{ background: '#f8f8f8 !important' }}>
            <CardHeaderBlack sx={{ height: '64px' }}>
              <Box width="100%">
                <Typography fontSize={20} color="white" fontWeight={700}>
                  {t('course.kahoot')}
                </Typography>
              </Box>
            </CardHeaderBlack>
            <StyledCardContent>
              <Box padding="24px 24px 24px 16px">
                <Box>
                  <KahootRating list={scores} />
                </Box>
              </Box>
            </StyledCardContent>
            {!isAdmin && (
              <>
                <RankingStatus list={scores} />
                <StyledCardContent>
                  <CardContent
                    sx={{
                      border: 'none !important',
                      marginTop: '24px',
                      borderRadius: '16px'
                    }}>
                    <CardHeaderBlack sx={{ height: '64px' }}>
                      <Box width="100%">
                        <Typography fontSize={20} color="white" fontWeight={700}>
                          {t('course.progress')}
                        </Typography>
                      </Box>
                    </CardHeaderBlack>
                    <Box
                      pb={4}
                      display="flex"
                      justifyContent="center"
                      marginTop={{ xs: 3, l: 5 }}
                      marginBottom={{ xs: 4, l: 8 }}>
                      <ProgressBar progress={progress} />
                    </Box>
                  </CardContent>
                </StyledCardContent>
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

export default Assignment;
