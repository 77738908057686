import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'src/enums';
import { deleteAssignment } from 'src/services/api';

const useDeleteAssignment = () => {
  const client = useQueryClient();

  const { mutate, isLoading } = useMutation<{ success?: boolean }, AxiosError, { id: string }>(
    ['delete-assignment'],
    ({ id }) => deleteAssignment(id),
    {
      onSuccess: () => {
        client.invalidateQueries([QUERY_KEYS.ASSIGNMENT]);
      }
    }
  );

  return {
    mutate,
    isLoading
  };
};

export default useDeleteAssignment;
