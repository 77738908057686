import React from 'react';
import Box from '@mui/system/Box';
import Grid from '@mui/material/Grid';
import { getDateDifference } from 'src/utils';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import useProfile from 'src/hooks/queries/useProfile';
import Navigate from 'src/components/common/navigate';
import useUserCourse from 'src/hooks/queries/useUserCourse';
import { CardHeader, CardImage } from '../courseDetails/styles';
import ModifySpecialization from 'src/components/UI/forms/ModifySpecialization';

const EditSpecialization = () => {
  const { t } = useTranslation();
  const { myCourses = [] } = useProfile();
  const subGroupId = myCourses[0]?.subGroupId;
  const { data: course } = useUserCourse();
  const { subCourses = [], groups = [] } = course || {};
  const { startDate: courseStart = '' } = groups[0] || {};
  const choosedSubGroup = subCourses.find((sub) => sub.subGroups[0]?.id === subGroupId);

  const date = new Date(courseStart);
  date.setMonth(date.getMonth() + 4);

  const daysBeforeStart = getDateDifference(date, new Date()) + 5;

  if (!subGroupId) {
    return <Navigate to="/my-course/specific/choose-chapter" />;
  }

  if (daysBeforeStart < 0) {
    return <Navigate to="/my-course/specific" />;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <CardHeader sx={{ justifyContent: 'flex-start' }}>
          <Typography
            textAlign="start"
            component="div"
            variant="subtitle4"
            color="white"
            textTransform="uppercase"
            fontWeight={700}>
            {t('course.choose')}
          </Typography>
          <CardImage sx={{ transform: 'scale(4)' }} />
        </CardHeader>

        <Box border={{ mb2: '1px solid #DEDEDE' }} borderRadius={'0px 0px 8px 8px'}>
          <ModifySpecialization
            isEditing
            specs={subCourses}
            daysLeft={daysBeforeStart + 2}
            selectedId={choosedSubGroup?.subGroups[0].id}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default EditSpecialization;
