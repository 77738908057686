import React, { useEffect, useRef, useState } from 'react';
import { Box, height, width } from '@mui/system';
import { useFormik } from 'formik';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import { useAuth } from 'src/context/authContext';
import { useTranslation } from 'react-i18next';
import { FormHelperText } from '@mui/material';
import { LOCALSTORAGE_KEYS } from 'src/enums';
import useLogin from 'src/hooks/mutations/useLogin';
import { loginSchema } from 'src/helpers/validations';
import { useNavigate } from 'src/context/languageContext';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputWithIcon from 'src/components/common/InputWithIcon';
import { ReactComponent as CheckBoxUnchecked } from 'src/assets/icons/square-checkbox.svg';
import { ReactComponent as CheckboxChecked } from 'src/assets/icons/square-checkbox-checked.svg';

interface FormData {
  email: string;
  password: string;
  rememberMe: boolean;
}

const Login = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setLocalTokens } = useAuth();
  const { mutate, isLoading } = useLogin();
  const [error, setError] = useState(false);

  const formik = useFormik<FormData>({
    initialValues: {
      email: '',
      password: '',
      rememberMe: false
    },
    validationSchema: loginSchema(t),
    onSubmit: async ({ email, password, rememberMe }) => {
      mutate(
        {
          username: email,
          password
        },
        {
          onSuccess: ({ access_token, refresh_token }) => {
            setLocalTokens(access_token, refresh_token);

            if (rememberMe) {
              localStorage.setItem(LOCALSTORAGE_KEYS.REMEMBER_ME, 'true');
            }
            document.querySelector('#fb-root')?.classList.add('vanish');
            // navigate('/my-course');
          },
          onError: (err) => {
            if (err.response?.status === 400) {
              setError(true);
            }
          }
        }
      );
    },
    validateOnBlur: false,
    validateOnChange: false
  });

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <form onSubmit={formik.handleSubmit}>
            <Stack mb={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                fontSize={22}
                textAlign="center">
                {t('headings.login')}
              </Typography>
            </Stack>
            <Stack direction="column" mt={6} spacing={2} pb={3} width="100%">
              <FormControl>
                <TextField
                  label={t('labels.emailAdress')}
                  variant="outlined"
                  name="email"
                  type="email"
                  placeholder={t('labels.emailAdress')}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  helperText={formik.errors.email}
                  error={Boolean(formik.errors.email)}
                  FormHelperTextProps={{
                    error: Boolean(formik.errors.email)
                  }}
                />
              </FormControl>
              <FormControl>
                <InputWithIcon
                  label={t('labels.password')}
                  variant="outlined"
                  name="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  helperText={formik.errors.password}
                  error={Boolean(formik.errors.password)}
                  FormHelperTextProps={{
                    error: Boolean(formik.errors.password)
                  }}
                />
              </FormControl>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      disableRipple
                      name="rememberMe"
                      icon={<CheckBoxUnchecked />}
                      checkedIcon={<CheckboxChecked />}
                      checked={formik.values.rememberMe}
                      onChange={() => formik.setFieldValue('rememberMe', !formik.values.rememberMe)}
                    />
                  }
                  label={
                    <Typography variant="subtitle2" fontSize={16}>
                      {t('labels.remeberMe')}
                    </Typography>
                  }
                />
              </FormGroup>
            </Stack>
            {error ? (
              <FormHelperText sx={{ textAlign: 'center', fontSize: 16, mb: 2 }} error={error}>
                {t('errors.auth')}
              </FormHelperText>
            ) : null}
            <Box textAlign="center" display="flex" flexDirection="column">
              <Button
                type="submit"
                disabled={isLoading}
                size="extraLarge"
                variant="primary"
                sx={{
                  width: '260px',
                  margin: 'auto'
                }}>
                <Typography fontSize={14} fontWeight="bold" color="white" textTransform="uppercase">
                  {t('button.login')}
                </Typography>
              </Button>

              <Button
                variant="text"
                size="auto"
                onClick={() => navigate('/restore-password')}
                sx={{
                  marginTop: 3
                }}>
                <Typography textAlign="center" color="violet">
                  {t('button.forgotPassword')}
                </Typography>
              </Button>
              <Box gap={1} mt={2} display="flex" justifyContent="center" flexWrap="wrap">
                <Typography fontSize={16} fontWeight={500} color="text.primary">
                  {t('messages.notRegistered')}
                </Typography>
                <Button
                  variant="text"
                  size="auto"
                  href={`${process.env.REACT_APP_SKILLWILL_BASE_URL}/register`}
                  target="_blank"
                  rel="noreferrer">
                  <Typography fontSize={16} color="violet">
                    {t('button.signUp')}
                  </Typography>
                </Button>
              </Box>
            </Box>
          </form>
        </Grid>
      </Grid>

      {/* <button onClick={() => sendMessageToIframe('sdsdsdsdsd')}>SEND</button> */}
    </>
  );
};

export default Login;
