import { AxiosError } from 'axios';
import { uploadHomework } from 'src/services/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UploadHomeworkPayload } from './../../models/service';
import { QUERY_KEYS } from 'src/enums';

const useUploadHomework = () => {
  const client = useQueryClient();

  const { mutate, isLoading, error } = useMutation<void, AxiosError, UploadHomeworkPayload>(
    ['upload-homework'],
    (payload: UploadHomeworkPayload) => uploadHomework(payload),
    {
      onSuccess: () => {
        client.invalidateQueries([QUERY_KEYS.ASSIGNMENT]);
      }
    }
  );

  return {
    mutate,
    isLoading,
    error
  };
};

export default useUploadHomework;
