import React from 'react';
import { LOCALES } from 'src/enums';
import { HEADER_ROUTES } from 'src/constants';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Typography } from '@mui/material';
import { geFlag, enFlag } from 'src/assets/images';
import azFlag from 'src/assets/icons/azFlag.svg';
import Logo from 'src/components/common/logo/Logo';
import BurgerMenu from 'src/components/common/BurgerMenu';
import { PrimaryWrapper, LangLogo } from './styles';
import { useLanguage, useNavigate } from 'src/context/languageContext';
import { Box } from '@mui/system';

interface HeaderProps {
  className?: any;
}

const Header: React.FC<HeaderProps> = ({ className }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { locale, changeLocale } = useLanguage();

  return (
    <React.Fragment>
      <PrimaryWrapper
        container
        className={className}
        py={{ sm: 2.5, xs: 2 }}
        px={{ l: 12, md: 10, sm: 6, xs: 3 }}>
        <Grid>
          <Logo />
        </Grid>
        <Grid>
          <BurgerMenu />
        </Grid>
        <Grid
          item
          mt={0.5}
          alignItems="center"
          gap={{ l: 4, xs: 2.5 }}
          display={{ sm: 'flex', xs: 'none' }}>
          {HEADER_ROUTES.map((route) => {
            return (
              <a href={`${route.path}`} key={route.id} target="_blank" rel="noreferrer">
                <Typography
                  variant="body1"
                  fontWeight={450}
                  textAlign="center"
                  color="text.primary"
                  textTransform="uppercase"
                  sx={{ fontFeatureSettings: "'case' on" }}
                  fontSize={{ l: 15.5, md: 13, sm: 12 }}>
                  {route.translations[locale]}
                </Typography>
              </a>
            );
          })}

          {process.env.REACT_APP_SKILLWILL_COUNTRY === 'ge' && (
            <>
              {locale === LOCALES.GE && (
                <LangLogo onClick={changeLocale}>
                  <img src={geFlag} alt="georgian" />
                </LangLogo>
              )}
              {locale === LOCALES.EN && (
                <LangLogo onClick={changeLocale}>
                  <img src={enFlag} alt="english" />
                </LangLogo>
              )}
            </>
          )}
          {process.env.REACT_APP_SKILLWILL_COUNTRY === 'az' && (
            <>
              {locale === LOCALES.AZ && (
                <LangLogo onClick={changeLocale}>
                  <img src={azFlag} alt="azerbaijan" />
                </LangLogo>
              )}
              {locale === LOCALES.EN && (
                <LangLogo onClick={changeLocale}>
                  <img src={enFlag} alt="english" />
                </LangLogo>
              )}
            </>
          )}

          <Box display="flex" gap="8px">
            <Button
              onClick={() => {
                window.open(
                  process.env.REACT_APP_SKILLWILL_BASE_URL + '/register',
                  '_blank',
                  'noopener,noreferrer'
                );
              }}
              sx={{
                fontFeatureSettings: "'case' on",
                backgroundColor: '#2A7DE1',
                border: 'none',
                paddingInline: 2,
                minWidth: 64
              }}
              variant="primary">
              <Typography fontSize="14px !important" textTransform="uppercase" color="white">
                {t('button.signUp')}
              </Typography>
            </Button>
            <Button
              onClick={() => navigate('/login')}
              size="extraLarge"
              sx={{ fontFeatureSettings: "'case' on", minWidth: 64, paddingInline: 3 }}
              variant="filled">
              <Typography fontSize="14px !important" textTransform="uppercase" color="white">
                {t('newLanding.oceanButton')}
              </Typography>
            </Button>
          </Box>
        </Grid>
      </PrimaryWrapper>
    </React.Fragment>
  );
};

export default Header;
