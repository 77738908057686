import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'src/context/languageContext';
import { addCriteriaSchema } from 'src/helpers/validations';
import useAddCriteria from 'src/hooks/mutations/useAddCriteria';
import useEditCriteria from 'src/hooks/mutations/useEditCriteria';
import { Button, Grid, TextField, Typography } from '@mui/material';

interface Props {
  criteria: {
    id: number;
    ka: { name: string; description: string };
    en: { name: string; description: string };
  };
  crtId: string;
}

export const AddCategoryForm = ({ criteria, crtId }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      criteriaTitleEn: criteria?.en.name || '',
      criteriaTitleKa: criteria?.ka.name || '',
      criteriaDescEn: criteria?.en.description || '',
      criteriaDescKa: criteria?.ka.description || ''
    },
    validationSchema: addCriteriaSchema(t),
    onSubmit: async ({ criteriaDescEn, criteriaDescKa, criteriaTitleKa, criteriaTitleEn }) => {
      const payload = {
        en: {
          name: criteriaTitleEn,
          description: criteriaDescEn
        },
        ka: {
          name: criteriaTitleKa,
          description: criteriaDescKa
        }
      };

      if (crtId) {
        editCriteriaMutation(payload);
      } else {
        addCriteriaMutation(payload);
      }
    },
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true
  });

  const { editCriteriaMutation } = useEditCriteria(String(crtId));
  const { addCriteriaMutation, isLoading } = useAddCriteria(formik.resetForm);

  return (
    <form style={{ marginTop: '18px' }} onSubmit={formik.handleSubmit}>
      <Grid container justifyContent="flex-end" gap={1.5}>
        <TextField
          variant="outlined"
          name="criteriaTitleKa"
          onChange={formik.handleChange}
          label={t('labels.criteriaTitleKa')}
          value={formik.values.criteriaTitleKa}
          helperText={formik.errors.criteriaTitleKa}
          error={Boolean(formik.errors.criteriaTitleKa)}
          FormHelperTextProps={{
            style: { whiteSpace: 'normal' },
            error: Boolean(formik.errors.criteriaTitleKa)
          }}
        />
        <TextField
          variant="outlined"
          name="criteriaTitleEn"
          onChange={formik.handleChange}
          label={t('labels.criteriaTitleEn')}
          value={formik.values.criteriaTitleEn}
          helperText={formik.errors.criteriaTitleEn}
          error={Boolean(formik.errors.criteriaTitleEn)}
          FormHelperTextProps={{
            style: { whiteSpace: 'normal' },
            error: Boolean(formik.errors.criteriaTitleEn)
          }}
        />
        <TextField
          variant="outlined"
          name="criteriaDescKa"
          onChange={formik.handleChange}
          label={t('labels.criteriaDescKa')}
          value={formik.values.criteriaDescKa}
          helperText={formik.errors.criteriaDescKa}
          error={Boolean(formik.errors.criteriaDescKa)}
          FormHelperTextProps={{
            style: { whiteSpace: 'normal' },
            error: Boolean(formik.errors.criteriaDescKa)
          }}
        />
        <TextField
          variant="outlined"
          name="criteriaDescEn"
          onChange={formik.handleChange}
          label={t('labels.criteriaDescEn')}
          value={formik.values.criteriaDescEn}
          helperText={formik.errors.criteriaDescEn}
          error={Boolean(formik.errors.criteriaDescEn)}
          FormHelperTextProps={{
            style: { whiteSpace: 'normal' },
            error: Boolean(formik.errors.criteriaDescEn)
          }}
        />
        <Grid mt={3} display="flex" gap={1}>
          {crtId && (
            <Button variant="secondary" onClick={() => navigate('/content-categories')}>
              <Typography>{t('button.createNew')}</Typography>
            </Button>
          )}
          <Button variant="primary" disabled={isLoading} type="submit">
            <Typography>{t('button.submit')}</Typography>
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddCategoryForm;
