const SKILLWILL_BASE_URL = process.env.REACT_APP_SKILLWILL_BASE_URL;
export const SKILLWILL_TARGET_ORIGIN = String(
  process.env.REACT_APP_SKILLWILL_BASE_URL?.slice(0, -1)
);

export const ERROR_CODES = {
  DuplicateUserName: {
    code: 'email',
    message: 'duplicateEmail'
  },
  Email_Registered: {
    code: 'email',
    message: 'duplicateEmail'
  },
  Phone_Registered: {
    code: 'phoneNumber',
    message: 'duplicatePhoneNumber'
  }
};

export const TIME_OPTIONS = [
  { label: '01:00', value: '01:00' },
  { label: '02:00', value: '02:00' },
  { label: '03:00', value: '03:00' },
  { label: '04:00', value: '04:00' },
  { label: '05:00', value: '05:00' },
  { label: '06:00', value: '06:00' },
  { label: '07:00', value: '07:00' },
  { label: '08:00', value: '08:00' },
  { label: '09:00', value: '09:00' },
  { label: '10:00', value: '10:00' },
  { label: '11:00', value: '11:00' },
  { label: '12:00', value: '12:00' },
  { label: '13:00', value: '13:00' },
  { label: '14:00', value: '14:00' },
  { label: '15:00', value: '15:00' },
  { label: '16:00', value: '16:00' },
  { label: '17:00', value: '17:00' },
  { label: '18:00', value: '18:00' },
  { label: '19:00', value: '19:00' },
  { label: '20:00', value: '20:00' },
  { label: '21:00', value: '21:00' },
  { label: '22:00', value: '22:00' },
  { label: '23:00', value: '23:00' }
];

export const HEADER_ROUTES = [
  {
    id: 1,
    path: `${SKILLWILL_BASE_URL}/buy-course`,
    translations: { en: 'Courses', ge: 'კურსები', az: 'Kurslar' }
  },
  {
    id: 5,
    path: `${SKILLWILL_BASE_URL}/about-us`,
    translations: { en: 'About Us', ge: 'ჩვენ შესახებ', az: 'bizim haqqımızda' }
  },
  {
    id: 2,
    path: `${SKILLWILL_BASE_URL}/become-partner?type=1`,
    translations: { en: 'Become a partner', ge: 'გახდი პარტნიორი', az: 'Partnyor ol' }
  },
  // {
  //   id: 3,
  //   path: '/work-with-us',
  //   translations: { en: 'Become a tutor', ge: 'დასაქმება' }
  // },
  {
    id: 7,
    path: `${SKILLWILL_BASE_URL}/posts`,
    translations: { en: 'News', ge: 'სიახლეები', az: 'Xəbərlər' }
  },
  {
    id: 4,
    path: `${SKILLWILL_BASE_URL}/faq`,
    translations: { en: 'FAQ', ge: 'FAQ', az: 'Tez-tez verilən suallar' }
  },
  {
    id: 6,
    path: `${SKILLWILL_BASE_URL}/contact-us`,
    translations: { en: 'Contact', ge: 'კონტაქტი', az: 'Bizimlə əlaqə' }
  }
];

export const COURSE_TABS = [
  {
    title: { en: 'general', ge: 'ზოგადი', az: 'general' },
    path: '/my-course/general',
    admin: true
  },
  { title: { en: 'specialization', ge: 'სპეციალიზაცია' }, path: '/my-course/specific', admin: true }
];

export const PROFILE_TABS = [
  { title: { en: 'profile', ge: 'პროფილი', az: 'profil' }, path: '/profile' },
  {
    title: { en: 'change password', ge: 'პაროლის შეცვლა', az: 'parol dəyişdirin' },
    path: '/profile/change-password'
  },
  { title: { en: 'status', ge: 'სტატუსი', az: 'status' }, path: '/profile/status' }
];

export const COUNTRY_CODES = [
  {
    name: 'Albania',
    dial_code: '+355',
    code: 'AL'
  },
  {
    name: 'Andorra',
    dial_code: '+376',
    code: 'AD'
  },
  {
    name: 'Argentina',
    dial_code: '+54',
    code: 'AR'
  },
  {
    name: 'Armenia',
    dial_code: '+374',
    code: 'AM'
  },
  {
    name: 'Australia',
    dial_code: '+61',
    code: 'AU'
  },
  {
    name: 'Austria',
    dial_code: '+43',
    code: 'AT'
  },
  {
    name: 'Azerbaijan',
    dial_code: '+994',
    code: 'AZ'
  },
  {
    name: 'Bangladesh',
    dial_code: '+880',
    code: 'BD'
  },
  {
    name: 'Belarus',
    dial_code: '+375',
    code: 'BY'
  },
  {
    name: 'Belgium',
    dial_code: '+32',
    code: 'BE'
  },
  {
    name: 'Bosnia and Herz.',
    dial_code: '+387',
    code: 'BA'
  },
  {
    name: 'Brazil',
    dial_code: '+55',
    code: 'BR'
  },
  {
    name: 'Bulgaria',
    dial_code: '+359',
    code: 'BG'
  },
  {
    name: 'Canada',
    dial_code: '+1',
    code: 'CA'
  },
  {
    name: 'Chile',
    dial_code: '+56',
    code: 'CL'
  },
  {
    name: 'China',
    dial_code: '+86',
    code: 'CN'
  },
  {
    name: 'Colombia',
    dial_code: '+57',
    code: 'CO'
  },
  {
    name: 'Costa Rica',
    dial_code: '+506',
    code: 'CR'
  },
  {
    name: 'Croatia',
    dial_code: '+385',
    code: 'HR'
  },
  {
    name: 'Cyprus',
    dial_code: '+357',
    code: 'CY'
  },
  {
    name: 'Czech Republic',
    dial_code: '+420',
    code: 'CZ'
  },
  {
    name: 'Denmark',
    dial_code: '+45',
    code: 'DK'
  },
  {
    name: 'Ecuador',
    dial_code: '+593',
    code: 'EC'
  },
  {
    name: 'Egypt',
    dial_code: '+20',
    code: 'EG'
  },
  {
    name: 'Estonia',
    dial_code: '+372',
    code: 'EE'
  },
  {
    name: 'Ethiopia',
    dial_code: '+251',
    code: 'ET'
  },
  {
    name: 'Faroe Islands',
    dial_code: '+298',
    code: 'FO'
  },
  {
    name: 'Fiji',
    dial_code: '+679',
    code: 'FJ'
  },
  {
    name: 'Finland',
    dial_code: '+358',
    code: 'FI'
  },
  {
    name: 'France',
    dial_code: '+33',
    code: 'FR'
  },
  {
    name: 'Georgia',
    dial_code: '+995',
    code: 'GE'
  },
  {
    name: 'Germany',
    dial_code: '+49',
    code: 'DE'
  },
  {
    name: 'Ghana',
    dial_code: '+233',
    code: 'GH'
  },
  {
    name: 'Gibraltar',
    dial_code: '+350',
    code: 'GI'
  },
  {
    name: 'Greece',
    dial_code: '+30',
    code: 'GR'
  },
  {
    name: 'Haiti',
    dial_code: '+509',
    code: 'HT'
  },
  {
    name: 'Honduras',
    dial_code: '+504',
    code: 'HN'
  },
  {
    name: 'Hong Kong',
    dial_code: '+852',
    code: 'HK'
  },
  {
    name: 'Hungary',
    dial_code: '+36',
    code: 'HU'
  },
  {
    name: 'Iceland',
    dial_code: '+354',
    code: 'IS'
  },
  {
    name: 'India',
    dial_code: '+91',
    code: 'IN'
  },
  {
    name: 'Indonesia',
    dial_code: '+62',
    code: 'ID'
  },
  {
    name: 'Iran',
    dial_code: '+98',
    code: 'IR'
  },
  {
    name: 'Ireland',
    dial_code: '+353',
    code: 'IE'
  },
  {
    name: 'Israel',
    dial_code: '+972',
    code: 'IL'
  },
  {
    name: 'Italy',
    dial_code: '+39',
    code: 'IT'
  },
  {
    name: 'Jamaica',
    dial_code: '+1876',
    code: 'JM'
  },
  {
    name: 'Japan',
    dial_code: '+81',
    code: 'JP'
  },
  {
    name: 'Kazakhstan',
    dial_code: '+77',
    code: 'KZ'
  },
  {
    name: 'South Korea',
    dial_code: '+82',
    code: 'KR'
  },
  {
    name: 'Kuwait',
    dial_code: '+965',
    code: 'KW'
  },
  {
    name: 'Kyrgyzstan',
    dial_code: '+996',
    code: 'KG'
  },
  {
    name: 'Latvia',
    dial_code: '+371',
    code: 'LV'
  },
  {
    name: 'Liechtenstein',
    dial_code: '+423',
    code: 'LI'
  },
  {
    name: 'Lithuania',
    dial_code: '+370',
    code: 'LT'
  },
  {
    name: 'Luxembourg',
    dial_code: '+352',
    code: 'LU'
  },
  {
    name: 'Macedonia',
    dial_code: '+389',
    code: 'MK'
  },
  {
    name: 'Malta',
    dial_code: '+356',
    code: 'MT'
  },
  {
    name: 'Mexico',
    dial_code: '+52',
    code: 'MX'
  },
  {
    name: 'Moldova',
    dial_code: '+373',
    code: 'MD'
  },
  {
    name: 'Monaco',
    dial_code: '+377',
    code: 'MC'
  },
  {
    name: 'Mongolia',
    dial_code: '+976',
    code: 'MN'
  },
  {
    name: 'Montenegro',
    dial_code: '+382',
    code: 'ME'
  },
  {
    name: 'Morocco',
    dial_code: '+212',
    code: 'MA'
  },
  {
    name: 'Netherlands',
    dial_code: '+31',
    code: 'NL'
  },
  {
    name: 'New Zealand',
    dial_code: '+64',
    code: 'NZ'
  },
  {
    name: 'Nigeria',
    dial_code: '+234',
    code: 'NG'
  },
  {
    name: 'Norway',
    dial_code: '+47',
    code: 'NO'
  },
  {
    name: 'Poland',
    dial_code: '+48',
    code: 'PL'
  },
  {
    name: 'Portugal',
    dial_code: '+351',
    code: 'PT'
  },
  {
    name: 'Puerto Rico',
    dial_code: '+1939',
    code: 'PR'
  },
  {
    name: 'Qatar',
    dial_code: '+974',
    code: 'QA'
  },
  {
    name: 'Romania',
    dial_code: '+40',
    code: 'RO'
  },
  {
    name: 'Russia',
    dial_code: '+7',
    code: 'RU'
  },
  {
    name: 'San Marino',
    dial_code: '+378',
    code: 'SM'
  },
  {
    name: 'Saudi Arabia',
    dial_code: '+966',
    code: 'SA'
  },
  {
    name: 'Serbia',
    dial_code: '+381',
    code: 'RS'
  },
  {
    name: 'Singapore',
    dial_code: '+65',
    code: 'SG'
  },
  {
    name: 'Slovakia',
    dial_code: '+421',
    code: 'SK'
  },
  {
    name: 'Slovenia',
    dial_code: '+386',
    code: 'SI'
  },
  {
    name: 'South Africa',
    dial_code: '+27',
    code: 'ZA'
  },
  {
    name: 'Spain',
    dial_code: '+34',
    code: 'ES'
  },
  {
    name: 'Sweden',
    dial_code: '+46',
    code: 'SE'
  },
  {
    name: 'Switzerland',
    dial_code: '+41',
    code: 'CH'
  },
  {
    name: 'Tajikistan',
    dial_code: '+992',
    code: 'TJ'
  },
  {
    name: 'Thailand',
    dial_code: '+66',
    code: 'TH'
  },
  {
    name: 'Tunisia',
    dial_code: '+216',
    code: 'TN'
  },
  {
    name: 'Turkey',
    dial_code: '+90',
    code: 'TR'
  },
  {
    name: 'Turkmenistan',
    dial_code: '+993',
    code: 'TM'
  },
  {
    name: 'Ukraine',
    dial_code: '+380',
    code: 'UA'
  },
  {
    name: 'UAE',
    dial_code: '+971',
    code: 'AE'
  },
  {
    name: 'United Kingdom',
    dial_code: '+44',
    code: 'GB'
  },
  {
    name: 'United States',
    dial_code: '+1',
    code: 'US'
  },
  {
    name: 'Uruguay',
    dial_code: '+598',
    code: 'UY'
  },
  {
    name: 'Uzbekistan',
    dial_code: '+998',
    code: 'UZ'
  }
];
