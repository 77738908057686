import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import styled from 'styled-components';
import image from 'src/assets/icons/email.svg';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import useQueryString from 'src/hooks/useQueryString';
import { resetPassword } from 'src/services/api';
import { useFeedbackModal } from 'src/context/feedbackModalContext';
import { useLanguage } from 'src/context/languageContext';
import palette from 'src/theme/overrides/palette';

const EmailImage = styled.img`
  width: 75px;
  height: 60px;
`;

const ResendPassword = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { parseQuery } = useQueryString();
  const { constructModal } = useFeedbackModal();
  const { email = '' } = parseQuery();
  const { locale } = useLanguage();

  const resendToken = async () => {
    setLoading(true);
    const lang = locale === 'en' ? '1' : '2';
    try {
      await resetPassword(
        {
          email: email.toString()
        },
        lang
      );
      constructModal({
        title: t('common.success'),
        type: 'success',
        subtitle: t('messages.passwordResetSent'),
        btnText: t('modal.close')
      });
    } catch (err) {
      // console.log(err);
    }
    setLoading(false);
  };
  return (
    <Grid container justifyContent="center" textAlign="center">
      <Grid item xs={12}>
        <Typography variant="body1" color="violet" fontSize={22} textAlign="center">
          {t('headings.login')}
        </Typography>
      </Grid>
      <Grid item xs={12} mt={3}>
        <EmailImage src={image} alt="email" />
      </Grid>
      <Grid item xs={12} mt={4} textAlign="center">
        <Typography variant="subtitle3">{t('messages.passwordResetSent')}</Typography>
      </Grid>
      <Grid item xs={12} mt={3}>
        <Button
          size="auto"
          variant="text"
          disabled={loading}
          onClick={resendToken}
          sx={{ color: palette.violet }}>
          {t('button.resend')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ResendPassword;
