import { Typography } from '@mui/material';
import React, { FC } from 'react';
import { ActionsCardInnerContainer, StatusBox, FlexRow, FlexBox } from './styles';
import { useTranslation } from 'react-i18next';
import palette from 'src/theme/overrides/palette';
import Navigate from 'src/components/common/navigate';
import useProfile from 'src/hooks/queries/useProfile';
import useUserCourse from 'src/hooks/queries/useUserCourse';

const UserStatus: FC = () => {
  const { t } = useTranslation();
  const { myCourses = [] } = useProfile();
  const isRegisteredOnCourse = myCourses[0]?.donePayment;
  const { data: course } = useUserCourse();

  if (!isRegisteredOnCourse) {
    return <Navigate to="/profile" />;
  }

  return (
    <ActionsCardInnerContainer>
      <StatusBox>
        <FlexRow>
          <FlexBox className="fr">
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              {t('profile.course')}
            </Typography>
          </FlexBox>

          <FlexBox className="sc">
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              {t('profile.price')}
            </Typography>
          </FlexBox>

          <FlexBox className="th">
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              {t('profile.status')}
            </Typography>
          </FlexBox>
        </FlexRow>

        <FlexRow>
          <FlexBox className="fr">
            <Typography
              variant="body2"
              fontSize={'18px'}
              fontWeight={{ sx: '300', mb3: '500' }}
              color={palette.text.primary}
              style={{ opacity: '0.7' }}>
              {course?.name}
            </Typography>
          </FlexBox>

          <FlexBox className="sc">
            <Typography
              variant="body2"
              fontSize={'18px'}
              fontWeight={{ sx: '300', mb3: '500' }}
              color={palette.text.primary}
              style={{ opacity: '0.7' }}>
              {course?.fee}
            </Typography>
          </FlexBox>

          <FlexBox className="th">
            <Typography
              variant="body2"
              fontSize={'18px'}
              fontWeight={{ sx: '300', mb3: '500' }}
              color={palette.text.primary}
              style={{ opacity: '0.7' }}>
              {isRegisteredOnCourse ? t('common.paid') : t('common.notPaid')}
            </Typography>
          </FlexBox>
        </FlexRow>
      </StatusBox>
    </ActionsCardInnerContainer>
  );
};

export default UserStatus;
