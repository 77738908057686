import { QUERY_KEYS } from 'src/enums';
import { getProgress } from 'src/services/api';
import { useQuery } from '@tanstack/react-query';
import useLanguageCode from '../useLanguageCode';
import { useAuth } from 'src/context/authContext';
import { ProgressPayload } from 'src/models/service';

const useProgress = (params: ProgressPayload) => {
  const { isAdmin } = useAuth();
  const { langCode } = useLanguageCode();
  const {
    data: progress,
    isLoading,
    isError
  } = useQuery([QUERY_KEYS.PROGRESS, params], () => getProgress(langCode, params), {
    enabled: !isAdmin
  });

  return {
    isError,
    progress,
    isLoading
  };
};

export default useProgress;
