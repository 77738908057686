import React, { FC } from 'react';
import { SubCourseInfo } from 'src/models/service';
import Grid from '@mui/material/Grid';
import SpecCard from 'src/components/common/SpecCard';
import { useNavigate } from 'src/context/languageContext';
import Loader from 'src/components/common/Loader';
import useSubCourses from 'src/hooks/queries/useSubCourses';

const SubCourses: FC = () => {
  const navigate = useNavigate();
  const { subCourses, isLoading } = useSubCourses();

  const handleNavigation = (spec: SubCourseInfo) => {
    navigate(`/my-course/specific/chapter/${spec.id}`);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Grid container spacing={2}>
      {subCourses?.map((spec: any) => (
        <Grid key={spec.id} item width={{ xs: 160, mb2: 276 }} maxHeight={{ xs: 270, mb2: 333 }}>
          <SpecCard spec={spec} clickHandler={handleNavigation} />
        </Grid>
      ))}
    </Grid>
  );
};

export default SubCourses;
