import React, {
  createContext,
  Dispatch,
  FC,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';

interface ModalProps {
  title: string;
  subtitle: string;
  btnText: string;
  type?: 'error' | 'success' | '';
  locale?: 'ge' | 'en' | 'az' | '';
  action?: () => void;
}

export interface FeedbackModalContextInterface {
  modalProps: ModalProps;
  setModalProps: Dispatch<SetStateAction<ModalProps>>;
  constructModal: (mdl: ModalProps) => void;
  resetModal: () => void;
}

export const FeedbackModalContext = createContext<FeedbackModalContextInterface | null>(null);

interface Props {
  children: ReactNode;
}

const FeedbackModalContextProvider: FC<Props> = ({ children }): ReactElement => {
  const [modalProps, setModalProps] = useState<ModalProps>({
    btnText: '',
    title: '',
    subtitle: '',
    type: '',
    locale: ''
  });

  const constructModal = useCallback((modal: ModalProps) => {
    setModalProps(modal);
  }, []);

  const resetModal = useCallback(() => {
    setModalProps({
      btnText: 'Okay',
      title: '',
      subtitle: '',
      type: '',
      locale: ''
    });
  }, []);

  const contextValues = useMemo(
    () => ({
      modalProps,
      setModalProps,
      constructModal,
      resetModal
    }),
    [modalProps, setModalProps, constructModal, resetModal]
  );

  return (
    <FeedbackModalContext.Provider value={contextValues}>{children}</FeedbackModalContext.Provider>
  );
};

export default FeedbackModalContextProvider;

export const useFeedbackModal = () => {
  const value = useContext(FeedbackModalContext);

  if (!value) {
    throw new Error('Auth Context Provider is not defined');
  }
  return value;
};
