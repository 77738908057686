import { AxiosError } from 'axios';
import { QUERY_KEYS } from 'src/enums';
import useLanguageCode from '../useLanguageCode';
import { PublishContentPayload } from 'src/models/service';
import { publishCareerContent } from './../../services/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const usePublishContent = () => {
  const client = useQueryClient();
  const { lang } = useLanguageCode();

  const { mutate, isLoading } = useMutation<
    { success?: boolean },
    AxiosError,
    { id: number; payload: PublishContentPayload }
  >(['publish-content'], ({ id, payload }) => publishCareerContent(id, payload, lang), {
    onSuccess: () => {
      client.invalidateQueries([QUERY_KEYS.CAREER_CONTENT]);
    }
  });

  return {
    mutate,
    isLoading
  };
};

export default usePublishContent;
