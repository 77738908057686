import { Button, Grid, styled } from '@mui/material';
import { breakPoints } from 'src/theme/defaultTheme';

export const CareerCard = styled(Grid)`
  background: #fff;
  justify-content: center;
  border: 1px solid #e4e4e4;
  border-radius: 16px;
  width: 23%;

  @media (max-width: ${breakPoints.l}px) {
    width: 30%;
    max-width: none;
  }
`;

export const SecondaryButton = styled(Button)`
  height: 30px;
  color: #707070;
  font-size: 14px;
  font-weight: 400;
  border-color: #707070;
`;

export const PublishButton = styled(Button)`
  height: 42px;
  font-size: 14px;
  font-weight: 400;
`;
