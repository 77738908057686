import { Typography } from '@mui/material';
import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useLanguage } from 'src/context/languageContext';
import useDetectDevice from 'src/hooks/useDetectDevice';
import Logo from 'src/components/common/logo/Logo';
import {
  ContactSection,
  Container,
  FlexContainer,
  Info,
  InvoiceTable,
  LogoContainer,
  MobileTableContainer,
  MobileTitle,
  MobileValue,
  Subtitle,
  TableWrapper,
  Title,
  TopBar
} from './styles';

interface Props {
  fee: number;
  name: string;
}

const Invoice: FC<Props> = ({ fee = 0, name }): ReactElement => {
  const { t } = useTranslation();
  const { locale } = useLanguage();
  const { isSmall } = useDetectDevice();

  return (
    <Container>
      <TopBar>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        {/* <TopBarRightSide /> */}
      </TopBar>
      <Info>
        <Title>{t('invoice.skillWill')}</Title>
        <Subtitle>{t('invoice.id')}</Subtitle>
      </Info>

      {!isSmall ? (
        <>
          <TableWrapper>
            <InvoiceTable>
              <tbody>
                <tr>
                  <th colSpan={2}>{t('invoice.description')}</th>
                  <th>{t('invoice.amount')}</th>
                </tr>
                <tr>
                  <td colSpan={2} style={{ textAlign: 'start', paddingLeft: '20px' }}>
                    სწავლის საფასური - სახელი, გვარი
                  </td>
                  <td>{`${fee} ₾`}</td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <Typography fontWeight={'bold'} color="#FE3B1F">
                      {t('invoice.total')}
                    </Typography>
                  </td>
                  <td>{`${fee} ₾`}</td>
                </tr>
              </tbody>
            </InvoiceTable>
          </TableWrapper>
          <TableWrapper>
            <InvoiceTable>
              <tbody>
                <tr>
                  <th colSpan={2}>{t('invoice.bankName')}</th>
                  <th>{t('invoice.bankCode')}</th>
                  <th colSpan={2}>{t('invoice.iban')}</th>
                </tr>
                <tr>
                  <td colSpan={2}>{t('invoice.tbc')}</td>
                  <td>TBCBGE22</td>
                  <td colSpan={2}>GE84TB7113136080100008</td>
                </tr>
                <tr>
                  <td colSpan={2}>{t('invoice.bog')}</td>
                  <td>BAGAGE22</td>
                  <td colSpan={2}>GE40BG0000000538184123</td>
                </tr>
              </tbody>
            </InvoiceTable>
          </TableWrapper>
        </>
      ) : (
        <>
          <MobileTableContainer>
            <FlexContainer>
              <MobileTitle>{t('invoice.description')}</MobileTitle>
              <MobileValue
                style={{
                  borderBottom: 'none'
                }}>
                {name}
              </MobileValue>
            </FlexContainer>

            <FlexContainer>
              <MobileTitle style={{ borderTop: '1px solid #ffffff' }}>
                {t('invoice.amount')}
              </MobileTitle>
              <MobileValue>{`${fee} ₾`}</MobileValue>
            </FlexContainer>
          </MobileTableContainer>
          <MobileTableContainer>
            <FlexContainer>
              <MobileTitle>{t('invoice.iban')}</MobileTitle>
              <MobileValue
                style={{
                  borderBottom: 'none'
                }}>
                GE84TB7113136080100008
              </MobileValue>
            </FlexContainer>
            <FlexContainer>
              <MobileTitle
                style={{ borderTop: '1px solid #ffffff', borderBottom: '1px solid #ffffff' }}>
                {t('invoice.bankName')}
              </MobileTitle>
              <MobileValue
                style={{
                  borderBottom: 'none'
                }}>
                {t('invoice.tbc')}
              </MobileValue>
            </FlexContainer>
            <FlexContainer>
              <MobileTitle>{t('invoice.bankCode')}</MobileTitle>
              <MobileValue>TBCBGE22</MobileValue>
            </FlexContainer>
          </MobileTableContainer>
          <MobileTableContainer>
            <FlexContainer>
              <MobileTitle>{t('invoice.iban')}</MobileTitle>
              <MobileValue
                style={{
                  borderBottom: 'none'
                }}>
                GE40BG0000000538184123
              </MobileValue>
            </FlexContainer>
            <FlexContainer>
              <MobileTitle
                style={{ borderTop: '1px solid #ffffff', borderBottom: '1px solid #ffffff' }}>
                {t('invoice.bankName')}
              </MobileTitle>
              <MobileValue
                style={{
                  borderBottom: 'none'
                }}>
                {t('invoice.bog')}
              </MobileValue>
            </FlexContainer>
            <FlexContainer>
              <MobileTitle>{t('invoice.bankCode')}</MobileTitle>
              <MobileValue>BAGAGE22</MobileValue>
            </FlexContainer>
          </MobileTableContainer>
        </>
      )}

      <ContactSection>
        <div className="info">
          <Typography className={`text-${locale}`}>{t('invoice.invoiceTop')}</Typography>
          <Typography className={`text-${locale} nino-bold-${locale}`} variant="descriptionSmall">
            {t('invoice.invoiceTopValue')}
          </Typography>
        </div>

        <div className="contact">
          <Typography className={`text-${locale}`}>{t('invoice.invoiceBottom')}</Typography>
          <Typography className={`text-en`} variant="descriptionSmall">
            {t('invoice.invoiceBottomValue')}
          </Typography>
        </div>
      </ContactSection>
    </Container>
  );
};

export default Invoice;
