import useLanguageCode from 'src/hooks/useLanguageCode';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { QUERY_KEYS } from 'src/enums';
import { deleteMonth } from 'src/services/api';

const useDeleteMonth = () => {
  const client = useQueryClient();
  const { lang } = useLanguageCode();

  const { mutate, isLoading } = useMutation<
    { success?: boolean },
    AxiosError,
    { month: number; chapter_id: number }
  >(['delete-month'], ({ month, chapter_id }) => deleteMonth(month, { chapter_id }, lang), {
    onSuccess: () => {
      client.invalidateQueries([QUERY_KEYS.SYLLABUS]);
    }
  });

  return {
    mutate,
    isLoading
  };
};

export default useDeleteMonth;
