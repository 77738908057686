import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import Navigate from 'src/components/common/navigate';
import useCourseStatus from 'src/hooks/queries/useCourseStatus';

const PaymentInProgress = () => {
  const { t } = useTranslation();
  const { data } = useCourseStatus();

  if (data?.data?.status === 'success') {
    return <Navigate to="/buy-course/success" />;
  }

  if (data?.data?.status === 'error') {
    return <Navigate to="/buy-course/fail" />;
  }

  return (
    <Grid container justifyContent="center">
      <Typography
        color="red"
        fontWeight="bold"
        textTransform="uppercase"
        fontSize={{ sm: 32, xs: 24 }}>
        {t('course.paymentProgress')}...
      </Typography>
    </Grid>
  );
};

export default PaymentInProgress;
