import { Box, Grid } from '@mui/material';
import styled from '@mui/system/styled';
import palette from 'src/theme/overrides/palette';
import { breakPoints } from 'src/theme/defaultTheme';

export const ContentCardWrapper = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  background: #fff;
  padding: 24px 24px 48px 24px;
  border-radius: 0 0 8px 8px;

  @media (max-width: ${breakPoints.sm}px) {
    padding: 16px;
    justify-content: center;
  }
`;

export const CardHeader = styled(Grid)`
  padding: 20px 80px;
  border-radius: 8px 8px 0 0;
  border: 1px solid ${palette.border};
  background: black;
`;

export const NewContentWrapper = styled(Box)`
  border: 1px solid #e5e5e5;
  border-radius: 16px;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  padding: 74px 0px;
  width: 23%;

  @media (max-width: ${breakPoints.l}px) {
    width: 30%;
    max-width: none;
  }
`;

export const ContentCircle = styled(Box)`
  border-radius: 50%;
  width: 96px;
  height: 96px;
  background: #e5e5e5;
  position: relative;

  > svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;
