import { LOCALSTORAGE_KEYS } from 'src/enums';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ge from './locales/ge/translations.json';
import en from './locales/en/translations.json';
import az from './locales/az/translations.json';

export const defaultNS = 'translations';

export const resources = {
  ge: {
    translations: ge
  },
  en: {
    translations: en
  },
  az: {
    translations: az
  }
} as const;

i18n.use(initReactI18next).init({
  lng: localStorage.getItem(LOCALSTORAGE_KEYS.LOCALE) || 'ge',
  ns: ['translations'],
  fallbackLng: 'en',
  defaultNS,
  resources,
  interpolation: {
    escapeValue: false
  },
  react: {
    useSuspense: false
  }
});
