import { createTheme } from '@mui/material/styles';
import { breakPoints } from './defaultTheme';
import components from './overrides/components';
import palette from './overrides/palette';

export const mainTheme = createTheme({
  palette,
  components,
  breakpoints: {
    values: breakPoints
  }
});
