import { ERROR_CODES } from 'src/constants';
import { COURSE_STATUS, SUBJECT_FILE_STATUS } from 'src/enums';
import { CourseMonth, Rating, SpecificCourse, SubjectDone } from './general';

export interface UserInfo {
  firstName: string;
  lastName: string;
  email: string;
  stack: null;
  expertiseValue: string;
  performanceValue: string;
  birthDate: string | null;
  phoneNumber: string;
  idNumber: null;
  projectCount: string;
  imageUrl: string;
  id: string;
  residenceCity: string;
  showWelcomeMessage: boolean;
}

export interface ExtendedGroupInfo {
  courseCompleted: boolean;
  donePayment: boolean;
  groupId: number;
  initCourseCompleted: boolean;
  paymentMethod: number;
  subCourseRegistered: boolean;
  subGroupId: number;
  userId: string;
  courseId: number;
  startDate: string;
  finishDate: string;
}

export interface UserProfileResponse extends UserInfo {
  myCourses: ExtendedGroupInfo[];
}

export interface PageInfo {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  pageCount: number;
  pageIndex: number;
  pageSize: number;
}
export interface CourseInfo {
  description: string;
  fee: number;
  id: number;
  name: string;
}

export interface GroupInfo {
  courseId: number;
  finishDate: string;
  id: number;
  name: string;
  startDate: string;
}

export interface SubCourseInfo {
  description: string;
  id: number;
  name: string;
  subGroups: GroupInfo[];
}

export interface Homework {
  attachment: string;
  calendar: any;
  description: string;
  due_date: string;
  id: number;
  points: number;
  status: string;
  subject: string;
  estimate_time: string | null;
  title: string;
  assignments: Assignment[];
  evaluation_criteria: { id: number }[];
  subjectWithRelations: SubjectWithRelations;
}

export interface SubjectWithRelations {
  created_at: number;
  id: number;
  position: number;
  title: string;
  updated_at: string;
  week_id: number;
}

export interface Assignment {
  attachment: string;
  id: number;
  score: number;
  user_id: string;
  title: string;
  link: string;
  user_lastname: string;
  user_name: string;
  user_email: string;
  submission_date: string;
}

export interface changePasswordPayload {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface CopySyllabusPayload {
  chapter_from_id: number;
  chapter_to_id: number;
}

export interface CourseResponse extends CourseInfo {
  groups: GroupInfo[];
  subCourses: SubCourseInfo[];
}

export interface HomeworkResponse {
  success: boolean;
  homeworks: Homework[];
}

export interface AssignmentResponse {
  success: boolean;
  homework: Homework;
}

export interface AllCoursesExtendedResponse extends PageInfo {
  data: CourseResponse[];
}

export interface StudentCourse extends CourseInfo {
  groupInfo: GroupInfo;
  subCourses: SubCourseInfo[];
}

export interface AllCourseResponse extends PageInfo {
  data: CourseInfo[];
}

export interface CourseStatusResponse {
  status: string;
  redirectUrl: string | null;
}

export interface LoginResponse {
  access_token: string;
  refresh_token: string;
}

export interface LoginPayload {
  username: string;
  password: string;
}

export interface RefreshTokenPayload {
  refresh_token: string;
}

export interface ResendEmailPayload {
  email: string;
}

export interface VerifyEmailPayload {
  token: string;
  email: string;
}

export interface ResetPasswordPayload {
  email: string;
}
export interface ResetPasswordConfirmPayload {
  email: string;
  token: string;
  password: string;
  confirmPassword: string;
}

export interface UploadAssignmentPayload {
  fileData: FormData;
}
export interface UpdateAssignmentPaylaod extends UploadAssignmentPayload {
  id: number;
}

export interface AddOceanNewsPayload {
  kind: 'news' | 'blogs' | null;
  title_en: string | null;
  title_ka: string | null;
  card_description_ka: string | null;
  card_description_en: string | null;
  body_en: string | null;
  body_ka: string | null;
  images: File[] | null;
  videos: File[] | null;
  tags: number[] | null;
  thumbnailImage: File | null;
}

export interface UploadHomeworkPayload {
  title: string | null;
  description: string | null;
  attachment: File | null;
  points: number | null;
  due_date: Date | null;
  status: string | null;
  subject_id: number | null;
  chapter_id: string;
  estimation?: string;
  evaluation_criteria?: number[];
}

export interface TranslateResponse {
  name: string;
  resourceId: string;
  value: string;
  language: string;
  translationPageId: string;
}

export interface EditProfilePayload {
  firstName: string;
  lastName: string;
  birthDate: string | null;
  email: string;
  phoneNumber: string;
  residenceCity: string;
}

export interface CustomError {
  code: string;
  description: string;
}

export interface ErrorMessages {
  [key: string]: string;
}

export interface EditNewsPayload extends AddOceanNewsPayload {
  id: number;
}

export interface CourseMonthResponse {
  data: CourseMonth[];
}

export interface AddEventPayload {
  start_date_time: string;
  end_date_time: string;
  group_id?: string | number;
  user_id: string | undefined;
  subject_id: string | number | undefined;
  ka: { summary: string; comment: string; description: string | undefined };
  en: { summary: string; comment: string; description: string | undefined };
  estimate_time?: string;
  evaluation_criteria?: number[];
}

export interface EditEventPayload {
  summary: string;
  description?: string;
  start_date_time: string;
  end_date_time: string;
  group_id: string | number;
  user_id: string | undefined;
  comment?: string;
}

export interface Month {
  chapter_id: number;
  id: number;
  is_disabled: boolean;
  learning_id: number;
  month: number;
  status: string;
  week: Week[];
}

export interface Week {
  group_week: any[];
  id: number;
  is_disabled: boolean;
  subjects: Subject[];
  title: string;
}

export interface Subject {
  files: any[];
  id: number;
  position: number;
  title: string;
  week_id: number;
}

export interface SpecificCourseResponse extends PageInfo {
  data: SpecificCourse[];
}

export interface RegisterOnSubCoursePayload {
  subGroupId: number;
  groupId: number;
}

export interface UploadRatingResponse {
  success?: true;
  kahoot_scores: Rating[];
}

export type ProgressPayload = {
  chapter_id?: string;
};

export interface KahootRatingResponse {
  data: Rating[];
}

export interface SubjectDoneResponse {
  data: SubjectDone[];
}

export interface SubjectStatusPayload {
  subject_id: number;
  user_id: string;
  is_done: 0 | 1;
}

export interface AddCriteriaPayload {
  en: {
    name: string;
    description: string;
  };
  ka: {
    name: string;
    description: string;
  };
}

export interface SylabussParams {
  status: COURSE_STATUS;
  chapter_id?: string;
}

export interface AddSubjectFile {
  ka: { title: string };
  en: { title: string };
  status: SUBJECT_FILE_STATUS;
  url: string;
}

export interface RatingParams {
  group_id?: number;
  subgroup_id?: number;
  chapter_id?: string;
}

export interface SubjectParams {
  chapter_id: string;
  status: 'general' | 'specific';
}

export interface AddSubjectFilesPayload {
  subject_id: number;
  file: AddSubjectFile[];
  status: SUBJECT_FILE_STATUS;
}

export interface AddSubjectPayload {
  week_id: number;
  en: {
    title: string;
  };
  ka: {
    title: string;
  };
  position: number;
}

export interface AddTagPayload {
  name: string;
}

export interface AddMonthPayload {
  month?: number;
  status: 'general' | 'specific';
  chapter_id?: number;
  is_disabled: boolean;
}

export interface AddWeekPayload {
  month_id: number;
  title?: 1 | 2 | 3 | 4;
  is_disabled?: boolean;
}

export interface EditSubjectPayload {
  [key: string]: {
    title: string;
  };
  position: any;
}

export interface EditSubjectFile {
  fileId: number;
  payload: any;
}

export interface CreateCareerPayload {
  title: string;
  content: string;
  images?: string[];
  description: string;
  due_date?: string;
  files?: File;
}

export interface PublishContentPayload {
  course_ids: number;
  publish_date: string;
}

export interface EditCourseContentPayload {
  content_id: number;
  course_ids: number;
  send_date: string;
}

export interface CareerContentItem {
  id: number;
  title: string;
  content: string;
  due_date: string;
  published: boolean;
  estimate_time: string | null;
  description: string;
  assignments: any[];
  content_courses: any[];
  publish_date: string | null;
  evaluation_criteria: { id: number }[];
  files: { id: number; title: string; attachment: string; attachment_name: string }[];
}

export interface CareerContentPayload {
  success?: boolean;
  data: {
    success?: boolean;
    contents: CareerContentItem[];
  };
  contents: CareerContentItem[];
}
export interface INewsOrBlogTranslation {
  id: number;
  body: string;
  locale: string;
  card_description: string;
  news_id: number;
  title: string;
}

export interface INewsTag {
  id: number;
  name: string;
}

export interface INewsOrBlog {
  id: number;
  created_at: string;
  images: { url: string }[];
  tags: INewsTag[];
  translations: INewsOrBlogTranslation[];
  videos: { url: string }[];
  thumbnailImage: { url: string | null };
}

export interface TagsResponse {
  success: boolean;
  tags: INewsTag[];
}
export interface NewsResponse {
  success: boolean;
  news: INewsOrBlog[];
}

export interface BlogsResponse {
  success: boolean;
  blogs: INewsOrBlog[];
}
export interface NewsDetailsResponse {
  success: boolean;
  news: INewsOrBlog;
}

export interface SimilarNewsResponse {
  success: boolean;
  similarPosts: INewsOrBlog[];
}

export class ErrorHandler {
  private error: CustomError | CustomError[];
  constructor(err: CustomError[]) {
    this.error = err;
  }

  transformMessages() {
    const messagesWithCodes: ErrorMessages = {};
    if (Array.isArray(this.error)) {
      this.error.forEach((err) => {
        if (Object.prototype.hasOwnProperty.call(ERROR_CODES, err.code)) {
          messagesWithCodes[ERROR_CODES[err.code].code] = ERROR_CODES[err.code].message;
        }
      });
    } else {
      if (Object.prototype.hasOwnProperty.call(ERROR_CODES, this.error.code)) {
        messagesWithCodes[ERROR_CODES[this.error.code].code] = ERROR_CODES[this.error.code].message;
      }
    }
    return messagesWithCodes;
  }
}
