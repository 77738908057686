import React from 'react';
import styled from 'styled-components';
import { useLanguage } from 'src/context/languageContext';
import { Link as RLink, LinkProps } from 'react-router-dom';

const SLink = styled(RLink)`
  text-decoration: none;
`;

const Link: React.FC<LinkProps> = ({ to, children }) => {
  const { locale } = useLanguage();

  return <SLink to={`/${locale}${to}`}>{children}</SLink>;
};

export default Link;
