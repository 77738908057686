import { QUERY_KEYS } from 'src/enums';
import { useQuery } from '@tanstack/react-query';
import { getSubCourses } from 'src/services/api';
import useLanguageCode from '../useLanguageCode';

const useSubCourses = (enabled = true) => {
  const { langCode } = useLanguageCode();
  const { data, isLoading } = useQuery([QUERY_KEYS.SUBCOURSES], () => getSubCourses(langCode), {
    enabled
  });
  return {
    data,
    subCourses: data?.data,
    isLoading
  };
};

export default useSubCourses;
