import { Card, CardContent } from '@mui/material';
import styled from '@mui/system/styled';

export const SpecHeader = styled('div')<{ width?: string }>(({ theme, width = `150px` }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 190,
  width: '100%',
  [theme.breakpoints.down(460)]: {
    height: '110px',
    width
  },
  backgroundColor: theme.palette.lightBlue
}));

export const StyledCard = styled(Card)<{ width?: string; selected?: boolean; disabled?: boolean }>(
  ({ theme, width = `150px`, selected = false, disabled = false }) => ({
    height: '100%',
    paddingBottom: '15px',
    opacity: disabled ? 0.5 : 1,
    boxShadow: selected ? `0 0 0 2px ${theme.palette.primary.main}` : 'none',
    borderRadius: 8,
    cursor: 'pointer',
    border: `1px solid ${theme.palette.border}`,
    [theme.breakpoints.down(460)]: {
      width
    },
    ':hover': {
      boxShadow: !selected && `0px 3px 6px ${theme.palette.headerShadow}`
    }
  })
);

export const SpecContent = styled(CardContent)(({ theme }) => ({
  paddingInline: 14,
  paddingBlock: 22,
  height: 127,
  overflow: 'hidden',

  borderTop: 'none',
  [theme.breakpoints.down(460)]: {
    paddingInline: 12,
    paddingBlock: 5,
    height: 127
  }
}));
