import React from 'react';
import { IconType } from 'react-icons/lib';

interface OceanFormBtnProps {
  action: () => void;
  Icon: IconType;
}
const OceanFormBtn: React.FC<OceanFormBtnProps> = ({ action, Icon }) => {
  return (
    <button
      onClick={action}
      type="button"
      style={{
        border: '1px solid gray',
        cursor: 'pointer',
        borderRadius: '3px',
        position: 'absolute',
        right: '8px',
        top: '8px',
        height: '35px',
        width: '35px',
        background: 'white',
        minWidth: '30px !important',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 100
      }}>
      <Icon width={'75%'} color={'crimson'} />
    </button>
  );
};

export default OceanFormBtn;
