import { QUERY_KEYS } from 'src/enums';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'src/context/authContext';
import { getUpcomingEvents } from 'src/services/api';
import useLanguageCode from 'src/hooks/useLanguageCode';

const useUpcomingEvents = () => {
  const { isLoggedIn } = useAuth();
  const { lang } = useLanguageCode();

  const langCode = lang === 'en' ? 0 : 1;

  const { data, isLoading, isError } = useQuery(
    [QUERY_KEYS.UPCOMING_EVENTS, lang],
    () => getUpcomingEvents(lang),
    { enabled: isLoggedIn }
  );

  const upEvents = data?.data?.slice(0, 5).map((event: any) => {
    return {
      id: event.id,
      end: event.end_date_time.slice(11, 16),
      start: event.start_date_time.slice(11, 16),
      title: event.translations[langCode].summary
    };
  });

  return {
    isError,
    upEvents,
    isLoading
  };
};

export default useUpcomingEvents;
