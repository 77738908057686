import { Grid } from '@mui/material';
import styled from 'styled-components';
import palette from 'src/theme/overrides/palette';
import { breakPoints } from 'src/theme/defaultTheme';
import { Link } from 'react-router-dom';

export const FooterWrapper = styled.div`
  margin: auto;
  padding: 0 144px;
  width: 100%;
  height: 120px;
  display: flex;
  gap: 80px;
  align-items: center;
  background-color: ${palette.lightBlue};

  @media (max-width: ${breakPoints.l}px) {
    padding: 0 100px;
  }

  @media (max-width: ${breakPoints.m}px) {
    padding: 0 50px;
    gap: 30px;
  }

  @media (max-width: ${breakPoints.sm}px) {
    flex-direction: column;
    justify-content: center;
    gap: 32px;
    padding-top: 24px;
    padding-bottom: 32px;
    height: auto;
  }

  @media (max-width: ${breakPoints.mb3}px) {
    padding: 24px 16px 32px 16px;
  }
`;

export const GridContainer = styled(Grid)`
  align-items: center;
  justify-content: space-between;
  background: #000;
  left: 0;
  bottom: 0;
  z-index: 101;

  @media (max-width: ${breakPoints.sm}px) {
    background: #000;
    overflow-y: scroll;
  }
`;

export const FooterActions = styled.div`
  display: flex;
  gap: 80px;
  align-items: center;

  @media (max-width: ${breakPoints.sm}px) {
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
  }
`;

export const SocialsContainer = styled.div`
  display: flex;
  gap: 13px;
  align-items: center;

  @media (max-width: ${breakPoints.mb}px) {
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 20px;
  }
`;

export const SocialLink = styled.a<{ color?: string }>`
  width: 44px;
  height: 44px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${palette.primary.main};
  border: ${(props) => `1px solid ${props.color}`};

  & svg {
    fill: ${palette.primary.main};
  }

  :hover svg {
    fill: ${palette.blue};
  }

  :hover {
    border-color: ${palette.blue};
  }
`;

export const NewLandingLink = styled.a`
  width: 44px;
  height: 44px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${palette.white};
  border: 1px solid ${palette.darkPurple};

  & svg {
    fill: #fe3b1f;
  }

  :hover svg {
    fill: ${palette.white};
  }

  :hover {
    background: #fe3b1f;
    transition: 0.2s ease-in;
    border-color: ${palette.darkPurple};
  }
`;

export const NewsShareLink = styled.a`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  padding: 7px !important;
  align-items: center;
  justify-content: center;
  background: ${palette.white};
  border: 1px solid #fe3b1f;

  & svg {
    fill: #fe3b1f;
  }

  :hover svg {
    fill: ${palette.white};
  }

  :hover {
    background: #fe3b1f;
    transition: 0.2s ease-in;
    border-color: ${palette.darkPurple};
  }
`;

export const Copyright = styled.p`
  font-size: 16px;
  line-height: 25px;

  @media (max-width: ${breakPoints.mb2}px) {
    font-size: 14px;
  }
`;

export const StyledTerms = styled(Link)`
  font-size: 18px;
  line-height: 27px;
  text-decoration: underline;
  color: ${palette.text.primary};

  @media (max-width: ${breakPoints.mb2}px) {
    font-size: 16px;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: ${breakPoints.sm}px) {
    flex-direction: column;
    justify-content: center;
    gap: 40px;
  }
`;
