import React, { FC, MutableRefObject, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import styled from 'styled-components';
import { breakPoints } from 'src/theme/defaultTheme';
import { StyledAddEvent, ViewSwitcher } from './styles';
import { Button, MenuItem, useTheme } from '@mui/material';
import { ReactComponent as PlusIcon } from 'src/assets/icons/addPlus.svg';

// import AddEventLogo from 'src/assets/icons/calendar_add_logo.svg';
// import SyncLogo from 'src/assets/icons/sinc_logo.svg';
import EventModal from 'src/components/modals/AddEventModal';
import AlertModal from 'src/components/modals/AlertModal';
import { googleSyncRedirect } from 'src/services/api';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';

interface Props {
  calendarRef: MutableRefObject<FullCalendar>;
  isSynced: boolean;
}

const Container = styled.div`
  width: 100%;
  height: 44px;
  justify-content: space-between;
  align-items: center;
  display: none;
  margin-bottom: 16px;

  @media (max-width: ${breakPoints.m}px) {
    display: flex;
  }
`;

const Actions = styled.div`
  display: flex;
  gap: 16px;
  img {
    cursor: pointer;
  }
`;

const CalendarResponsiveHeader: FC<Props> = ({ calendarRef }) => {
  const [openEventModal, setOpenEventModal] = useState(false);
  const [calendarValue, setCalendarValue] = useState<string>('dayGridMonth');
  const [openAlertModal, setOpenAlertModal] = useState(false);

  const theme = useTheme();

  const { t } = useTranslation();

  const changeValueHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCalendarValue(e.target.value);
    calendarRef.current.getApi().changeView(e.target.value);
  };

  const closeModalHandler = () => {
    setOpenEventModal(false);
    setOpenAlertModal(false);
  };

  const googleSyncHandler = async () => {
    const { data } = await googleSyncRedirect();
    setOpenAlertModal(false);
    window.open(data.authUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <Container>
      <Box
        zIndex={100}
        display={'flex'}
        alignItems={'center'}
        width={'100%'}
        justifyContent={'space-between'}>
        <Button
          sx={{ padding: theme.spacing(1) }}
          variant="filled"
          onClick={() => setOpenEventModal(true)}>
          <StyledAddEvent>{t('calendar.addEvent')}</StyledAddEvent>
          <PlusIcon />
        </Button>

        <ViewSwitcher value={calendarValue} onChange={changeValueHandler}>
          <MenuItem value={'timeGridDay'}>{t('calendar.day')}</MenuItem>
          <MenuItem value={'timeGridWeek'}>{t('calendar.week')}</MenuItem>
          <MenuItem value={'dayGridMonth'}>{t('calendar.month')}</MenuItem>
        </ViewSwitcher>
      </Box>
      <EventModal open={openEventModal} close={closeModalHandler} />
      <AlertModal
        open={openAlertModal}
        close={closeModalHandler}
        onConfirm={googleSyncHandler}
        confirmationText={t('modal.syncConfirmationText')}
      />
      <Actions>
        {/* <img
          src={AddEventLogo}
          onClick={() => {
            setOpenEventModal(true);
          }}
        /> */}
        {/* {!isSynced && (
          <img
            src={SyncLogo}
            onClick={() => {
              setOpenAlertModal(true);
            }}
          />
        )} */}
        {/* <img
          src={SyncLogo}
          onClick={() => {
            setOpenAlertModal(true);
          }}
        /> */}
      </Actions>
    </Container>
  );
};

export default CalendarResponsiveHeader;
