import Card from '@mui/material/Card';
import styled from 'styled-components';
import palette from 'src/theme/overrides/palette';
import { breakPoints } from 'src/theme/defaultTheme';

export const CardHeader = styled.div`
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  img {
    object-fit: cover;
  }
`;

export const NewsCardWrapper = styled(Card)`
  max-width: 95%;
  cursor: pointer;
  box-shadow: none;
  border: 1px solid ${palette.border};
  border-radius: 8px;

  @media (max-width: ${breakPoints.mb3}px) {
    width: 100%;
    max-width: none;
  }

  &:hover {
    box-shadow: ${`${palette.border} 0px 7px 20px 0px`};
  }
`;

export const CardDivider = styled.hr`
  width: 88%;
  margin: 10px auto 0;
  opacity: 0.5;
`;

export const OceanCardTrashBox = styled.button`
  border: 1px solid #afa8ef;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  fill: crimson;

  &:hover {
    border: 1px solid black;
    cursor: pointer;
  }

  @media (max-width: 500px) {
    width: 40px;
  }
`;

export const OceanCardButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  border-radius: 8px;
  height: 40px;
  flex: 1;
  margin-left: 8px;
`;

export const OceanCardDetails = styled.div`
  padding: 8px 15px;
  left: 12px;
  right: 12px;
  bottom: 12px;
  display: flex;
  align-items: center;
`;
