import React, { FC } from 'react';
import { useFormModal } from 'src/context/formModalContext';
import { Dialog, DialogContent, Grid, Typography } from '@mui/material';

const FormModal: FC = () => {
  const { modalProps, resetModal } = useFormModal();

  const { form, isOpen, title } = modalProps;

  return (
    <Dialog
      open={isOpen}
      disableAutoFocus
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={resetModal}
      PaperProps={{ style: { borderRadius: '8px', boxShadow: '5px 5px 15px #0000001A' } }}>
      <DialogContent
        sx={{
          marginTop: { xs: 1, l: 5 },
          width: { sm: 416, l: 516 },
          marginBottom: { xs: 1, l: 4 }
        }}>
        <Grid container justifyContent="center">
          {title && (
            <Grid item xs={12} textAlign="center">
              <Typography>{title}</Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            {form}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default FormModal;
