import styled from 'styled-components';
import palette from 'src/theme/overrides/palette';
import { breakPoints } from 'src/theme/defaultTheme';

export const DetailsModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  border-radius: 16px;
  background: ${palette.white};
  transform: translate(-50%, -50%);
  z-index: 101;
  box-shadow: 0px 0px 10px #3c3c3c33;

  @media (max-width: ${breakPoints.mb2}px) {
    min-width: 80%;
  }
`;
