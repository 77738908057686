import Grid from '@mui/material/Grid';
import React, { FC } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { CourseMonth } from 'src/models/general';
import useChangeWeekPosition from 'src/hooks/mutations/useChangeWeekPosition';
import { Button, FormHelperText, MenuItem, TextField, Typography } from '@mui/material';

interface EditWeekProps {
  weekId: number;
  close: () => void;
  data: CourseMonth[];
}

const EditWeek = ({ close, weekId, data = [] }: EditWeekProps) => {
  const { t } = useTranslation();
  const { mutate, isLoading, isError } = useChangeWeekPosition();

  const formik = useFormik<any>({
    initialValues: {
      week: '',
      month: ''
    },
    onSubmit: ({ month, week }) => {
      if (!(month && week)) return;

      mutate(
        {
          weekId,
          payload: {
            title: week,
            month_id: month,
            is_disabled: false
          }
        },
        { onSuccess: () => close() }
      );
    }
  });

  const submitted = formik.submitCount > 0;
  // const filteredMonths = data.filter((el) => el.week.length !== 4);
  // const weekLen = data.find((el) => el.id === formik.values.month)?.week.length || 0;

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2} px={4} py={4}>
        <Grid item xs={12} display="flex" justifyContent="center" mb={4}>
          <Typography variant="h5" fontWeight="bold" textTransform="uppercase">
            {t('labels.weekModify')}
          </Typography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <TextField
            select
            name="month"
            label={t('labels.month')}
            value={formik.values.month}
            onChange={formik.handleChange}
            helperText={
              submitted &&
              Boolean(!formik.values.month) &&
              t('validations.required', { field: t('labels.month') })
            }
            error={submitted && Boolean(!formik.values.month)}>
            {data.map((el) => (
              <MenuItem key={el.month} value={el.id}>
                <Typography textTransform="capitalize" fontSize={16}>
                  {t('common.month')} {el.month}
                </Typography>
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <TextField
            select
            name="week"
            value={formik.values.week}
            onChange={formik.handleChange}
            helperText={
              submitted &&
              Boolean(!formik.values.week) &&
              t('validations.required', { field: t('labels.week') })
            }
            error={submitted && Boolean(!formik.values.week)}
            label={t('common.week')}>
            {Array.from({ length: 4 }).map((_, i) => {
              return (
                <MenuItem key={i} value={i + 1}>
                  <Typography textTransform="capitalize" fontSize={16}>
                    {t('common.week')} {i + 1}
                  </Typography>
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        {isError && <FormHelperText error>{t('errors.modifyWeek')}</FormHelperText>}
        <Grid item xs={12} mt={4} textAlign="center">
          <Button variant="primary" type="submit" disabled={isLoading}>
            <Typography textTransform="uppercase" color="lightYellow" fontWeight="bold">
              {t('button.submit')}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditWeek;
