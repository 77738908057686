import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'src/enums';
import { CourseResponse } from 'src/models/service';
import { getCourse } from 'src/services/api';
import useLanguageCode from '../useLanguageCode';

const useCourse = (courseId: string) => {
  const { langCode } = useLanguageCode();
  const { data, isLoading, isError } = useQuery<CourseResponse>(
    [QUERY_KEYS.COURSES, courseId, langCode],
    () => getCourse(courseId, langCode),
    {
      enabled: Boolean(courseId)
    }
  );
  return {
    data,
    isLoading,
    isError
  };
};

export default useCourse;
