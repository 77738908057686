import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ContactWrapper, PhoneNum, SVGBox, TextWrapper, TopHeaderWrapper } from './styles';
import { ReactComponent as PhoneIcon } from '../../../assets/icons/Phone.svg';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useDetectDevice from 'src/hooks/useDetectDevice';
import { useLanguage } from 'src/context/languageContext';

const TopHeader = () => {
  const { t } = useTranslation();
  const { locale } = useLanguage();
  const { pathname } = useLocation();
  const [over, setOver] = useState(false);

  const { isSmall } = useDetectDevice();
  const isLanding = pathname === `/${locale}`;

  useEffect(() => {
    const handleScroll = () => setOver(window.pageYOffset > 800);

    if (typeof window !== undefined && !isSmall) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
      setOver(false);
    };
  }, [isLanding, isSmall]);

  return (
    <TopHeaderWrapper animation={over} position={over ? 'fixed' : 'auto'} over={over}>
      <TextWrapper>
        <Typography
          fontWeight={500}
          textAlign={'center'}
          fontSize={{ xs: 14, md: 16, color: '#fff' }}>
          {t('topHeader')}
        </Typography>
      </TextWrapper>

      <ContactWrapper>
        <SVGBox>
          <PhoneIcon fill="#CDDB00" />
        </SVGBox>
        <PhoneNum href="Tel: +995 551 155 225">+995 551 155 225</PhoneNum>
      </ContactWrapper>
    </TopHeaderWrapper>
  );
};

export default TopHeader;
