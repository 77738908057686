import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/context/authContext';
import { Container, ModalActions } from './styles';
import { Button, Dialog, Typography } from '@mui/material';

interface Props {
  open: boolean;
  close: () => void;
}

const LogoutModal: FC<Props> = ({ open, close }) => {
  const { t } = useTranslation();
  const { handleLogout } = useAuth();

  return (
    <Dialog
      open={open}
      onClose={close}
      PaperProps={{
        style: {
          borderRadius: '16px'
        }
      }}>
      <Container>
        <Typography
          color="green"
          lineHeight="32px"
          textTransform="uppercase"
          fontSize={{ sm: 24, xs: 20 }}
          sx={{ fontFeatureSettings: "'case' on" }}>
          {t('modal.logoutMessage')}
        </Typography>
        <ModalActions>
          <Button onClick={handleLogout} variant="primary">
            <Typography>{t('common.yes')}</Typography>
          </Button>
          <Button onClick={close} variant="secondary">
            <Typography>{t('common.no')}</Typography>
          </Button>
        </ModalActions>
      </Container>
    </Dialog>
  );
};

export default LogoutModal;
