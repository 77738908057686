import React from 'react';
import { Box } from '@mui/system';
import { isBeforeDate } from 'src/utils';
import { LOCALSTORAGE_KEYS } from 'src/enums';
import { COURSES } from '../buyCourse/courses';
import { useTranslation } from 'react-i18next';
import palette from 'src/theme/overrides/palette';
import { useAuth } from 'src/context/authContext';
import Loader from 'src/components/common/Loader';
import { Button, Typography } from '@mui/material';
import useCourse from 'src/hooks/queries/useCourse';
import useProfile from 'src/hooks/queries/useProfile';
import Navigate from 'src/components/common/navigate';
import { useLanguage, useNavigate } from 'src/context/languageContext';

const PaymentSucces = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { locale } = useLanguage();
  const { isLoggedIn } = useAuth();

  const { myCourses = [] } = useProfile();
  const { courseId } = myCourses[0] || {};

  const ICourseId = localStorage.getItem(LOCALSTORAGE_KEYS.COURSE_ID) || courseId;
  const registeredCourse = COURSES.filter(({ id }) => String(id) === ICourseId)[0];
  const { id, fee, layerCtgr, title, description, duration, startDate: start } = registeredCourse;

  const userInfoJson = localStorage.getItem(LOCALSTORAGE_KEYS.USER_INFO) || '';
  const userInfo = JSON.parse(userInfoJson);

  const { data: course, isLoading: loading } = useCourse(String(ICourseId));

  const { groups = [] } = course || {};
  const { startDate = '' } = groups[0] || {};
  const isCourseStarted = isBeforeDate(new Date(startDate), new Date());

  if (loading)
    return (
      <Box minHeight="100vh">
        <Loader />
      </Box>
    );

  if (isLoggedIn && startDate && isCourseStarted) return <Navigate to="/my-course/general" />;

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography
        color="green"
        fontWeight="bold"
        textTransform="uppercase"
        fontSize={{ sm: 32, xs: 24 }}>
        {t('course.congrats')}
      </Typography>
      <Typography fontFamily="Poppins" fontSize={{ sm: 22, xs: 16 }}>
        {t('course.congratsSubtitle')}
      </Typography>
      <Typography
        color="black"
        fontWeight="bold"
        fontFamily="Poppins"
        textTransform="uppercase"
        fontSize={{ l: 42, md: 34, xs: 24 }}>
        {title[locale]}
      </Typography>
      <Typography variant="listItem" color="text.primary" fontSize={{ sm: 18, xs: 14 }}>
        {description[locale]}
      </Typography>
      <Box display="flex" gap={1} alignItems="center">
        <Typography fontWeight={400} fontSize={{ sm: 18, xs: 14 }}>
          {t('course.courseStart')}:
        </Typography>
        <Typography fontWeight="bold" fontSize={{ sm: 18, xs: 14 }}>
          {start}
        </Typography>
      </Box>
      <Box display="flex" gap={1} mb={3} alignItems="center">
        <Typography fontWeight={400} fontSize={{ sm: 18, xs: 14 }}>
          {t('course.duration')}:
        </Typography>
        <Typography fontWeight="bold" fontSize={{ sm: 18, xs: 14 }}>
          {duration} {t('common.month')}
        </Typography>
      </Box>
      <Button
        size="medium"
        type="submit"
        variant="containedPrimary"
        onClick={() => navigate('')}
        sx={{
          width: '30%',
          borderRadius: '30px',
          background: palette.text.primary
        }}>
        <Typography fontSize={16} textTransform="uppercase" color="white">
          {t('course.home')}
        </Typography>
      </Button>
    </Box>
  );
};

export default PaymentSucces;
