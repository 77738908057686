import React, {
  createContext,
  FC,
  ReactElement,
  ReactNode,
  useContext,
  useMemo,
  useState
} from 'react';
import { IEvent } from 'src/models/general';

export const CalendarContext = createContext<any>(null);

interface CalendarContextProps {
  children: ReactNode;
}

const CalendarContextProvider: FC<CalendarContextProps> = ({ children }): ReactElement => {
  const [userEvents] = useState([]);
  const [addEventModalShown, setAddEventModalShown] = useState<boolean>(false);
  const [eventToEdit, setEventToEdit] = useState<IEvent>();
  const [eventDetailModalShown, setEventDetailModalShown] = useState<boolean>(false);

  const contextValues = useMemo(
    () => ({
      userEvents,
      addEventModalShown,
      setAddEventModalShown,
      eventDetailModalShown,
      setEventDetailModalShown,
      eventToEdit,
      setEventToEdit
    }),
    [
      userEvents,
      addEventModalShown,
      setAddEventModalShown,
      eventDetailModalShown,
      setEventDetailModalShown,
      eventToEdit
    ]
  );

  return <CalendarContext.Provider value={contextValues}>{children}</CalendarContext.Provider>;
};

export default CalendarContextProvider;

export const useCalendar = () => {
  const value = useContext(CalendarContext);

  if (!value) {
    throw new Error('Calendar Context Provider is not defined');
  }
  return value;
};
