import React from 'react';
import Navigate from 'src/components/common/navigate';
import ModifySpecialization from 'src/components/UI/forms/ModifySpecialization';
import useProfile from 'src/hooks/queries/useProfile';
import useUserCourse from 'src/hooks/queries/useUserCourse';
import { getDateDifference } from 'src/utils';

const ChooseSpecialization = () => {
  const { myCourses = [] } = useProfile();
  const subGroupId = myCourses[0]?.subGroupId;
  const { data: course } = useUserCourse();
  const { subCourses = [], groups = [] } = course || {};
  const { startDate: courseStart = '' } = groups[0] || {};
  const date = new Date(courseStart);
  date.setMonth(date.getMonth() + 4);

  const daysBeforeStart = getDateDifference(date, new Date()) + 5;
  if (subGroupId) {
    return <Navigate to="/my-course/specific/choosed-chapter" />;
  }
  return <ModifySpecialization daysLeft={daysBeforeStart} specs={subCourses} />;
};

export default ChooseSpecialization;
