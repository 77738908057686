import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'src/context/authContext';
import { QUERY_KEYS } from 'src/enums';
import { SubjectDoneResponse } from 'src/models/service';
import { getDoneSubjects } from 'src/services/api';

const useDoneSubjects = () => {
  const { isLoggedIn } = useAuth();
  const { data } = useQuery<SubjectDoneResponse>(
    [QUERY_KEYS.DONE_SUBJECTS],
    () => getDoneSubjects(),
    {
      enabled: isLoggedIn
    }
  );
  return {
    data: data?.data || []
  };
};

export default useDoneSubjects;
