import React, { useState } from 'react';
import { Container, StyledForm, InnerContainer } from './styles';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import { changePasswordSchema } from 'src/helpers/validations';
import { changePassword } from 'src/services/api';
import { useFeedbackModal } from 'src/context/feedbackModalContext';
import InputWithIcon from 'src/components/common/InputWithIcon';
import { FormHelperText, Grid, Typography } from '@mui/material';
import { useNavigate } from 'src/context/languageContext';

interface FormData {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const ChangePasswordForm = () => {
  const { t } = useTranslation();
  const { constructModal } = useFeedbackModal();
  const navigate = useNavigate();
  const [error, setError] = useState<string>('');

  const formik = useFormik<FormData>({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    },
    validationSchema: changePasswordSchema(t),
    onSubmit: async ({ confirmPassword, currentPassword, newPassword }) => {
      setError('');

      try {
        await changePassword({
          confirmPassword,
          currentPassword,
          newPassword
        });
        constructModal({
          title: t('common.success'),
          btnText: t('modal.close'),
          subtitle: t('messages.resetPasswordSuccess'),
          type: 'success',
          action: () => {
            navigate('/profile');
          }
        });
      } catch (err) {
        setError(err.response.data.message);

        constructModal({
          title: t('common.error'),
          btnText: t('modal.close'),
          subtitle: t('errors.networkError'),
          type: 'error',
          action: () => {
            navigate('/profile/change-password');
          }
        });
      }
    },
    validateOnChange: false,
    validateOnBlur: false
  });

  return (
    <Container>
      <StyledForm onSubmit={formik.handleSubmit}>
        <InnerContainer>
          <InputWithIcon
            label={t('labels.currentPassword')}
            variant="outlined"
            name="currentPassword"
            value={formik.values.currentPassword}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.currentPassword)}
            helperText={formik.errors.currentPassword}
            FormHelperTextProps={{
              error: Boolean(formik.errors.currentPassword)
            }}
          />

          <InputWithIcon
            label={t('labels.newPassword')}
            variant="outlined"
            name="newPassword"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.newPassword)}
            helperText={formik.errors.newPassword}
            FormHelperTextProps={{
              error: Boolean(formik.errors.newPassword)
            }}
          />

          <InputWithIcon
            label={t('labels.confirmPassword')}
            variant="outlined"
            name="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.confirmPassword)}
            helperText={formik.errors.confirmPassword}
            FormHelperTextProps={{
              error: Boolean(formik.errors.confirmPassword)
            }}
          />

          <FormHelperText sx={{ color: '#F73E48', fontSize: 16, mb: 2 }}>{error}</FormHelperText>
        </InnerContainer>
      </StyledForm>

      <Grid
        container
        alignItems={{ xs: 'center', sm: 'flex-end' }}
        gap={1}
        justifyContent={{ xs: 'center', sm: 'flex-end' }}>
        <Button
          sx={{
            padding: '20px !important',
            background: 'black',
            width: '150px',
            borderRadius: '26px',
            '&:hover': {
              background: 'black'
            }
          }}
          onClick={() => formik.handleSubmit()}
          disabled={formik.isSubmitting}>
          <Typography color={'white'} fontWeight={'regular'}>
            {t('button.save')}
          </Typography>
        </Button>
        <Button
          sx={{
            padding: '20px !important',
            width: '150px',
            borderRadius: '26px',
            border: '1px solid black',
            color: 'black'
          }}
          disabled={formik.isSubmitting}
          onClick={() => navigate('/profile')}>
          <Typography fontWeight={'regular'}>{t('button.cancel')}</Typography>
        </Button>
      </Grid>
    </Container>
  );
};

export default ChangePasswordForm;
