import styled from 'styled-components';

export const TextArea = styled.textarea`
  border: 1px solid rgba(122, 118, 118, 0.25);
  border-radius: 8px;
  width: 100%;
  background: transparent;
  padding: 12px 18px;
  margin-bottom: 20px;
  outline: none;
  font-size: 16px;
  margin-top: 24px;

  &:focus {
    border: 1px solid #2a7de1;
  }
`;

export const AssignInput = styled.input`
  border: 1px solid rgba(122, 118, 118, 0.25);
  border-radius: 8px;
  width: 100%;
  outline: none;
  background: transparent;
  padding: 12px 18px;
  font-size: 16px;
  height: 56px;

  &:focus {
    border: 1px solid #2a7de1;
  }
`;
