import React, { FC } from 'react';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import {
  NewsCardWrapper,
  CardHeader,
  CardDivider,
  OceanCardButton,
  OceanCardTrashBox,
  OceanCardDetails
} from './styled';
import { INewsOrBlogTranslation } from 'src/models/service';
import { ReactComponent as AssignTrash } from 'src/assets/icons/assign-trash.svg';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const LeftSideImage = styled.div<{ image: string }>`
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${(props) => props.image});
`;

interface NewsCardProps {
  id: number;
  thumbnail: string;
  translations: INewsOrBlogTranslation;
  images: { url: string }[];
  onChooseCourse: () => void;
  setShowAlertModal: () => void;
  setId: (id: number) => void;
}

const OceanNewsCard: FC<NewsCardProps> = ({
  id,
  translations = {} as INewsOrBlogTranslation,
  images,
  onChooseCourse,
  setShowAlertModal,
  setId,
  thumbnail
}) => {
  const { t } = useTranslation();

  return (
    <NewsCardWrapper sx={{ boxShadow: 'none' }} onClick={onChooseCourse}>
      <CardHeader>
        <LeftSideImage image={thumbnail} />
      </CardHeader>
      <CardContent sx={{ paddingX: '16px', paddingY: '8px' }}>
        <Typography
          mt={1}
          color="text.primary"
          whiteSpace="normal"
          fontFamily={'FiraGo'}
          fontWeight={700}
          fontSize={{ sm: 15, xs: 14 }}
          minHeight={{ xs: 24, sm: 52, l: 32 }}>
          {translations.title}
        </Typography>
        <Typography
          mt={0.5}
          height={60}
          fontSize={14}
          fontWeight={400}
          fontFamily={'FiraGo'}
          color="text.primary"
          sx={{
            overflow: 'hidden'
          }}>
          {translations.card_description}
        </Typography>
      </CardContent>

      <CardDivider />

      <OceanCardDetails>
        <OceanCardTrashBox
          onClick={(e) => {
            e.stopPropagation();
            setId(id);
            setShowAlertModal();
          }}>
          <AssignTrash />
        </OceanCardTrashBox>

        <OceanCardButton onClick={onChooseCourse}>{t('button.details')}</OceanCardButton>
      </OceanCardDetails>
    </NewsCardWrapper>
  );
};

export default OceanNewsCard;
