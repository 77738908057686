import React from 'react';
import FormControl from '@mui/material/FormControl';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useNavigate } from 'src/context/languageContext';
import { useFormik } from 'formik';
import { resetPasswordSchema } from 'src/helpers/validations';
import { useTranslation } from 'react-i18next';
import useQueryString from 'src/hooks/useQueryString';
import { resetPasswordConfirm } from 'src/services/api';
import palette from 'src/theme/overrides/palette';
import { useFeedbackModal } from 'src/context/feedbackModalContext';

interface FormData {
  password: string;
  confirmPassword: string;
}

const ResetPasswordForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { constructModal } = useFeedbackModal();

  const { parseQuery } = useQueryString();

  const { email = '', token = '' } = parseQuery();

  const formik = useFormik<FormData>({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: resetPasswordSchema(t),
    onSubmit: async ({ confirmPassword, password }) => {
      try {
        const decodedToken = encodeURIComponent(token.toString());
        await resetPasswordConfirm({
          password,
          confirmPassword,
          email: email.toString(),
          token: decodedToken
        });

        constructModal({
          title: t('common.success'),
          btnText: t('button.login'),
          type: 'success',
          subtitle: t('messages.resetPasswordSuccess'),
          action: () => {
            navigate('/login');
          }
        });
      } catch (err) {
        // console.log(err);
      }
    },
    validateOnChange: false,
    validateOnBlur: false
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container rowSpacing={3}>
        <Grid item xs={12}>
          <FormControl>
            <TextField
              label={t('labels.newPassword')}
              variant="outlined"
              type="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.password)}
              helperText={formik.errors.password}
              FormHelperTextProps={{
                error: Boolean(formik.errors.password)
              }}
              inputProps={{
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'off'
                }
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <TextField
              label={t('labels.confirmPassword')}
              variant="outlined"
              type="password"
              name="confirmPassword"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              helperText={formik.errors.confirmPassword}
              error={Boolean(formik.errors.confirmPassword)}
              FormHelperTextProps={{
                error: Boolean(formik.errors.confirmPassword)
              }}
              inputProps={{
                autoComplete: 'new-password-confirm',
                form: {
                  autoComplete: 'off'
                }
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} marginTop={5}>
          <Button
            variant="containedPrimary"
            fullWidth
            type="submit"
            disabled={formik.isSubmitting}
            sx={{
              borderRadius: '30px',
              background: palette.text.primary
            }}>
            <Typography fontSize={14} fontWeight={500} color="white" textTransform="uppercase">
              {t('common.resetPassword')}
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            type="submit"
            variant="text"
            size="auto"
            sx={{ color: palette.violet }}
            onClick={() => navigate('/login')}>
            {t('button.backToLogin')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ResetPasswordForm;
