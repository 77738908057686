import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import UserActions from './UserActionsCard';
import { ProfileContainer } from './styles';
import { PROFILE_TABS } from 'src/constants';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/context/authContext';
import useProfile from 'src/hooks/queries/useProfile';
import { Outlet, useLocation } from 'react-router-dom';
import { useLanguage } from 'src/context/languageContext';
import AuthWrapper from 'src/components/layouts/AuthWrapper';
import { useFeedbackModal } from 'src/context/feedbackModalContext';

const ProfilePage = () => {
  const { isAdmin } = useAuth();
  const { t } = useTranslation();
  const { locale } = useLanguage();
  const { pathname } = useLocation();
  const { myCourses = [] } = useProfile();
  const { constructModal } = useFeedbackModal();

  const courseId = myCourses[0]?.courseId;

  useEffect(() => {
    if (!isAdmin && !courseId) {
      constructModal({
        btnText: t('modal.close'),
        title: t('common.success'),
        subtitle: t('modal.noCourses')
      });
    }
  }, [constructModal, isAdmin, t, courseId]);

  const ActionTitle =
    pathname === `/${locale}/profile/change-password`
      ? t('button.changePassword')
      : pathname === `/${locale}/profile/status`
      ? t('profile.status')
      : t('profile.contactInfo');

  return (
    <Grid container>
      <AuthWrapper
        isProfile
        tabs={isAdmin ? PROFILE_TABS.filter((c) => c.path !== '/profile/status') : PROFILE_TABS}>
        <ProfileContainer>
          <UserActions title={ActionTitle} button={pathname === `/${locale}/profile`}>
            <Outlet />
          </UserActions>
        </ProfileContainer>
      </AuthWrapper>
    </Grid>
  );
};

export default ProfilePage;
