import { Box } from '@mui/material';
import { breakPoints } from 'src/theme/defaultTheme';
import palette from 'src/theme/overrides/palette';
import styled from 'styled-components';

export const CardContainer = styled(Box)`
  min-width: 230px;
  min-height: 275px;
  display: flex;
  border-radius: 16px;
  gap: 16px;
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid #e5e5e5;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  border: 1px solid #e4e4e4;
  padding: 24px 36px;
  transition: 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 3px 6px ${palette.headerShadow};
  }

  @media (max-width: ${breakPoints.mb3}px) {
    width: 90%;
  }

  img {
    width: 40px;
    height: 40px;
  }
`;
