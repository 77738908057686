export enum LOCALSTORAGE_KEYS {
  TOKEN = 'token',
  REFRESH_TOKEN = 'refresh_token',
  REMEMBER_ME = 'remember_me',
  COURSE_ID = 'course_id',
  CATEGORY = 'course_category',
  COURSE_FEE = 'course_fee',
  COURSE_NAME = 'course_name',
  PAYMENT_TYPE = 'payment_type',
  USER_EMAIL = 'user_email',
  LOCALE = 'locale',
  ORDER_ID = 'order_id',
  USER_INFO = 'user_info',
  TEMP_USER_ID = 'temp_user_id',
  LMS_TOKEN = 'lms_token'
}

export enum API_ROUTES {
  LOGIN = '/connect/token',
  REGISTER = '/api/Users/SkillwillStudents/self-register',
  RESEND_VERIFICATION_EMAIL = '/api/account/resend-confirm-email',
  READ_WELCOME_MESSAGE = '/api/UserInfo/ReadWelcomeMessage',
  VERIFY_EMAIL = '/api/account/confirm-email',
  RESET_PASSWORD = '/api/account/reset-password',
  RESET_PASSWORD_CONFIRM = '/api/account/set-password',
  SYLLABUS = '/api/month',
  USER_INFO = 'api/UserInfo/SkillwillUserInfo',
  USER_AVATAR = 'api/userInfo/UpdateUserImage',
  COURSES = 'api/Courses/',
  CHANGE_PASSWORD = 'api/account/change-password',
  CITIES = 'api/city',
  TRANSLATIONS = 'api/translate/getValues',
  BUY_COURSE = '/api/payments/payment-url',
  BOG_INSTALLMENT = '/api/payments/bog-installment-url',
  TBC_INSTALLMENT = '/api/payments/tbc-installment-url',
  SOLO = 'api/payments/bog/solo/payment-url',
  SOLO_STATUS = 'api/payments/bog/solo/payment/details',
  STUDENT_CARD = 'api/payments/bog/student/payment-url',
  STUDENT_CARD_STATUS = 'api/payments/bog/student/payment/details',
  CALENDAR = 'api/calendar',
  UPCOMING_EVENTS = 'api/upcoming-events',
  ALL_COURSES = 'api/courses/GetAllDetailed',
  ALL_SUB_COURSES = 'api/subCourses/GetAllDetailed',
  O_AUTH = 'api/oauth',
  CALENDAR_SYNC = 'api/oauth/gmail/callback',
  COURSE_DETAILS = 'api/month',
  SUBJECT_STATUS = 'api/subject/done',
  REGISTER_USER_ON_SUBGROUP = 'api/SubGroups/AddUserToSubGroup',
  SPECIFIC_SUBCOURSES = 'api/subcourses/GetAllDetailed',
  GET_INVOICE = 'api/Account/DownloadInvoice',
  UPLOAD_RATING = 'api/kahoot-scores-import',
  KAHOOT_RATING = '/api/kahoot-scores',
  SUBJECT_DONE = 'api/subject/done',
  WEEK_VISIBILITY = 'api/group-subject',
  SUBJECT_DETAILS = 'api/subjects',
  SUBJECT_FILES = 'api/files',
  SUBJECT_MONTH = 'api/month',
  SUBJECT_HOMEWORKS = 'api/homeworks',
  SUBJECT_ASSIGNMENTS = 'api/assignments',
  PROGRESS = 'api/progress-bar',
  CURRENT_SUBJECT = 'api/closest-subject',
  BECOME_TUTOR = 'api/tutor',
  BECOME_PARTNER = 'api/partner',
  COPY_SYLLABUS = 'api/copy-syllabus',
  SUBJECT_WEEK = 'api/weeks',
  CAREER_CARE = 'api/contents',
  CAREER_PUBLISH = 'api/contents/publish',
  COURSE_CONTENT = 'api/contents/courses',
  UPLOAD_IMAGE = 'api/contents/images',
  NEWS = 'api/news',
  BLOGS = 'api/blogs',
  TAGS = 'api/tags',
  SIMILAR_POSTS = 'api/similarPosts',
  PROMO_STATUS = 'api/PromoCode/GetPromoCodeDetails',
  PROMO_COURSE = 'api/PromoCode/GetPromoCodeCourseIds',
  CRITERIA = 'api/evaluation-criteria',
  CRITERIA_LIST = 'api/evaluation-criteria/list'
}

export enum LOCALES {
  GE = 'ge',
  EN = 'en',
  AZ = 'az'
}

export enum USER_ROLES {
  ADMIN = 'Admin',
  STUDENT = 'SkillwillStudent'
}

export enum COURSE_STATUS {
  GENERAL = 'general',
  SPECIFIC = 'specific'
}

export enum SUBJECT_FILE_STATUS {
  POST = 'post',
  PRE = 'pre'
}

export enum QUERY_KEYS {
  PROFILE = 'profile',
  COURSES = 'courses',
  SUBCOURSES = 'subCourses',
  TRANSLATIONS = 'translations',
  SYLLABUS = 'syllabus',
  DONE_SUBJECTS = 'done-subjects',
  RATING = 'rating',
  INVOICE_PDF = 'invoice-pdf',
  EVENTS = 'events',
  UPCOMING_EVENTS = 'upcoming-events',
  PROGRESS = 'progress',
  SUBJECTS = 'subjects',
  CURRENT = 'current-subject',
  ASSIGNMENT = 'assignment',
  CAREER_CONTENT = 'career-content',
  CAREER_CONTENT_DETAIL = 'career-content-detail',
  SOLO_STATUS = 'solo-course-status',
  STUDENT_CARD_STATUS = 'student-card-course-status',
  NEWS = 'news',
  BLOGS = 'blogs',
  NEWS_DETAILS = 'news_details',
  PROMO_DETAILS = 'promo-details',
  PROMO_COURSES = 'promo-courses',
  TAGS = 'tags_list',
  CAREER_ITEM = 'career-content',
  CRITERIA = 'criteria'
}

export enum NewDetailsEnum {
  BLOGS = 'blogs',
  NEWS = 'news'
}
