import React, { useState, FormEvent, FC } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import useAddSubject from 'src/hooks/mutations/useAddSubject';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

interface Props {
  weekId: number;
  close: () => void;
}

const NewSubject: FC<Props> = ({ weekId, close }) => {
  const { t } = useTranslation();
  const [titleEn, setTitleEn] = useState('');
  const [titleKa, setTitleKa] = useState('');
  const [position, setPosition] = useState('');

  const { mutate, isLoading } = useAddSubject();

  const handleAddSubject = (e: FormEvent) => {
    e.preventDefault();
    mutate(
      {
        week_id: weekId,
        en: {
          title: titleEn
        },
        ka: {
          title: titleKa
        },
        position: Number(position)
      },
      {
        onSuccess: () => close()
      }
    );
  };

  return (
    <form onSubmit={handleAddSubject}>
      <Grid container spacing={2} py={2}>
        <Grid item xs={6}>
          <TextField
            required
            type="text"
            value={titleKa}
            label={t('labels.titleGe')}
            onChange={(e) => setTitleKa(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            type="text"
            value={titleEn}
            label={t('labels.titleEn')}
            onChange={(e) => setTitleEn(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            type="number"
            value={position}
            label={t('labels.position')}
            onChange={(e) => setPosition(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} mt={1} mb={2} display="flex" justifyContent="flex-end">
          <Button variant="primary" type="submit" disabled={isLoading}>
            <Typography textTransform="uppercase">{t('button.submit')}</Typography>
          </Button>
          <Button variant="secondary" sx={{ marginLeft: '8px', height: '46px' }}>
            <Typography textTransform="uppercase" onClick={close}>
              {t('button.cancel')}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default NewSubject;
