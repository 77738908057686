import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddTagForm from 'src/components/UI/forms/AddTagForm';
import Loader from 'src/components/common/Loader';
import AlertModal from 'src/components/modals/AlertModal';
import { useAuth } from 'src/context/authContext';
import useTags from 'src/hooks/queries/useTags';
import useToggle from 'src/hooks/useToggle';
import { INewsTag } from 'src/models/service';
import palette from 'src/theme/overrides/palette';
import { ReactComponent as AssignTrash } from 'src/assets/icons/assign-trash.svg';
import { TagTrashBox } from './styles';
import useRemoveTag from 'src/hooks/mutations/useRemoveTag';

const TagTab: React.FC = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useTags();
  const { isAdmin } = useAuth();
  const { mutate } = useRemoveTag();
  const [showAlertModal, setShowAlertModal] = useToggle();
  const [id, setId] = useState(0);

  const deleteTag = () => {
    mutate({ id: `${id}` });
    setShowAlertModal();
  };

  if (isLoading) {
    return (
      <Box height={'75vh'}>
        <Loader />
      </Box>
    );
  }

  return (
    <>
      <AlertModal
        open={showAlertModal}
        close={setShowAlertModal}
        onConfirm={deleteTag}
        confirmationText={t('modal.removeTag')}
      />

      <Grid container justifyContent="center" maxWidth="1920px" margin="auto">
        <Grid
          item
          xs={12}
          marginTop={{ xs: 3, m: 5, l: 6 }}
          marginBottom={{ xs: 4, l: 6 }}
          paddingX={{ xs: 2, sm: 10, l: 18 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height={{ xs: 56, mb2: '64px' }}
            paddingLeft={{ xs: '16px', mb2: '135px' }}
            paddingRight={{ xs: '16px', mb2: '135px', m: 0 }}
            maxWidth="1632px"
            margin="auto"
            sx={{
              borderTopRightRadius: 8,
              borderTopLeftRadius: 8
            }}
            bgcolor="black">
            <Box
              width={{ xs: '100%', m: 'auto' }}
              display="flex"
              gap={{ sx: 2, sm: 8 }}
              alignItems="center"
              zIndex={100}
              justifyContent="space-between">
              <Typography color={palette.white} fontSize={{ sx: 16, sm: 24 }}>
                {t('labels.tags')}
              </Typography>
            </Box>
          </Box>

          <Box
            padding={{ xs: '16px', mb2: '48px' }}
            textAlign={{ xs: 'center', md: 'start' }}
            sx={{
              border: '1px solid #DEDEDE',
              borderBottomRightRadius: 8,
              borderBottomLeftRadius: 8
            }}>
            <Grid container spacing={1} justifyContent={{ xs: 'center', md: 'flex-start' }}>
              <Grid item xs={6} borderRight={'2px solid #DEDEDE'}>
                {isAdmin && data?.tags?.length === 0 && (
                  <Box textAlign={'center'} width={'100%'} mt={2}>
                    <Typography fontSize={{ sx: 16, sm: 24 }}>{t('labels.noHomework')}</Typography>
                  </Box>
                )}

                <Grid gap={1} display={'flex'} flexWrap={'wrap'}>
                  {isAdmin &&
                    data &&
                    data.tags?.map((tag: INewsTag) => {
                      return (
                        <Grid
                          item
                          width={'48%'}
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                          key={tag.id}
                          paddingY={2}
                          paddingLeft={3}
                          paddingRight={2}
                          borderRadius={2}
                          sx={{
                            background: '#eeeeee'
                          }}>
                          <Typography fontSize={{ sx: 16, sm: 18 }}># {tag.name}</Typography>

                          {isAdmin && (
                            <TagTrashBox
                              onClick={(e) => {
                                e.stopPropagation();
                                setId(tag!.id!);
                                setShowAlertModal();
                              }}>
                              <AssignTrash />
                            </TagTrashBox>
                          )}
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>

              <Grid item xs={6} display={'flex'} justifyContent={'center'}>
                <AddTagForm />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default TagTab;
