import { useQuery } from '@tanstack/react-query';
import { getNewsDetails } from 'src/services/api';
import { NewDetailsEnum, QUERY_KEYS } from 'src/enums';
import { NewsDetailsResponse } from 'src/models/service';

const useNewsDetails = (id: number, type: NewDetailsEnum) => {
  const { data, isLoading } = useQuery<NewsDetailsResponse>([QUERY_KEYS.NEWS_DETAILS], () =>
    getNewsDetails(id, type)
  );

  return {
    data,
    isLoading
  };
};

export default useNewsDetails;
