import { QUERY_KEYS } from 'src/enums';
import { useQuery } from '@tanstack/react-query';
import { getTags } from 'src/services/api';
import { TagsResponse } from 'src/models/service';

const useTags = () => {
  const { data, isLoading } = useQuery<TagsResponse>([QUERY_KEYS.TAGS], () => getTags());

  return {
    data,
    isLoading
  };
};

export default useTags;
