import { Card, CardContent, Grid } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AssignCard from 'src/components/common/assignCard';
import { Homework } from 'src/models/service';
import { useLanguage } from 'src/context/languageContext';
import useCourse from 'src/hooks/queries/useCourse';
import { useAuth } from 'src/context/authContext';
import { Box, Typography } from '@mui/material';
import useHomeWork from 'src/hooks/queries/useHomeWork';
import useDeleteHomework from 'src/hooks/mutations/useDeleteHomework';
import useToggle from 'src/hooks/useToggle';
import AlertModal from 'src/components/modals/AlertModal';
import AuthWrapper from 'src/components/layouts/AuthWrapper';
import { CardHeaderBlack, StyledCardContent } from '../courseDetails/styles';
import KahootRating from 'src/components/UI/lists/KahootRating';
import ProgressBar from 'src/components/common/progressBar';
import RankingStatus from 'src/components/UI/rankingStatus';
import useProgress from 'src/hooks/queries/useProgress';
import useRating from 'src/hooks/queries/useRating';
import useProfile from 'src/hooks/queries/useProfile';
import Loader from 'src/components/common/Loader';

const AssignmentList = () => {
  const { t } = useTranslation();
  const { courseId } = useParams();
  const { myCourses = [] } = useProfile();
  const { locale } = useLanguage();
  const { data: course } = useCourse(courseId!);
  const { isAdmin } = useAuth();
  const [id, setId] = useState<number>();
  const { data, isLoading } = useHomeWork(courseId!);
  const { mutate } = useDeleteHomework();
  const [showAlertModal, setShowAlertModal] = useToggle();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const crsId = pathname.split('/')[3];

  const groupId = myCourses?.find((crs) => String(crs.courseId) === crsId)?.groupId;

  const { progress } = useProgress({
    chapter_id: crsId
  });

  const { scores } = useRating({ group_id: groupId });

  const { homeworks } = data || {};

  const deleteHomework = () => {
    mutate({ id: `${id}` });
    setShowAlertModal();
  };

  return (
    <AuthWrapper isAssignment userPage={!isAdmin}>
      <AlertModal
        open={showAlertModal}
        close={setShowAlertModal}
        onConfirm={deleteHomework}
        confirmationText={t('modal.deleteAssignment')}
      />

      <Grid container justifyContent="space-between" pb={12} mb={8}>
        <Grid item xs={12} display={{ sm: 'none', xs: 'block' }} mt={-2}>
          <Typography fontWeight="bold" fontSize={24} fontFamily="basenji-variable">
            / {course?.name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={isAdmin ? 12 : 8.8} mt={{ sm: 0, xs: 2 }}>
          <Box
            display="flex"
            bgcolor="black"
            alignItems="center"
            pl={{ sm: 4, xs: 2 }}
            height={{ sm: 64, xs: 51 }}
            justifyContent="space-between"
            sx={{
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10
            }}>
            <Box minHeight="24px">
              {course?.name && (
                <Typography fontSize={20} color="white" fontWeight={700}>
                  {locale === 'ge' && course?.name + '- ის '}
                  {locale === 'en' && course?.name + ' '}
                  {t('labels.chooseHomework')}
                </Typography>
              )}
            </Box>
          </Box>

          <Box
            padding="24px"
            textAlign={{ sm: 'center', md: 'start' }}
            sx={{
              border: '1px solid #DEDEDE',
              borderBottomRightRadius: 8,
              borderBottomLeftRadius: 8,
              background: '#fff'
            }}>
            {isLoading && (
              <Grid minHeight="400px" container alignItems="center">
                <Loader />
              </Grid>
            )}
            {!isLoading && (
              <Grid container spacing={1} justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                {isAdmin && (
                  <Grid
                    item
                    width={{ xs: '100%', mb2: '100%', mb3: 250 }}
                    height={{ xs: 270, mb2: 350 }}
                    position="relative">
                    <AssignCard
                      setShowAlertModal={setShowAlertModal}
                      setId={setId}
                      isAdmin={isAdmin}
                      isTemplate={true}
                      clickHandler={() => {
                        navigate(`/${locale}/assignments/add-homework/${course?.id}`);
                      }}
                    />
                  </Grid>
                )}
                {!isAdmin && homeworks?.length === 0 && (
                  <Box textAlign={'center'} width={'100%'} mt={2}>
                    <Typography fontSize={{ sx: 16, sm: 24 }}>{t('labels.noHomework')}</Typography>
                  </Box>
                )}
                {homeworks?.map((homework: Homework) => {
                  return (
                    <Grid item key={homework.id} width={{ xs: '100%', mb2: '100%', mb3: 250 }}>
                      <AssignCard
                        setShowAlertModal={setShowAlertModal}
                        setId={setId}
                        homework={homework}
                        isAdmin={isAdmin}
                        recievedLink={`/${locale}/assignments/homework/${homework.id}?course=${courseId}&section=recieved`}
                        clickHandler={() => {
                          navigate(
                            `/${locale}/assignments/homework/${homework.id}?course=${courseId}&section=edit`
                          );
                        }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Box>
        </Grid>
        <Grid item display={{ xs: 'none', sm: 'block' }} sm={2.8}>
          <Card variant="outlined" sx={{ background: '#f8f8f8 !important' }}>
            {!isAdmin && (
              <>
                <CardHeaderBlack sx={{ height: '64px' }}>
                  <Box width="100%">
                    <Typography fontSize={20} color="white" fontWeight={700}>
                      {t('course.kahoot')}
                    </Typography>
                  </Box>
                </CardHeaderBlack>
                <StyledCardContent>
                  <Box padding="24px 24px 24px 16px">
                    <Box>
                      <KahootRating list={scores} />
                    </Box>
                  </Box>
                </StyledCardContent>
                <RankingStatus list={scores} />
                <StyledCardContent>
                  <CardContent
                    sx={{
                      border: 'none !important',
                      marginTop: '24px',
                      borderRadius: '16px'
                    }}>
                    <CardHeaderBlack sx={{ height: '64px' }}>
                      <Box width="100%">
                        <Typography fontSize={20} color="white" fontWeight={700}>
                          {t('course.progress')}
                        </Typography>
                      </Box>
                    </CardHeaderBlack>
                    <Box
                      pb={4}
                      display="flex"
                      justifyContent="center"
                      marginTop={{ xs: 3, l: 5 }}
                      marginBottom={{ xs: 4, l: 8 }}>
                      <ProgressBar progress={progress} />
                    </Box>
                  </CardContent>
                </StyledCardContent>
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

export default AssignmentList;
