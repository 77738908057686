import React from 'react';
import { COURSE_STATUS } from 'src/enums';
import CourseDetails from '../courseDetails';
import Loader from 'src/components/common/Loader';
import useRating from 'src/hooks/queries/useRating';
import useProfile from 'src/hooks/queries/useProfile';
import useSyllabus from 'src/hooks/queries/useSyllabus';

const GeneralCourse = () => {
  const { myCourses = [] } = useProfile();
  const { groupId = 0 } = myCourses[0] || {};

  const { data, isLoading } = useSyllabus({
    status: COURSE_STATUS.GENERAL
  });

  const { scores } = useRating({
    group_id: groupId
  });

  if (isLoading) {
    return <Loader />;
  }

  return <CourseDetails months={data} rating={scores} />;
};

export default GeneralCourse;
