import React, { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import { SUBJECT_FILE_STATUS } from 'src/enums';
import { useTranslation } from 'react-i18next';
import { SubjectFile } from 'src/models/general';
import CloseIcon from '@mui/icons-material/Close';
import useLanguageCode from 'src/hooks/useLanguageCode';
import useEditFile from 'src/hooks/mutations/useEditFile';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import {
  Box,
  Grid,
  Button,
  Dialog,
  Select,
  MenuItem,
  TextField,
  Typography,
  FormHelperText,
  FormControl,
  InputLabel
} from '@mui/material';
import useCategories from 'src/hooks/queries/useCategories';

interface EditFileModalProps {
  open: boolean;
  close: () => void;
  file: SubjectFile;
}

interface FormData {
  title: string;
  url: string;
  status: SUBJECT_FILE_STATUS;
  description?: string;
  estimation?: string;
  attachment?: string | Blob;
  category: any;
}

const EditFileModal = ({ open, close, file }: EditFileModalProps) => {
  const { t } = useTranslation();
  const { lang } = useLanguageCode();
  const { mutate: editSubject, isError } = useEditFile();
  const [internalError, setInternalError] = useState(false);

  const [fileUploaded, setFileUploaded] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const description = draftToHtml(convertToRaw(editorState.getCurrentContent()));

  const { categories } = useCategories();

  useEffect(() => {
    const blocksFromHTML = convertFromHTML(file?.description || '');
    const initialEditorState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );

    setEditorState(EditorState.createWithContent(initialEditorState));
  }, [file?.description]);

  useEffect(() => {
    if (file?.attachment) setFileUploaded(true);
    else setFileUploaded(false);
  }, [file?.attachment]);

  useEffect(() => {
    return () => setInternalError(false);
  }, [close]);

  const onEditorStateChange = (editorState: EditorState) => setEditorState(editorState);
  const hasText = editorState.getCurrentContent().hasText();

  const formik = useFormik<FormData>({
    initialValues: {
      title: file?.title,
      url: file?.url,
      status: file?.status,
      description: file?.description,
      attachment: file?.attachment,
      category: file?.evaluation_criteria?.[0].id
    },
    onSubmit: async ({ status, title, url, attachment = '', estimation, category }) => {
      if ((url && hasText) || (hasText && attachment) || (url && attachment)) {
        setInternalError(true);
        return;
      }

      if (!file) return;

      const isFileEdited = file.attachment !== attachment;
      const formData = new FormData();

      formData.append(`_method`, 'PUT');
      formData.append(`status`, status);
      if (url) formData.append(`url`, url);
      formData.append(`${lang}[title]`, title);
      if (estimation) formData.append(`estimate_time`, estimation);
      if (isFileEdited) formData.append(`attachment`, attachment || '');
      formData.append(`evaluation_criteria[0]`, category);
      if (editorState.getCurrentContent().hasText()) formData.append(`description`, description);

      editSubject({ fileId: file.id, payload: formData }, { onSuccess: () => close() });
    },
    enableReinitialize: true
  });

  return (
    <Dialog
      open={open}
      onClose={close}
      PaperProps={{
        style: { maxWidth: '680px' }
      }}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container justifyContent="center" rowSpacing={2} px={10} py={5}>
          <Grid item xs={12} textAlign="center">
            <Typography variant="subtitle1" sx={{ fontFeatureSettings: "'case' on" }}>
              {t('modal.editFile')}
            </Typography>
          </Grid>
          <Grid item xs={12} mt={2}>
            <TextField
              name="title"
              type="text"
              variant="outlined"
              label={t('labels.title')}
              onChange={formik.handleChange}
              defaultValue={formik.values.title}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="text"
              variant="outlined"
              name="url"
              label={t('labels.url')}
              onChange={formik.handleChange}
              defaultValue={formik.values.url}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <InputLabel>{t('labels.category')}</InputLabel>
              <Select
                name="category"
                value={formik.values.category}
                variant="outlined"
                label={t('labels.category')}
                onChange={formik.handleChange}>
                {categories?.map((el: any) => {
                  return (
                    <MenuItem key={el} value={el.id}>
                      {el.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Select
              variant="outlined"
              name="status"
              onChange={formik.handleChange}
              defaultValue={formik.values.status}>
              {(Object.keys(SUBJECT_FILE_STATUS) as Array<keyof typeof SUBJECT_FILE_STATUS>).map(
                (type) => (
                  <MenuItem value={SUBJECT_FILE_STATUS[type]} key={SUBJECT_FILE_STATUS[type]}>
                    {type}
                  </MenuItem>
                )
              )}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="text"
              name="estimation"
              variant="outlined"
              label={t('labels.estimation')}
              onChange={formik.handleChange}
              value={formik.values.estimation}
              defaultValue={file?.estimate_time}
              helperText={'Format: 1m, 2h, 3d...'}
            />
          </Grid>
          <Grid item xs={12} my={1} ml={0.5}>
            <Box display="flex" alignItems="center" gap={1}>
              <label htmlFor="attachment">
                <CloudUploadIcon sx={{ cursor: 'pointer' }} />
              </label>
              <Typography variant="listItem" fontSize={16} color="black">
                {fileUploaded ? t('messages.fileSelected') : t('button.upFile')}
              </Typography>
              {fileUploaded && (
                <CloseIcon
                  fontSize="small"
                  sx={{ cursor: 'pointer', marginTop: '-5px' }}
                  onClick={() => {
                    formik.setFieldValue('attachment', null);
                    setFileUploaded(false);
                  }}
                />
              )}
            </Box>
            <input
              hidden
              type="file"
              id="attachment"
              onChange={(e) => {
                if (!e.target.files) return;
                formik.setFieldValue('attachment', e.target.files[0]);
                setFileUploaded(true);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ border: '1px solid #e4e4e4' }}>
              <Editor
                editorState={editorState}
                editorClassName="demo-editor"
                wrapperClassName="demo-wrapper"
                editorStyle={{ paddingLeft: '15px' }}
                onEditorStateChange={onEditorStateChange}
              />
              <textarea disabled hidden value={description} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            {isError && <FormHelperText error>{t('errors.networkError')}</FormHelperText>}
            {internalError && (
              <FormHelperText error>
                გთხოვთ, აირჩიოთ ერთ-ერთი ველი არსებული ველებიდან
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} mt={3}>
            <Grid container justifyContent="flex-end" columnSpacing={1}>
              <Grid item>
                <Button variant="primary" size="large" type="submit">
                  <Typography textTransform="uppercase">{t('common.save')}</Typography>
                </Button>
              </Grid>
              <Grid item>
                <Button fullWidth variant="secondary" size="medium" onClick={close}>
                  <Typography textTransform="uppercase">{t('modal.cancel')}</Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

export default EditFileModal;
