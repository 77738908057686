import React, { FC } from 'react';
import { Dialog, Typography } from '@mui/material';
import { Container } from './styles';

interface ToastProps {
  open: boolean;
  close: () => void;
  confirmationText: string;
}

const Toast: FC<ToastProps> = ({ open, close, confirmationText }) => {
  return (
    <Dialog open={open} onClose={close}>
      <Container>
        <Typography variant="caption" fontSize={{ xs: '16px', mb3: '20px' }}>
          {confirmationText}
        </Typography>
      </Container>
    </Dialog>
  );
};

export default Toast;
