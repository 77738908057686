import styled from '@mui/system/styled';
import CircularProgress from '@mui/material/CircularProgress';

export const CircularProgressBar = styled(CircularProgress)(({ theme }) => ({
  '& circle': {
    strokeLinecap: 'round',
    color: '#CDDB00'
  },
  '&.bottom-circle': {
    position: 'absolute',
    color: 'white',
    '& svg': {
      filter: `drop-shadow(0px 3px 6px ${theme.palette.headerShadow})`,
      '& circle': {
        opacity: 0.1,
        fill: 'white'
      }
    }
  }
}));
