import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, ModalActions } from './styles';
import { Button, Dialog, Typography } from '@mui/material';

interface AlertModalProps {
  open: boolean;
  close: () => void;
  confirmationText: string;
  onConfirm: (e?: React.MouseEvent) => void;
}

const AlertModal: FC<AlertModalProps> = ({ open, close, onConfirm, confirmationText }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={close}
      PaperProps={{
        style: { borderRadius: '16px' }
      }}>
      <Container>
        <Typography
          lineHeight="32px"
          textTransform="uppercase"
          fontSize={{ sm: 24, xs: 20 }}
          sx={{ fontFeatureSettings: "'case' on" }}>
          {confirmationText}
        </Typography>
        <ModalActions>
          <Button onClick={onConfirm} variant="primary" sx={{ width: '100%' }}>
            <Typography>{t('common.yes')}</Typography>
          </Button>
          <Button onClick={close} variant="secondary" sx={{ width: '100%' }}>
            <Typography>{t('common.no')}</Typography>
          </Button>
        </ModalActions>
      </Container>
    </Dialog>
  );
};

export default AlertModal;
